import React, { useState } from 'react';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline';

export default function NewPasswordInput({ inputId, passwordRef, setError }) {
    const [viewPassword, setViewPassword] = useState(false);
    const handleViewPassword = () => {
        const passwordInput = document.getElementById(`${inputId}`);
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            setViewPassword(true);
        } else {
            passwordInput.type = 'password';
            setViewPassword(false);
        }
    };
    return (
        <div className='sm:grid sm:grid-cols-3 sm:items-center sm:border-t sm:border-gray-200 sm:py-5'>
            <label htmlFor={inputId} className='block text-sm font-medium text-gray-700'>
                New Password
            </label>
            <div className='mt-1 sm:col-span-2 sm:mt-0 relative'>
                <input
                    ref={passwordRef}
                    onFocus={() => setError('')}
                    onBlur={() => setError('')}
                    id={inputId}
                    name={inputId}
                    type='password'
                    autoComplete='current-password'
                    required
                    className='border block h-9 w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-sm'
                />
                {!viewPassword ? (
                    <EyeSlashIcon
                        className='absolute right-0 -top-1 m-3 h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500 '
                        onClick={() => {
                            handleViewPassword();
                        }}
                    />
                ) : (
                    <EyeIcon
                        className='absolute right-0 -top-1 m-3 h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500 '
                        onClick={() => {
                            handleViewPassword();
                        }}
                    />
                )}
            </div>
        </div>
    );
}
