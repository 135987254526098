import React, { useState, Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import LoadingButtonFixedWidth from '../../components/LoadingButtonFixedWidth';

export default function MorePartsModal({ openEditModal, setOpenEditModal, handleSubmit, isLoading, setNewPartInput }) {
    const [isShowing, setIsShowing] = useState(false);
    return (
        <Transition.Root show={openEditModal} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={setOpenEditModal}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden transition-all sm:w-full max-w-2xl'>
                                <div>
                                    <div className='mt-3  '>
                                        <div className='px-2 pb-5'>
                                            <form>
                                                <div className='overflow-hidden '>
                                                    <div className='md:w-5/6 mx-auto mt-6 mb-3 pb-12 border-2 bg-gray-50 border-blue-primary shadow-xl  '>
                                                        <div className='relative'>
                                                            <button
                                                                className='text-red-500 rounded-full cursor-pointer bg-white hover:bg-gray-400 w-6 h-6 flex justify-center items-center mt-1 absolute right-1 top-1 mr-2'
                                                                onClick={() => {
                                                                    setOpenEditModal(false);
                                                                }}
                                                            >
                                                                <XMarkIcon className='w-5 h-5' />
                                                            </button>
                                                        </div>
                                                        <h2 className='text-3xl font-semibold py-2 mb-6 px-12 text-center text-white bg-blue-primary'>
                                                            Bulk Parts Search
                                                        </h2>
                                                        <div className='relative'>
                                                            <p className='pl-4 text-gray-600 text-center'>
                                                                Enter a list of part numbers on new lines.{' '}
                                                                <QuestionMarkCircleIcon
                                                                    onMouseEnter={() => setIsShowing(true)}
                                                                    onMouseLeave={() => setIsShowing(false)}
                                                                    className='h-5 w-5 inline-block'
                                                                />
                                                            </p>
                                                            {isShowing && (
                                                                <Transition
                                                                    appear={true}
                                                                    show={isShowing}
                                                                    enter='transition-opacity duration-200'
                                                                    enterFrom='opacity-0'
                                                                    enterTo='opacity-100'
                                                                    leave='transition-opacity duration-150'
                                                                    leaveFrom='opacity-100'
                                                                    leaveTo='opacity-0'
                                                                >
                                                                    <div className='absolute right-0 md:-top-30 md:right-10 bg-blue-primary p-4 w-36 text-lg'>
                                                                        <p className='pl-4  text-white'>
                                                                            Example:
                                                                            <br />
                                                                            12345
                                                                            <br />
                                                                            GQ756-2
                                                                            <br />
                                                                            G700-2
                                                                        </p>
                                                                    </div>
                                                                </Transition>
                                                            )}
                                                        </div>
                                                        <div className='px-12 py-4'>
                                                            <textarea
                                                                onChange={(e) => {
                                                                    setNewPartInput(e.target.value);
                                                                }}
                                                                style={{ resize: 'both' }}
                                                                className='w-full px-3 py-2 text-base text-gray-700 placeholder-gray-400 border rounded-lg focus:shadow-outline resize-y focus:outline-gray-200 '
                                                                placeholder='Enter part numbers here'
                                                                rows={7}
                                                            ></textarea>
                                                        </div>
                                                        {!isLoading ? (
                                                            <button
                                                                className='bg-blue-primary hover:bg-blue-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto cursor-pointer w-1/2'
                                                                onClick={handleSubmit}
                                                            >
                                                                Add More Parts
                                                            </button>
                                                        ) : (
                                                            <LoadingButtonFixedWidth />
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
