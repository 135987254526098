import React from 'react';
import {
    OverridesInput,
    OverridesDropdown,
    OverridesCheckBox,
    OverridesDropdownTextareaPair,
    OverridesTextarea,
} from '../FormInputs';
import { asaAndBoeingCommentDefaults } from './utils/data';

export default function BoeingDocumentForm({
    dynamicallyParseFormData,
    documentDefaults,
    formName,
    showForm,
    conditionCodes,
}) {
    return (
        <form
            onChange={(e) => {
                dynamicallyParseFormData(e, formName);
            }}
            className={
                'w-full h-full flex flex-col justify-between gap-4 px-8 duration-75 ' +
                (showForm ? 'opacity-100' : 'opacity-0 pointer-events-none hidden')
            }
        >
            <section className='w-full flex flex-row gap-4'>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesInput
                        text={'3. Reference #:'}
                        name={'customerPoNumber'}
                        defaultInput={documentDefaults?.boeing?.customerPoNumber}
                    />
                    <OverridesInput
                        text={"5A. Seller's Contract #:"}
                        name={'sellerContractNumber'}
                        defaultInput={documentDefaults?.boeing?.sellerContractNumber}
                    />
                    <OverridesInput
                        text={'6. Item:'}
                        name={'lineNumber'}
                        defaultInput={documentDefaults?.boeing?.lineNumber}
                    />
                    <OverridesInput
                        text={'8. Part Number'}
                        name={'partNumber'}
                        defaultInput={documentDefaults?.boeing?.partNumber}
                    />
                    <OverridesInput
                        text={'10. Serial/Batch #'}
                        name={'serialNumber'}
                        defaultInput={documentDefaults?.boeing?.serialNumber}
                    />
                    <OverridesInput text={'13B PAH'} name={'PAH'} defaultInput={documentDefaults?.boeing?.PAH} />
                    <OverridesCheckBox
                        text={'14A New Parts/Material Verification'}
                        name={'newPartsVerification14A'}
                        defaultInput={documentDefaults?.boeing?.newPartsVerification14A}
                    />
                    <OverridesCheckBox text={'14C New Parts/Material Verification'} name={'newPartsVerification14C'} />
                    <OverridesCheckBox text={'15B Parts procured "AS IS" UNSERVICEABLE'} name={'asIsUnserviceable'} />
                    <OverridesInput text={'Name'} name={'userName'} defaultInput={documentDefaults?.boeing?.userName} />
                    <OverridesDropdownTextareaPair
                        dropdownText={'Select a Remark'}
                        defaultOption={`Current Remark - ${documentDefaults?.boeing?.remarks || 'None'}`}
                        defaultOptionValue={documentDefaults?.boeing?.remarks}
                        selectOptions={asaAndBoeingCommentDefaults}
                        addEmptyOption={true}
                        emptyOptionName='--None--'
                        textareaText={'Edit Remark'}
                        name={'remarks'}
                        textareaDefaultInput={documentDefaults?.boeing?.remarks}
                        canEdit={true}
                    />
                </section>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesDropdown
                        text={'11. Status'}
                        selectOptions={conditionCodes}
                        name={'conditionCodeDescription'}
                        defaultOption={documentDefaults?.boeing?.conditionCodeDescription}
                        defaultSelectedValue={documentDefaults?.boeing?.conditionCodeDescription}
                    />
                    <OverridesInput
                        text={"5B. Buyer's PO #:"}
                        name={'customerPoNumber'}
                        defaultInput={documentDefaults?.boeing?.customerPoNumber}
                    />
                    <OverridesInput
                        text={'7. Description:'}
                        name={'partDescription'}
                        defaultInput={documentDefaults?.boeing?.partDescription}
                    />
                    <OverridesInput
                        text={'9. Qty'}
                        name={'partQuantity'}
                        defaultInput={documentDefaults?.boeing?.partQuantity}
                    />
                    <OverridesInput
                        text={'13A. Obtained From:'}
                        name={'obtainedFrom'}
                        defaultInput={documentDefaults?.boeing?.obtainedFrom}
                    />
                    <OverridesInput
                        text={'113C. Last Certificated Agency:'}
                        name={'lastCertifiedAgency'}
                        defaultInput={documentDefaults?.boeing?.lastCertifiedAgency}
                    />
                    <OverridesCheckBox text={'14B New Parts/Material Verification.'} name={'newPartsVerification14B'} />
                    <OverridesCheckBox
                        text={'15A USED, REPAIRED, NEW SURPLUS, INSPECTED, MODIFIED, TESTED ETC.'}
                        name={'usedConditionCheck'}
                        defaultInput={documentDefaults?.boeing?.newPartsVerification14B}
                    />
                    <OverridesInput text={'Date'} name={'date'} defaultInput={documentDefaults?.boeing?.date} />
                    <OverridesTextarea
                        text={'Organization Address'}
                        name={'address'}
                        defaultInput={documentDefaults?.form8130?.address}
                    />
                </section>
            </section>
        </form>
    );
}
