import React, { useEffect, useState, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { formatAsCurrency } from '../../utils/formatAsCurrency';

import { calculatePartsTotal, calculateOrderTotal } from '../../utils/calculateFee';

export default function FeeTable({
    order,
    orderDetails,
    isDarOrder,
    setIsDarOrder,
    setOrderSubtotal,
    orderSubtotal,
    isShowing,
}) {
    const [activeDarFees, setActiveDarFees] = useState(0);
    const [partsTotal, setPartsTotal] = useState(0);

    useEffect(() => {
        setIsDarOrder(false);
        order.map((item) => {
            if (item.isDar) {
                setIsDarOrder(true);
            }
        });
    }, [order]);

    useEffect(() => {
        calculatePartsTotal(order)
            .then((total) => {
                setPartsTotal(total);
            })
            .catch((err) => console.error(err));
    }, [order]);

    useEffect(() => {
        calculateOrderTotal(order, orderDetails)
            .then((total) => {
                setOrderSubtotal(total);
            })
            .catch((err) => console.error(err));
    }, [order]);

    const calculateDarFees = () => {
        let darItems = 0;
        order.map((item) => {
            if (item.isDar) {
                darItems++;
            }
        });

        if (darItems > 0) {
            let darFee = darItems * orderDetails.DARFee;

            return darFee;
        }
        return 0;
    };

    useEffect(() => {
        calculateDarFees();
        setActiveDarFees(calculateDarFees());
    }, [order]);

    return (
        <div className=''>
            <Transition
                as={Fragment}
                show={isShowing}
                enter='transform transition duration-[00ms]'
                enterFrom='opacity-0 scale-50'
                enterTo='opacity-100  scale-100'
                leave='transform duration-75 transition ease-in'
                leaveFrom='opacity-100 scale-100 '
                leaveTo='opacity-0 scale-100 '
            >
                <div>
                    <div className='w-full flex justify-end pt-4'>
                        <table className='table-auto w-1/3 mb-6'>
                            <thead>
                                <tr>
                                    <th className='border px-4 py-2'>Fee</th>
                                    <th className='border px-4 py-2'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=''>
                                    <td className='border px-4 py-2'>Parts total</td>
                                    <td className='border px-4 py-2'>{formatAsCurrency(partsTotal.toFixed(2))}</td>
                                </tr>

                                {isDarOrder && (
                                    <tr className=''>
                                        <td className='border px-4 py-2'>DAR Fee</td>
                                        <td className='border px-4 py-2'>{formatAsCurrency(orderDetails.totalDarFee)}</td>
                                    </tr>
                                )}

                                {orderDetails.isAOG ? (
                                    <>
                                        <tr className=''>
                                            <td className='border px-4 py-2'>AOG Fee (Courier Only)</td>
                                            <td className='border px-4 py-2'>{formatAsCurrency(orderDetails.AOGFee)}</td>
                                        </tr>
                                    </>
                                ) : null}
                                {orderDetails.totalMinOrderFee >= orderDetails.minOrder && isShowing && (
                                    <tr className=''>
                                        <td className='border px-4 py-2'>
                                            Minimum Order Surcharge{' '}
                                            {orderDetails.totalMinOrderFee - partsTotal > orderDetails.minOrder
                                                ? '(AOG)'
                                                : ''}
                                        </td>
                                        <td className='border px-4 py-2'>
                                            {formatAsCurrency(orderDetails.totalMinOrderFee - partsTotal.toFixed(2))}
                                        </td>
                                    </tr>
                                )}

                                <tr className='bg-gray-100'>
                                    <td className='border px-4 py-2 font-semibold'>Sub Total</td>
                                    <td className='border px-4 py-2 font-semibold'>
                                        {formatAsCurrency(orderSubtotal.toFixed(2))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Transition>
        </div>
    );
}
