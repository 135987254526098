import React from 'react';

export default function HolderHeader({ text, showMore, setShowMore, hasMore }) {
    return (
        <div className='flex items-center justify-between w-full py-2 text-start px-3 bg-gray-100 rounded-t-lg'>
            <h1 className='text-2xl text-gray-600 '>{text}</h1>
            {hasMore ? (
                <button
                    className='border-none text-blue-400 underline bg-transparent text-lg hover:cursor-pointer hover:text-blue-500'
                    onClick={() => {
                        setShowMore((state) => !state);
                    }}
                    style={{
                        all: 'revert',
                    }}
                >
                    {'Show ' + (showMore ? 'Less -' : 'More +')}
                </button>
            ) : (
                <></>
            )}
        </div>
    );
}
