import React, { useEffect, useRef, useState } from 'react';
import { CameraIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { maxCameraHeightResolution, maxCameraWidthResolution } from '../Camera/data';
import { getCameraHeight, getCameraWidth, detectMobile } from '../Camera/functions';
import MobileCameraButtonWrapper from '../Camera/MobileCameraButton';

export default function PackingCamera({ photoUpload }) {
    const canvasRef = useRef(null);
    const videoRef = useRef(null);

    const [videoStream, setVideoStream] = useState(null);
    const [cameras, setCameras] = useState([]);
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const isMobile = detectMobile();

    useEffect(() => {
        getAvailableCameras();
        startCamera();
    }, []);

    useEffect(() => {
        if (cameras.length > 0) {
            startCamera();
        }
    }, [currentCameraIndex, cameras]);

    async function startCamera() {
        if (videoRef?.current?.srcObject) {
            // Stop all tracks of the current stream
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach((track) => track.stop());

            // Pause the video element before changing the source
            videoRef.current.pause();
            videoRef.current.srcObject = null; // Clear the current source object
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    focusMode: 'continuous',
                    deviceId: cameras[currentCameraIndex]?.deviceId,
                    width: { ideal: maxCameraWidthResolution },
                    height: { ideal: maxCameraHeightResolution },
                },
            });

            setVideoStream(stream);
            videoRef.current.srcObject = stream;

            // Wait for the metadata to be loaded before playing
            videoRef.current.onloadedmetadata = () => {
                videoRef.current.play();
            };
        } catch (err) {
            console.error('Failed to start the camera:', err);
        }
    }

    function handleTakePhoto() {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, getCameraWidth(videoStream), getCameraHeight(videoStream));
        const dataUri = canvasRef.current.toDataURL('image/png');
        photoUpload({ dataUri });
    }

    function handleMobilePhotoUpload(dataUri) {
        photoUpload({ dataUri });
    }

    async function getAvailableCameras() {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');
            setCameras(videoDevices);
        } catch (err) {
            console.error('Failed to get cameras:', err);
        }
    }

    function switchCamera() {
        if (cameras.length > 1) {
            const nextIndex = (currentCameraIndex + 1) % cameras.length;
            setCurrentCameraIndex(nextIndex);
        }
    }

    if (!videoStream) {
        return null;
    }

    return (
        <>
            {isMobile ? (
                <MobileCameraButtonWrapper uploadPhoto={handleMobilePhotoUpload} />
            ) : (
                <div className='flex justify-center'>
                    <div className='flex flex-col items-center justify-center' style={{ height: '400px' }}>
                        <video className='mx-auto' ref={videoRef} width='550' height='300'></video>
                        <canvas
                            ref={canvasRef}
                            style={{ display: 'none' }}
                            width={getCameraWidth(videoStream)}
                            height={getCameraHeight(videoStream)}
                        ></canvas>
                        <div className='flex justify-center my-4'>
                            <div className='flex items-center'>
                                {cameras.length > 1 ? (
                                    <div className='relative group'>
                                        <button
                                            className='bg-green-200 border flex items-center justify-center border-green-600 rounded-l-sm text-green-600 hover:bg-green-600 hover:text-white px-3 py-2'
                                            style={{ height: '45px' }}
                                            onClick={switchCamera}
                                        >
                                            <ArrowPathIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                        <div className='w-24 absolute left-0 transform -translate-x-1/2 top-full mt-1 bg-gray-800 text-white text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                            Switch Camera
                                        </div>
                                    </div>
                                ) : (
                                    <div className='relative group'>
                                        <button
                                            className='bg-gray-300 border flex items-center justify-center border-gray-600 rounded-l-sm text-white px-3 py-2'
                                            style={{ height: '45px' }}
                                            onClick={switchCamera}
                                        >
                                            <ArrowPathIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                        <div className='w-28 absolute left-0 transform -translate-x-1/2 top-full mt-1 bg-gray-800 text-white text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                            Cannot switch cameras
                                        </div>
                                    </div>
                                )}
                                <button
                                    className='bg-green-200 border flex items-center justify-center border-green-600 rounded-r-sm text-green-600 hover:bg-green-600 hover:text-white px-4 py-2'
                                    style={{ height: '45px' }}
                                    onClick={handleTakePhoto}
                                >
                                    Take Photo <CameraIcon className='h-7 w-7 ml-2' aria-hidden='true' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
