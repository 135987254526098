import config from './config';
import logError from './errorUtil';

export const GetShippingInfo = async (customer) => {
    let ORIGIN = config.origin;
    // ORIGIN = 'https://47.181.195.118';

    const response = await fetch(`${ORIGIN}/cp/shipping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            userId: customer.Id,
            accountId: customer.Account.Id,
        }),
    });

    const data = await response.json();

    Promise.resolve(data);

    return data;
};

export const setShippingDefault = async (customer, shipAddressId) => {
    let ORIGIN = config.origin;

    const response = await fetch(`${ORIGIN}/cp/shipping/update-default-address`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            shipAddressId: shipAddressId,
            accountId: customer.Account.Id,
        }),
    });

    const data = await response.json();

    Promise.resolve(data);

    return data;
};

export const getRfqFilter = async (customer, params) => {
    let ORIGIN = config.origin;

    const now = new Date();
    const offset = now.getTimezoneOffset();

    const response = await fetch(`${ORIGIN}/cp/hx/quotes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            accountId: customer.Account.Id,
            userId: customer.Id,
            pageOffset: params.pageOffset,
            fromDate: params.fromDate,
            toDate: params.toDate,
            searchTerm: params.searchTerm,
            timezoneDifferenceInMinutes: offset,
            sortBy: params.sortBy,
            orderBy: params.orderBy,
        }),
    });

    const data = await response.json();

    Promise.resolve(data);

    return data;
};

export const getSalesOrderFilter = async (customer, params) => {
    let ORIGIN = config.origin;

    const now = new Date();
    const offset = now.getTimezoneOffset();

    const response = await fetch(`${ORIGIN}/cp/hx/orders`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            accountId: customer.Account.Id,
            userId: customer.Id,
            pageOffset: params.pageOffset,
            fromDate: params.fromDate,
            toDate: params.toDate,
            searchTerm: params.searchTerm,
            timezoneDifferenceInMinutes: offset,
            sortBy: params.sortBy,
            orderBy: params.orderBy,
        }),
    });

    const data = await response.json();

    Promise.resolve(data);

    return data;
};

export const getUserAccountInfo = async (customer, signal) => {
    let ORIGIN = config.origin;

    try {
        const response = await fetch(`${ORIGIN}/cp/contact-info`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${customer.Id}${customer.Email}`,
            },
            body: JSON.stringify({
                userId: customer.Id,
                accountId: customer.Account.Id,
            }),
        });

        const data = await response.json();
        //resole the promise
        Promise.resolve(data);

        return data;
    } catch (error) {
        return error;
    }
};

export const getOrderCSV = async (customer, order, quoteNumber) => {
    let ORIGIN = config.origin;
    const now = new Date();
    const offset = now.getTimezoneOffset();

    const response = await fetch(`${ORIGIN}/cp/generate-csv`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            contactId: customer.Id,
            accountId: customer.Account.Id,
            order: order,
            quoteNumber: quoteNumber,
        }),
    })
        .then((response) => response.blob())

        .then((blob) => {
            const url = URL.createObjectURL(blob);

            // Create a link element and click it to trigger the file download
            const link = document.createElement('a');
            link.href = url;
            link.download = `${quoteNumber}.csv`;
            link.click();

            // Clean up the URL object
            URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Error downloading CSV file:', error);
        });

    return response;
};

export const getWMSFields = async (dataToSend, route, dropDownRoute) => {
    let ORIGIN = config.origin;

    try {
        console.log('dataToSend', dataToSend);
        // Initialize both fetch calls
        const fetchData = fetch(`${ORIGIN}/wms/receiving/${route}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        const fetchDropdowns = fetch(`${ORIGIN}/wms/receiving/${dropDownRoute}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        // Use Promise.all to fetch data and dropdowns simultaneously
        const [responseData, responseDropdowns] = await Promise.all([fetchData, fetchDropdowns]);
        const data = await responseData.json();
        const dropdownsData = await responseDropdowns.json();
        console.log('responseData', data);

        // sort the data by ordinal
        data.sort((a, b) => a.ordinal - b.ordinal);

        // Sync the data by 'referencesTo' field and enhance data items
        data.forEach((item) => {
            // Access the array within the dropdownsData object using the referencesTo value
            const dropdownArray = dropdownsData[item.referencesTo];

            if (dropdownArray && Array.isArray(dropdownArray)) {
                // Assuming each dropdown array contains unique values or objects,
                // You can add the entire dropdownArray to your data or further process it.
                item.dropdownData = dropdownArray;
            } else {
                console.warn(`No dropdown data found for ${item.referencesTo} or it's not an array.`);
            }
        });

        return data; // Return the enhanced data array
    } catch (error) {
        return error;
    }
};

export const getAllScanners = async (currentUser, setCurrentUser) => {
    let ORIGIN = config.origin;

    if (localStorage.getItem('wmsPrinters') !== null) {
        const getLocalPrinters = localStorage.getItem('wmsPrinters');
        const localWMSPrinters = JSON.parse(getLocalPrinters);

        return localWMSPrinters;
    }

    try {
        const response = await fetch(`${ORIGIN}/wms/receiving/get-scanner-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authentication: {
                    userId: currentUser.authentication.userId,
                    userEmail: currentUser.authentication.userEmail,
                    userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
                },
            }),
        });

        const data = await response.json();
        console.log('data', data);

        if (data.error) {
            return data;
        }

        if (data && !data.error) {
            // Save data to local storage
            localStorage.setItem('wmsPrinters', JSON.stringify(data?.scanners));
        }

        return data.scanners;
    } catch (error) {
        return error;
    }
};

export const validateFields = async (formData, route, currentUser) => {
    let ORIGIN = config.origin;
    try {
        const response = await fetch(`${ORIGIN}${route}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authentication: {
                    ...currentUser.authentication,
                },
                formData: formData,
            }),
        });

        const data = await response.json();

        return data;
    } catch (error) {
        return error;
    }
};

export const handleReceivingTraceScans = async (body, currentUser, isWorkOrder = false) => {
    let ORIGIN = config.origin;
    try {
        const routeFolder = isWorkOrder ? 'work-order' : 'receiving';
        const suffix = isWorkOrder ? '-wo' : '';
        const url = `${ORIGIN}/wms/${routeFolder}/submit-all-receiving-trace-scans${suffix}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authentication: {
                    userId: currentUser.authentication.userId,
                    userEmail: currentUser.authentication.userEmail,
                    userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
                },
                data: body,
            }),
        });

        if (!response.ok) {
            return null;
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return null;
    }
};

export const handleReceivingTracePhotos = async (body, currentUser, isWorkOrder = false) => {
    try {
        let ORIGIN = config.origin;
        const routeFolder = isWorkOrder ? 'work-order' : 'receiving';
        const suffix = isWorkOrder ? '-wo' : '';

        const url = `${ORIGIN}/wms/${routeFolder}/submit-all-receiving-trace-photos${suffix}`;

        const authObject = {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        };

        // Create a new FormData object and append the authObject
        const formData = new FormData();
        formData.append('authentication', JSON.stringify(authObject));

        // Get the blob of the image to be sent and append it
        const photoBase64 = body.photos[0].dataUri.split(',')[1];
        const photoBlob = base64ToBlob(photoBase64, 'image/png');
        formData.append('photo', photoBlob, 'photo.png');

        body.photos = [
            {
                selectedOption: body.photos[0].selectedOption,
                uploaded: body.photos[0].uploaded,
            },
        ];
        formData.append('data', JSON.stringify(body));

        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            return null;
        }

        const data = await response.json();

        return data;
    } catch (error) {
        return null;
    }
};

export async function updateReceiptStatus(inventoryId, currentUser) {
    let ORIGIN = config.origin;
    try {
        await fetch(`${ORIGIN}/wms/inventory/update-receipt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inventoryId: inventoryId,
                authentication: {
                    ...currentUser.authentication,
                },
            }),
        });
    } catch (error) {
        logError(error, 'updateReceiptStatus');
    }
}

export const getTableStructures = (currentUser, setCurrentUser) => {
    let ORIGIN = config.origin;

    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
    };
    // check local storage first
    if (localStorage.getItem('tableStructures')) {
        currentUser.tableStructures = JSON.parse(localStorage.getItem('tableStructures'));
        return;
    }

    fetch(`${ORIGIN}/wms/receiving/get-table-structures`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    })
        .then((res) => res.json())
        .then((data) => {
            localStorage.setItem('tableStructures', JSON.stringify(data));
            setCurrentUser((prevUser) => ({
                ...prevUser,
                tableStructures: data,
            }));
        })
        .catch((err) => {
            return [];
        });
};

export const searchByValue = async (value, currentUser) => {
    //sql-search-by-value
    let ORIGIN = config.origin;

    try {
        const response = await fetch(`${ORIGIN}/wms/receiving/sql-search-by-value`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authentication: {
                    ...currentUser.authentication,
                },
                tableName: value.tableName,
                searchValue: value.value,
            }),
        });

        const data = await response.json();

        return data;
    } catch (error) {
        return error;
    }
};

// Search through Part Number/Quote Number/SO Numbers/PO Numbers/Invoices for matching identifiers.
export const globalSearch = async (customer, params) => {
    let ORIGIN = config.origin;

    const now = new Date();
    const offset = now.getTimezoneOffset();

    const response = await fetch(`${ORIGIN}/cp/hx/global`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            accountId: customer.Account.Id,
            userId: customer.Id,
            searchTerm: params.searchTerm,
            timezoneDifferenceInMinutes: offset,
            limit: params.limit,
        }),
    });

    const data = await response.json();

    Promise.resolve(data);

    return data;
};

// Get exact row match from table
export const globalSpecificSearch = async (customer, params) => {
    let ORIGIN = config.origin;

    const response = await fetch(`${ORIGIN}/cp/hx/global-specific`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${customer.Id}${customer.Email}`,
        },
        body: JSON.stringify({
            accountId: customer.Account.Id,
            userId: customer.Id,
            searchTerm: params.searchTerm,
            tableName: params.tableName,
            limit: params.limit,
        }),
    });

    const data = await response.json();

    return data;
};

export const CheckUserData = async (currentUser, updateCurrentUser) => {
    const ORIGIN = config.origin;

    try {
        const response = await fetch(`${ORIGIN}/cp/compare-local-data/update-stale-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(currentUser),
        });

        const data = await response.json();

        if (data.data) {
            updateCurrentUser(data.data);
        }

        return data; // return the data or any relevant information
    } catch (err) {
        console.error(err);
        return null; // or throw an error or return an error object
    }
};

export const partSearch = async (dataToSend, currentUser) => {
    const ORIGIN = config.origin;

    try {
        const response = await fetch(`${ORIGIN}/cp/parts-search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });

        const data = await response.json();

        if (data.failure) {
            return {
                error: 'partSearch: Server failure. No parts returned.',
                failure: true,
            };
        }

        if (data.found.length === 0) {
            return {
                error: 'partSearch: No data found',
                failure: false,
            };
        }

        return data;
    } catch (error) {
        console.error(error);
        return {
            error: `partSearch: Function failure | Error: ${error}`,
        };
    }
};

export const billingDataFetch = async (dataToSend, currentUser) => {
    const ORIGIN = config.origin;

    try {
        const response = await fetch(`${ORIGIN}/cp/billing`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });

        const data = await response.json();

        if (data.failure) {
            return {
                error: 'billingData: Server failure. No parts returned.',
                failure: true,
            };
        }

        if (!data) {
            return {
                error: 'billingData: No data found',
                failure: false,
            };
        }

        return data;
    } catch (error) {
        console.error(error);
        return {
            error: `billingData: Function failure | Error: ${error}`,
        };
    }
};

export const billingAddressFetch = async (dataToSend, currentUser) => {
    const ORIGIN = config.origin;

    try {
        console.log(dataToSend, currentUser);
        const response = await fetch(`${ORIGIN}/cp/billing/address`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });

        const data = await response.json();

        if (data.failure) {
            return {
                error: 'billingAddressFetch: Server failure. Address not found.',
                failure: true,
            };
        }

        if (data.items.length === 0) {
            return {
                error: 'billingAddressFetch: No data found',
                failure: false,
            };
        }

        return data;
    } catch (error) {
        console.error(error);
        return {
            error: `billingAddressFetch: Function failure | Error: ${error}`,
        };
    }
};

export const getPackingReleaseInfo = async (dataToSend, currentUser) => {
    const ORIGIN = config.origin;

    try {
        const response = await fetch(`${ORIGIN}/wms/shipping/get-release-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });

        const data = await response.json();

        if (data.error) {
            return {
                error: 'getPackingReleaseInfo: Server failure. No parts returned.',
                failure: true,
            };
        }

        if (!data) {
            return {
                error: 'getPackingReleaseInfo: No data found',
                failure: false,
            };
        }

        return data;
    } catch (error) {
        console.error(error);
        return {
            error: `getPackingReleaseInfo: Function failure | Error: ${error}`,
        };
    }
};

export const postPhotoData = async (releaseId, releaseNumber, photoDataUri) => {
    try {
        const ORIGIN = config.origin;

        const response = await fetch(`${ORIGIN}/receiving/picture`, {
            method: 'POST',
            body: JSON.stringify({
                dataUri: photoDataUri,
                params: { id: releaseId, name: releaseNumber },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            return {
                error: 'Bad response',
            };
        }

        return response;
    } catch (err) {
        return {
            error: err,
        };
    }
};

export function base64ToBlob(base64, mime) {
    const byteChars = atob(base64);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
}

export async function checkPrivateRouteKey(token) {
    const ORIGIN = config.origin;

    const response = await fetch(`${ORIGIN}/pr`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token,
        }),
    });

    if (!response.ok) {
        return false;
    } else {
        return true;
    }
}
