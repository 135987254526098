import { dbDefaultName, dbDefaultVersion } from './data';

export const indexedDBConnection = async (dbName, version) => {
    const name = dbName ?? dbDefaultName;
    const dbVersion = version ?? dbDefaultVersion;
    const dbConnection = indexedDB.open(name, dbVersion);

    // Handle indexedDB data migration
    dbConnection.onupgradeneeded = (event) => {
        const db = event.target.result;

        const quoteStore = db.createObjectStore('Quote', { keyPath: 'quoteNumber' });
        const saleStore = db.createObjectStore('Order', { keyPath: 'salesOrderNumber' });

        quoteStore.transaction.oncomplete = () => {
            db.transaction('Quote', 'readwrite').objectStore('Quote');
        };

        saleStore.transaction.oncomplete = () => {
            db.transaction('Order', 'readwrite').objectStore('Order');
        };
    };

    return dbConnection;
};

export const getFromIndexedDB = async (conn, storeName, query) => {
    try {
        const dbConnection = conn ?? (await indexedDBConnection());

        const getData = new Promise((resolve) => {
            dbConnection.onsuccess = (event) => {
                const db = event.target.result;
                const request = db.transaction(storeName).objectStore(storeName).get(query);

                request.onerror = (event) => {
                    resolve({
                        error: `Request: ${storeName} | ${event.target.error}`,
                    });
                };
                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };
            };
        })
            .then((result) => {
                return result;
            })
            .catch((err) => {
                return {
                    error: err,
                };
            });

        return await getData;
    } catch (error) {
        return {
            error: `getFromIndexedDB: ${storeName} | ${error}`,
        };
    }
};

export const addToIndexedDB = async (conn, storeName, data) => {
    try {
        const dbConnection = conn ?? (await indexedDBConnection());

        dbConnection.onsuccess = (event) => {
            const db = event.target.result;

            for (const dataItem of data) {
                db.transaction(storeName, 'readwrite').objectStore(storeName).add(dataItem);
            }
        };
        return {
            success: 'Wrote to indexedDB without issues',
        };
    } catch (error) {
        console.error(error);
        return {
            error: `addToIndexedDB: ${storeName} | ${error}`,
        };
    }
};

export const clearIndexedDB = async (conn) => {
    try {
        const dbConnection = conn ?? (await indexedDBConnection());

        dbConnection.onsuccess = (event) => {
            const db = event.target.result;

            const objectStoreNames = db.objectStoreNames;

            if (objectStoreNames.length === 0) return;

            for (const objectStoreName of objectStoreNames) {
                const clearStore = db.transaction(objectStoreName, 'readwrite').objectStore(objectStoreName).clear();

                clearStore.onerror = (event) => {
                    console.log(event.target.error);
                };
            }
        };
    } catch (error) {
        console.error(error);
    }
};
