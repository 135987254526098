import React, { createContext, useEffect, useState } from 'react';
import { chatterModalView, defaultChatterModalState, defaultNotificationState } from '../utils/data';
import logError from '../../../../utils/errorUtil';

export const TraceContext = createContext({
    handlePhotoAddition: () => {},
    handleIsHeroChange: () => {},
    updateNotificationState: () => {},
    notificationState: {},
    photosAdded: [],
    photosChanged: [],
    submissionStatus: [],
    updateSubmissionStatus: () => {},
    setSubmissionStatus: () => {},
    photosForSubmission: [],
    updatePhotoStatusesForSubmission: () => {},
    removeSuccessfulPhotos: () => {},
    resetContextVariables: () => {},
    chatterModalState: {},
    updateChatterModalView: () => {},
    addDataToChatterState: () => {},
    removeDataFromChatterState: () => {},
});

export function TraceContextWrapper({ children }) {
    const [photosAdded, setPhotosAdded] = useState([]);
    const [photosChanged, setPhotosChanged] = useState([]);
    const [notificationState, setNotificationState] = useState(defaultNotificationState);
    const [submissionStatus, setSubmissionStatus] = useState([]);
    const [photosForSubmission, setPhotosForSubmission] = useState([]);
    const [chatterModalState, setChatterModalState] = useState(defaultChatterModalState);

    // General Functions --------------------------------------------------------------------
    const resetContextVariables = () => {
        setPhotosAdded([]);
        setPhotosChanged([]);
        setNotificationState(defaultNotificationState);
        setChatterModalState({ view: chatterModalView.HIDDEN, messageData: [] });
        setSubmissionStatus([]);
        setPhotosForSubmission([]);
    };

    const updateNotificationState = (updatedState, errorDetails = null) => {
        setNotificationState(updatedState);
        if (errorDetails) logError(errorDetails.error, errorDetails.function);
    };

    const handlePhotoAddition = (photoData) => {
        const addedPhotos = photoData.map((data) => {
            return {
                name: data.name,
                isHero: data.isHero,
                base64: data.base64,
            };
        });
        setPhotosAdded((data) => [...data, ...addedPhotos]);
    };

    const handleIsHeroChange = (updatedPhotoData) => {
        const { name: photoName, isHero: updatedIsHero, base64 } = updatedPhotoData;

        // Handle photos that are newly added being updated
        const changedAddedPhoto = photosAdded.find((photo) => photo.name === photoName);

        if (changedAddedPhoto) {
            const updatedPhotos = photosAdded.map((photo) => {
                if (photo.name === photoName) {
                    return { ...photo, isHero: updatedIsHero };
                }

                return photo;
            });

            setPhotosAdded(updatedPhotos);
            return;
        }

        // Handle old photos being updated
        const changedPreviously = photosChanged.find((photo) => photo.name === photoName);

        if (changedPreviously) {
            const updatedPhotos = photosChanged.filter((photo) => photo.name !== photoName);
            setPhotosChanged(updatedPhotos);
            return;
        }

        // Base case
        const updatedPhoto = {
            name: photoName,
            isHero: updatedIsHero,
            base64,
        };

        setPhotosChanged([...photosChanged, updatedPhoto]);
    };

    const removeSuccessfulPhotos = () => {
        setPhotosForSubmission((prevState) => {
            return prevState.filter((photo) => !photo.uploadStatus);
        });
    };

    // Chatter Functions --------------------------------------------------------------------
    const updateChatterModalView = (viewState) => {
        const updatedModalState = { ...chatterModalState, view: viewState };
        setChatterModalState(updatedModalState);
    };

    const addDataToChatter = (dataToAdd) => {
        const updatedMessageData = chatterModalState.messageData;
        updatedMessageData.push(dataToAdd);

        setChatterModalState((prev) => {
            return { ...prev, messageData: updatedMessageData };
        });
    };

    const removeDataFromChatter = (inventoryName) => {
        const chatterData = [...chatterModalState.messageData];
        const updatedData = chatterData.filter((data) => data.inventoryName !== inventoryName);
        setChatterModalState((prevState) => {
            return { ...prevState, messageData: updatedData };
        });
    };

    // Submission Functions --------------------------------------------------------------------
    const updatePhotoStatusesForSubmission = (photoNames, response) => {
        setPhotosForSubmission((prevState) => {
            return prevState.map((photoData) => {
                if (photoNames.includes(photoData.name)) {
                    return { ...photoData, uploadStatus: response?.success ? true : false };
                }
                return photoData;
            });
        });
    };

    const updateSubmissionStatus = (stepTitle, newStatus) => {
        setSubmissionStatus((prevState) => {
            return prevState.map((submissionStep) => {
                if (submissionStep.title === stepTitle) {
                    return { ...submissionStep, status: newStatus };
                }

                return submissionStep;
            });
        });
    };

    useEffect(() => {
        setPhotosForSubmission([...photosChanged, ...photosAdded]);
    }, [photosAdded, photosChanged]);

    const value = {
        handlePhotoAddition,
        handleIsHeroChange,
        photosAdded,
        photosChanged,
        updateNotificationState,
        notificationState,
        submissionStatus,
        updateSubmissionStatus,
        setSubmissionStatus,
        photosForSubmission,
        updatePhotoStatusesForSubmission,
        removeSuccessfulPhotos,
        resetContextVariables,
        addDataToChatter,
        removeDataFromChatter,
        updateChatterModalView,
        chatterModalState,
    };
    return <TraceContext.Provider value={value}>{children}</TraceContext.Provider>;
}
