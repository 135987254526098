import React, { useState } from 'react';

export const PartResult = ({ part, setSelectedObject, selectedObject }) => {
    const name = part.Name;
    const [selected, setSelected] = useState(false);

    const queryQuoteLink = `/?part-search=${name}`;

    return (
        <a
            href={queryQuoteLink}
            className={
                'w-full py-2 no-underline text-gray-600 text-center hover:cursor-pointer hover:bg-gray-300 ' +
                (selected && selectedObject.Name && selectedObject.Name === name && 'bg-gray-300')
            }
            onMouseEnter={() => {
                setSelectedObject(part);
                setSelected(true);
            }}
            style={{
                all: 'revert',
            }}
        >
            {name}
        </a>
    );
};
