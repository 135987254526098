import LoadingSpinner from '../../../components/LoadingSpinner';
import { formatAsCurrency } from '../../../utils/formatAsCurrency';

export default function Account({ userOrderInfo, accountTerm, openModal }) {
    if (!userOrderInfo) {
        return (
            <div className='pb-24'>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Account</h2>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <section className='flex flex-col w-full lg:min-w-[600px] lg:w-[60%] h-[100%] overflow-hidden gap-5'>
            <article className='flex flex-row w-full justify-between items-start'>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Account</h2>
                <button
                    disabled={!userOrderInfo || userOrderInfo?.orders?.length === 0}
                    className='flex flex-row-reverse items-center bg-blue-primary rounded-md shadow-sm px-4 py-2 font-medium disabled:pointer-events-none hover:bg-gray-50 border text-sm text-white hover:text-gray-800'
                    onClick={() => {
                        openModal();
                    }}
                >
                    <span className=''>Print Statement</span>
                </button>
            </article>
            <article className='flex flex-row gap-10'>
                <h1>
                    <strong>Current A/P: </strong>
                    {userOrderInfo.total}
                </h1>
                <h1 className='relative flex flex-row gap-x-1'>
                    <strong>Account Terms: </strong>
                    {accountTerm === '' ? (
                        <div className='absolute -right-[85px] -top-1 flex w-fit items-center justify-center px-3 py-1 animate-pulse bg-gray-100 text-gray-500 rounded-md'>
                            Loading
                        </div>
                    ) : (
                        accountTerm
                    )}
                </h1>
            </article>
            <table className='w-full border border-gray-100'>
                <thead className='bg-blue-primary text-white border border-blue-primary'>
                    <th className='text-center py-1 font-semibold'>Date</th>
                    <th className='text-center py-1 font-semibold'>Reference</th>
                    <th className='text-center py-1 font-semibold'>Due Date</th>
                    <th className='text-center py-1 font-semibold'>Total</th>
                </thead>
                <tbody>
                    {userOrderInfo?.orders
                        .sort((a, b) => a.AcctSeed__Date__c < b.AcctSeed__Date__c)
                        .map((order, index) => {
                            const {
                                AcctSeed__Date__c,
                                AcctSeed__Due_Date2__c,
                                AcctSeed__Proprietary_Billing_Number__c,
                                AcctSeed__Total__c,
                            } = order;

                            if (
                                AcctSeed__Total__c === '0' ||
                                AcctSeed__Total__c === '0.0' ||
                                AcctSeed__Total__c.includes('-')
                            ) {
                                return null;
                            }

                            return (
                                <tr key={index} className='even:bg-gray-50'>
                                    <td className='text-center px-8 py-1 border border-gray-200 '>{AcctSeed__Date__c}</td>
                                    <td className='text-center px-8 py-1 border border-gray-200'>
                                        {AcctSeed__Proprietary_Billing_Number__c}
                                    </td>
                                    <td className='text-center px-8 py-1 border border-gray-200'>
                                        {AcctSeed__Due_Date2__c}
                                    </td>
                                    <td className='text-center px-8 py-1 border border-gray-200'>
                                        {formatAsCurrency(AcctSeed__Total__c)}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {!userOrderInfo?.orders ||
                (userOrderInfo?.orders?.length === 0 && (
                    <h1 className='w-full text-center mt-10 mb-10 text-xl'>No outstanding billings</h1>
                ))}
        </section>
    );
}
