import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useAuth } from '../../portal/context/auth-context';
import AddNewShippingMethod from './AddNewShippingMethod';
import Tooltip from '../../components/Tooltip';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
export default function ShippingMethod({ setShowPO, showPO, shipMethod, handleSubmitOrder }) {
    const { currentUser } = useAuth();

    const getSelectedShipping = () => {
        const selectedShipping = currentUser.shipMethods.find((item) => item.selected === true);
        return selectedShipping || null;
    };

    const [shippingInfoState, setShippingInfoState] = useState(currentUser.shipMethods);
    const [newShipping, setNewShipping] = useState(false);
    const [selectedShipping, setSelectedShipping] = useState(getSelectedShipping());
    const [open, setOpen] = useState(false);
    const [errorAddingAddress, setErrorAddingAddress] = useState(false);
    const [noShippingSelected, setNoShippingSelected] = useState(false);
    console.log(selectedShipping);

    const handleSubmit = (e) => {
        shippingInfoState.map((item) => {
            if (item.selected) {
                setSelectedShipping(item);
                currentUser.selectedShipMethod = item;
            }
        });
        handleSubmitOrder();
    };

    const handleNewAddress = (newAddress, isError) => {
        newAddress.selected = true;
        setSelectedShipping(newAddress);
        setShippingInfoState((prevState) => {
            return [...prevState, newAddress];
        });
        console.log(newAddress);
    };
    return (
        <>
            {open && (
                <AddNewShippingMethod
                    open={open}
                    setOpen={setOpen}
                    handleNewAddress={handleNewAddress}
                    setErrorAddingAddress={setErrorAddingAddress}
                    errorAddingAddress={errorAddingAddress}
                />
            )}
            <div className=' px-4 py-5 border-2 border-gray-200 mt-6 sm:p-6'>
                <div className='mt-4'>
                    <h3 className='text-xl leading-6 font-medium text-gray-900'>Shipping Method</h3>
                    {!showPO && (
                        <div className='mt-2'>
                            <p className='text-sm text-gray-500'>Please select your ship method from the list below.</p>
                        </div>
                    )}
                </div>
                <div className='mt-6'>
                    <div className='flex flex-row justify-between'>
                        <div className='-my-2  sm:-mx-6 lg:-mx-8'>
                            <div className='py-2 flex flex-col md:flex-row justify-start pl-6 w-full flex-wrap'>
                                {!showPO &&
                                    shippingInfoState.map((info, idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => {
                                                setShippingInfoState(
                                                    shippingInfoState.map((item) => {
                                                        if (item.shipMethodId === info.shipMethodId) {
                                                            return { ...item, selected: true };
                                                        } else {
                                                            return { ...item, selected: false };
                                                        }
                                                    })
                                                );
                                                setSelectedShipping(info);
                                                setNewShipping(false);
                                            }}
                                            className={classNames(
                                                info.selected
                                                    ? 'bg-gray-50 border-2 border-blue-primary '
                                                    : 'bg-white text-gray-900',
                                                'shadow-md border cursor-pointer sm:rounded-md w-52 hover:-translate-y-1 transform transition-all duration-300 mx-1 my-2 p-3 flex flex-col justify-around'
                                            )}
                                        >
                                            <div className='py-5 sm:px-2'>
                                                <h3 className='text-md leading-6 font-medium text-gray-900'>
                                                    {info.shipMethodDescription}
                                                </h3>
                                                <p className='mt-1 max-w-2xl text-sm text-gray-600'>{info.shipCarrier}</p>
                                                <p className='mt-1 max-w-2xl text-sm text-gray-600'>{info.shipMethodName}</p>
                                                <p className='mt-1 max-w-2xl text-sm text-gray-600'>
                                                    Account: <span className='font-semibold'> {info.accountNumber}</span>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                {!showPO && (
                                    <div
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                        className='shadow-md border bg-white text-gray-900 cursor-pointer sm:rounded-md w-52 hover:-translate-y-1 transform transition-all duration-300  mx-1 my-2 p-3 flex flex-col justify-around'
                                    >
                                        <div className='px-4 py-5 sm:px-6 flex justify-center flex-col items-center'>
                                            <h3 className='text-lg leading-6 font-medium text-gray-900'>Add new</h3>
                                            <PlusIcon className='h-20 w-20 text-blue-primary' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!showPO ? (
                    <div
                        onMouseEnter={() => {
                            if (selectedShipping === null || selectedShipping === undefined) {
                                setNoShippingSelected(true);
                            } else {
                                setNoShippingSelected(false);
                            }
                        }}
                        onMouseLeave={() => {
                            setNoShippingSelected(false);
                        }}
                        className='inline-block'
                    >
                        <button
                            onClick={handleSubmit}
                            disabled={selectedShipping === null || selectedShipping === undefined}
                            type='button'
                            className='cursor-pointer mt-6 w-52 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-primary hover:bg-blue-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary'
                        >
                            <p>Review Order</p>
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={() => {
                            setShowPO(!showPO);
                        }}
                        type='button'
                        className='inline-flex justify-center py-2 px-4 border border-blue-primary shadow-sm text-md font-medium rounded-md text-blue-primary bg-white hover:bg-blue-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary'
                    >
                        <p>Next</p>
                    </button>
                )}
                {noShippingSelected && <Tooltip text='Please select shipping method to proceed' />}
            </div>
        </>
    );
}
