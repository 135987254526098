import React from 'react';
import ErrorPage from '../portal/pages/ErrorPage';
import logError from './errorUtil';
import ErrorBoundaryWrapper from './ErrorBoundaryWrapper';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        // Include more information in the logError function
        logError(error, 'ErrorBoundary');

        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundaryWrapper(ErrorBoundary);
