import { formatDate } from '../../../../../utils/formatDate';
import { formatAsCurrency } from '../../../../../utils/formatAsCurrency';

export const OrderDescription = ({ sale }) => {
    const { salesOrderNumber, customerPoNumber, createdDate, lineTotal } = sale;
    return (
        <>
            <div>
                <strong>Date</strong>
                <p>{formatDate(createdDate)}</p>
            </div>
            <div>
                <strong>Sales Number</strong>
                <p>{salesOrderNumber}</p>
            </div>
            <div>
                <strong>PO Number</strong>
                <p>{customerPoNumber}</p>
            </div>
            <div>
                <strong>Total Cost</strong>
                <p>{formatAsCurrency(lineTotal)}</p>
            </div>
        </>
    );
};
