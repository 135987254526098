import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { AuthContextProvider } from './portal/context/auth-context';
import { WMSContextProvider } from './wms/context/wms-context';
import App from './App';
import ErrorBoundary from './utils/ErrorBoundary';

ReactDOM.render(
    <AuthContextProvider>
        <WMSContextProvider>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </WMSContextProvider>
    </AuthContextProvider>,
    document.getElementById('payment-form')
);
