import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import AddNewShippingForm from './AddNewShippingForm';
import { useAuth } from '../../portal/context/auth-context';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ShippingInformation({ isShowing, setIsShowing, setShowShipping, showShipping }) {
    const [newShipping, setNewShipping] = useState(false);
    const { currentUser } = useAuth();
    const [shippingInfoState, setShippingInfoState] = useState(currentUser.shipAddresses);
    const [selectedShipping, setSelectedShipping] = useState();

    const handleSubmit = (id) => {
        setShowShipping(!showShipping);

        shippingInfoState.map((item) => {
            if (item.shipAddressId === id) {
                setSelectedShipping(item);
                currentUser.selectedShipAddress = item;
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [showShipping]);

    const handleNewAddress = (newAddress) => {
        shippingInfoState.map((item) => {
            if (item.selected) {
                item.selected = false;
            }
        });

        setShippingInfoState((prevState) => {
            return [...prevState, newAddress];
        });
        setNewShipping(!newShipping);
    };

    return (
        <>
            <Transition
                appear={true}
                show={isShowing}
                enter='ease-in-out'
                enterFrom='translate-x-full opacity-0'
                enterTo='translate-x-0 opacity-100'
                leave='ease-out'
                leaveFrom='translate-x-0 opacity-100'
                leaveTo='translate-x-full opacity-0'
            >
                <div className=' px-4 py-5 border-2 border-gray-200 mt-6 sm:p-6'>
                    <div className='mt-4'>
                        <h3 className='text-xl leading-6 font-medium text-gray-900'>Shipping Information</h3>

                        {!showShipping && (
                            <div className='mt-2'>
                                <p className='text-sm text-gray-500'>
                                    Please select the shipping information you would like to use for this order.
                                </p>
                                <div className='mt-12'>
                                    <div className='flex flex-row justify-between'>
                                        <div className='-my-2  sm:-mx-6 lg:-mx-8'>
                                            <div className='py-2 flex flex-col md:flex-row justify-start pl-6 w-full flex-wrap'>
                                                {!showShipping &&
                                                    shippingInfoState.map((info) => (
                                                        <div
                                                            onClick={() => {
                                                                setShippingInfoState(
                                                                    shippingInfoState.map((item) => {
                                                                        if (item.shipAddressId === info.shipAddressId) {
                                                                            return { ...item, selected: true };
                                                                        } else {
                                                                            return { ...item, selected: false };
                                                                        }
                                                                    })
                                                                );
                                                                setNewShipping(false);
                                                                handleSubmit(info.shipAddressId);
                                                            }}
                                                            key={info.shipAddressId}
                                                            className={classNames(
                                                                info.selected ? '' : 'bg-white text-gray-900',
                                                                'shadow-md border cursor-pointer sm:rounded-md w-64 hover:-translate-y-1 transform transition-all duration-300 mx-1 my-2 p-3 flex flex-col justify-center items-start'
                                                            )}
                                                        >
                                                            <div className='px-1 pb-4 pt-6 mt-2'>
                                                                <h3 className='text-md leading-4 font-medium text-gray-900'>
                                                                    {info.shipAddressName}
                                                                </h3>
                                                                <p className='mt-2 text-sm text-gray-600'>{info.street}</p>
                                                                <p className=' text-sm text-gray-600'>
                                                                    {info.city}, {info.state} {info.zip}
                                                                </p>
                                                                <p className='text-sm text-gray-600'>
                                                                    {info.postalCode}, {info.country}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}

                                                {!newShipping && !showShipping && (
                                                    <div
                                                        onClick={() => {
                                                            setNewShipping(!newShipping);
                                                        }}
                                                        className='shadow-md border bg-white text-gray-900 cursor-pointer sm:rounded-md w-64 hover:-translate-y-1 transform transition-all duration-300  mx-1 my-2 p-3 flex flex-col justify-around'
                                                    >
                                                        <div className='px-4 py-5 sm:px-6 flex justify-center flex-col items-center'>
                                                            <h3 className='text-lg leading-6 font-medium text-gray-900'>
                                                                Add new
                                                            </h3>
                                                            <PlusIcon className='h-20 w-20 text-blue-primary' />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {newShipping && (
                                            <AddNewShippingForm
                                                setNewShipping={setNewShipping}
                                                handleNewAddress={handleNewAddress}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {showShipping && (
                        <div className='mt-4 text-sm ml-1'>
                            <p>{selectedShipping.shipAddressName}</p>
                            <p> {selectedShipping.street} </p>
                            <p>
                                {selectedShipping.city}, {selectedShipping.state} {selectedShipping.zip}
                            </p>
                            <p>
                                {selectedShipping.postalCode}, {selectedShipping.country}
                            </p>
                        </div>
                    )}

                    <div className='mt-4'>
                        {!showShipping ? (
                            // <button
                            //   onClick={handleSubmit}
                            //   type="button"
                            //   disabled={selectedShipping === null}
                            //   className="w-52 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-blue-primary hover:bg-blue-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary"
                            // >
                            //   <p>Next</p>
                            // </button>
                            <></>
                        ) : (
                            <button
                                onClick={() => {
                                    setShowShipping(!showShipping);
                                }}
                                type='button'
                                className='inline-flex justify-center py-2 px-4 border border-blue-primary shadow-sm text-md font-medium rounded-md text-blue-primary bg-white hover:bg-blue-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary'
                            >
                                <p>Edit</p>
                            </button>
                        )}
                    </div>
                </div>
            </Transition>
        </>
    );
}
