import React, { useEffect, useState } from 'react';
import { formatAsCurrency } from '../../../utils/formatAsCurrency';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import ShippingInformation from '../ShippingInformation';
import config from '../../../utils/config';
import NotFoundInventoryTable from '../NotFoundInventoryTable';
import ShippingMethod from '../ShippingMethod';
import { useNavigate } from 'react-router-dom';
import { GetShippingInfo, getOrderCSV } from '../../../utils/commonAPICalls';
import Notification from '../../../components/Notification';
import DocModal from '../DocModal';
import TermsAndConditionsBox from '../TermsAndConditionsBox';
import FeeTable from '../FeeTable';
import MorePartsModal from '../MorePartsModal';
import { validatePartsSearch } from '../../../utils/validators';
import AdvancedFilterDropdown from './AdvancedFilterDropdown';
import AdvancedOptionsDropdown from './AdvancedOptionsDropdown';
import AvailableInventorySortButton from './AvailableInventorySortButton';
import logError from '../../../utils/errorUtil';

import { calculateDarFee, calculateMinOrderFee } from '../../../utils/calculateFee';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function AvailableInventory({ response, setResponse, currentUser, noQuote }) {
    let quoteId = response.quoteId;
    const [orderDetails, setOrderDetails] = useState(response);

    const [partsNotFound, setPartsNotFound] = useState(response.notFound);
    const [substituteParts, setSubstituteParts] = useState(response.substitutions);
    response = response.found;
    const [arePartsSelected, setArePartsSelected] = useState(false);

    const ORIGIN = config.origin;
    const history = useNavigate();

    const [order, setOrder] = useState(response);
    const [originalOrders, setOriginalOrders] = useState([]);
    const [totalParts, setTotalParts] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [isShowing, setIsShowing] = useState(false);
    const [finalQuote, setFinalQuote] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [showShipping, setShowShipping] = useState(false);
    const [showPO, setShowPO] = useState(false);
    const [showRFQNotification, setShowRFQNotification] = useState(false);
    const [showSavedNotification, setShowSavedNotification] = useState(false);

    const [isDarOrder, setIsDarOrder] = useState(false);
    const [orderSubtotal, setOrderSubtotal] = useState(0);

    const [docs, setDocs] = useState();

    const [morePartsModal, setMorePartsModal] = useState(false);
    const [newPartInput, setNewPartInput] = useState('');
    const [newPartsSearchLoading, setNewPartsSearchLoading] = useState(false);

    const [surcharges, setSurcharges] = useState({});
    const [showFeeTable, setShowFeeTable] = useState(false);
    const [leadTimeWarning, setLeadTimeWarning] = useState(false);
    const [showNoAogWarning, setShowNoAogWarning] = useState(null);

    const [advancedOptionSelection, setAdvancedOptionSelection] = useState({
        allSelected: false,
        allCartSelected: false,
        allRFQSelected: false,
        allStockSelected: false,
    });

    const [rfqAvailable, setRfqAvailable] = useState(false);
    const [cartAvailable, setCartAvailable] = useState(false);

    //Sort states
    const [arrowDirections, setArrowDirections] = useState({
        partNo: { direction: null },
        condition: { direction: null },
        trace: { direction: null },
        tag: { direction: null },
        tagDate: { direction: null },
        traceLinks: { direction: null },
        leadTime: { direction: null },
        availableQty: { direction: null },
        unitPrice: { direction: null },
    });

    useEffect(() => {
        if (originalOrders.length === 0 && order.length > 0) {
            setOriginalOrders(order);
        }
    }, [order]);

    useEffect(() => {
        let arr = [...originalOrders];
        for (const ord of order) {
            if (!arr.some((item) => item.quoteLineId === ord.quoteLineId)) {
                arr.push(ord);
            }
        }
        setOriginalOrders(arr);
    }, [order]);

    useEffect(() => {
        let arr = [];
        for (const ord of originalOrders) {
            if (!arr.includes(ord.partNo)) {
                arr.push(ord.partNo);
            }
        }
        setTotalParts(arr);
    }, [originalOrders]);

    useEffect(() => {
        let isCartAvailable = false;
        let isRfqAvailable = false;

        for (let item of order) {
            if (!item.makeOffer) {
                isCartAvailable = true;
            }
            if (item.makeOffer) {
                isRfqAvailable = true;
            }
        }

        setCartAvailable(isCartAvailable);
        setRfqAvailable(isRfqAvailable);
    }, [order]);

    useEffect(() => {
        calculateDarFee(order, orderDetails.DARFee).then((res) => {
            setOrderDetails((prev) => {
                return {
                    ...prev,
                    totalDarFee: res,
                };
            });
        });
    }, [order]);

    useEffect(() => {
        calculateMinOrderFee(order, orderDetails.minOrder, orderDetails.minAogOrder, orderDetails.isAOG).then((res) => {
            setOrderDetails((prev) => {
                return {
                    ...prev,
                    totalMinOrderFee: res,
                };
            });
        });
    }, [order]);

    useEffect(() => {
        if (order.length === 0) {
            return setResponse();
        }
    }, [order]);

    useEffect(() => {
        setOrder(response);
    }, [response]);

    useEffect(() => {
        if (showSavedNotification) {
            setTimeout(() => {
                setShowSavedNotification(false);
            }, 3000);
        }
    }, [showSavedNotification]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('editOrder') === '155638') {
            if (currentUser.orderDetails === null) {
                return history('/');
            }
            setOrderDetails(currentUser.orderDetails);
        }
    }, []);

    const handleOrderStatus = async (status) => {
        order.notFound = null;
        if (status === 'cancel') {
            setResponse();
            history('/');
            //fetch request to cancel order
        }
        if (status === 'submit') {
            saveQuote('submit');
            let isRfqOnly = false;
            let isCart = false;
            order.forEach((line) => {
                if (line.makeOffer === true && line.selected === true) {
                    isRfqOnly = true;
                }
                if (line.makeOffer === false && line.selected === true) {
                    isCart = true;
                }
            });
            if (isRfqOnly === true && isCart === false) {
                history('/?nsdg=ggtf4351001');
                window.location.reload();
            } else if (isRfqOnly === true && isCart === true) {
                let data = GetShippingInfo(currentUser);
                data.then((data) => {
                    currentUser.shipAddresses = data.shipAddresses;
                    currentUser.shipMethods = data.shipMethods;
                    currentUser.availableShipMethods = data.availableShipMethods;
                    setIsShowing(true);
                    setShowSavedNotification(true);
                    setTimeout(() => {
                        setShowSavedNotification(false);
                    }, 10000);
                });
            } else {
                let data = GetShippingInfo(currentUser);
                data.then((data) => {
                    currentUser.shipAddresses = data.shipAddresses;
                    currentUser.shipMethods = data.shipMethods;
                    currentUser.availableShipMethods = data.availableShipMethods;
                    setIsShowing(true);
                    //scroll the window to down
                    window.scrollTo(0, document.body.scrollHeight);
                });
            }
        }
        if (status === 'save') {
            setIsSaving(true);
            await saveQuote('save');
            //fetch request to save order
        }
        setIsSaving(false);
    };

    const saveQuote = async (action) => {
        const dataToSend = {
            found: [...order],
            contactId: currentUser.Id,
            accountId: currentUser.Account.Id,
            action: action,
            accountOwnerEmail: currentUser.Owner.Email,
            quoteNumber: finalQuote.quoteNumber,
            quoteId: finalQuote.quoteId,
            isAog: orderDetails.isAOG,
        };
        const response = await fetch(`${ORIGIN}/cp/save-quote`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });
        const data = await response.json();
        if (data.message) {
            return;
        }

        if (action !== 'submit') return setShowSavedNotification(true);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const quoteId = urlParams.get('quoteId');
        if (quoteId) {
            currentUser.finalQuote = {
                quoteId: orderDetails.quoteId,
                quoteNumber: orderDetails.quoteNumber,
            };
            setFinalQuote(orderDetails.quoteNumber);
        } else {
            fetchNewQuote();
        }
        if (currentUser.finalQuote) setFinalQuote(currentUser.finalQuote);
    }, []);

    const fetchNewQuote = async () => {
        const response = await fetch(`${ORIGIN}/cp/get-qn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({ tempId: quoteId }),
        });
        const data = await response.json();
        setFinalQuote(data);
    };

    const changeOrderQty = (partNo, qty, availableQty) => {
        if (qty === '-' || qty <= 0) {
            setOrder((prevState) =>
                prevState.map((line) => {
                    if (line.quoteLineId === partNo) {
                        return {
                            ...line,
                            quantityRequested: null,
                        };
                    }
                    return line;
                })
            );
        }
        if (qty <= availableQty && qty >= 0) {
            setOrder((prevState) =>
                prevState.map((line) => {
                    if (line.quoteLineId === partNo) {
                        return {
                            ...line,
                            quantityRequested: Number(qty, 10),
                        };
                    }
                    return line;
                })
            );
        }
    };
    useEffect(() => {
        const selectedParts = order.filter((line) => line.selected);
        if (selectedParts.length > 0) {
            setArePartsSelected(true);
        } else {
            setArePartsSelected(false);
        }
    }, [order]);

    useEffect(() => {
        const leadTimeCheck = order.map((line) => {
            if (line.selected === true && line.leadTime !== 'Stock') {
                return true;
            }
            return false;
        });
        if (leadTimeCheck.includes(true)) {
            setLeadTimeWarning(true);
        } else {
            setLeadTimeWarning(false);
        }
    }, [order]);
    const handleSelect = (partNo) => {
        setOrder((prevState) =>
            prevState.map((line) => {
                if (line.quoteLineId === partNo) {
                    return {
                        ...line,
                        selected: !line.selected,
                        isDar: false,
                    };
                }
                return line;
            })
        );
    };

    /////////////////////////////
    // Advanced Options Functions
    /////////////////////////////

    // updates the order with all rows selected
    const handleSelectAll = () => {
        const allSelected = order.every((line) => line.selected);

        setOrder((prevState) =>
            prevState.map((line) => {
                return {
                    ...line,
                    selected: !allSelected,
                };
            })
        );
    };

    const updateSetAdvancedOptionSelection = (key, value) => {
        setAdvancedOptionSelection((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // for useEffect
    const handleAllSelectedToggle = () => {
        const selectedParts = order.filter((line) => line.selected);
        updateSetAdvancedOptionSelection('allSelected', selectedParts.length === order.length);
    };

    // Toggles all rows that are stock and price available (Add to Cart)
    const handleSelectAllCart = () => {
        const allSelected = order.every((line) => line.makeOffer || line.selected);

        setOrder((prevState) =>
            prevState.map((line) => {
                return {
                    ...line,
                    selected: !line.makeOffer ? !allSelected : line.selected,
                };
            })
        );

        updateSetAdvancedOptionSelection('allStockSelected', false);
    };

    // for useEffect
    const handleAllCartSelectedToggle = () => {
        const eligibleCartParts = order.filter((line) => !line.makeOffer);
        const selectedCartParts = eligibleCartParts.filter((line) => line.selected);
        updateSetAdvancedOptionSelection(
            'allCartSelected',
            eligibleCartParts.length > 0 && selectedCartParts.length === eligibleCartParts.length
        );
    };

    // Toggles all rows that have stock, but price is not available (Add to RFQ)
    const handleSelectAllRFQ = () => {
        const allSelected = order.every((line) => !line.makeOffer || line.selected);

        setOrder((prevState) =>
            prevState.map((line) => {
                return {
                    ...line,
                    selected: line.makeOffer ? !allSelected : line.selected,
                };
            })
        );
    };

    // for useEffect
    const handleAllRFQSelectedToggle = () => {
        const eligibleRFQParts = order.filter((line) => line.makeOffer);
        const selectedRFQParts = eligibleRFQParts.filter((line) => line.selected);
        updateSetAdvancedOptionSelection(
            'allRFQSelected',
            eligibleRFQParts.length > 0 && selectedRFQParts.length === eligibleRFQParts.length
        );
    };

    // Toggles all Add to Cart and updates quantity to max available
    const handleBuyAllAvailable = () => {
        // const allSelected = order.every((line) => line.makeOffer || line.selected);
        if (!advancedOptionSelection.allCartSelected) {
            handleSelectAllCart();
        }
        if (advancedOptionSelection.allStockSelected) {
            handleSelectAllCart();
        }
        setOrder((prevState) => {
            return prevState.map((line) => {
                if (!line.makeOffer) {
                    const newQty = advancedOptionSelection.allStockSelected ? 1 : line.availableQty;
                    changeOrderQty(line.quoteLineId, newQty, line.availableQty);
                    line.qty = newQty;
                }
                return line;
            });
        });
        updateSetAdvancedOptionSelection('allStockSelected', !advancedOptionSelection.allStockSelected);
    };

    // Updates the options dropdown on screen to match what the functionality is doing
    useEffect(() => {
        handleAllSelectedToggle();
        handleAllCartSelectedToggle();
        handleAllRFQSelectedToggle();
    }, [order]);

    const handleSubmitText = () => {
        let isRfqOnly = '';
        let isCart = '';

        order.forEach((detail) => {
            if (detail.makeOffer === true && detail.selected === true) {
                isRfqOnly = ' Submit RFQ';
            }
            if (detail.makeOffer === false && detail.selected === true) {
                isCart = 'Proceed to Cart';
            }
        });

        if (isRfqOnly && isCart) {
            return `${isCart} / ${isRfqOnly}`;
        }

        return isRfqOnly + isCart;
    };

    useEffect(() => {
        setSurcharges({
            darFee: orderDetails.totalDarFee,
            aogFee: orderDetails.isAOG ? orderDetails.AOGFee : '',
            creditCardFee: '',
            total: '',
            isCreditCard: orderDetails.isCreditCard,
        });
        handleSubmitText();
    }, [order]);

    const handleSubmitOrder = () => {
        const dataToSend = {
            quoteNumber: finalQuote.quoteNumber,
            quoteId: finalQuote.quoteId,
            shipAddressId: currentUser.selectedShipAddress.shipAddressId,
            shipAddressName: currentUser.selectedShipAddress.shipAddressName,
            shipMethodId: currentUser.selectedShipMethod.shipMethodId,
            shipMethodName: currentUser.selectedShipMethod.shipMethodName,
        };

        fetch(`${ORIGIN}/cp/save-quote/ship-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then((data) => {
                let orderDefaults = order.map((item) => {
                    if (item.selected) {
                        item.originalCost = item.unitPrice;
                    }
                    return item;
                });
                setOrder(orderDefaults);
                currentUser.order = order;
            })
            .then((data) => {
                currentUser.order = order;
                currentUser.finalQuote = finalQuote;
                currentUser.orderDetails = orderDetails;
                currentUser.surcharges = surcharges;
                currentUser.orderDetails.orderSubtotal = orderSubtotal;
                return history('/checkout');
            });
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                const inputs = document.querySelectorAll('input[type="number"]');
                const index = Array.prototype.indexOf.call(inputs, e.target);
                if (index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }
            }
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                const inputs = document.querySelectorAll('input[type="number"]');
                const index = Array.prototype.indexOf.call(inputs, e.target);
                if (index > 0) {
                    inputs[index - 1].focus();
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setNewPartsSearchLoading(true);

        const filteredData = validatePartsSearch(newPartInput);

        const dataToSend = {
            newParts: {
                parts: [...filteredData],
                contactId: currentUser.Id,
                accountId: currentUser.Account.Id,
            },
            currentParts: {
                found: [...order],
                contactId: currentUser.Id,
                accountId: currentUser.Account.Id,
                accountOwnerEmail: currentUser.Owner.Email,
                quoteNumber: finalQuote.quoteNumber,
                quoteId: finalQuote.quoteId,
            },
        };

        fetch(`${ORIGIN}/cp/add-parts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then((data) => {
                setMorePartsModal(false);
                setNewPartsSearchLoading(false);
                setOrder(data.found);
                if (data?.notFound?.length > 0) {
                    setPartsNotFound(data.notFound);
                }
                if (data?.substitutions?.length > 0) {
                    setSubstituteParts(data.substitutions);
                }
            })
            .catch((error) => {
                logError(error, 'AvailableInventory.js');
            });
    };

    useEffect(() => {
        const selectedParts = order.filter((line) => line.selected);
        if (selectedParts.length === 0) {
            setShowFeeTable(false);
            return;
        }
    }, [order]);

    /////////////////////////////
    // Sorting Section
    /////////////////////////////

    const sortData = (field, direction) => {
        const sortedData = [...order];

        sortedData.sort((a, b) => {
            let aValue = a[field];
            let bValue = b[field];

            // Convert leadTime to number for sorting
            if (field === 'leadTime') {
                const convertLeadTime = (value) => {
                    const lowercasedValue = value.toLowerCase();
                    if (lowercasedValue === 'stock') return 0;

                    const numberValue = parseInt(lowercasedValue.split(' ')[0], 10);

                    if (isNaN(numberValue)) {
                        console.error(`Unexpected leadTime format: ${value}`);
                        return 9999; // Default high value for unexpected formats
                    }

                    return numberValue;
                };

                aValue = convertLeadTime(aValue);
                bValue = convertLeadTime(bValue);
            }

            if (field === 'tagDate') {
                aValue = new Date(aValue).getTime();
                bValue = new Date(bValue).getTime();
            }

            // Sorting logic for numbers
            if (typeof aValue === 'number' && typeof bValue === 'number') {
                if (direction === 'asc') {
                    return aValue - bValue;
                } else {
                    return bValue - aValue;
                }
            }
            // Sorting logic for strings
            else {
                if (direction === 'asc') {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            }
        });

        setOrder(sortedData);
    };

    return (
        <>
            <TermsAndConditionsBox />
            {docs && <DocModal setDocs={setDocs} docs={docs} />}
            <div className='w-full mx-auto  transition-all max-w-10xl'>
                <div className='flex justify-between items-end'>
                    <div className='flex space-x-2'>
                        <div className='flex flex-col-reverse'>
                            <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
                                Available Inventory
                            </h1>
                            {noQuote ? null : finalQuote ? (
                                <h1 className='text-xl leading-tight tracking-tight text-gray-900 transition-all'>
                                    {finalQuote.quoteNumber}
                                </h1>
                            ) : (
                                <div className='h-8 w-36 animate-pulse bg-gray-100 rounded-md mt-2 flex justify-center items-center'>
                                    <p className='text-sm text-gray-500'>loading quote</p>
                                </div>
                            )}
                        </div>
                        <div className='flex items-end m-1 rounded-md w-32 md:w-auto'>
                            <AdvancedFilterDropdown
                                originalOrders={originalOrders}
                                order={order}
                                setOrder={setOrder}
                                setNoResults={setNoResults}
                                totalParts={totalParts}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row justify-end mb-2 space-x-4'>
                        <div
                            onClick={() => {
                                getOrderCSV(currentUser, order, finalQuote.quoteNumber);
                            }}
                        >
                            <button
                                disabled={!finalQuote}
                                className='cursor-pointer hover:bg-blue-primary hover:text-white bg-white text-blue-primary border border-blue-primary font-bold py-2 md:px-4 rounded mt-4 ml-auto w-24 md:w-auto'
                            >
                                Download CSV
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    setMorePartsModal(true);
                                }}
                                disabled={!finalQuote}
                                className='cursor-pointer hover:bg-blue-primary hover:text-white bg-white text-blue-primary border border-blue-primary font-bold py-2 md:px-4 rounded mt-4 ml-auto w-24 md:w-auto'
                            >
                                Add more parts
                            </button>
                        </div>
                    </div>
                </div>

                <div>
                    <table>
                        <thead className='sticky top-0 z-10'>
                            <tr className='bg-blue-primary text-white'>
                                <th scope='col' className='px-3 py-2 font-normal w-36 relative'>
                                    <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                        Part Number
                                    </div>
                                    <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                        <AvailableInventorySortButton
                                            arrowDirections={arrowDirections}
                                            setArrowDirections={setArrowDirections}
                                            name='partNo'
                                            sortData={sortData}
                                        />
                                    </div>
                                </th>
                                <th scope='col' className='px-6 py-2 font-normal relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Description
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-2 font-normal relative w-36'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Condition
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='condition'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Trace
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='trace'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal relative w-36'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Tag
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='tag'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-36 relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Tag Date
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='tagDate'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-24 relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Docs
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-36 relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Lead Time
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='leadTime'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-40 relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute mr-2'>
                                            Available Qty
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='availableQty'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-36 text-center relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Qty to Order
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-28 relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Price Per Unit
                                        </div>
                                        <div style={{ bottom: '10px', right: '10px' }} className='absolute'>
                                            <AvailableInventorySortButton
                                                arrowDirections={arrowDirections}
                                                setArrowDirections={setArrowDirections}
                                                name='unitPrice'
                                                sortData={sortData}
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='px-3 py-4 font-normal w-28 text-center relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Request for DAR
                                        </div>
                                    </div>
                                </th>
                                <th scope='col' className='py-4 font-normal flex justify-around w-44 relative'>
                                    <AdvancedOptionsDropdown
                                        handleSelectAll={handleSelectAll}
                                        handleSelectAllCart={handleSelectAllCart}
                                        handleSelectAllRFQ={handleSelectAllRFQ}
                                        handleBuyAllAvailable={handleBuyAllAvailable}
                                        advancedOptionSelection={advancedOptionSelection}
                                        rfqAvailable={rfqAvailable}
                                        cartAvailable={cartAvailable}
                                    />
                                </th>

                                <th scope='col' className='px-3 py-4 font-normal w-36 relative'>
                                    <div className='flex flex-col justify-between h-full'>
                                        <div style={{ top: '8px', left: '10px' }} className='absolute'>
                                            Total Cost
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {noResults ? (
                                // If no results from filters, display the "No results found" message
                                <tr>
                                    <td
                                        colSpan='14'
                                        className='border px-4 py-2 text-centered'
                                        style={{ textAlign: 'center' }}
                                    >
                                        <p className='text-xl text-red-500 px-2 font-bold'>No results found.</p>
                                        <p className='text-xs text-red-500 px-2'>
                                            Either no results found, or parts do not match filters applied.
                                        </p>
                                    </td>
                                </tr>
                            ) : (
                                order.map((item) => (
                                    <tr key={item.quoteLineId} className='border-b border-gray-200 hover:bg-gray-100 h-20'>
                                        <td className='border px-4 py-2 font-semibold text-center'>
                                            {item.partNo}
                                            {item.originalPartNumber && (
                                                <>
                                                    <QuestionMarkCircleIcon
                                                        onMouseEnter={() => {
                                                            setOrder(
                                                                order.map((i) => {
                                                                    if (i.quoteLineId === item.quoteLineId) {
                                                                        return {
                                                                            ...i,
                                                                            showOriginalPartToolTip: true,
                                                                        };
                                                                    }
                                                                    return i;
                                                                })
                                                            );
                                                        }}
                                                        onMouseLeave={() => {
                                                            setOrder(
                                                                order.map((i) => {
                                                                    if (i.quoteLineId === item.quoteLineId) {
                                                                        return {
                                                                            ...i,
                                                                            showOriginalPartToolTip: false,
                                                                        };
                                                                    }
                                                                    return i;
                                                                })
                                                            );
                                                        }}
                                                        className='h-5 w-5 inline mt-0.5 text-blue-primary float-right cursor-pointer mr-2'
                                                    />
                                                    {item.showOriginalPartToolTip && (
                                                        <div className='absolute z-10 bg-gray-50 shadow-lg rounded-md p-2 w-36'>
                                                            <p className='text-xs text-gray-800'>
                                                                This is a substitute part for the part you originally
                                                                requested original part: ({item.originalPartNumber})
                                                            </p>
                                                        </div>
                                                    )}
                                                    <p className='text-xs text-gray-500'>
                                                        original part: ({item.originalPartNumber})
                                                    </p>
                                                </>
                                            )}
                                        </td>
                                        <td className='border px-4 py-2 text-center'> {item.description}</td>
                                        <td className='border px-4 py-2 text-center'>{item.condition}</td>
                                        <td className='border px-4 py-2'>
                                            <div className=' relative'>
                                                <div className='p-2 text-left'>{item.trace}</div>
                                                <div className='absolute -top-1 -right-3'>
                                                    {item.traceSources.length > 0 && (
                                                        <QuestionMarkCircleIcon
                                                            className='h-5 w-5 text-gray-400'
                                                            onMouseEnter={() => {
                                                                setOrder(
                                                                    order.map((i) => {
                                                                        if (i.quoteLineId === item.quoteLineId) {
                                                                            return {
                                                                                ...i,
                                                                                showTraceModal: true,
                                                                            };
                                                                        }
                                                                        return i;
                                                                    })
                                                                );
                                                            }}
                                                            onMouseLeave={() => {
                                                                setOrder(
                                                                    order.map((i) => {
                                                                        if (i.quoteLineId === item.quoteLineId) {
                                                                            return {
                                                                                ...i,
                                                                                showTraceModal: false,
                                                                            };
                                                                        }
                                                                        return i;
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    {item.showTraceModal && <SourceModal traceSources={item.traceSources} />}
                                                </div>
                                            </div>
                                        </td>
                                        <td className='border px-4 py-2'>
                                            <div className=' relative w-20'>
                                                <div className='p-2 text-left'> {item.tag}</div>
                                                {item.tagAgencies.length > 0 && (
                                                    <div className='absolute -top-1 -right-3'>
                                                        <QuestionMarkCircleIcon
                                                            className='h-5 w-5 text-gray-400'
                                                            onMouseEnter={() => {
                                                                setOrder(
                                                                    order.map((i) => {
                                                                        if (i.quoteLineId === item.quoteLineId) {
                                                                            return {
                                                                                ...i,
                                                                                showTagModal: true,
                                                                            };
                                                                        }
                                                                        return i;
                                                                    })
                                                                );
                                                            }}
                                                            onMouseLeave={() => {
                                                                setOrder(
                                                                    order.map((i) => {
                                                                        if (i.quoteLineId === item.quoteLineId) {
                                                                            return {
                                                                                ...i,
                                                                                showTagModal: false,
                                                                            };
                                                                        }
                                                                        return i;
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                        {item.showTagModal && (
                                                            <SourceModal traceSources={item.tagAgencies} />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className='border px-4 py-2 text-center'>{item.tagDate}</td>
                                        <td
                                            className='border px-4 py-2 cursor-pointer mr-2'
                                            onClick={() => {
                                                setDocs(item);
                                            }}
                                        >
                                            {item.traceLinks.length > 0 && (
                                                <DocumentTextIcon className='h-5 w-5 inline mt-0.5 text-gray-700 float-right cursor-pointer mr-2' />
                                            )}
                                        </td>
                                        <td className='border px-4 py-2 text-center'>{item.leadTime}</td>
                                        <td className='border px-4 py-2 text-center'>{item.availableQty}</td>
                                        <td className='border px-2 py-1'>
                                            <input
                                                type='number'
                                                min='0'
                                                disabled={isShowing}
                                                className='w-full border border-gray-300 shadow-sm'
                                                defaultValue={1}
                                                value={item.quantityRequested || ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    changeOrderQty(item.quoteLineId, e.target.value, item.availableQty)
                                                }
                                                onBlur={(e) => {
                                                    if (e.target.value < 1) {
                                                        e.target.value = 1;
                                                        changeOrderQty(item.quoteLineId, e.target.value, item.availableQty);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className='border px-4 py-2'>
                                            {item.makeOffer === true ? (
                                                <div className='text-center text-2xl'>*</div>
                                            ) : (
                                                formatAsCurrency(item.unitPrice)
                                            )}
                                        </td>
                                        <td
                                            className='border px-4 py-2'
                                            onMouseLeave={() => {
                                                setOrder(
                                                    order.map((i) => {
                                                        if (i.quoteLineId === item.quoteLineId) {
                                                            return {
                                                                ...i,
                                                                showDarWarning: false,
                                                            };
                                                        }
                                                        return i;
                                                    })
                                                );
                                            }}
                                        >
                                            <div
                                                onMouseEnter={() => {
                                                    const darInput = document.getElementById('dar');
                                                    if (!darInput.disabled) {
                                                        return;
                                                    }

                                                    setOrder(
                                                        order.map((i) => {
                                                            if (i.quoteLineId === item.quoteLineId) {
                                                                return {
                                                                    ...i,
                                                                    showDarWarning: true,
                                                                };
                                                            } else {
                                                                return {
                                                                    ...i,
                                                                    showDarWarning: false,
                                                                };
                                                            }
                                                        })
                                                    );
                                                }}
                                            >
                                                {item.makeOffer === false && item.condition === 'NE' ? (
                                                    <div className='center w-20'>
                                                        <div className='flex align-items-center'>
                                                            <div className='text-center mr-2'>
                                                                <p>{item.isDar ? 'Remove DAR' : 'Add DAR'}</p>
                                                            </div>

                                                            <input
                                                                type='checkbox'
                                                                id='dar'
                                                                disabled={orderDetails.isAOG || isShowing}
                                                                className='h-5 w-5 flex-shrink-0 disabled:cursor-pointer'
                                                                checked={item.isDar}
                                                                onChange={(e) => {
                                                                    setOrder((prevState) => {
                                                                        return prevState.map((i) => {
                                                                            if (i.quoteLineId === item.quoteLineId) {
                                                                                return {
                                                                                    ...i,
                                                                                    isDar: e.target.checked,
                                                                                    selected: true,
                                                                                };
                                                                            }
                                                                            return i;
                                                                        });
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {item.showDarWarning && (
                                                <div className='absolute z-10 bg-white border shadow-lg rounded-md p-2 w-36'>
                                                    <p className='text-xs text-gray-800'>
                                                        <p className='text-xs text-gray-800'>Order cannot be AOG and DAR</p>
                                                    </p>
                                                </div>
                                            )}
                                        </td>
                                        <td className='border px-4 py-2'>
                                            <ToggleButton
                                                isSelected={item.selected}
                                                partType={item.makeOffer}
                                                partNo={item.quoteLineId}
                                                handleSelect={handleSelect}
                                                isShowing={isShowing}
                                                setShowFeeTable={setShowFeeTable}
                                            />
                                        </td>
                                        <td className='border px-4 py-2 text-center'>
                                            {item.makeOffer === false ? (
                                                <p
                                                    className={classNames(
                                                        item.selected ? 'text-blue-primary font-semibold' : '',
                                                        'text-gray-400'
                                                    )}
                                                >
                                                    {formatAsCurrency(item.unitPrice * item.quantityRequested)}
                                                </p>
                                            ) : (
                                                <p
                                                    className={classNames(
                                                        item.selected ? 'text-blue-primary font-semibold' : '',
                                                        'text-gray-400'
                                                    )}
                                                >
                                                    For Review
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                            <tr>
                                <td colSpan='14' className='border px-4 py-2'>
                                    <p className='text-sm text-gray-400 px-2'>
                                        *Orders may be subject to minimum order fees. Which include ${orderDetails.DARFee}{' '}
                                        for DAR and ${orderDetails.AOGFee} for AOG. Credit card fees and sales tax may be
                                        applied where applicable
                                    </p>
                                    <p className='text-sm text-gray-400 px-2'>
                                        **This amount will be evenly distributed to all your parts on the order confirmation
                                        page
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <FeeTable
                        order={order}
                        orderDetails={orderDetails}
                        isDarOrder={isDarOrder}
                        setIsDarOrder={setIsDarOrder}
                        orderSubtotal={orderSubtotal}
                        setOrderSubtotal={setOrderSubtotal}
                        isShowing={showFeeTable}
                    />

                    {!noQuote && (
                        <div className='flex justify-end w-1/3 ml-auto space-x-12 py-4'>
                            <div
                                onMouseEnter={() => {
                                    setShowNoAogWarning(true);
                                }}
                                onMouseLeave={() => {
                                    setShowNoAogWarning(false);
                                }}
                            >
                                <button
                                    type='checkbox'
                                    disabled={isShowing || leadTimeWarning}
                                    onClick={() => {
                                        setOrderDetails((prevState) => {
                                            return {
                                                ...prevState,
                                                isAOG: !prevState.isAOG,
                                                totalDarFee: 0,
                                            };
                                        });
                                        setOrder((prevState) => {
                                            return prevState.map((i) => {
                                                return {
                                                    ...i,
                                                    isDar: false,
                                                };
                                            });
                                        });
                                        setIsDarOrder(false);
                                    }}
                                    id='aog-order'
                                    className={classNames(
                                        orderDetails.isAOG ? 'bg-blue-primary text-white' : 'bg-gray-300 text-gray-900',
                                        'font-semibold py-4 px-5  rounded   h-14 -mt-0 cursor-pointer'
                                    )}
                                >
                                    {orderDetails.isAOG ? <span className=''>Cancel AOG</span> : 'AOG'}
                                </button>
                                {showNoAogWarning && leadTimeWarning && (
                                    <div className='absolute z-50 bg-gray-100 border shadow-lg rounded-md p-2 w-36 m-2'>
                                        <p className='text-xs text-gray-800'>All part Lead Times must be stock</p>
                                    </div>
                                )}
                            </div>

                            {!isShowing && (
                                <button
                                    disabled={!arePartsSelected || !finalQuote}
                                    className='hover:text-blue-primary hover:bg-white border border-blue-primary bg-blue-primary text-white font-bold py-4 px-5  rounded  cursor-pointer text-lg flex items-center justify-center'
                                    onClick={() => {
                                        handleOrderStatus('submit');
                                    }}
                                >
                                    {!finalQuote && (
                                        <>
                                            <svg
                                                role='status'
                                                className='inline mr-3 w-4 h-4 text-white animate-spin'
                                                viewBox='0 0 100 101'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                                    fill='#E5E7EB'
                                                />
                                                <path
                                                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                                    fill='currentColor'
                                                />
                                            </svg>
                                            Loading...
                                        </>
                                    )}
                                    {!arePartsSelected && finalQuote && 'Select Parts'}
                                    {finalQuote && handleSubmitText()}
                                </button>
                            )}
                        </div>
                    )}
                </div>

                {showRFQNotification && (
                    <Notification
                        setShowSavedNotification={setShowSavedNotification}
                        showSavedNotification={showSavedNotification}
                        title={'RFQ Sent'}
                        desc={
                            'The RFQ has been successfully submitted. Your Pacific Air Account Rep will get back to you with a quote as soon as possible.'
                        }
                        status={'success'}
                    />
                )}

                {isShowing && (
                    <ShippingInformation
                        isShowing={isShowing}
                        setIsShowing={setIsShowing}
                        setShowShipping={setShowShipping}
                        showShipping={showShipping}
                    />
                )}

                {showShipping && (
                    <ShippingMethod setShowPO={setShowPO} showPO={showPO} handleSubmitOrder={handleSubmitOrder} />
                )}
            </div>
            {substituteParts && substituteParts.length > 0 && !isShowing && (
                <NotFoundInventoryTable data={substituteParts} title={'Alternate Parts'} />
            )}
            {partsNotFound.length > 0 && !isShowing && (
                <NotFoundInventoryTable data={partsNotFound} title={'Searched parts currently not in stock'} />
            )}
            <Notification
                setShowSavedNotification={setShowSavedNotification}
                showSavedNotification={showSavedNotification}
                title={'Your Quote has been successfully saved.'}
                desc={'To view your saved quotes, click on the `Quotes` tab in the navigation above.'}
                status={'success'}
            />
            {morePartsModal && (
                <MorePartsModal
                    handleSubmit={handleSubmit}
                    openEditModal={morePartsModal}
                    setOpenEditModal={setMorePartsModal}
                    setNewPartInput={setNewPartInput}
                    isLoading={newPartsSearchLoading}
                />
            )}
        </>
    );
}

const ToggleButton = ({ partType, partNo, handleSelect, isSelected, isShowing, setShowFeeTable }) => {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (isSelected) {
            setChecked(true);
        }
        if (!isSelected) {
            setChecked(false);
        }
    }, [isSelected]);
    let partName = '';
    partType === true ? (partName = 'RFQ') : (partName = 'Cart');

    useEffect(() => {
        if (isSelected && partType === false) {
            setShowFeeTable(true);
        }
    }, [checked]);

    return (
        <div className='flex justify-center items-start !mt-0'>
            <button
                disabled={isShowing}
                className={classNames(
                    !checked ? 'bg-blue-primary text-white' : 'bg-gray-300 text-gray-900 ',
                    ' font-semibold py-2 px-3 rounded-sm cursor-pointer'
                )}
                onClick={() => {
                    handleSelect(partNo);
                    setChecked(!checked);
                }}
            >
                {!checked ? `Add to ${partName}` : `Remove from ${partName}`}
            </button>
        </div>
    );
};

const SourceModal = ({ traceSources }) => {
    return (
        <>
            <div className='absolute z-10 bg-white border shadow-lg rounded-md p-2'>
                <p className='text-xs text-gray-800'>
                    {traceSources.map((item) => {
                        return <p className='text-xs text-gray-800 w-max'>{item}</p>;
                    })}
                </p>
            </div>
        </>
    );
};
