import React from 'react';

export default function GenericNavbar({ title }) {
    return (
        <nav className='bg-blue-primary p-4 h-[5vh] flex items-center'>
            <div className='container mx-auto'>
                <h1 className='text-white text-center text-2xl'>{title}</h1>
            </div>
        </nav>
    );
}
