import React from 'react';
import WMSUserPermissions from '../components/admin_settings/WMSUserPermissions';

export default function WMSAdminSettings() {
    return (
        <div className='p-8'>
            <WMSUserPermissions />
        </div>
    );
}
