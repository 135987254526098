import React, { useState, useEffect } from 'react';
import logError from '../../utils/errorUtil';
import { useAuth } from '../../portal/context/auth-context';
import { calculateMinOrderFee } from '../../utils/calculateFee';
import { formatAsCurrency } from '../../utils/formatAsCurrency';

export default function OrderSummary({ grandTotal, setGrandTotal, setCreditCardFee }) {
    const { currentUser } = useAuth();

    const [minOrderFee, setMinOrderFee] = useState(0);
    const [newOrder, setNewOrder] = useState([]);

    let order = currentUser.order;
    useEffect(() => {
        distrbuteMinOrderFee();
    }, [minOrderFee, order]);

    useEffect(() => {
        calculateMinOrderFee(
            order,
            currentUser.orderDetails.minOrder,
            currentUser.orderDetails.minAogOrder,
            currentUser.orderDetails.isAOG
        )
            .then((fee) => {
                if (fee === 0) return setMinOrderFee(0);
                let partsTotal = 0;
                order.map((item) => {
                    if (item.selected) {
                        partsTotal += item.unitPrice * item.quantityRequested;
                    }
                });

                let actualFee = fee - partsTotal;
                setMinOrderFee(actualFee.toFixed(2));
            })
            .then(() => {
                let grandTotal = calculateOrderTotal();

                setGrandTotal(grandTotal);
            })
            .catch((err) => logError(err, 'OrderSummary'));
    }, [minOrderFee]);

    const selectedOrder = newOrder.filter((item) => item.selected === true);
    const filteredOrder = selectedOrder.filter((item) => item.makeOffer !== true && item.unitPrice !== null);

    const calculateCreditCardFee = () => {
        let creditCardFee = 0;

        if (currentUser.surcharges.isCreditCard === false) {
            return 0;
        }
        order.map((item) => {
            if (item.selected) {
                creditCardFee += item.unitPrice * item.quantityRequested;
            }
        });

        if (currentUser.surcharges.darFee) {
            creditCardFee += currentUser.surcharges.darFee;
        }

        if (currentUser.surcharges.aogFee) {
            creditCardFee += currentUser.surcharges.aogFee;
        }

        creditCardFee = creditCardFee * currentUser.orderDetails.creditCardRate;
        setCreditCardFee(creditCardFee.toFixed(2));
        return creditCardFee.toFixed(2);
    };

    const distrbuteMinOrderFee = async () => {
        let numberOfPartsSelected = 0;
        let quantitySelected = 0;
        order.map((item) => {
            if (item.selected) {
                numberOfPartsSelected++;
            }
            if (item.selected && item.quantityRequested) {
                quantitySelected += item.quantityRequested;
            }
        });

        let amountToAddToEachPart = Number(minOrderFee) / (Number(quantitySelected) * Number(numberOfPartsSelected));

        //round up to nearest cent
        amountToAddToEachPart = Math.ceil(amountToAddToEachPart * 100) / 100;

        let newOrder = order.map((item) => {
            if (item.selected) {
                item.unitPrice += Number(amountToAddToEachPart);
            }
            return item;
        });
        setNewOrder(newOrder);
    };

    const calculateOrderTotal = () => {
        let orderTotal = 0;
        order.map((item) => {
            if (item.selected) {
                orderTotal += item.unitPrice * item.quantityRequested;
            }
        });

        if (currentUser.orderDetails.totalDarFee) {
            orderTotal += Number(currentUser.surcharges.darFee);
        }

        if (currentUser.surcharges.aogFee) {
            orderTotal += Number(currentUser.surcharges.aogFee);
        }
        orderTotal += Number(minOrderFee);

        if (currentUser.surcharges.isCreditCard === true) {
            orderTotal += orderTotal * Number(currentUser.orderDetails.creditCardRate);
        }
        return Number(orderTotal).toFixed(2);
    };

    return (
        <div className='h-full w-full border-2 border-gray-300 noDrag flex flex-col justify-between' draggable={false}>
            <div className='flex flex-col items-center justify space-y-4'>
                <div className='flex justify-center items-center bg-blue-primary w-full'>
                    <h2 className='text-3xl font-semibold h-12 text-white mt-2'>Order Summary</h2>
                </div>
                <table draggable={false} className='w-full text-center'>
                    <thead>
                        <tr className='border-b-2 border-gray-300 '>
                            <th className='text-center w- pb-3'>Item</th>
                            <th className='text-center w- pb-3'>Condition</th>
                            <th className='text-center w- pb-3'>Trace</th>
                            <th className='text-center w- pb-3'>Tag</th>
                            <th className='text-center w- pb-3'>Quantity</th>
                            <th className='text-right w- pb-3 px-4'>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrder.map((item) => (
                            <tr draggable={false} key={item.productId} className='border-b border-gray-300'>
                                <td className='text-center py-2'>{item.partNo}</td>
                                <td className='text-center py-2'>{item.condition}</td>
                                <td className='text-center py-2'>{item.trace}</td>
                                <td className='text-center py-2'>{item.tag}</td>
                                <td className='text-center py-2'>{item.quantityRequested}</td>
                                <td className='text-right py-2 px-2'>{formatAsCurrency(item.unitPrice)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='pt-12 w-full '>
                    <table className='w-1/2 ml-auto'>
                        <tbody className=''>
                            {currentUser.surcharges.darFee ? (
                                <tr className='border-b border-gray-300 '>
                                    <td className='text-left py-2 text-red-800'>
                                        DAR Fee<span className='p-1 text-red-700'>*</span>
                                    </td>
                                    <td className='text-right  py-2 pr-2'>${currentUser.surcharges.darFee}</td>
                                </tr>
                            ) : null}
                            {currentUser.surcharges.aogFee ? (
                                <tr className='border-b border-gray-300'>
                                    <td className='text-left py-2'>AOG Fee</td>
                                    <td className='text-right py-2 pr-2'>${currentUser.surcharges.aogFee}</td>
                                </tr>
                            ) : null}
                            {currentUser.surcharges.isCreditCard ? (
                                <tr className='border-b border-gray-300'>
                                    <td className='text-left w-1/2 py-2'>Credit Card Fee</td>
                                    <td className='text-right w-1/2 py-2 pr-2'>${calculateCreditCardFee()}</td>
                                </tr>
                            ) : null}

                            <tr className='border-b-2 border-gray-300'>
                                <td className='text-left w-1/2 py-2'>Grand Total</td>
                                <td className='text-right w-1/2 py-2 pr-2'>{formatAsCurrency(grandTotal)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {currentUser.surcharges.darFee ? (
                <div className='text-left text-red-800 pl-2 pb-1 text-sm italic'>
                    Need Quality approval to confirm DAR. We will let you know if your order needs to change.
                </div>
            ) : null}
        </div>
    );
}
