import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './ScannerUpload.css';
import logError from '../utils/errorUtil';

function ScannerUploadWebsite() {
    const [folders, setFolders] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false);

    useEffect(() => {
        async function getBtnInfo() {
            const btnInfo = await getDbxDir();
            setFolders(btnInfo);
        }
        getBtnInfo();
    }, []);

    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    let params =
        queryParams.get('type') === `DAR`
            ? {
                  id: queryParams.get('id'),
                  name: queryParams.get('name'),
                  type: queryParams.get('type'),
                  req: queryParams.get('req'),
              }
            : {
                  id: queryParams.get('id'),
                  name: queryParams.get('name'),
                  type: queryParams.get('type'),
                  part: queryParams.get('part'),
                  owner: queryParams.get('owner'),
              };

    async function handleBtnClick(data) {
        setDisableBtn(true);
        data.params = params;
        await btnClickUpdate(data);
        const dirInfo = await getDbxDir();
        setFolders(dirInfo);
        setDisableBtn(false);
    }

    function makeButton(data) {
        return (
            <Col xs='6' key={data.folderName}>
                <Button
                    className='scanner'
                    style={{ backgroundColor: data.color, borderColor: data.color, outlineColor: data.color }}
                    onClick={() => handleBtnClick(data)}
                    disabled={disableBtn}
                >
                    {data.folderName}
                    <br />
                    {disableBtn ? `Please Wait` : `${data.numberOfDocuments} Documents`}
                </Button>
            </Col>
        );
    }

    function makeRow(data) {
        return <Row key={data}>{data.map(makeButton, this)}</Row>;
    }

    function blankPage() {
        return (
            <div class='header' style={{ textAlign: 'center', fontSize: '3vw', marginTop: '65px' }}>
                Please scan documents to continue. Refresh this page after scanning.
            </div>
        );
    }

    return (
        <div>
            {params.part ? (
                <div style={{ textAlign: 'center', fontSize: '3vw' }}>
                    {params.name} || Part # {params.part} || {params.type}
                </div>
            ) : (
                <div style={{ textAlign: 'center', fontSize: '3vw' }}>
                    {params.name} || {params.type}
                </div>
            )}
            <Container fluid>{folders.length > 0 ? folders.map(makeRow, this) : blankPage()}</Container>
            <div style={{ fontSize: '3vw', margin: '20px', marginTop: '5px' }}>
                {folders.length > 0 &&
                    `**If any of the above buttons have the message 'Please Wait' for more than 5 seconds, then please refresh the browser.`}
            </div>
        </div>
    );
}

async function getDbxDir() {
    return new Promise((resolve, reject) => {
        try {
            fetch(`${origin}/receiving/scan-dbx`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
                mode: 'cors',
            })
                .then(function (result) {
                    return result.json();
                })
                .then(function (response) {
                    resolve(response);
                    return;
                })
                .catch((e) => {
                    logError(e, 'getDbxDir');
                    reject(e);
                });
        } catch (e) {
            reject(e);
        }
    });
}

async function btnClickUpdate(data) {
    return new Promise((resolve, reject) => {
        try {
            fetch(`${origin}/receiving/move-scan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                mode: 'cors',
            })
                .then(function (result) {
                    return result.json();
                })
                .then(function (response) {
                    resolve(response);
                    return;
                })
                .catch((e) => {
                    reject(e);
                });
        } catch (e) {
            reject(e);
        }
    });
}

export default ScannerUploadWebsite;
