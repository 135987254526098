export const searchStateEnum = {
    DEFAULT: 'DEFAULT',
    LOADING: 'LOADING',
    RESULTS: 'RESULTS',
    NORESULTS: 'NORESULTS',
};

export const defaultStatus = {
    error: false,
    message: 'Type to search',
    searchState: searchStateEnum.DEFAULT,
};

export const searchErrorStates = {
    NORESULTSFOUND: 'No results found',
};

export const defaultHeaderArray = [
    {
        header: 'Fetching Data',
        count: null,
    },
];

export const noResultsHeaderArray = [
    {
        header: 'No Results',
        count: null,
    },
];
