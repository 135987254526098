import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export default function AdvancedFilterDropdown({ originalOrders, setOrder, setNoResults, totalParts }) {
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedPartNumbers, setSelectedPartNumbers] = useState([]);
    const [selectedTraces, setSelectedTraces] = useState([]);
    const [selectedLeadTimes, setSelectedLeadTimes] = useState([]);
    const [selectedTraceLinks, setSelectedTraceLinks] = useState([]);
    const [filterByTraceLinks, setFilterByTraceLinks] = useState(false);
    const [selectedStockTypes, setSelectedStockTypes] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [filterByTag, setFilterByTag] = useState(false);
    const [filterHeight, setFilterHeight] = useState(200);
    const [scrollHeight, setScrollHeight] = useState(205);

    const [activeMenu, setActiveMenu] = useState(null);

    /////////////////////////////
    // Applying the Filters
    /////////////////////////////

    const applyFilters = () => {
        if (!originalOrders.length) return;
        let filteredOrders = [...originalOrders];

        if (selectedPartNumbers.length > 0) {
            filteredOrders = filteredOrders.filter((item) => selectedPartNumbers.includes(item.partNo));
        }

        if (selectedConditions.length > 0) {
            filteredOrders = filteredOrders.filter((item) => selectedConditions.includes(item.condition));
        }

        if (selectedTraces.length > 0) {
            filteredOrders = filteredOrders.filter((item) => selectedTraces.includes(item.trace));
        }
        if (selectedLeadTimes.length > 0) {
            filteredOrders = filteredOrders.filter((item) => selectedLeadTimes.includes(item.leadTime));
        }
        if (selectedStockTypes.length > 0) {
            filteredOrders = filteredOrders.filter((item) => {
                if (item.makeOffer && selectedStockTypes.includes('Add to RFQ')) return true;
                if (!item.makeOffer && selectedStockTypes.includes('Add to Cart')) return true;
                return false;
            });
        }

        if (filterByTraceLinks) {
            filteredOrders = filteredOrders.filter((item) => {
                let hasTraceLink = item.traceLinks.length >= 1;
                return filterByTraceLinks == hasTraceLink;
            });

            let newArray = [];
            for (let item of filteredOrders) {
                if (item.traceLinks?.length) {
                    newArray.push(item);
                }
            }
            if (newArray.length > 0) {
                setSelectedTraceLinks(newArray);
            }
            if (filteredOrders.length === 0) {
                setNoResults(true);
                return;
            }
        }

        if (filterByTag) {
            filteredOrders = filteredOrders.filter((item) => {
                let hasTag = item.tagAgencies.length >= 1;
                return filterByTag === hasTag;
            });

            let newArray = [];
            for (let item of filteredOrders) {
                if (item.tagAgencies?.length) {
                    newArray.push(item);
                }
            }
            if (newArray.length > 0) {
                setSelectedTag(newArray);
            }

            if (filteredOrders.length === 0) {
                setNoResults(true);
                return;
            }
        }

        if (
            filteredOrders.length === 0 &&
            (selectedPartNumbers.length > 0 ||
                selectedConditions.length > 0 ||
                selectedTraces.length > 0 ||
                selectedLeadTimes.length > 0 ||
                selectedTraceLinks.length > 0 ||
                selectedStockTypes.length > 0 ||
                selectedTag.length > 0)
        ) {
            setNoResults(true);
        } else {
            setNoResults(false);
            setOrder(filteredOrders);
        }
    };

    useEffect(() => {
        applyFilters();
    }, [
        selectedPartNumbers,
        selectedConditions,
        selectedTraces,
        selectedLeadTimes,
        filterByTraceLinks,
        selectedStockTypes,
        filterByTag,
    ]);

    /////////////////////////////
    // Stock Type
    /////////////////////////////

    const handleStockTypeCheckboxChange = (stockType) => {
        if (selectedStockTypes.includes(stockType)) {
            setSelectedStockTypes((prev) => prev.filter((item) => item !== stockType));
        } else {
            setSelectedStockTypes((prev) => [...prev, stockType]);
        }
    };

    const handleStockTypeFilter = () => {
        let stockTypes = [];
        for (const ord of originalOrders) {
            if (ord.makeOffer) {
                // Assuming ord.makeOffer is a boolean
                stockTypes.push('Add to RFQ');
            } else {
                stockTypes.push('Add to Cart');
            }
        }
        return [...new Set(stockTypes)];
    };

    const availableStockTypes = handleStockTypeFilter();
    const stockTypes = ['Add to RFQ', 'Add to Cart'];

    /////////////////////////////
    // Parts Filter
    /////////////////////////////

    const handlePartNoCheckboxChange = (partNo) => {
        if (selectedPartNumbers.includes(partNo)) {
            setSelectedPartNumbers((prev) => prev.filter((item) => item !== partNo));
        } else {
            setSelectedPartNumbers((prev) => [...prev, partNo]);
        }
    };

    const handlePartNoFilter = () => {
        const partNumbers = [];
        for (const ord of totalParts) {
            if (!partNumbers.includes(ord.partNo)) {
                partNumbers.push(ord.partNo);
            }
        }
        return partNumbers;
    };

    const availablePartNumbers = handlePartNoFilter();

    /////////////////////////////
    // Conditions Filter
    /////////////////////////////
    const handleConditionCheckboxChange = (condition) => {
        if (selectedConditions.includes(condition)) {
            setSelectedConditions((prev) => prev.filter((item) => item !== condition));
        } else {
            setSelectedConditions((prev) => [...prev, condition]);
        }
    };

    const handleConditionFilter = () => {
        let conditions = [];
        for (const ord of originalOrders) {
            if (!conditions.includes(ord.condition)) {
                conditions.push(ord.condition);
            }
        }
        return conditions;
    };

    const allConditions = ['AR', 'BER', 'BT', 'EX', 'FN', 'N/A', 'NE', 'NQ', 'NS', 'NU', 'OH', 'QP', 'RP', 'SV', 'US', 'XX'];
    const availableConditions = handleConditionFilter();
    const validConditions = allConditions.filter((condition) => availableConditions.includes(condition));
    const invalidConditions = allConditions.filter((condition) => !availableConditions.includes(condition));

    /////////////////////////////
    // Trace Filter
    /////////////////////////////

    const handleTraceCheckboxChange = (trace) => {
        if (selectedTraces.includes(trace)) {
            setSelectedTraces((prev) => prev.filter((item) => item !== trace));
        } else {
            setSelectedTraces((prev) => [...prev, trace]);
        }
    };

    const handleTraceFilter = () => {
        let traces = [];
        for (const ord of originalOrders) {
            if (!traces.includes(ord.trace)) {
                traces.push(ord.trace);
            }
        }
        return traces;
    };

    const availableTraces = handleTraceFilter();

    /////////////////////////////
    // Lead Time
    /////////////////////////////
    const handleLeadTimeCheckboxChange = (leadTime) => {
        if (selectedLeadTimes.includes(leadTime)) {
            setSelectedLeadTimes((prev) => prev.filter((item) => item !== leadTime));
        } else {
            setSelectedLeadTimes((prev) => [...prev, leadTime]);
        }
    };

    const handleLeadTimeFilter = () => {
        let leadTimes = [];
        for (const ord of originalOrders) {
            if (!leadTimes.includes(ord.leadTime)) {
                leadTimes.push(ord.leadTime);
            }
        }
        return leadTimes;
    };

    const availableLeadTimes = handleLeadTimeFilter();

    /////////////////////////////
    // Docs/Trace Links
    /////////////////////////////
    const handleTraceLinksCheckboxChange = () => {
        setFilterByTraceLinks((prev) => !prev);
    };

    /////////////////////////////
    // Tags
    /////////////////////////////
    const handleTagCheckboxChange = () => {
        setFilterByTag((prev) => !prev);
    };

    /////////////////////////////
    // Filter Height
    /////////////////////////////

    const handleFilterHeight = () => {
        let height = 207;
        if (totalParts.length > 1) {
            height += 65;
        }
        if (availableTraces.length > 1) {
            height += 40;
        }
        if (availableLeadTimes.length > 0 && new Set(availableLeadTimes).size > 1) {
            height += 40;
        }
        return height;
    };

    const handleScrollHeight = () => {
        let height = 163;
        if (totalParts.length > 1) {
            height += 64;
        }
        if (availableTraces.length > 1) {
            height += 41;
        }
        if (availableLeadTimes.length > 0 && new Set(availableLeadTimes).size > 1) {
            height += 41;
        }
        return height;
    };

    useEffect(() => {
        setFilterHeight(handleFilterHeight());
        setScrollHeight(handleScrollHeight());
    }, [totalParts, availablePartNumbers, availableLeadTimes]);

    /////////////////////////////
    // Clear Filters
    /////////////////////////////

    const handleClearFilters = () => {
        setOrder(originalOrders);
        setSelectedConditions([]);
        setSelectedPartNumbers([]);
        setSelectedTraces([]);
        setSelectedLeadTimes([]);
        setFilterByTraceLinks(false);
        setFilterByTag(false);
        setSelectedStockTypes([]);
    };

    return (
        <div className='rounded-md bg-gray-100 shadow-md hover:bg-gray-200'>
            <Menu as='div' className='relative inline-block text-left'>
                <div>
                    <Menu.Button className='inline-flex justify-center rounded-md px-4 py-1 text-lg text-blue-primary font-bold underline'>
                        Advanced Filters
                        <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 mt-1.5' aria-hidden='true' />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Menu.Items
                        className='absolute left-0 mt-2 origin-top-left divide-y divide-gray-100 rounded-md bg-white z-50 border'
                        style={{ width: '300px', height: `${filterHeight}px` }}
                    >
                        <div className='bg-blue-primary text-white py-2 px-4 rounded-md'>Filters</div>
                        <div className='flex flex-row'>
                            <div className='flex-none w-2/5'>
                                <div
                                    onMouseEnter={() => setActiveMenu(1)}
                                    className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                >
                                    Stock Type
                                </div>
                                {totalParts.length > 1 && (
                                    <div
                                        onMouseEnter={() => setActiveMenu(2)}
                                        className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                    >
                                        Part Numbers
                                    </div>
                                )}
                                <div
                                    onMouseEnter={() => setActiveMenu(3)}
                                    className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                >
                                    Conditions
                                </div>
                                {availableTraces.length > 1 && (
                                    <div
                                        onMouseEnter={() => setActiveMenu(4)}
                                        className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                    >
                                        Trace
                                    </div>
                                )}
                                <div
                                    onMouseEnter={() => setActiveMenu(5)}
                                    className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                >
                                    Tags
                                </div>
                                <div
                                    onMouseEnter={() => setActiveMenu(6)}
                                    className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                >
                                    Docs
                                </div>
                                {availableLeadTimes.length > 0 && new Set(availableLeadTimes).size > 1 && (
                                    <div
                                        onMouseEnter={() => setActiveMenu(7)}
                                        className='text-black py-2 px-4 hover:bg-gray-200 cursor-pointer border-b rounded-md'
                                    >
                                        Lead Time
                                    </div>
                                )}
                            </div>
                            <div
                                // set the className to have a dynamic height based on the number of items in the dropdown
                                className='flex-grow overflow-y-auto'
                                style={{ height: `${scrollHeight}px` }}
                            >
                                {activeMenu === null && (
                                    <div className='px-4 py-1 text-gray-700 h-6 text-sm'>
                                        Hover over an option to see more details.
                                    </div>
                                )}
                                {activeMenu === 1 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>
                                            Stock Type
                                        </div>
                                        {stockTypes.map((stockType) => (
                                            <label
                                                key={stockType}
                                                className={`block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6 ${
                                                    !availableStockTypes.includes(stockType)
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    type='checkbox'
                                                    className='mr-2 leading-tight'
                                                    value={stockType}
                                                    onChange={() => handleStockTypeCheckboxChange(stockType)}
                                                    checked={selectedStockTypes.includes(stockType)}
                                                    disabled={!availableStockTypes.includes(stockType)}
                                                />
                                                {stockType}
                                            </label>
                                        ))}
                                    </>
                                )}

                                {activeMenu === 2 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>
                                            Part Numbers
                                        </div>
                                        {totalParts.map((partNo) => (
                                            <label
                                                key={partNo}
                                                className='block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6'
                                            >
                                                <input
                                                    type='checkbox'
                                                    className='mr-2 leading-tight'
                                                    value={partNo}
                                                    onChange={() => handlePartNoCheckboxChange(partNo)}
                                                    checked={selectedPartNumbers.includes(partNo)}
                                                />
                                                {partNo}
                                            </label>
                                        ))}
                                    </>
                                )}
                                {activeMenu === 3 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>
                                            Conditions
                                        </div>
                                        {validConditions.concat(invalidConditions).map((value) => (
                                            <label
                                                key={value}
                                                className={`block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6 ${
                                                    !availableConditions.includes(value)
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    type='checkbox'
                                                    className='mr-2 leading-tight'
                                                    value={value}
                                                    onChange={() => handleConditionCheckboxChange(value)}
                                                    checked={selectedConditions.includes(value)}
                                                    disabled={!availableConditions.includes(value)}
                                                />
                                                {value}
                                            </label>
                                        ))}
                                    </>
                                )}
                                {activeMenu === 4 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>Trace</div>
                                        {availableTraces.map((trace) => (
                                            <label
                                                key={trace}
                                                className='block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6'
                                            >
                                                <input
                                                    type='checkbox'
                                                    className='mr-2 leading-tight'
                                                    value={trace}
                                                    onChange={() => handleTraceCheckboxChange(trace)}
                                                    checked={selectedTraces.includes(trace)}
                                                />
                                                {trace}
                                            </label>
                                        ))}
                                    </>
                                )}
                                {activeMenu === 5 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>Tags</div>
                                        <label className='block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6 hover:h-12'>
                                            <input
                                                type='checkbox'
                                                className='mr-2 leading-tight'
                                                onChange={handleTagCheckboxChange}
                                                checked={filterByTag}
                                            />
                                            Filter for parts that have Tags
                                        </label>
                                    </>
                                )}
                                {activeMenu === 6 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>Docs</div>
                                        <label className='block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6 hover:h-12'>
                                            <input
                                                type='checkbox'
                                                className='mr-2 leading-tight'
                                                onChange={handleTraceLinksCheckboxChange}
                                                checked={filterByTraceLinks}
                                            />
                                            Filter for parts that have Trace Docs
                                        </label>
                                    </>
                                )}
                                {activeMenu === 7 && (
                                    <>
                                        <div className='px-4 py-1 text-gray-700 h-6 text-sm border-b rounded-md'>
                                            Lead Time
                                        </div>
                                        {availableLeadTimes.length > 0 &&
                                            new Set(availableLeadTimes).size > 1 &&
                                            availableLeadTimes.map((leadTime) => (
                                                <label
                                                    key={leadTime}
                                                    className='block px-4 py-1 text-gray-700 hover:bg-gray-200 h-6'
                                                >
                                                    <input
                                                        type='checkbox'
                                                        className='mr-2 leading-tight'
                                                        value={leadTime}
                                                        onChange={() => handleLeadTimeCheckboxChange(leadTime)}
                                                        checked={selectedLeadTimes.includes(leadTime)}
                                                    />
                                                    {leadTime}
                                                </label>
                                            ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            {selectedConditions.length ||
            selectedPartNumbers.length ||
            selectedTraces.length ||
            selectedLeadTimes.length ||
            filterByTraceLinks ||
            selectedStockTypes.length ||
            filterByTag ? (
                <div
                    onClick={handleClearFilters}
                    className='inline-flex justify-center rounded-md py-2 text-gray-500 hover:text-blue-primary cursor-pointer pr-4'
                >
                    Clear Filters
                </div>
            ) : null}
        </div>
    );
}
