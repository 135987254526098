import React from 'react';

export default function NoOrder() {
    return (
        <div className='flex flex-col items-center justify-center h-[550px] pt-64 '>
            <div className='text-3xl font-bold'>The previously selected parts are no longer available. </div>
            <a href='/quotes'>
                <button className='bg-blue-primary text-white font-semibold px-4 py-2 rounded-md mt-4'>
                    Go back to quotes
                </button>
            </a>
        </div>
    );
}
