import React from 'react';

export default function PlaceholderText({ width, height, bgColor }) {
    return <div className={`animate-pulse bg-gray-200 rounded-lg ${bgColor} ${width} ${height}`} />;
}

PlaceholderText.defaultProps = {
    width: 'w-full',
    height: 'h-7',
    bgColor: 'bg-gray-200',
};
