// React
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

// Components
import config from '../utils/config';
import GenericNavbar from '../components/GenericNavbar';
import LoadingSpinner from '../components/LoadingSpinner';
import logError from '../utils/errorUtil';
import SimpleLogo from '../assets/images/simpleLogo.jpg';

export default function CmmIpcDocument() {
    const ORIGIN = config.origin;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObject = {};
    queryParams.forEach((value, key) => {
        queryParamsObject[key] = value;
    });

    const [documents, setDocuments] = useState([]);
    const [activeDocument, setActiveDocument] = useState(null);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingDocument, setLoadingDocument] = useState(false);

    const setActive = async (item) => {
        if (loadingDocument) return;
        if (item.pdf) {
            setActiveDocument(item);
        } else {
            setActiveDocument(item);
            setLoadingDocument(true);
            const token = queryParamsObject?.fahPAIsfo;
            const response = await fetch(`${ORIGIN}/document/cmmipc/download?fahPAIsfo=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    document: item,
                }),
            });

            if (!response.ok) {
                setActiveDocument({ ...item, error: true });
            }

            if (response.status === 204) {
                setActiveDocument({ ...item, empty: true });
            } else {
                const data = await response.json();
                const updatedDocuments = documents.map((doc) =>
                    doc.name === item.name ? { ...doc, ...data.document } : doc
                );
                setDocuments(updatedDocuments);
                setActiveDocument(data.document);
            }
            setLoadingDocument(false);
        }
    };

    async function fetchPageData() {
        try {
            const partNumber = queryParamsObject?.partNumber;
            const token = queryParamsObject?.fahPAIsfo;
            const id = queryParamsObject?.id;

            const response = await fetch(`${ORIGIN}/document/cmmipc?fahPAIsfo=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    partNumber,
                    id,
                }),
            });

            if (!response.ok) {
                setActiveDocument({ error: true });
                throw new Error('Error fetching document');
            }

            if (response.status === 204) {
                setDocuments([]);
                setActiveDocument({ empty: true });
            } else {
                const data = await response.json();
                const updatedDocuments = data.documents.map((doc) =>
                    doc.name === data.document.name ? { ...doc, ...data.document } : doc
                );

                setDocuments(updatedDocuments ?? []);
                setActiveDocument(data.document ?? null);
            }
        } catch (error) {
            logError(error, 'CMM+IPC fetchPageData');
            setDocuments([{ name: 'Error fetching documents' }]);
        } finally {
            setLoadingPage(false);
        }
    }

    useEffect(() => {
        if (loadingPage) {
            fetchPageData();
        }
    }, []);

    return (
        <div className='flex flex-col min-h-screen'>
            <GenericNavbar title='CMM+IPC' />
            {loadingPage ? (
                <div className='flex items-center justify-center flex-grow'>
                    <div className='text-center -mt-28'>
                        <LoadingSpinner />
                        <p className='pt-6 text-2xl font-semibold'>Loading Documents...</p>
                    </div>
                </div>
            ) : (
                <div className='flex flex-grow p-4'>
                    <div className='w-[30%] border-gray-300'>
                        <h2 className='mb-6 text-2xl font-semibold text-center underline'>Table of Contents</h2>
                        <div className='h-full overflow-auto'>
                            <table className='w-full border'>
                                <thead className='sticky top-0 border bg-blue-primary border-blue-primary'>
                                    <tr className='w-full'>
                                        <th className='w-full p-2 text-left text-white'>Document Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.length ? (
                                        documents.map((item, index) => (
                                            <DocumentRow
                                                item={item}
                                                index={index}
                                                active={activeDocument?.name === item.name}
                                                setActive={setActive}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td className='p-2 text-center'>
                                                No documents were found for this part number.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='w-[70%] pl-4 flex-grow flex items-center justify-center sticky left-[30%] top-[1%] h-[93vh]'>
                        {activeDocument?.error ? (
                            <div className='flex items-center justify-center flex-grow'>
                                <main className='px-6 bg-white lg:px-8'>
                                    <div className='text-center'>
                                        <div className='flex justify-center'>
                                            <img className='w-auto h-40' src={SimpleLogo} alt='Pacific Air Logo' />
                                        </div>
                                        <p className='text-base font-semibold text-indigo-600'>404</p>
                                        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                                            Error loading document.
                                        </h1>
                                        <p className='mt-6 text-base leading-7 text-gray-600'>
                                            There was an error loading the document from this URL.
                                        </p>
                                    </div>
                                </main>
                            </div>
                        ) : !loadingDocument ? (
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <h2 className='mb-6 text-2xl font-semibold text-center'>
                                    Part Number: {queryParamsObject?.partNumber}
                                </h2>
                                <div className='flex items-center justify-center w-full h-full border-2 border-gray-300'>
                                    {activeDocument?.empty ? (
                                        <p className='text-xl font-semibold text-center underline'>No document was found.</p>
                                    ) : (
                                        <embed
                                            src={`data:application/pdf;base64,${activeDocument?.pdf}`}
                                            type='application/pdf'
                                            className='w-full h-full'
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='flex self-start items-center justify-center fixed top-[25%] left-[50%]'>
                                <LoadingSpinner />
                                <p className='mt-20 ml-4 text-2xl font-semibold'>Loading Document...</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

function DocumentRow({ item, index, active, setActive }) {
    return (
        <tr
            className={`flex flex-col items-center w-full cursor-pointer ${active ? 'border-2 border-gray-400 ' : ''}`}
            onClick={() => setActive(item)}
        >
            <div
                key={index}
                className={`border-b ${index % 2 === 1 ? 'bg-gray-200' : ''} flex justify-between items-center w-full`}
            >
                <div className='p-2 ml-4'>
                    <a
                        href={item.documentLink ?? '#'}
                        className='text-blue-500 hover:underline'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {item.name}
                    </a>
                </div>
                <div className='flex items-center justify-between w-16 mr-4'>
                    {item.pdf ? <CheckIcon className='w-5 h-5 text-green-500' /> : null}
                    <button className='p-1 border rounded'>
                        {active ? <ChevronDownIcon className='w-5 h-5' /> : <ChevronRightIcon className='w-5 h-5' />}
                    </button>
                </div>
            </div>
            {active ? (
                <div className='w-[95%] mb-2'>
                    <div className='w-full border'>
                        <div className='sticky top-0 bg-gray-200 border'>
                            <div className='flex justify-around font-bold'>
                                <h3 className='p-2 text-left'>Page #</h3>
                                <h3 className='p-2 text-right'>From Page</h3>
                            </div>
                        </div>
                        <div>
                            {item?.pages?.map((page, index) => (
                                <div className='flex justify-around'>
                                    <p className='p-2 text-left '>{index + 1}</p>
                                    <p className='p-2 text-right '>{page}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </tr>
    );
}
