import React, { useState, useEffect } from 'react';
import LoadingButtonFixedWidth from '../../../components/LoadingButtonFixedWidth';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import WildCardResults from '../dashboard/WildCardResults';

export default function WildCardSearch({
    onSubmit,
    wildcardIsLoading,
    setNoPartsFound,
    results,
    addToBulkSearch,
    buttonDisabled,
    setButtonDisabled,
    removeFromBulkSearch,
    data,
}) {
    const [isShowing, setIsShowing] = useState(false);
    const [inputField, setInputField] = useState('');
    const [hoveredOrderIcon, setHoveredOrderIcon] = useState(null);
    const [orderLine, setOrderLine] = useState(null);
    const [addedItems, setAddedItems] = useState([]);
    const [allAdded, setAllAdded] = useState(false);

    useEffect(() => {
        if (!data || data.length === 0) {
            setAllAdded(false);
        }
    }, [data]);

    return (
        <div>
            <h2 className='text-3xl font-semibold py-2 mb-6 px-12 text-center text-white bg-blue-primary'>
                Wild Card Search
            </h2>
            <div className='relative'>
                <p className='pl-4 text-gray-600 text-center'>
                    Enter at least 2 characters to find parts that match closest.{' '}
                    <QuestionMarkCircleIcon
                        onMouseEnter={() => setIsShowing(true)}
                        onMouseLeave={() => setIsShowing(false)}
                        className='h-5 w-5 inline-block'
                    />
                </p>
                {isShowing && (
                    <Transition
                        appear={true}
                        show={isShowing}
                        enter='transition-opacity duration-200'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity duration-150'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='absolute right-0 md:-top-30 md:right-10 bg-blue-primary p-4 w-36 text-lg'>
                            <p className='pl-4  text-white'>
                                <strong>Example:</strong>
                                <br />
                                <u>G384</u>
                                <br />
                                <strong>Result:</strong>
                                <br />
                                <u>G384</u>0
                                <br />
                                <u>G384</u>5678
                                <br />
                                123<u>G384</u>
                            </p>
                        </div>
                    </Transition>
                )}
            </div>
            <form
                className='px-12 py-4'
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(inputField);
                    setButtonDisabled(true);
                }}
            >
                <input
                    onChange={(e) => {
                        setInputField(e.target.value);
                        setNoPartsFound(false);
                    }}
                    style={{ resize: 'both' }}
                    className='w-full px-3 py-2 text-base text-gray-700 placeholder-gray-400 border rounded-lg focus:shadow-outline resize-y focus:outline-gray-200 '
                    placeholder='Enter wild card part to search for'
                    rows={7}
                    value={inputField}
                    type='text'
                />
            </form>
            {!wildcardIsLoading ? (
                <button
                    className='bg-blue-primary hover:bg-blue-primary text-white font-bold py-2 px-4 rounded mx-auto cursor-pointer w-1/2'
                    onClick={() => {
                        onSubmit(inputField);
                        setButtonDisabled(true);
                    }}
                    disabled={inputField === '' || buttonDisabled}
                >
                    Wild Card Search
                </button>
            ) : (
                <LoadingButtonFixedWidth />
            )}
            {results ? (
                <div className='text-center text-gray-600 pt-2 grid grid-cols-3 items-center'>
                    <div className='col-span-1 col-start-2 text-center'>{results?.length} Results found.</div>
                    <div className='col-start-3'>
                        {allAdded ? (
                            <p
                                className='text-gray-400 hover:text-red-500 underline font-bold py-1 px-2 text-xs rounded cursor-pointer'
                                onClick={() => {
                                    results.forEach((result) => {
                                        removeFromBulkSearch(result);
                                        setAddedItems((prevItems) => prevItems.filter((item) => !results.includes(item)));
                                    });
                                    setAllAdded(false);
                                }}
                            >
                                Remove All From Bulk Search
                            </p>
                        ) : (
                            <p
                                className='hover:text-green-500 underline text-gray-400 font-bold px-2 text-xs rounded cursor-pointer'
                                onClick={() => {
                                    results.map((result) => {
                                        addToBulkSearch(result);
                                        setAddedItems((prevItems) => [...prevItems, result]);
                                    });
                                    setAllAdded(true);
                                }}
                            >
                                Add All To Bulk Search
                            </p>
                        )}
                    </div>
                </div>
            ) : (
                <div className='h-8'></div>
            )}

            <WildCardResults
                results={results}
                addToBulkSearch={addToBulkSearch}
                removeFromBulkSearch={removeFromBulkSearch}
                addedItems={addedItems}
                setAddedItems={setAddedItems}
                setHoveredOrderIcon={setHoveredOrderIcon}
                setOrderLine={setOrderLine}
                hoveredOrderIcon={hoveredOrderIcon}
                orderLine={orderLine}
            />
        </div>
    );
}
