import React from 'react';
import { useAuth } from '../portal/context/auth-context';

function ErrorBoundaryWrapper(Component) {
    return function WrappedComponent(props) {
        const { currentUser } = useAuth();
        return <Component {...props} currentUser={currentUser} />;
    };
}

export default ErrorBoundaryWrapper;
