import _ from 'lodash';
import config from '../../../../../utils/config';
import logError from '../../../../../utils/errorUtil';
import { documentKeys, inputVerificationTypes, overrideDefaults } from './data';
import { PDFDocument } from 'pdf-lib';
import { mergePdfs, openDocumentInNewTab } from '../../../../utils/common-utils';

export const getFormDataForReleaseLine = () => {};

const ORIGIN = config.origin;

export const getDataForDocuments = async (releaseNumber, selectedReleaseLineName, currentUser) => {
    let dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        releaseNumber,
        selectedReleaseLineName,
        userName: currentUser?.authentication?.userName,
    };

    const response = await fetch(`${ORIGIN}/wms/documents/get-release-document-info`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get data for documents');

    const responseData = await response.json();

    return responseData;
};

export const printGeneratedDocuments = async (documents, currentUser, isInternational, isWillCall) => {
    let dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        documents,
    };

    await fetch(`${ORIGIN}/wms/documents/get-documents`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    })
        .then((res) => res.json())
        .then((data) => {
            if (!data) throw Error();

            for (const key in data) {
                const bufferData = data[key].data;
                let count = 1;

                if (isInternational && key === 'packingSheetPriced') {
                    count = 4;
                }

                if (isWillCall && key === 'packingSheetUnpriced') {
                    count = 3;
                }

                const dataToPrint = Array(count)
                    .fill(0)
                    .map(() => {
                        return new Uint8Array(bufferData).buffer;
                    });

                mergePdfPagesAndOpen(dataToPrint);
            }
        })
        .catch((err) => {
            logError(err, 'printGeneratedDocuments');
            throw Error();
        });
};

export const mergePdfPagesAndOpen = async (pdfData) => {
    const mergedData = await mergePdfs(null, pdfData);

    openDocumentInNewTab(mergedData);
};

export const submitUpdatedHeaderData = async (currentUser, updatedPackingSheetData) => {
    try {
        let dataToSend = {
            updatedPackingSheetData,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        await fetch(`${ORIGIN}/wms/trace/update-release-header`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });
    } catch (error) {
        logError(error, 'printAllPickingLabels');
    }
};

export const addOverridesToFormData = (formOverrides, documentData) => {
    const updatedDocumentData = {};

    // Go through each document
    for (const documentName in formOverrides) {
        if (!(documentName in documentData)) continue;

        const overrideDocument = _.clone(formOverrides[documentName]);
        const documentToUpdate = _.clone(documentData[documentName]);
        const defaults = _.clone(overrideDefaults[documentName]);

        for (const document of documentToUpdate) {
            // Go through each key to check for updates
            for (const override in overrideDocument) {
                // Skip overriding all data for pack sheets since there is more than one line
                const isPackSheet =
                    documentName === documentKeys.PACKING_SLIP_PRICED || documentName === documentKeys.PACKING_SLIP_UNPRICED;
                if (isPackSheet) {
                    if (!(override in defaults)) continue;

                    if (document[override] !== overrideDocument[override]) {
                        document[override] = overrideDocument[override];
                    }
                    continue;
                }

                if (document[override] !== overrideDocument[override]) {
                    document[override] = overrideDocument[override];
                }
            }
        }

        updatedDocumentData[documentName] = _.clone(documentToUpdate);
    }

    return updatedDocumentData;
};

export const isInputValid = (input, verificationType) => {
    if (verificationType === inputVerificationTypes.NUMBERS_ONLY) {
        return hasOnlyNumbers(input);
    }

    if (verificationType === inputVerificationTypes.TEXT_ONLY) {
        return hasOnlyText(input);
    }
};

export const hasOnlyNumbers = (value) => {
    if (value === '') return true;
    return /^\d+$/.test(value);
};

export const hasOnlyText = (value) => {
    if (value === '') return true;
    return /^[A-Za-z]+$/.test(value);
};
