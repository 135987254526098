import React, { useContext, useState } from 'react';
import PackingCamera from '../packing/PackingCamera';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TraceContext } from './Context/TraceContextWrapper';

export default function CameraContainer({ handlePhotoSubmission, selectedInventoryName, handlePhotoEnlarge, isLoading }) {
    const { handlePhotoAddition } = useContext(TraceContext);
    const [photos, setPhotos] = useState([]);

    const handlePhotoUpload = (photo) => {
        setPhotos([...photos, photo.dataUri]);
    };

    const handleRemovePhoto = (uriToDelete) => {
        const updatedPhotos = photos.filter((photoUri) => photoUri !== uriToDelete);
        setPhotos(updatedPhotos);
    };

    const submitPhotos = () => {
        const updatedPhotoData = photos.map((photoUri) => {
            const name = crypto.randomUUID();
            return {
                name,
                base64: photoUri.replace('data:image/png;base64,', ''),
                isHero: false,
            };
        });

        // Add photos to currently selected inventory line and reset photo list
        handlePhotoSubmission(updatedPhotoData);
        handlePhotoAddition(updatedPhotoData);
        setPhotos([]);
    };

    return (
        <section className={'flex flex-col w-[30%] items-start h-[90%] pl-8'}>
            <PackingCamera photoUpload={handlePhotoUpload} />
            <article className='w-full flex flex-row flex-wrap gap-2'>
                {photos.map((photoUri, index) => {
                    return (
                        <div key={index} className='relative'>
                            <img
                                className='hover:cursor-pointer'
                                src={photoUri}
                                onClick={() => handlePhotoEnlarge(photoUri)}
                            />
                            <button
                                onClick={() => handleRemovePhoto(photoUri)}
                                className='absolute top-4 right-4 p-2 bg-gray-200 rounded-full font-semibold text-xl hover:bg-white'
                            >
                                <XMarkIcon className='w-8 h-8 text-red-600' />
                            </button>
                        </div>
                    );
                })}
            </article>
            <div className='w-full flex justify-end mt-5'>
                <button
                    type='button'
                    disabled={!photos.length || !selectedInventoryName || isLoading}
                    className='cursor-pointer rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                    onClick={() => submitPhotos()}
                >
                    Add Photos
                </button>
            </div>
        </section>
    );
}
