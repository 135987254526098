import React, { useState, useEffect } from 'react';
import Notification from '../../components/Notification.js';
import logError from '../../utils/errorUtil.js';

// Components and Util Imports
import config from '../../utils/config';

export default function MFAKey() {
    // Current User information and the origin
    const ORIGIN = config.origin;

    // Parts to send in the text box
    const [mfaKey, setMfaKey] = useState('');
    // Loading state for the submit button
    const [isLoading, setIsLoading] = useState(false);
    // State for the notification
    const [showNotification, setShowNotification] = useState(false);
    const [tempNotification, setTempNotification] = useState({
        title: '',
        message: '',
        status: '',
    });

    useEffect(() => {
        if (tempNotification.title) {
            setTimeout(() => {
                setShowNotification(false);
                setTempNotification({
                    title: '',
                    message: '',
                    status: '',
                });
            }, 5000);
        }
    }, [showNotification, tempNotification]);

    useEffect(() => {
        if (mfaKey) {
            setTimeout(() => {
                setMfaKey('');
            }, 15000);
        }
    }, [mfaKey]);

    // Function that gets the MFA key from the server
    async function onSubmit(e) {
        try {
            e?.preventDefault();
            setIsLoading(true);

            const response = await fetch(`${ORIGIN}/wms/get-mfa-key`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Response not ok');
            }

            const mfaKey = await response.json();

            if (!mfaKey) {
                throw new Error();
            }

            setIsLoading(false);
            setMfaKey(mfaKey);
        } catch (error) {
            logError(error, 'WMSDataScrape onSubmit');
            setTempNotification({
                title: 'Error Getting Key',
                message: 'Failed to get the MFA key. Please try again.',
                status: 'error',
            });
            setShowNotification(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <Notification
                showSavedNotification={showNotification}
                setShowSavedNotification={setShowNotification}
                title={tempNotification?.title}
                desc={tempNotification?.message}
                status={tempNotification?.status}
            />
            <div className='flex h-[calc(100vh-64px)] items-center justify-center'>
                <div className='flex flex-col items-center'>
                    <label className='mb-2 text-lg text-center'>
                        Production Salesforce
                        <br />
                        Mark Brooks
                    </label>
                    <div className='p-2 border border-gray-500 rounded w-56 h-14 flex items-center justify-center'>
                        {mfaKey && (
                            <>
                                <CircleSpinner />
                                <p className='text-3xl'>{mfaKey}</p>
                                <button
                                    type='button'
                                    onClick={() => {
                                        navigator.clipboard.writeText(mfaKey);
                                        setTempNotification({
                                            title: 'Copied to Clipboard',
                                            message: 'MFA Key copied to clipboard.',
                                            status: 'success',
                                        });
                                        setShowNotification(true);
                                    }}
                                    className='ml-2 w-10 h-6 bg-blue-primary text-white rounded'
                                >
                                    Copy
                                </button>
                            </>
                        )}
                    </div>

                    {!isLoading ? (
                        <button
                            disabled={mfaKey}
                            type='submit'
                            onClick={() => onSubmit()}
                            className='mt-4 px-4 py-2 bg-blue-primary text-white rounded'
                        >
                            Get Key
                        </button>
                    ) : (
                        <button disabled type='button' className='mt-4 px-4 py-2 bg-blue-primary text-white rounded'>
                            <svg
                                role='status'
                                className='inline mr-3 w-4 h-4 text-white animate-spin'
                                viewBox='0 0 100 101'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                    fill='#E5E7EB'
                                />
                                <path
                                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                    fill='currentColor'
                                />
                            </svg>
                            {'Loading'}...
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

const CircleSpinner = () => {
    const [percent, setPercent] = useState(100);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercent((prevPercent) => {
                if (prevPercent > 0) return prevPercent - 100 / 15;
                clearInterval(interval);
                return 0;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}
            className='w-14 h-14 pr-2'
        >
            <svg style={{ transform: 'rotate(-90deg)' }} viewBox='0 0 50 50'>
                <circle cx='25' cy='25' r='20' fill='transparent' stroke='white' strokeWidth='5' />
                <circle
                    cx='25'
                    cy='25'
                    r='20'
                    fill='transparent'
                    stroke='blue'
                    strokeWidth='5'
                    strokeDasharray='125.6' // Circumference of the circle
                    strokeDashoffset={`${125.6 - (125.6 * percent) / 100}`}
                />
            </svg>
        </div>
    );
};
