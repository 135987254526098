import React, { useEffect, useState } from 'react';
import ControlHeader from '../components/receiving/ControlHeader';
import ReceivingPanel from '../components/receiving/ReceivingPanel';
import ActionPanel from '../components/receiving/ActionPanel';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAuth } from '../../wms/context/wms-context';
import Notification from '../../components/Notification';
import ReceiveNewLineModal from '../components/receiving/ReceiveNewLineModal';
import { getTableStructures } from '../../utils/commonAPICalls';
import { useSearchParams } from 'react-router-dom';

export default function WMSReceiving() {
    const { currentUser, setCurrentUser } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState({
        loading: false,
        error: false,
        errorMessage: '',
        receivingPanel: false,
        actionPanel: false,
        receiveNewLinePanel: false,
    });
    const closeActionPanel = () => {
        if (searchParams.has('editinventory')) {
            searchParams.delete('editinventory');
            searchParams.delete('inventoryId');
            setSearchParams(searchParams);
        }
        setStatus({ ...status, actionPanel: false });
        setCurrentUser({ ...currentUser, receiving_inventoryId: null, receiving_inventoryLine: null });
        currentUser.receiving_inventoryId = null;
        currentUser.receiving_inventoryLine = null;
    };

    useEffect(() => {
        getTableStructures(currentUser, setCurrentUser);
    }, []);

    // Check for search params
    useEffect(() => {
        if (searchParams.has('editinventory')) {
            setStatus((prevStatus) => ({
                ...prevStatus,
                actionPanel: true,
            }));
        }
    }, [searchParams])

    return (
        <div>
            {/* Control header */}
            <ControlHeader status={status} setStatus={setStatus} />
            {/* Loading spinner */}
            {status.loading && <LoadingSpinner />}
            {/* Modal */}
            {status.receivingPanel && <ReceivingPanel status={status} setStatus={setStatus} />}
            {/* Action panel */}
            {status.actionPanel && (
                <ActionPanel
                    status={status.actionPanel}
                    setStatus={setStatus}
                    closeActionPanel={closeActionPanel}
                    canClose={searchParams.get('editinventory') ? true : false}
                    isReceiving={true}
                />
            )}

            {status.receiveNewLinePanel && <ReceiveNewLineModal status={status} setStatus={setStatus} />}
            {/* Error */}
            {status.error && (
                <Notification
                    showSavedNotification={status.error}
                    setShowSavedNotification={setStatus}
                    title='Internal Error'
                    desc={
                        status.errorMessage ||
                        'There was an error. Please refresh and try again. If the problem persists, please contact your administrator.'
                    }
                    status='error'
                />
            )}
        </div>
    );
}
