import React from 'react';

export default function EnlargedPhoto({ enlargedPhoto, handlePhotoEnlarge }) {
    if (!enlargedPhoto?.photo) return null;

    return (
        <section
            className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'
            onClick={() => handlePhotoEnlarge(null)} // Close when clicking outside the photo
        >
            <img
                src={enlargedPhoto.photo}
                alt='Enlarged Photo'
                className='max-w-screen max-h-screen'
                onClick={(e) => e.stopPropagation()} // Prevent the event from propagating when the image is clicked
            />
            {enlargedPhoto?.print ? null : ( // Remove close button if print is true
                <button
                    onClick={() => handlePhotoEnlarge(null, false)}
                    className='absolute top-4 right-4 p-2 bg-gray-200 rounded-full font-semibold text-xl hover:bg-white'
                >
                    Close
                </button>
            )}
        </section>
    );
}
