import { useSearchParams } from 'react-router-dom';

export default function IndividualResultWrapper({ table, name, columns, children, checkForPart }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { sm, md, lg } = columns;

    const clickFunction = () => {
        if (table === 'Part' && checkForPart) {
            checkForPart(name, `/?part-search=${name}`);
            // navigate(`/?part-search=${name}`)
            return;
        }
        searchParams.set('modal', name);
        searchParams.set('type', table);

        setSearchParams(searchParams);
    };

    return (
        <section
            className={`w-full grid grid-cols-${sm ?? 3} md:grid-cols-${md ?? 3} lg:grid-cols-${
                lg ?? 3
            } px-3 h-14 gap-3 bg-white hover:bg-slate-50 hover:cursor-pointer last:rounded-b-lg border-t border-gray-200`}
            onClick={clickFunction}
        >
            {children}
        </section>
    );
}
