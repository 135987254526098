import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import RFQTable from './RFQTable';
import config from '../../../utils/config';
import { useAuth } from '../../../portal/context/auth-context';
import SearchBox from '../search-pagionation/SearchBox';
import DateInputs from '../search-pagionation/DateInputs';
import Pagination from '../search-pagionation/Pagination';
import { getRfqFilter } from '../../../utils/commonAPICalls';
import Notification from '../../../components/Notification';
import PdfEmbed from '../../../components/PdfEmbed';
import logError from '../../../utils/errorUtil';

export default function RFQSection() {
    const [isLoading, setIsLoading] = useState(true);
    const [gettingNextPage, setGettingNextPage] = useState(false);
    const ORIGIN = config.origin;
    const { currentUser, logout } = useAuth();
    const [quoteData, setQuoteData] = useState([]);
    const [pageOffset, setPageOffset] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);
    const [printQuoteLoading, setPrintQuoteLoading] = useState(false);
    const [quotePrintError, setQuotePrintError] = useState(false);
    const [pdf, sefPdf] = useState('');
    const [open, setOpen] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [searchError, setSearchError] = useState({
        title: '',
        desc: '',
        show: false,
    });
    const [arrowDirections, setArrowDirections] = useState({
        quoteNumber: {
            direction: 'null',
        },
        createdDate: {
            direction: 'null',
        },
        quoteTotal: {
            direction: 'null',
        },
    });

    const fromDateRef = useRef();
    const toDateRef = useRef();
    const searchRef = useRef();

    const fetchdata = (refetch = false, sortField = null, sortOrder = null) => {
        if (refetch) {
            fromDateRef.current.value = '';
            toDateRef.current.value = '';
            searchRef.current.value = '';
            setHasSearched(false);
            setGettingNextPage(true);

            setArrowDirections({
                // set all other directions to null
                ...Object.keys(arrowDirections).reduce((acc, key) => {
                    acc[key] = { direction: null };
                    return acc;
                }, {}),
            });
        }

        const now = new Date();
        const offset = now.getTimezoneOffset();

        fetch(`${ORIGIN}/cp/hx/quotes `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                userId: currentUser.Id,
                timezoneDifferenceInMinutes: offset,
                sortBy: sortField,
                orderBy: sortOrder,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Auth Token Not Recognized.') {
                    logout();
                }

                setQuoteData(data);
                setIsLoading(false);
                setGettingNextPage(false);
            })
            .catch((err) => logError(err, 'fetchdata'));
    };

    useEffect(() => {
        fetchdata(false);

        return () => {
            setIsLoading(false);
        };
    }, []);

    const handleFilterSubmit = async (offset, reset, sortBy, orderBy) => {
        setSortBy(sortBy);
        setOrderBy(orderBy);
        setGettingNextPage(true);
        if (reset) {
            setPageOffset(0);
        }
        if (!sortBy) {
            setArrowDirections({
                // set all other directions to null
                ...Object.keys(arrowDirections).reduce((acc, key) => {
                    acc[key] = { direction: null };
                    return acc;
                }, {}),
            });
        }
        let isoFromDate = '';
        let isoToDate = '';
        const fromDate = fromDateRef.current?.value;
        const toDate = toDateRef.current?.value;
        const search = searchRef.current?.value;
        setSearchKey(search);

        const now = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(now.getDate() - 31);

        if ((fromDate !== '' || toDate !== '') && new Date(fromDate) < thirtyDaysAgo) {
            setSearchError({
                title: 'Date Range Error',
                desc: 'You cannot select a date older than 30 days from now.',
                show: true,
            });

            fromDateRef.current.value = null;
            toDateRef.current.value = null;
            setIsLoading(false);
            handleFilterSubmit(0, null, sortBy, orderBy);
            return;
        }

        if (fromDate !== '') {
            isoFromDate = new Date(fromDate);
        }
        if (toDate !== '') {
            isoToDate = new Date(toDate);
        }

        const dataToSend = {
            accountId: currentUser.Account.Id,
            userId: currentUser.Id,
            fromDate: isoFromDate,
            toDate: isoToDate,
            searchTerm: search,
            pageOffset: offset,
            sortBy: sortBy,
            orderBy: orderBy,
        };

        const filteredData = await getRfqFilter(currentUser, dataToSend);
        // setHasSearched(true);
        setQuoteData(filteredData);
        setGettingNextPage(false);
    };

    const handleNextPage = () => {
        setPageOffset((prev) => prev + 1, null, sortBy, orderBy);
        handleFilterSubmit(pageOffset + 1, null, sortBy, orderBy);
    };

    const handlePrevPage = () => {
        setPageOffset((prev) => prev - 1, null, sortBy, orderBy);
        handleFilterSubmit(pageOffset - 1, null, sortBy, orderBy);
    };

    const handleQuoteClick = (quoteId, quoteName) => {
        setPrintQuoteLoading(true);
        fetch(`${ORIGIN}/cp/print`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                type: 'quote',
                id: quoteId,
                name: quoteName,
                accountEmail: currentUser.Owner.Email,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                sefPdf(data.form);
                setOpen(true);
                setPrintQuoteLoading(false);
                searchRef.current.value = searchKey;
            })
            .catch((err) => logError(err, 'handleQuoteClick'));
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }, [open, pdf]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (printQuoteLoading) {
        return (
            <div className='flex flex-col items-center justify-center h-[550px] pt-64 animate-pulse'>
                <div className='text-2xl font-bold'>Preparing Quote...</div>
            </div>
        );
    }

    return (
        <>
            <Notification
                setShowSavedNotification={setQuotePrintError}
                showSavedNotification={quotePrintError}
                title={'There was an error.'}
                desc={'There was a problem preparing your PDF please try again later.'}
                status={'fail'}
            />
            <Notification
                setShowSavedNotification={() => setSearchError({ ...searchError, show: false })}
                showSavedNotification={searchError.show}
                title={searchError.title}
                desc={searchError.desc}
                status={'fail'}
            />
            {pdf && <PdfEmbed base64={pdf} setOpen={setOpen} open={open} />}

            <div className='flex flex-col md:flex-row justify-end items-center space-x-4 mr-10 mt-4'>
                {(hasSearched && fromDateRef.current?.value !== '') ||
                (hasSearched && toDateRef.current?.value !== '') ||
                (hasSearched && searchRef.current?.value !== '') ? (
                    <div className='border rounded shadow px-4 py-2 text-sm'>
                        {searchRef.current?.value !== '' ? (
                            <p>
                                Filtered by: <strong> {searchRef.current?.value}</strong>
                            </p>
                        ) : null}
                        {(hasSearched && fromDateRef.current?.value !== '') ||
                        (hasSearched && toDateRef.current?.value !== '') ? (
                            <>
                                <p className='underline'>
                                    {fromDateRef.current?.value} thru{' '}
                                    {toDateRef.current?.value ? toDateRef.current?.value : 'Present'}
                                </p>
                            </>
                        ) : null}
                    </div>
                ) : null}

                {(hasSearched && searchRef.current?.value !== '') ||
                (hasSearched && fromDateRef.current?.value !== '') ||
                (hasSearched && toDateRef.current?.value !== '') ? (
                    <button
                        onClick={() => {
                            fetchdata(true);
                        }}
                        type='button'
                        className='inline-flex items-center mt-0.5 px-4 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-blue-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        Clear Filters
                    </button>
                ) : null}

                <DateInputs fromDateRef={fromDateRef} toDateRef={toDateRef} thirtyDayQuotes={true} />
                <SearchBox
                    submitFn={() => {
                        handleFilterSubmit(0, 'reset');
                        setHasSearched(true);
                    }}
                    searchRef={searchRef}
                    placeholder={'Search by Quote or Part Number'}
                    width={'96'}
                />
                <div>
                    <button
                        onClick={() => {
                            handleFilterSubmit(0, 'reset');
                            setHasSearched(true);
                        }}
                        type='button'
                        className='inline-flex items-center mt-4 md:mt-0.5 px-4 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-blue-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        Search
                    </button>
                </div>
            </div>
            <RFQTable
                hasSearched={hasSearched}
                data={quoteData}
                gettingNextPage={gettingNextPage}
                setQuoteData={setQuoteData}
                handleQuoteClick={handleQuoteClick}
                handleFilterSubmit={handleFilterSubmit}
                fetchdata={fetchdata}
                arrowDirections={arrowDirections}
                setArrowDirections={setArrowDirections}
            />
            <Pagination
                pageOffset={pageOffset}
                nextPage={handleNextPage}
                prevPage={handlePrevPage}
                quoteData={quoteData}
                gettingNextPage={gettingNextPage}
            />
        </>
    );
}
