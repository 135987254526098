import React from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function PersonalInformation({ userAccountInfo, setUserAccountInfo, inputError }) {
    return (
        <>
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                </label>
                <div className='block font-normal text-gray-700 sm:mt-px sm:pt-1'>{userAccountInfo?.contactData?.Name}</div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='Title' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Title
                </label>
                <div className='mt-1 sm:col-span-2 sm:mt-0'>
                    <input
                        onChange={(e) => {
                            setUserAccountInfo({
                                ...userAccountInfo,
                                contactData: {
                                    ...userAccountInfo.contactData,
                                    Title: e.target.value,
                                },
                            });
                        }}
                        value={userAccountInfo?.contactData.Title}
                        type='text'
                        name='Title'
                        id='Title'
                        autoComplete='family-name'
                        className={classNames(
                            inputError && userAccountInfo?.contactData.Title === ''
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                                : '',
                            'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                        )}
                    />
                </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='email' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Email address
                </label>
                <div className='mt-1 sm:col-span-2 sm:mt-0'>
                    <input
                        value={userAccountInfo?.contactData.Email}
                        onChange={(e) => {
                            setUserAccountInfo({
                                ...userAccountInfo,
                                contactData: {
                                    ...userAccountInfo.contactData,
                                    Email: e.target.value,
                                },
                            });
                        }}
                        id='email'
                        name='email'
                        type='text'
                        autoComplete='email'
                        className={classNames(
                            inputError && userAccountInfo?.contactData.Email === ''
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                                : '',
                            'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                        )}
                    />
                </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='Phone' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Phone
                </label>
                <div className='mt-1 sm:col-span-2 sm:mt-0'>
                    <input
                        value={userAccountInfo?.contactData.Phone}
                        onChange={(e) => {
                            setUserAccountInfo({
                                ...userAccountInfo,
                                contactData: {
                                    ...userAccountInfo.contactData,
                                    Phone: e.target.value,
                                },
                            });
                        }}
                        type='text'
                        id='Phone'
                        name='Phone'
                        autoComplete='email'
                        className={classNames(
                            inputError && userAccountInfo?.contactData.Phone === ''
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                                : '',
                            'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm'
                        )}
                    />
                </div>
            </div>
        </>
    );
}
