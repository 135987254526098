import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../wms/context/wms-context';

export default function WMSPrivateRoute({ children }) {
    const { currentUser, logout } = useAuth();
    const location = useLocation();

    useEffect(() => {
        document.title = 'Pacific Air | WMS';
    }, []);

    // Function to map route to permission
    const routeToPermission = (route) => {
        if (route === 'wms') return 'inventory'; // Map '/wms' to 'inventory'
        return route; // All other routes are direct mappings
    };

    useEffect(() => {
        if (!currentUser) {
            return <Navigate to='/wms/login' replace />;
        }

        // Extract the route name from the URL and get the corresponding permission
        const currentRoute = location.pathname.split('/').pop().toLowerCase();
        const requiredPermission = routeToPermission(currentRoute);

        // Check if the current user has permission for this route
        if (!currentUser.permissions.map((p) => p.toLowerCase()).includes(requiredPermission)) {
            // Redirect and log out the user
            logout();
            return <Navigate to='/wms/login' replace />;
        }
    }, [currentUser, location, logout]);

    // Redirect to login page if not a current user
    if (!currentUser) {
        return <Navigate to='/wms/login' replace />;
    }

    return children;
}
