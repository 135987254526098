import React, { useEffect, useState } from 'react';
import LoadingButton from '../../components/LoadingButton';
import '../../utils/resetNumberField.css';
import { Link } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
export default function EnterVerificationCodeForgotPassword({
    submitVerificationCode,
    stepTwoError,
    verificationLoading,
    setVerificationLoading,
    setStepTwoError,
    hasCode,
    email,
}) {
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        setVerificationLoading(true);
        if (verificationCode.length < 6) {
            setVerificationLoading(false);
            return setError('Verification code must be 6 characters');
        }

        submitVerificationCode(verificationCode, email);
    };

    const handleChange = (e) => {
        setError('');
        if (e.target.value.length > 6) {
            return;
        } else {
            setVerificationCode(e.target.value);
        }
    };

    useEffect(() => {
        if (hasCode) {
            setVerificationLoading(true);
            setVerificationCode(hasCode);
        }
    }, []);

    return (
        <div>
            <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>Enter Verification Code</h2>
            <p className='mt-2 text-center text-sm text-gray-600'>A verification code has been sent to your email</p>
            {error && <p className='text-red-500 text-xs italic text-center -mb-6 mt-4'>{error}</p>}
            <div className='space-y-6 my-12'>
                <input
                    style={{ MozAppearance: 'textfield !important' }}
                    onFocus={() => setStepTwoError('')}
                    name='verificationCode'
                    onChange={(e) => handleChange(e)}
                    max='6'
                    type='number'
                    id='verificationCode'
                    value={verificationCode}
                    className={classNames(
                        error ? 'border-2 border-red-400' : 'border border-gray-300',
                        'block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-md'
                    )}
                    placeholder='Verification Code'
                />

                {!verificationLoading ? (
                    <button
                        type='submit'
                        onClick={handleSubmit}
                        disabled={verificationCode.length <= 3}
                        className='cursor-pointer group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        Submit
                    </button>
                ) : (
                    <LoadingButton />
                )}
            </div>
            <p className='text-sm text-center underline pb-2 text-blue-primary font-semibold -mt-8'>
                <Link to='/login' className='hover:text-gray-500'>
                    Back to Login Screen
                </Link>
            </p>
            {stepTwoError && (
                <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative' role='alert'>
                    <p className='font-bold'>There was an error with you verification code</p>
                    <p className='block sm:inline'>{stepTwoError}</p>
                </div>
            )}
        </div>
    );
}
