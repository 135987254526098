import { useEffect, useRef, useState } from 'react';
import { maxCameraHeightResolution, maxCameraWidthResolution } from '../Camera/data';
import MobileCameraButtonWrapper from '../Camera/MobileCameraButton';
import { getCameraHeight, getCameraWidth, detectMobile } from '../Camera/functions';
import { ArrowPathIcon, CameraIcon, CheckCircleIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getAvailableCameras, printImage } from './utils/guided-interface-functions';

export default function PhysicalCamera({
    cameras,
    currentCameraIndex,
    loading,
    photos,
    setCameras,
    setCurrentCameraIndex,
    setLoading,
    setPhotos,
    setVideoStream,
    submitApproval,
    videoStream,
    buttonLoading,
}) {
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const toAddPhotoListRef = useRef(null);
    const addedPhotoListRef = useRef(null);
    const photosAddedRef = useRef(0);
    const isMobile = detectMobile();
    const [enlargedPhoto, setEnlargedPhoto] = useState(null);

    async function startCamera() {
        if (videoRef?.current?.srcObject) {
            // Stop all tracks of the current stream
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach((track) => track.stop());
            // Pause the video element before changing the source
            videoRef.current.pause();
            videoRef.current.srcObject = null; // Clear the current source object
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    focusMode: 'continuous',
                    deviceId: cameras[currentCameraIndex]?.deviceId,
                    width: { ideal: maxCameraWidthResolution },
                    height: { ideal: maxCameraHeightResolution },
                },
            });
            setVideoStream(stream);
            videoRef.current.srcObject = stream;
            // Wait for the metadata to be loaded before playing
            videoRef.current.onloadedmetadata = () => {
                videoRef.current.play();
            };
            setLoading({ ...loading, camera: false });
        } catch (err) {
            console.error('Failed to start the camera:', err);
        }
    }

    function handleTakePhoto() {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, getCameraWidth(videoStream), getCameraHeight(videoStream));
        const dataUri = canvasRef.current.toDataURL('image/png');
        setPhotos({ ...photos, toAdd: [...photos.toAdd, { url: dataUri, uploaded: false }] });
        toAddPhotoListRef.current.scrollTop = toAddPhotoListRef.current.scrollHeight;
    }

    function handleMobilePhotoUpload(dataUri) {
        setPhotos({ ...photos, toAdd: [...photos.toAdd, { url: dataUri, uploaded: false }] });
    }

    function switchCamera() {
        if (cameras.length > 1) {
            const nextIndex = (currentCameraIndex + 1) % cameras.length;
            setCurrentCameraIndex(nextIndex);
        }
    }
    const handlePhotoEnlarge = (photoUri, print = false) => {
        setEnlargedPhoto({
            photo: photoUri,
            print: print,
        });
    };

    const handleAddPhoto = () => {
        setPhotos({
            ...photos,
            toAdd: [],
            added: [...photos.added, ...photos.toAdd.map((photo) => ({ ...photo, isHero: false, uploaded: false }))],
        });
    };

    const handleRemoveAddedPhoto = (urlToDelete) => {
        const updatedPhotos = photos.added.filter((photo) => photo.url !== urlToDelete);
        setPhotos({ ...photos, added: updatedPhotos });
    };

    const handleRemovePhoto = (uriToDelete) => {
        const updatedPhotos = photos.toAdd.filter((photo) => photo.url !== uriToDelete);
        setPhotos({ ...photos, toAdd: updatedPhotos });
    };

    const toggleImageHeroStatus = (url, collection) => {
        const updatedPhotos = photos[collection].map((photo) => {
            if (photo.url === url) {
                return { ...photo, isHero: !photo.isHero };
            }
            return photo;
        });
        setPhotos({ ...photos, [collection]: updatedPhotos });
    };

    useEffect(() => {
        getAvailableCameras(setCameras);
    }, [setCameras]);

    useEffect(() => {
        if (toAddPhotoListRef?.current) toAddPhotoListRef.current.scrollTop = toAddPhotoListRef.current.scrollHeight;
    }, [photos.toAdd]);

    useEffect(() => {
        if (photosAddedRef.current < photos.added.length) {
            addedPhotoListRef.current.scrollTop = addedPhotoListRef.current.scrollHeight;
        }
        photosAddedRef.current = photos.added.length;
    }, [photos.added.length]); // only scroll when a new photo is added, not when toggled

    useEffect(() => {
        if (cameras.length > 0) {
            startCamera();
        }
    }, [currentCameraIndex, cameras, setCameras]);

    return (
        <div className='w-full p-2 text-lg'>
            <div className=''>
                <div className='flex justify-between w-full'>
                    <div className='w-[50%] group'>
                        <h3 className='mb-3 font-bold'>Current Photos</h3>
                        <div className='flex flex-wrap max-h-[250px] overflow-y-auto'>
                            {photos.current.length ? (
                                photos.current.map((photo) => (
                                    <div
                                        key={photo.name}
                                        className='relative flex flex-col items-center flex-wrap max-w-[30%] m-2'
                                    >
                                        <img
                                            className='duration-100 hover:cursor-pointer max-w-64 opacity-80 group-hover:opacity-100'
                                            src={photo.url}
                                            alt={photo.name}
                                            onClick={() => handlePhotoEnlarge(photo.url, false)}
                                        />
                                        <CheckCircleIcon
                                            className={
                                                'hover:cursor-pointer absolute w-8 h-8 top-1 right-1 rounded-full duration-100 ' +
                                                (photo.isHero
                                                    ? 'bg-green-500 text-white hover:bg-green-400'
                                                    : 'bg-white hover:bg-gray-200 border-2 border-gray-500 text-white hover:text-gray-200')
                                            }
                                            onClick={() => toggleImageHeroStatus(photo.url, 'current')}
                                        />

                                        <button
                                            className='flex flex-row items-center hover:opacity-100 justify-center rounded-b border border-gray-300 w-full py-1 hover:!bg-gray-100 hover:!contrast-100 active:!bg-gray-200 gap-2 !outline-none !shadow-none active:transform-none '
                                            onClick={() => printImage(photo.url)}
                                        >
                                            Print
                                            <PrinterIcon className='w-5 h-5' />
                                        </button>
                                    </div>
                                ))
                            ) : loading.photos ? (
                                <p>Loading Photos...</p>
                            ) : (
                                <p className='text-base font-bold text-red-600'>No Photos Found</p>
                            )}
                        </div>
                        <h3 className='my-3 font-bold'>Added Photos</h3>
                        <div className='flex flex-wrap max-h-[250px] overflow-y-auto' ref={addedPhotoListRef}>
                            {photos.added.map((photo) => (
                                <div
                                    key={photo.url}
                                    className='relative flex flex-col items-center flex-wrap max-w-[30%] m-2'
                                >
                                    <img
                                        className='duration-100 hover:cursor-pointer max-w-64 opacity-80 group-hover:opacity-100'
                                        src={photo.url}
                                        onClick={() => handlePhotoEnlarge(photo.url, false)}
                                    />
                                    <button
                                        onClick={() => handleRemoveAddedPhoto(photo.url)}
                                        className='absolute p-1 text-xl font-semibold bg-gray-200 rounded-full top-1 left-1 hover:bg-white '
                                    >
                                        <XMarkIcon className='w-5 h-5 text-red-600' />
                                    </button>
                                    <CheckCircleIcon
                                        className={
                                            'hover:cursor-pointer absolute w-8 h-8 top-1 right-1 rounded-full duration-100 ' +
                                            (photo.isHero
                                                ? 'bg-green-500 text-white hover:bg-green-400'
                                                : 'bg-white hover:bg-gray-200 border-2 border-gray-500 text-white hover:text-gray-200')
                                        }
                                        onClick={() => toggleImageHeroStatus(photo.url, 'added')}
                                    />
                                    <button
                                        className='flex flex-row items-center hover:opacity-100 justify-center rounded-b border border-gray-300 w-full py-1 hover:!bg-gray-100 hover:!contrast-100 active:!bg-gray-200 gap-2 !outline-none !shadow-none active:transform-none '
                                        onClick={() => printImage(photo.url)}
                                    >
                                        Print
                                        <PrinterIcon className='w-5 h-5' />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex justify-center max-w-[50%]'>
                        <div className='flex flex-col items-center '>
                            {isMobile ? (
                                <MobileCameraButtonWrapper photoUpload={handleMobilePhotoUpload} />
                            ) : (
                                <div className={loading.camera ? 'hidden' : ''}>
                                    <video className='mx-auto' ref={videoRef} width='550' height='300'></video>
                                    <canvas
                                        ref={canvasRef}
                                        className='hidden'
                                        width={getCameraWidth(videoStream)}
                                        height={getCameraHeight(videoStream)}
                                    ></canvas>
                                </div>
                            )}
                            {!isMobile && loading.camera ? <LoadingSpinner /> : null}

                            <div className='flex justify-center my-4 '>
                                <div className='flex items-center'>
                                    {cameras.length > 1 ? (
                                        <div className='relative group'>
                                            <button
                                                className='bg-green-200 h-[45px] border flex items-center justify-center border-green-600 rounded-l-sm text-green-600 hover:bg-green-600 hover:text-white px-3 py-2'
                                                onClick={switchCamera}
                                            >
                                                <ArrowPathIcon className='w-5 h-5' aria-hidden='true' />
                                            </button>
                                            <div className='absolute left-0 w-24 px-2 py-1 mt-1 text-xs text-white transition-opacity duration-300 transform -translate-x-1/2 bg-gray-800 rounded-md opacity-0 top-full group-hover:opacity-100'>
                                                Switch Camera
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='relative group'>
                                            <button
                                                className='bg-gray-300 h-[45px] border flex items-center justify-center border-gray-600 rounded-l-sm text-white px-3 py-2'
                                                onClick={switchCamera}
                                            >
                                                <ArrowPathIcon className='w-5 h-5' aria-hidden='true' />
                                            </button>
                                            <div className='absolute left-0 px-2 py-1 mt-1 text-xs text-white transition-opacity duration-300 transform -translate-x-1/2 bg-gray-800 rounded-md opacity-0 w-28 top-full group-hover:opacity-100'>
                                                Cannot switch cameras
                                            </div>
                                        </div>
                                    )}
                                    <button
                                        className='bg-green-200 h-[45px] border flex items-center justify-center border-green-600 rounded-r-sm text-green-600 hover:bg-green-600 hover:text-white px-4 py-2'
                                        onClick={handleTakePhoto}
                                    >
                                        Take Photo <CameraIcon className='ml-2 h-7 w-7' aria-hidden='true' />
                                    </button>
                                </div>
                            </div>

                            <div
                                className='flex flex-wrap overflow-y-auto items-center max-h-[350px] p-auto'
                                ref={toAddPhotoListRef}
                            >
                                {photos.toAdd.map(({ url }) => (
                                    <div key={url} className='relative w-[48%] p-2'>
                                        <img
                                            className='hover:cursor-pointer'
                                            src={url}
                                            onClick={() => handlePhotoEnlarge(url)}
                                        />
                                        <button
                                            onClick={() => handleRemovePhoto(url)}
                                            className='absolute p-2 text-xl font-semibold bg-gray-200 rounded-full top-4 right-4 hover:bg-white'
                                        >
                                            <XMarkIcon className='w-6 h-6 text-red-600' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <button
                                className='self-end p-2 mt-3 mr-12 font-bold text-white rounded-lg bg-blue-primary'
                                onClick={handleAddPhoto}
                                disabled={!photos.toAdd.length}
                            >
                                Add Photos
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <button
                disabled={buttonLoading === 'accepted'}
                className='m-2 bg-green-600 rounded-lg p-2 text-white text-center w-[97%] font-medium'
                onClick={() => submitApproval()}
            >
                {buttonLoading === 'accepted' ? (
                    <div className='flex items-center justify-center'>
                        <LoadingSpinner className='w-44' spinnerSize={'8'} />
                    </div>
                ) : (
                    'Hero Pictures Are Taken and Selected'
                )}
            </button>
            {enlargedPhoto?.photo ? (
                <section
                    className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'
                    onClick={() => handlePhotoEnlarge(null)} // Close when clicking outside the photo
                >
                    <img
                        src={enlargedPhoto.photo}
                        alt='Enlarged Photo'
                        className='max-h-screen max-w-screen'
                        onClick={(e) => e.stopPropagation()} // Prevent the event from propagating when the image is clicked
                    />
                    {enlargedPhoto?.print ? null : ( // Remove close button if print is true
                        <button
                            onClick={() => handlePhotoEnlarge(null, false)}
                            className='absolute p-2 text-xl font-semibold bg-gray-200 rounded-full top-4 right-4 hover:bg-white'
                        >
                            Close
                        </button>
                    )}
                </section>
            ) : null}
        </div>
    );
}
