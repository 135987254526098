import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

export default function NoPartsFound({ data, title }) {
    return (
        <div className='rounded-md bg-yellow-100 p-4 w-1/3 mx-auto'>
            <div className='flex'>
                <div className='flex-shrink-0'>
                    <ExclamationTriangleIcon className='h-5 w-5 text-yellow-400' aria-hidden='true' />
                </div>
                <div className='ml-3'>
                    <h3 className='text-sm font-medium text-yellow-800'>{data.title ? data.title : title}</h3>
                    <div className='mb-2 text-sm text-yellow-700'>
                        <ul>
                            {data.notFound.map((part) => (
                                <li className='font-semibold list-disc ml-8 text-md' key={part}>
                                    {part}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
