import React, { useEffect, useState } from 'react';
import { getPackingReleaseInfo, postPhotoData } from '../../../utils/commonAPICalls';
import { useAuth } from '../../context/wms-context';
import { TIME_BEFORE_SET_INACTIVE, packingPageStatusEnum } from './data';
import CameraUploadAndPhotoArray from '../../components/packing/CameraUploadAndPhotoArray';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getPackingPageStatusText } from './functions';

export default function WMSPacking() {
    const { NO_RELEASE, LOADING, RELEASE_ERROR, RELEASE_SUCCESS } = packingPageStatusEnum;
    const { currentUser } = useAuth();
    const defaultReleaseInfo = {
        releaseNumber: '',
        releaseData: null,
        status: NO_RELEASE,
    };
    const defaultNotificationObject = {
        message: null,
        error: false,
    };

    const [releaseInfo, setReleaseInfo] = useState(defaultReleaseInfo);

    const [photos, setPhotos] = useState([]);
    const [enlargedPhoto, setEnlargedPhoto] = useState(null);

    const [inactivityTimerId, setInactivityTimerId] = useState(null);

    const [formNotification, setFormNotification] = useState(defaultNotificationObject);
    const [photoNotification, setPhotoNotification] = useState(defaultNotificationObject);

    const resetPageData = () => {
        setFormNotification(defaultNotificationObject);
        setPhotoNotification(defaultNotificationObject);
        setReleaseInfo(defaultReleaseInfo);
        setPhotos([]);
        setEnlargedPhoto(null);
    };

    // Reset to initial state
    const inactivityTimer = () => {
        inactivityTimerId && clearTimeout(inactivityTimerId);

        let newInactivitytimerId = setTimeout(() => {
            resetPageData();
        }, TIME_BEFORE_SET_INACTIVE);

        setInactivityTimerId(newInactivitytimerId);
    };

    const handleTimers = () => {
        inactivityTimer();
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            const formattedReleaseNumber = releaseInfo.releaseNumber.toLowerCase().includes('rls-')
                ? releaseInfo.releaseNumber
                : `RLS-${releaseInfo.releaseNumber}`;

            const dataToSend = {
                releaseNumber: formattedReleaseNumber,
                authentication: {
                    ...currentUser.authentication,
                },
            };
            handleTimers();
            setReleaseInfo({ ...releaseInfo, releaseData: null, status: LOADING });
            setPhotos([]);

            let data = await getPackingReleaseInfo(dataToSend, currentUser);

            if (data?.error) {
                setReleaseInfo({ releaseData: null, status: RELEASE_ERROR });
                setFormNotification({
                    message: `Error, please try again with a different release number.`,
                    error: true,
                });
                return;
            }

            handleTimers();
            setReleaseInfo({ releaseNumber: '', releaseData: data, status: RELEASE_SUCCESS });
            setFormNotification({ message: null, error: false });
        } catch (error) {
            setReleaseInfo({ releaseNumber: '', releaseData: null, status: RELEASE_ERROR });
            setFormNotification({
                message: `Error, please try again with a different release number.`,
                error: true,
            });
        }
    };

    const photoUpload = async (photo) => {
        try {
            setPhotoNotification({
                message: 'Uploading photo...',
                error: false,
            });
            handleTimers();

            // Post photo data to database and local files
            const uploadPhotoResponse = await postPhotoData(
                releaseInfo?.releaseData?.id,
                releaseInfo?.releaseData?.releaseNumber,
                photo.dataUri
            );

            if (uploadPhotoResponse?.error) {
                setPhotoNotification({ message: 'Error uploading photo, please try again', error: true });
                handleTimers();
                return;
            }

            // Add uploaded photo into photo array
            setPhotos((prevPhotos) => [...prevPhotos, photo]);
            setPhotoNotification({
                message: null,
                error: false,
            });
            handleTimers();
        } catch (error) {
            setPhotoNotification({ message: 'Error uploading photo, please try again', error: true });
            handleTimers();
        }
    };

    const updateEnlargedPhoto = (photoURI) => {
        setEnlargedPhoto(photoURI);
        handleTimers();
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleTimers);

        return () => {
            window.removeEventListener('mousemove', handleTimers);
            clearTimeout(inactivityTimerId);
        };
    }, [inactivityTimerId]);

    return (
        <div className='flex h-[calc(100vh-64px)]'>
            <div className='flex-1 flex flex-col justify-center items-center'>
                <form onSubmit={handleSubmit} className='flex flex-col items-center'>
                    <label htmlFor='releaseNumber' className='mb-2 text-lg'>
                        Release Number
                    </label>
                    <input
                        id='releaseNumber'
                        type='text'
                        value={releaseInfo.releaseNumber}
                        onChange={(e) => setReleaseInfo({ ...releaseInfo, releaseNumber: e.target.value })}
                        className='p-2 border border-gray-300 rounded-md'
                        autoFocus
                    />
                    <button type='submit' className='mt-4 px-4 py-2 bg-blue-primary text-white rounded-md'>
                        Submit
                    </button>
                </form>
                {formNotification.message && (
                    <p className={formNotification.error ? 'mt-2 text-red-600' : 'mt-2'}>{formNotification.message}</p>
                )}
            </div>
            {releaseInfo.status === RELEASE_SUCCESS ? (
                <CameraUploadAndPhotoArray
                    releaseData={releaseInfo.releaseData}
                    updateEnlargedPhoto={updateEnlargedPhoto}
                    enlargedPhoto={enlargedPhoto}
                    photos={photos}
                    photoUpload={photoUpload}
                    photoNotification={photoNotification}
                />
            ) : (
                <div className='flex-1 flex flex-col p-8 border-l items-center justify-center gap-y-24'>
                    <LoadingSpinner className={'mt-0'} />
                    <div>{getPackingPageStatusText(releaseInfo.status)}</div>
                </div>
            )}
        </div>
    );
}
