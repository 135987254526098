import config from './config';
import rollbar from './rollbar';

/**
 * Log error to Rollbar and send email
 * @param {error} error Error from the function
 * @param {string} functionName Name of the function that the error is coming from
 * @returns
 */
export default async function logError(error, functionName) {
    let ORIGIN = config.origin;
    try {
        let loggedError = rollbar.error(error);

        const errorDetails = { message: error.message, functionMessage: functionName, errorUUID: loggedError.uuid };

        const response = await fetch(`${ORIGIN}/cp/error`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(errorDetails),
        });
        const data = await response.json();
        Promise.resolve(data);
        return data;
    } catch (error) {
        return error;
    }
}
