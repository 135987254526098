import React from 'react';
import ControlHeader from '../components/picking/ControlHeader';

export default function WMSPicking() {
    return (
        <div>
            <ControlHeader />
        </div>
    );
}
