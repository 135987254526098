import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LinkIcon } from '@heroicons/react/24/outline';

export default function DocModal({ docs, setDocs }) {
    const [open, setOpen] = useState(true);

    let traceLinks = docs.traceLinks;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={setDocs}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                                <div>
                                    <div className='mt-3 text-center sm:mt-5'>
                                        <Dialog.Title
                                            as='h3'
                                            className='text-2xl font-medium leading-6 text-gray-900 underline'
                                        >
                                            Trace Links
                                        </Dialog.Title>
                                        <h4 className='text-center font-semibold mt-6 text-lg text-md'>
                                            Condition: {docs.condition}, Trace: {docs.trace}
                                            {docs.tag ? <p>Tag: {docs.tag}</p> : ''}
                                        </h4>
                                        {traceLinks.map((doc) => (
                                            <div className='text-left px-2 my-b  border-b-2 py-4'>
                                                <p className='text-sm text-gray-500'>
                                                    <strong>Reference Number: </strong>
                                                    {doc.reference}
                                                </p>
                                                <p className='text-sm text-gray-500'>
                                                    <strong>Trace: </strong>
                                                    {doc.traceSource}
                                                </p>
                                                <p className='text-sm text-gray-500'>
                                                    <strong>Tag Agency: </strong>
                                                    {doc.tagAgency}
                                                </p>
                                                <p className='text-sm text-gray-500'>
                                                    <strong>Quantity: </strong>
                                                    {doc.quantity}
                                                </p>
                                                <div className='flex justify-between'>
                                                    <p className='text-sm text-gray-500'>
                                                        <strong>Links:</strong>
                                                    </p>
                                                    {doc.links.map((link) => (
                                                        <div className='flex items-center'>
                                                            <LinkIcon className='h-5 w-5 text-gray-500 ' />
                                                            <a href={link.url} target='_blank'>
                                                                <p className='text-sm ml-1 underline text-gray-500'>
                                                                    {link.type}
                                                                </p>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <button
                                        type='button'
                                        className=' w-1/2 mx-auto justify-center rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 sm:text-sm'
                                        onClick={() => {
                                            setOpen(false);
                                            setDocs(false);
                                        }}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
