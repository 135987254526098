import React, { useEffect, useState } from 'react';
import { globalSearch, partSearch } from '../../utils/commonAPICalls';
import { useAuth } from '../../portal/context/auth-context';
import { defaultHeaderArray, defaultStatus, noResultsHeaderArray, searchStateEnum } from '../components/global-search/data';
import { checkForNestedData, checkSearchState, sanitizeCageCode } from '../components/global-search/utils';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { PlaceholderResults } from '../components/global-search/Placeholder/PlaceholderResults';
import { PageResultHolder } from '../components/global-search/PageResultHolder';
import ResultModal from '../components/global-search/Modal/ResultModal';
import { addToIndexedDB } from '../../utils/IndexedDB';
import ActionModal from '../components/global-search/Modal/ActionModal/ActionModal';
import { validatePartsSearch } from '../../utils/validators';
import LoadingIndicatorWithText from '../components/global-search/LoadingIndicatorWithText';
import Notification from '../../components/Notification';

export default function GlobalSearch() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [status, setStatus] = useState(defaultStatus);
    const [partSearchStatus, setPartSearchStatus] = useState(defaultStatus);

    const [search, setSearch] = useState();
    const [part, setPart] = useState('');
    const { LOADING, RESULTS, NORESULTS, DEFAULT } = searchStateEnum;

    const [resultHeaderArray, setResultHeaderArray] = useState(defaultHeaderArray);
    const [defaultResultArray, setDefaultResultArray] = useState();
    const [resultsArray, setResultsArray] = useState(null);

    const [currentFilter, setCurrentFilter] = useState('Top Results');

    const [documentData, setDocumentData] = useState();
    const [actionModalOpen, setActionModalOpen] = useState(false);

    // Run part check before redirecting to part search page
    const checkForPart = async (part, url) => {
        try {
            setPartSearchStatus({ ...partSearchStatus, searchState: LOADING });
            setPart(part);
            const filteredData = validatePartsSearch(part);

            const dataToSend = {
                parts: filteredData,
                contactId: currentUser.Id,
                accountId: currentUser.Account.Id,
            };

            const checkPartResponse = await partSearch(dataToSend, currentUser);

            if (checkPartResponse.error) {
                setPartSearchStatus({ ...partSearchStatus, searchState: NORESULTS });
                console.error(checkPartResponse.error);
                return;
            }

            setPartSearchStatus({ ...partSearchStatus, searchState: RESULTS });
            navigate(url);
        } catch (error) {
            console.error(error);
        }
    };

    // Handle sidebar functionality
    const filterResults = (filter) => {
        let objectNameFilter = filter.slice(0, -1);
        if (filter === 'Top Results') {
            setResultsArray(defaultResultArray);
            return;
        }

        let filteredArray = defaultResultArray.filter((dataObject) => dataObject.objectName === objectNameFilter);

        setResultsArray(filteredArray);
    };

    // Calculate result lengths and add buttons to sidebar
    const populateSidebarData = (results) => {
        let filterArray = results.flatMap((dataObject) => {
            let data = dataObject.data;

            if (data.length > 0) {
                return {
                    header: dataObject.objectName + 's',
                    count: data.length > 500 ? '500+' : data.length,
                };
            } else {
                return [];
            }
        });

        if (filterArray.length > 1) {
            setResultHeaderArray([{ header: 'Top Results', count: null }, ...filterArray]);
        } else {
            setResultHeaderArray([{ header: 'Top Results', count: null }]);
        }
    };

    // Add fetched data to indexedDB
    const cacheFetchedData = async (results) => {
        try {
            for (const resultArray of results) {
                const { objectName, data } = resultArray;
                if (data.length === 0 || objectName === 'Part') continue;

                const cacheData = await addToIndexedDB(null, objectName, data);

                if (cacheData.error) {
                    console.error(cacheData.error);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Get data from backend
    const globalSearchFetch = async (input) => {
        const dataToSend = {
            accountId: currentUser.Account.Id,
            userId: currentUser.Id,
            fromDate: '',
            toDate: '',
            searchTerm: sanitizeCageCode(input),
            pageOffset: 0,
        };

        try {
            setResultHeaderArray(defaultHeaderArray);
            const searchedData = await globalSearch(currentUser, dataToSend);
            return searchedData;
        } catch (err) {
            console.error(err);
        }
    };

    // Run the backend fetch and handle errors. If there are no errors add data to indexedDB
    const globalDataHandler = async (searchQuery) => {
        setStatus({ ...status, searchState: LOADING });

        const globalResults = await globalSearchFetch(searchQuery);
        const results = await checkForNestedData(globalResults);

        if (globalResults && results) {
            setResultsArray(globalResults);
            setDefaultResultArray(globalResults);
            setStatus({ ...status, searchState: RESULTS });
            populateSidebarData(globalResults);
            cacheFetchedData(globalResults);
        } else {
            setResultHeaderArray(noResultsHeaderArray);
            setStatus({ ...status, searchState: NORESULTS });
            setResultsArray(null);
        }
    };

    // Get query search params
    useEffect(() => {
        const searchQuery = searchParams.get('query');
        if (searchQuery !== search) {
            setSearch(searchQuery);
            globalDataHandler(searchQuery);
        }
    }, [searchParams]);

    // Handle popup close
    useEffect(() => {
        if (checkSearchState(NORESULTS, partSearchStatus)) {
            setTimeout(() => {
                setPartSearchStatus({ ...partSearchStatus, searchState: DEFAULT });
            }, 2000);
        }
    }, [partSearchStatus]);

    return (
        <>
            <section className='w-full flex flex-row !h-[calc(100vh-64px)]'>
                <article className='hidden lg:flex flex-col items-start justify-start w-[250px] py-7'>
                    <h1 className='text-xl pl-8 font-bold'>Search Results</h1>
                    <div
                        className={
                            'w-full flex flex-col mt-5 ' + (!checkSearchState(RESULTS, status) && 'pointer-events-none')
                        }
                    >
                        {resultHeaderArray.map((headerObject, index) => {
                            const { header, count } = headerObject;

                            return (
                                <button
                                    key={index}
                                    className={
                                        'flex items-center justify-between text-left text-lg pl-8 pr-3 py-3 duration-100 hover:cursor-pointer first:border-y border-b border-t-0 border-x-0 border-solid border-gray-300 ' +
                                        (header === currentFilter ? ' !bg-blue-300/40 ' : ' hover:bg-blue-300/20 bg-white ')
                                    }
                                    onClick={() => {
                                        setCurrentFilter(header);
                                        filterResults(header);
                                    }}
                                    style={{
                                        all: 'revert',
                                    }}
                                >
                                    <h1>{header}</h1>
                                    <h1 className='text-gray-500'>{count && count}</h1>
                                </button>
                            );
                        })}
                    </div>
                </article>
                <article className='flex flex-col w-full h-full bg-gradient-to-b from-blue-primary/80 to-blue-primary/30 px-1 lg:px-4 gap-1 lg:gap-2 overflow-y-auto'>
                    {checkSearchState(LOADING, status) || checkSearchState(LOADING, status) ? (
                        <PlaceholderResults />
                    ) : (
                        <PageResultHolder
                            resultsArray={resultsArray}
                            search={search}
                            resultsFound={checkSearchState(RESULTS, status)}
                            updateDocumentData={setDocumentData}
                            checkForPart={checkForPart}
                        />
                    )}
                </article>
            </section>
            <ResultModal updateDocumentData={setDocumentData} />
            <ActionModal documentData={documentData} modalOpen={actionModalOpen} setModalOpen={setActionModalOpen} />

            <section
                className={
                    'absolute w-screen h-screen items-center justify-center top-0 left-0 bg-black/60 z-20 ' +
                    (checkSearchState(LOADING, partSearchStatus) ? ' opacity-100 flex duration-150 ' : ' opacity-0 hidden ')
                }
            >
                <LoadingIndicatorWithText staticText={'Checking for part availability'} textClassName={'!text-white'} />
            </section>

            <Notification
                desc={'Searched Part Currently Not In Stock'}
                title={part}
                new
                showSavedNotification={checkSearchState(NORESULTS, partSearchStatus)}
                setShowSavedNotification={() => setPartSearchStatus({ ...partSearchStatus, searchState: DEFAULT })}
            />
        </>
    );
}
