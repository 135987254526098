import React, { useState, useEffect } from 'react';

import { useAuth } from '../../context/wms-context';
import config from '../../../utils/config';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Notification from '../../../components/Notification';
import logError from '../../../utils/errorUtil';

export default function WMSUserPermissions() {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();
    const [allEmployees, setAllEmployees] = useState();
    const [loading, setLoading] = useState(true);
    const [employeeLoading, setEmployeeLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notification, setNotification] = useState({ title: '', desc: '', status: '' });

    useEffect(() => {
        // fetch get-all-employee-permissions
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(
            `${ORIGIN}/wms/get-all-employee-permissions?userId=${currentUser.authentication.userId}&userEmail=${currentUser.authentication.userEmail}`,
            requestOptions
        )
            .then((res) => res.json())
            .then((data) => {
                console.log(data.results);
                setAllEmployees(data.results);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        // When the notification state changes, clear it after 5 seconds
        if (showNotification) {
            const timeoutId = setTimeout(() => {
                setShowNotification(false);
            }, 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [showNotification]);

    const handleCheckboxChange = (employeeId, permissionValue) => {
        setAllEmployees((currentEmployees) =>
            currentEmployees.map((emp) => {
                if (emp.Id === employeeId) {
                    const updatedPermissions = emp.WMS_Permissions__c.includes(permissionValue)
                        ? emp.WMS_Permissions__c.filter((perm) => perm !== permissionValue) // Remove permission
                        : [...emp.WMS_Permissions__c, permissionValue]; // Add permission

                    return { ...emp, WMS_Permissions__c: updatedPermissions };
                }
                return emp;
            })
        );
    };

    const saveSingleEmployeePermissions = (employeeId, permissions, priority) => {
        setEmployeeLoading(employeeId);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: employeeId,
                permissions: permissions,
                priority: priority,
                ...currentUser.authentication,
            }),
        };

        fetch(`${ORIGIN}/wms/update-employee-permissions`, requestOptions)
            .then((res) => {
                if (!res.ok) throw new Error('Network response was not ok');
                setShowNotification(true);
                setNotification({
                    title: 'User Permissions Saved',
                    desc: 'Permissions have been saved successfully',
                    status: 'success',
                });
                setEmployeeLoading(false);
            })
            .catch((error) => {
                setShowNotification(true);
                setNotification({
                    title: 'Error saving user permissions.',
                    desc: 'Please try again.',
                    status: 'failure',
                });
                logError('saveSingleEmployeePermissions', error);
                setEmployeeLoading(false);
            });
    };

    const handleSelectAllPermissions = (employeeId, selectAll) => {
        setAllEmployees((currentEmployees) =>
            currentEmployees.map((emp) => {
                if (emp.Id === employeeId) {
                    const updatedPermissions = selectAll ? emp.permissions.map((perm) => perm.value) : [];
                    return { ...emp, WMS_Permissions__c: updatedPermissions };
                }
                return emp;
            })
        );
    };

    if (loading) return <LoadingSpinner />;

    return (
        <>
            {showNotification && (
                <Notification
                    showSavedNotification={showNotification}
                    setShowSavedNotification={setShowNotification}
                    title={notification?.title}
                    desc={notification?.desc}
                    status={notification?.status}
                />
            )}
            <h1 className='text-2xl font-bold mb-4'>User Permissions</h1>
            <div className='grid grid-cols-3 gap-4'>
                {allEmployees &&
                    allEmployees.map((employee) => (
                        <div key={employee.Id} className='border p-4 rounded-lg relative'>
                            <h3 className='font-bold mb-3'>{employee.inscor__Employee_Name__c}</h3>
                            <p
                                className='absolute top-2 right-2 text-gray-400 cursor-pointer hover:text-blue-800 text-sm'
                                onClick={() => handleSelectAllPermissions(employee.Id, true)}
                            >
                                Select All Permissions
                            </p>
                            <div className='flex'>
                                <div className='mr-16'>
                                    <h3 className='font-semibold mb-3'>Permissions</h3>
                                    {employee.permissions.map((perm, permIndex) => (
                                        <div key={`${employee.Id}-${permIndex}`} className='mb-2'>
                                            <label className='inline-flex items-center'>
                                                <input
                                                    type='checkbox'
                                                    checked={employee.WMS_Permissions__c.includes(perm.value)}
                                                    onChange={() => handleCheckboxChange(employee.Id, perm.value)}
                                                    className='form-checkbox h-5 w-5 text-blue-600'
                                                />
                                                <span className='ml-2 text-gray-700'>{perm.label}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button
                                type='button'
                                disabled={employeeLoading}
                                onClick={() => {
                                    saveSingleEmployeePermissions(employee.Id, employee.WMS_Permissions__c);
                                }}
                                className='absolute bottom-2 right-2 bg-blue-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer disabled:opacity-80'
                            >
                                {employeeLoading === employee.Id ? 'loading...' : 'Save'}
                            </button>
                        </div>
                    ))}
            </div>
        </>
    );
}
