import React, { useState } from 'react';
import LargeActionModal from '../LargeActionModalWorkOrder';
import ScannerUpload from './ScannerUpload';
import Camera from '../Camera';

export default function ActionPanelWorkOrder({ status, closeActionPanel, canClose, setStatus, workOrderName }) {
    const [formData, setFormData] = useState({
        photos: [],
        scans: [],
    });

    return (
        <div className=' mx-auto px-4 sm:px-6 lg:px-8 border h-screen'>
            <LargeActionModal
                workOrderName={workOrderName}
                status={status}
                closeAction={closeActionPanel}
                canClose={canClose}
                formData={formData}
                setFormData={setFormData}
                setStatus={setStatus}>
                <div className='relative'>
                    <h1 className='text-2xl font-bold text-center  mx-auto p-2 w-fit h-12'>{workOrderName}</h1>
                </div>
                <div className='flex flex-col md:flex-row justify-center md:space-x-4  space-y-2 md:space-y-0 p-4 items-start'>
                    <ScannerUpload setFormData={setFormData} formData={formData} />
                    <Camera formData={formData} setFormData={setFormData} />
                </div>
            </LargeActionModal>
        </div>
    );
}
