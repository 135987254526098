import React, { createContext, useEffect, useState } from 'react';
import Notification from '../../../../components/Notification';

export const GuidedInterfaceContext = createContext({
    showNotification: false,
    setShowNotification: () => {},
});

export function GuidedInterfaceContextWrapper({ children }) {
    const [showNotification, setShowNotification] = useState({ shown: false, status: null, title: '', message: '' });
    const [partInspectionSeconds, setPartInspectionSeconds] = useState(0);
    const [partInspectionTimeDisplay, setPartInspectionTimeDisplay] = useState('0:00');

    const closeNotification = () => {
        setShowNotification({ shown: false, status: null, title: '', message: '' });
    };

    const startTimer = () => {
        const timer = setInterval(
            () =>
                setPartInspectionSeconds((prev) => {
                    const time = prev + 1;
                    const minutes = Math.floor(time / 60);
                    const seconds = time % 60;
                    setPartInspectionTimeDisplay(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
                    return time;
                }),
            1000
        );
        return timer;
    };

    const stopTimer = (timer) => {
        clearInterval(timer);
        setPartInspectionSeconds(0);
        setPartInspectionTimeDisplay('0:00');
    };

    useEffect(() => {
        if (showNotification.shown) {
            setTimeout(() => {
                closeNotification();
            }, 5000);
        }
    }, [showNotification]);

    const value = {
        partInspectionSeconds,
        partInspectionTimeDisplay,
        showNotification,
        setShowNotification,
        startTimer,
        stopTimer,
    };

    return (
        <GuidedInterfaceContext.Provider value={value}>
            <Notification
                showSavedNotification={showNotification.shown}
                setShowSavedNotification={closeNotification}
                status={showNotification.status}
                title={showNotification.title}
                desc={showNotification.message}
            />
            {children}
        </GuidedInterfaceContext.Provider>
    );
}
