import React, { Fragment, useContext, useEffect, useState } from 'react';
import { TraceContext } from './Context/TraceContextWrapper';
import { stepStatus, uploadStep } from './utils/data';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';

export default function SubmissionBoard({
    releaseName,
    releaseHeaderuploadStep,
    heroImageuploadStep,
    newPhotouploadStep,
    addMessagesToChatterStep,
    resetStoredVariables,
    containsAnyHeroImages,
    isOpen,
}) {
    const {
        submissionStatus,
        setSubmissionStatus,
        updateNotificationState,
        notificationState,
        photosAdded,
        photosChanged,
        photosForSubmission,
        removeSuccessfulPhotos,
        chatterModalState,
    } = useContext(TraceContext);
    const [retryCount, setRetryCount] = useState(0);

    const maxRetries = 2;

    const getCurrentSubmissionSteps = () => {
        let submissionSteps = [];

        // Retry state
        if (submissionStatus.length) {
            submissionSteps = submissionStatus.map((state) => {
                if (state.status === stepStatus.SUCCESS) {
                    return state;
                }

                return { ...state, status: stepStatus.LOADING };
            });
        }

        // Default state
        if (!submissionStatus.length) {
            submissionSteps.push({
                title: uploadStep.UPDATING_RELEASE,
                status: stepStatus.LOADING,
                function: releaseHeaderuploadStep,
            });

            if (chatterModalState?.messageData?.length)
                submissionSteps.push({
                    title: uploadStep.ADDING_CHATTER_MESSAGES,
                    status: stepStatus.LOADING,
                    function: addMessagesToChatterStep,
                });
            if (photosChanged?.length)
                submissionSteps.push({
                    title: uploadStep.UPDATING_HERO_IMAGES,
                    status: stepStatus.LOADING,
                    function: heroImageuploadStep,
                });
            if (!photosChanged?.length && containsAnyHeroImages)
                submissionSteps.push({
                    title: uploadStep.PERSISTING_HERO_IMAGES,
                    status: stepStatus.LOADING,
                    function: heroImageuploadStep,
                });
            if (photosAdded?.length)
                submissionSteps.push({
                    title: uploadStep.ADDING_NEW_PHOTOS,
                    status: stepStatus.LOADING,
                    function: newPhotouploadStep,
                });
        }

        setSubmissionStatus(submissionSteps);
        return submissionSteps;
    };

    // Handle the final release submission
    const handleReleaseSubmission = async () => {
        try {
            updateNotificationState({ ...notificationState, shown: false });
            const uploadSteps = getCurrentSubmissionSteps();
            let stepPromises = [];

            uploadSteps.forEach((step) => {
                if (step.status === stepStatus.SUCCESS) return;
                stepPromises.push(step.function());
            });

            const evaluation = await Promise.allSettled(stepPromises);

            if (evaluation.some((promise) => promise.status === 'rejected')) throw Error();

            // Finalize if all functions succeed
            finalizeSuccessfulSubmission();
        } catch (error) {
            let errorMessage = '';
            if (retryCount >= maxRetries) {
                errorMessage =
                    'Please refresh the page to retry the process. If the issue persists please contact your manager.';
            } else {
                errorMessage = 'Please try again by clicking the retry button.';
            }
            updateNotificationState(
                {
                    message: errorMessage,
                    title: 'Error Submitting Release',
                    shown: true,
                    error: true,
                },
                { error, function: 'handleReleaseSubmission' }
            );
        }
    };

    const retrySubmissionProcess = async () => {
        setRetryCount((prevState) => prevState + 1);
        removeSuccessfulPhotos();
        await handleReleaseSubmission();
    };

    const finalizeSuccessfulSubmission = () => {
        const timeBeforeSubmission = 5_000;
        setTimeout(() => {
            resetStoredVariables();
        }, timeBeforeSubmission);
        updateNotificationState({
            message: 'Refresh the page or wait 5 seconds to be redirected.',
            title: 'Successfully Updated The Release',
            shown: true,
            error: false,
        });
    };

    useEffect(() => {
        if (!isOpen) return;
        handleReleaseSubmission();
    }, [isOpen, chatterModalState]);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog className='relative z-10' onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>
                <section className='fixed w-screen h-screen top-0 left-0 bg-gray-600 opacity-30 z-10' />
                <section className='fixed top-0 left-0 z-20 flex items-center justify-center w-screen h-screen'>
                    <section className='fixed flex flex-col justify-between bg-white rounded-lg px-8 py-7 w-[750px] h-[600px] z-20'>
                        <section className='h-1/2'>
                            <header className='w-full items-center justify-center mb-4'>
                                <h1 className='text-2xl w-full text-center'>{releaseName.toUpperCase()}</h1>
                            </header>
                            <section className='w-full flex flex-row justify-between h-full'>
                                <section className='flex flex-col gap-y-4 w-2/5'>
                                    {submissionStatus?.map((step, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-row gap-2 items-center justify-between h-8 w-full'
                                            >
                                                <h1 className='text-xl'>{step.title}</h1>
                                                {step.status === stepStatus.LOADING && (
                                                    <div className='w-8 h-8 border-b-[2px] border-e-[2px] border-gray-500 rounded-full animate-spin out' />
                                                )}
                                                {step.status === stepStatus.SUCCESS && (
                                                    <CheckCircleIcon
                                                        className={
                                                            'hover:cursor-pointer w-8 h-8 rounded-full bg-green-500 text-white '
                                                        }
                                                    />
                                                )}
                                                {step.status === stepStatus.FAILURE && (
                                                    <XMarkIcon
                                                        className={
                                                            'hover:cursor-pointer w-8 h-8 rounded-full bg-red-500 text-white '
                                                        }
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </section>
                                {submissionStatus.every((state) => state.status !== stepStatus.LOADING) &&
                                submissionStatus.some((state) => state.status === stepStatus.FAILURE) &&
                                retryCount <= maxRetries ? (
                                    <section className='flex flex-col w-3/5 items-center mt-8 gap-7 h-full'>
                                        <h1 className='text-xl'>Click Here To Retry</h1>
                                        <button
                                            type='button'
                                            className='cursor-pointer w-fit rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                            onClick={() => retrySubmissionProcess()}
                                        >
                                            Retry
                                        </button>
                                    </section>
                                ) : (
                                    <section />
                                )}
                            </section>
                        </section>
                        <section className='h-1/2 relative flex flex-row flex-wrap w-full items-start justify-start gap-8 overflow-y-auto'>
                            {photosForSubmission?.map((photo, index) => {
                                const imageUriFromBase64 = `data:image/jpeg;base64,${photo?.base64}`;

                                return (
                                    <div
                                        key={index}
                                        className={
                                            'w-32 h-32 ' +
                                            (photo?.uploadStatus === undefined ? ' ' : ' border-4 ') +
                                            (photo?.uploadStatus ? 'border-green-500' : 'border-red-500')
                                        }
                                    >
                                        <img className='w-full h-full' src={imageUriFromBase64} />
                                    </div>
                                );
                            })}
                        </section>
                    </section>
                </section>
            </Dialog>
        </Transition.Root>
    );
}
