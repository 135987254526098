import React, { useEffect, useState, useRef } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SFDynamicForm from '../sf-dynamic-form/Index.js';
import { getWMSFields, validateFields } from '../../../utils/commonAPICalls.js';
import { useAuth } from '../../../wms/context/wms-context.js';
import LoadingSpinner from '../../../components/LoadingSpinner.js';
import config from '../../../utils/config.js';
import Notification from '../../../components/Notification.js';
import logError from '../../../utils/errorUtil.js';

export default function ReceivingPanel({ status, setStatus }) {
    const { currentUser, setCurrentUser } = useAuth();
    const ORIGIN = config.origin;

    // scroll to top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({});

    const dialogRef = useRef(null);

    useEffect(() => {
        setStatus({ ...status, loading: true });
        let data = {
            manifestLine: {
                ...(currentUser.selectedMaidLine || currentUser.selectedMaid),
            },
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            tableStructure: currentUser.tableStructures,
        };
        getWMSFields(data, 'get-inventory-fields', 'get-dropdowns')
            .then((data) => {
                setFormData(data);
                setStatus({ ...status, loading: false });
            })
            .catch((error) => {
                logError(error, 'ReceivingPanel');
                setStatus({ ...status, loading: false, error: true });
            });
    }, []);

    const handleSubmission = async () => {
        setIsLoading(true);
        // check that every field that is required has a value
        let allFieldsValid = true;
        formData.forEach((field) => {
            if ((field.required && !field.value) || (field.required && field.value === '')) {
                allFieldsValid = false;
            }
        });
        if (!allFieldsValid) {
            alert('Please fill out all required fields');
            return;
        }

        let serverFiledValidation = await validateFields(formData, '/wms/receiving/validate-form', currentUser);

        if (serverFiledValidation.valid === false) {
            setNotification(true);
            setNotificationMessage({
                status: 'error',
                title: 'Error',
                message: serverFiledValidation.message,
            });

            setTimeout(() => {
                setNotification(false);
            }, 4000);

            return setIsLoading(false);
        }

        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            manifestLine: {
                ...(currentUser.selectedMaidLine || currentUser.selectedMaid),
            },
            formData: formData,
        };
        setCurrentUser({ ...currentUser, copiedForm: formData });
        currentUser.copiedForm = formData;

        setStatus({ ...status, receivingPanel: false, actionPanel: true });
        fetch(`${ORIGIN}/wms/receiving/receive-manifest-line`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 500) {
                        // Process the response to get the error message
                        return res.json().then((errorData) => {
                            console.log(errorData);
                            setStatus({
                                ...status,
                                loading: false,
                                error: true,
                                errorMessage: errorData.message || 'Internal Server Error',
                            });
                        });
                    }
                    // Handle other non-2xx responses if needed
                }
                return res.json();
            })
            .then((data) => {
                if (data?.status === 'success') {
                    setCurrentUser({
                        ...currentUser,
                        receiving_inventoryId: data.inventoryLine.Id,
                        receiving_inventoryLine: data.inventoryLine,
                    });
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                logError(err, 'handleSubmission');
                setStatus({ ...status, loading: false, error: true });
            });
    };

    useEffect(() => {
        function handleBackdropClick(event) {
            if (dialogRef.current && dialogRef.current.contains(event.target)) {
                event.stopPropagation();
            }
        }

        document.addEventListener('click', handleBackdropClick);

        return () => {
            document.removeEventListener('click', handleBackdropClick);
        };
    }, []);

    const handleClose = (event) => {
        console.log('handleClose');
    };


    const handleCopyFromLast = () => {
        let copiedForm = currentUser.copiedForm;

        if (!copiedForm) {
            return; // If there's no previous form data, exit early.
        }

        // Create a helper function to perform a deep copy
        const deepClone = (value) => {
            if (Array.isArray(value)) {
                return value.map((item) => deepClone(item));
            } else if (value && typeof value === 'object') {
                let clonedObj = {};
                for (let key in value) {
                    clonedObj[key] = deepClone(value[key]);
                }
                return clonedObj;
            } else {
                return value;
            }
        };

        // Create the updated formData array
        let updatedFormData = formData.map((field) => {
            // Look for the corresponding field in copiedForm
            let copiedField = copiedForm.find((f) => f.name === field.name);

            // If found, and it has a value, then copy the value (deeply if necessary)
            if (copiedField && copiedField.value !== undefined) {
                return {
                    ...field, // Spread to retain other properties
                    value: deepClone(copiedField.value), // Deep clone the value
                };
            }
            return field; // If not found, return the original field
        });

        setFormData(updatedFormData);
    };

    return (
        <>
            <Transition.Root show={status.receivingPanel} as={Fragment}>
                <Dialog as='div' className='relative z-10' open={status.receivingPanel} onClose={handleClose}>
                    <Notification
                        showSavedNotification={notification}
                        setShowSavedNotification={setNotification}
                        title={notificationMessage.title}
                        desc={notificationMessage.message}
                        status={notificationMessage.status}
                        autoClose={true}
                    />
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed inset-0 z-10 overflow-y-auto'>
                        <div className='flex items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                                enterTo='opacity-100 translate-y-0 sm:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            >
                                <Dialog.Panel className='relative transform rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-9xl min-h-96'>
                                    <div>
                                        {/* <p
                                        onClick={clearAll}
                                        className='float-right text-sm underline text-gray-500 cursor-pointer hover:text-gray-700'
                                    >
                                        Clear all
                                    </p> */}
                                        <div className='text-center '>
                                            <Dialog.Title
                                                as='h3'
                                                className='text-2xl leading-6 font-medium bg-blue-primary p-2 rounded-t-md text-white mb-4 py-4'
                                            >
                                                Receiving
                                            </Dialog.Title>
                                            {status.loading && <LoadingSpinner />}
                                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-8'>
                                                <SFDynamicForm formData={formData} setFormData={setFormData} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-8 flex flex-row justify-end space-x-4 p-4'>
                                        <button
                                            type='button'
                                            className='w-24 cursor-pointer rounded-md border border-transparent bg-gray-500 px-3 py-1.5 text-lg font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 '
                                            onClick={() => {
                                                setStatus({ ...status, receivingPanel: false });
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type='button'
                                            disabled={status.loading || !currentUser.copiedForm}
                                            className='w-48 cursor-pointer rounded-md border border-transparent bg-gray-500 px-3 py-1.5 text-lg font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 '
                                            onClick={() => {
                                                handleCopyFromLast();
                                            }}
                                        >
                                            Copy From last
                                        </button>
                                        <button
                                            type='button'
                                            disabled={isLoading}
                                            className='w-48 rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-lg font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                            onClick={() => {
                                                handleSubmission();
                                            }}
                                        >
                                            Receive Inventory
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
