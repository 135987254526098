import { PageOrderHolder } from './IndividualHolders/PageOrderHolder';
import { PagePartHolder } from './IndividualHolders/PagePartHolder';
import { PageQuoteHolder } from './IndividualHolders/PageQuoteHolder';

export const PageResultHolder = ({ resultsArray, search, resultsFound, updateDocumentData, checkForPart }) => {
    return (
        <>
            {resultsFound ? (
                <section className='sticky z-10 top-0 text-xl w-full bg-slate-50 p-3 rounded-lg mt-7'>
                    Search results for <strong>"{search}"</strong>
                </section>
            ) : (
                <section className='sticky z-10 top-0 text-xl w-full bg-slate-50 p-3 rounded-lg mt-7'>
                    No search results found for <strong>"{search}"</strong>
                </section>
            )}
            {resultsArray &&
                resultsArray.map((resultObject, index) => {
                    const { data, objectName } = resultObject;

                    if (!data || data.length === 0) return null;

                    if (objectName === 'Order') {
                        return <PageOrderHolder key={index} object={resultObject} updateDocumentData={updateDocumentData} />;
                    }
                    if (objectName === 'Quote') {
                        return <PageQuoteHolder key={index} object={resultObject} updateDocumentData={updateDocumentData} />;
                    }
                    if (objectName === 'Part') {
                        return <PagePartHolder key={index} object={resultObject} checkForPart={checkForPart} />;
                    }
                })}
        </>
    );
};
