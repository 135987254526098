import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { documentKeys, overrideDefaults } from '../forms/utils/data';

export const FormContext = createContext({
    updateDocumentDefault: () => {},
    documentDefaults: {},
    resetDocumentDefaults: () => {},
    errorWithinForm: {},
    handleFormError: () => {},
    documentInfo: {},
    setDocumentInfo: () => {},
    currentRelease: '',
    setCurrentRelease: () => {},
    selectedDocuments: [],
    setSelectedDocuments: () => {},
});

export function FormContextWrapper({ children }) {
    const [documentDefaults, setDocumentDefaults] = useState(_.clone(overrideDefaults));
    const [errorWithinForm, setErrorWithinForm] = useState(false);
    const [documentInfo, setDocumentInfo] = useState({});
    const [currentRelease, setCurrentRelease] = useState();
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const updateDocumentDefaults = (formOverrides, selectedInventoryName) => {
        const defaults = _.clone(overrideDefaults);

        // Go through each document
        for (const documentName in defaults) {
            if (!(documentName in formOverrides)) continue;

            const updatedDocument = formOverrides[documentName].find((data) => data.inventoryName === selectedInventoryName);
            const defaultDocument = _.clone(defaults[documentName]);

            // Go through each key to check for updates
            for (const override in defaultDocument) {
                if (updatedDocument[override] !== defaultDocument[override]) {
                    defaultDocument[override] = updatedDocument[override];
                }
            }

            // Link both packing sheets together
            const isPackSheet =
                documentName === documentKeys.PACKING_SLIP_PRICED || documentName === documentKeys.PACKING_SLIP_UNPRICED;
            if (isPackSheet) {
                defaults[documentKeys.PACKING_SLIP_PRICED] = defaultDocument;
                defaults[documentKeys.PACKING_SLIP_UNPRICED] = defaultDocument;

                continue;
            }
            defaults[documentName] = _.clone(defaultDocument);
        }
        setDocumentDefaults(defaults);
    };

    const handleFormError = (errorState = false) => {
        if (errorState) {
            setErrorWithinForm(true);
            return;
        }

        setErrorWithinForm(false);
    };

    const resetDocumentDefaults = () => {
        setDocumentDefaults(_.clone(overrideDefaults));
    };

    const resetGlobalValues = () => {
        setDocumentInfo(null);
        setSelectedDocuments([]);
    };

    useEffect(() => {
        resetGlobalValues();
    }, [currentRelease]);

    const value = {
        updateDocumentDefaults,
        documentDefaults,
        resetDocumentDefaults,
        errorWithinForm,
        handleFormError,
        documentInfo,
        setDocumentInfo,
        currentRelease,
        setCurrentRelease,
        selectedDocuments,
        setSelectedDocuments,
    };

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
