import { QuoteResult } from './QuoteResult';
import { OrderResult } from './OrderResult';
import { PartResult } from './PartResult';

// Handles the dynamic display of items on the left hand side of the global search dropdown
export const ResultToDisplay = ({ object, setSelectedObject, selectedObject, searchInput }) => {
    if (object?.data?.length === 0) return null;
    return (
        <div className='flex flex-col w-full'>
            {object.objectName === 'Order' && (
                <>
                    <h1 className='sticky top-0 z-20 w-full py-2 text-gray-600 text-start px-3 bg-gray-100'>
                        Order Results for <strong>{searchInput}</strong>
                    </h1>
                    {object.data.map((object, index) => {
                        return (
                            <OrderResult
                                key={index}
                                sale={object}
                                setSelectedObject={setSelectedObject}
                                selectedObject={selectedObject}
                                searchInput={searchInput}
                            />
                        );
                    })}
                </>
            )}
            {object.objectName === 'Quote' && (
                <>
                    <h1 className='sticky top-0 z-20 w-full py-2 text-gray-600 text-start px-3 bg-gray-100'>
                        {object.objectName} Results for <strong>{searchInput}</strong>
                    </h1>
                    {object.data.map((object, index) => {
                        return (
                            <QuoteResult
                                key={index}
                                quote={object}
                                setSelectedObject={setSelectedObject}
                                selectedObject={selectedObject}
                                searchInput={searchInput}
                            />
                        );
                    })}
                </>
            )}
            {object.objectName === 'Part' && (
                <>
                    <h1 className='sticky top-0 z-20 w-full py-2 text-gray-600 text-start px-3 bg-gray-100'>
                        Part Results for <strong>{searchInput}</strong>
                    </h1>
                    {object.data.map((object, index) => {
                        return (
                            <PartResult
                                key={index}
                                part={object}
                                setSelectedObject={setSelectedObject}
                                selectedObject={selectedObject}
                            />
                        );
                    })}
                </>
            )}
        </div>
    );
};
