import Rollbar from 'rollbar';

// Set environment
let environment = 'DEV';
switch (true) {
    case window.location.origin.includes('localhost'):
        environment = 'DEV';
        break;
    case window.location.origin.includes('bytrek'):
        environment = 'QA';
        break;
    case window.location.origin.includes('portal2'):
        environment = 'DO WMS';
        break;
    case window.location.origin.includes('pac-air'):
        environment = 'Azure PROD';
        break;
    default:
        break;
}

const rollbarConfig = {
    accessToken: '9960ca3258624b95be22627fff66d7d4',
    environment: environment,
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
