import { XMarkIcon } from '@heroicons/react/24/solid';

export const SearchModalTemplateWrapper = ({ headerText, closeModal, children }) => {
    return (
        <section className='w-full flex flex-col items-center'>
            <header className='flex flex-row justify-between items-center w-full border-b border-gray-400 pb-4 pl-3'>
                <h1 className='text-3xl'>{headerText} Details</h1>
                <button className='w-8 h-8 p-1 bg-gray-100 rounded-md hover:bg-gray-300' onClick={closeModal}>
                    <XMarkIcon />
                </button>
            </header>
            {children}
        </section>
    );
};
