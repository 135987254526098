import config from '../../../../utils/config.js';
import logError from '../../../../utils/errorUtil.js';

const ORIGIN = config.origin;

export const fetchReleaseData = async (inputReleaseNumber, currentUser) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        inputReleaseNumber,
        userName: currentUser.authentication.userName,
    };
    const response = await fetch(`${ORIGIN}/wms/trace-legacy/get-inv-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get inventory data');

    const responseData = await response.json();

    return responseData;
};

export const fetchDocumentsAndPhotos = async (currentUser, body) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        ...body,
    };
    const response = await fetch(`${ORIGIN}/wms/trace-legacy/get-inv-files`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get inventory documents and photos');

    const responseData = await response.json();

    return responseData;
};

export const fetchBinderData = async (traceBinderOneClickLink, currentUser) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        traceBinderOneClickLink,
    };
    const response = await fetch(`${ORIGIN}/wms/trace-legacy/get-trace-one-click`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response.ok) throw new Error('Failed to get inventory data');

    const responseData = await response.json();

    return responseData;
};

export const submitReleaseData = async (releaseId, isAccepted, authData) => {
    try {
        const dataToSend = {
            authentication: authData,
            releaseId,
            isAccepted,
        };

        const response = await fetch(`${ORIGIN}/wms/trace-legacy/submit-release-update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) throw new Error('Failed to get inventory data');

        const data = await response.json();

        return data;
    } catch (error) {
        logError(error, 'submitTracePhotos');
        return null;
    }
};

export const updatePhotoData = async (photosChanged, authData, invLineName, isAccepted, releaseId, ownerCode) => {
    try {
        if (!photosChanged?.length) return { success: true };
        const url = `${ORIGIN}/wms/trace-legacy/legacy-submit-photos-changed`;

        // Remove the base64 data from the photos
        const photos = photosChanged.map((photo) => {
            return {
                name: photo.name,
                isHero: photo.isHero,
            };
        });

        const body = JSON.stringify({
            authentication: authData,
            photos,
            invLineName,
            isAccepted,
            releaseId,
            ownerCode,
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        if (!response.ok) throw new Error('Failed to update the photos to be modified in Trace tab.');

        const data = response.json();

        return data;
    } catch (error) {
        logError(error, 'updatePhotoData');
        return null;
    }
};

export const submitTracePhoto = async (photoBlob, isHero, authData, inventoryData, isAccepted, releaseId) => {
    try {
        const { invLineId, invLineName, productName, ownerCodeName } = inventoryData;
        const url = `${ORIGIN}/wms/trace-legacy/submit-trace-photo`;

        // Create the formData and append the necessary data
        const formData = new FormData();
        formData.append('authentication', JSON.stringify(authData));
        formData.append('isHero', isHero);
        formData.append('invLineId', invLineId);
        formData.append('invLineName', invLineName);
        formData.append('ProductName', productName);
        formData.append('ownerCodeName', ownerCodeName);
        formData.append('photo', photoBlob, 'photo.png');
        formData.append('isAccepted', isAccepted);
        formData.append('releaseId', releaseId);

        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) throw new Error('Error sending Trace photo upload to server.');

        const data = await response.json();

        return data;
    } catch (error) {
        logError(error, 'submitTracePhotos');
        return null;
    }
};

export const sendChatterMessage = async (currentUser, body) => {
    try {
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            ...body,
        };
        const response = await fetch(`${ORIGIN}/wms/trace-legacy/submit-chatter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) throw new Error('Failed to upload chatter message');

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        logError(error, 'submitChatterMessage');
    }
};

export const printInventoryLineBarcode = async (currentUser, inventoryLine) => {
    try {
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: [
                {
                    Id: inventoryLine.id,
                    Name: inventoryLine.name,
                },
            ],
        };

        const data = await fetch(`${ORIGIN}/wms/inventory/print-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!data) throw Error('Error fetching data');

        const blobData = data.blob();

        return blobData;
    } catch (error) {
        logError(error, 'printInventoryLineBarcode');
    }
};

export const printGeneratedDocuments = async (documents, currentUser) => {
    const ORIGIN = config.origin;
    let dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        documents,
    };

    await fetch(`${ORIGIN}/wms/documents/get-documents`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    })
        .then((res) => res.json())
        .then((data) => {
            if (!data) throw Error();

            for (const key in data) {
                const bufferData = data[key].data;
                const blob = new Blob([new Uint8Array(bufferData).buffer], { type: 'application/pdf' });

                // open in a new tab
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            }
        })
        .catch((err) => {
            logError(err, 'printGeneratedDocuments');
            throw Error();
        });
};

export const submitUpdatedHeaderData = async (currentUser, updatedPackingSheetData) => {
    try {
        let dataToSend = {
            updatedPackingSheetData,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        await fetch(`${ORIGIN}/wms/trace-legacy/update-release-header`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });
    } catch (error) {
        logError(error, 'printAllPickingLabels');
    }
};

export const printAllPickingLabels = async (currentUser, releaseNumber) => {
    try {
        let dataToSend = {
            releaseNumber: releaseNumber,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        await fetch(`${ORIGIN}/wms/picking/get-picking-tags`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((blob) => {
                // open in a new tab
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                //name of the file
                a.name = 'order.pdf';
                a.click();
            });
    } catch (error) {
        logError(error, 'printAllPickingLabels');
    }
};