import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import NewLineFormWrapper from './NewLineFormWrapper';
import { useAuth } from '../../../wms/context/wms-context';
import Notification from '../../../components/Notification';

export default function ReceiveNewLineModal({ status, setStatus }) {
    const { currentUser, setCurrentUser } = useAuth();
    const [formData, setFormData] = useState([]);

    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({});

    const handleSubmit = () => {
        let allFieldsValid = true;
        formData.forEach((field) => {
            if ((field.required && !field.value) || (field.required && field.value === '')) {
                allFieldsValid = false;
            }
        });

        if (!allFieldsValid) {
            setShowNotification(true);
            setNotificationMessage({
                title: 'Error',
                desc: 'Please fill out all required fields.',
                status: 'error',
            });
            return;
        }
        currentUser.selectedMaidLine = null;
        setCurrentUser({ ...currentUser, selectedMaidLine: null });
        let selectedWarehouse = formData[3].value;

        let selectedWarehouseLocation = formData[3].dropdownData.find((warehouse) => warehouse.Id === selectedWarehouse);
        console.log('your dm is here', selectedWarehouseLocation);
        setCurrentUser({
            ...currentUser,
            selectedMaid: {
                ...currentUser.selectedMaid,
                inscor__Company_Location__c: selectedWarehouseLocation.inscor__Company_Location__c,
                inscor__Condition_Code__c: formData[0].value,
                inscor__Owner_Code__c: formData[1].value,
                inscor__Part_Master__c: formData[2].value,
                inscor__Warehouse__c: formData[3].value,
                inscor__Manifest__c: currentUser.selectedMaid.Id,
                inscor__Serialized__c: currentUser.inscor__Serialized__c,
                Id: null,
            },
        });
        console.log('current user', currentUser);

        setStatus({ ...status, receiveNewLinePanel: false, receivingPanel: true });
    };
    return (
        <>
            <Notification
                showSavedNotification={showNotification}
                setShowSavedNotification={setShowNotification}
                title={notificationMessage.title}
                desc={notificationMessage.desc}
                status={notificationMessage.status}
            />

            <Transition.Root show={status.receiveNewLinePanel} as={Fragment}>
                <Dialog
                    as='div'
                    className='relative z-10 overflow-visible'
                    onClose={() => setStatus({ ...status, receiveNewLinePanel: false })}
                >
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ' />
                    </Transition.Child>

                    <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                                enterTo='opacity-100 translate-y-0 sm:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            >
                                <Dialog.Panel className='relative transform rounded-lg overflow-visible bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6'>
                                    <div className=''>
                                        <NewLineFormWrapper
                                            status={status}
                                            setStatus={setStatus}
                                            formData={formData}
                                            setFormData={setFormData}
                                        />
                                    </div>
                                    <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                                        <button
                                            type='button'
                                            className='inline-flex w-full justify-center rounded-md bg-blue-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-secondary sm:ml-3 sm:w-auto'
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type='button'
                                            className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                                            onClick={() => setStatus({ ...status, receiveNewLinePanel: false })}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
