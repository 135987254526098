// React and Helper imports
import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

// Component and Util imports
import { useAuth } from '../../wms/context/wms-context';
import { validatePartsSearch } from '../../utils/validators.js';
import logError from '../../utils/errorUtil.js';
import LoadingButtonFixedWidth from '../../components/LoadingButtonFixedWidth.js';
import Notification from '../../components/Notification.js';
import { addPartsToScraperSystem } from '../utils/parts-scrape/api-calls.js';

export default function WMSDataScrape() {
    const defaultScrapeOptions = {
        hasStandardPriority: true,
        useCCP: true,
    };
    // Current User information and the origin
    const { currentUser } = useAuth();

    // Parts to send in the text box
    const [partsList, setPartsList] = useState('');
    // Loading state for the submit button
    const [isLoading, setIsLoading] = useState(false);
    // State for the notification
    const [showNotification, setShowNotification] = useState(false);
    const [tempNotification, setTempNotification] = useState({
        title: '',
        message: '',
        status: '',
    });
    // State for the question mark icon
    const [isShowing, setIsShowing] = useState(false);
    const [scrapeOptions, setScrapeOptions] = useState(defaultScrapeOptions);

    // Part scrape limit and formatted part scrape limit to have a comma
    const partScrapeLimit = 50_000;
    const formatetedPartScrapeLimit = partScrapeLimit.toLocaleString();

    // When the tempNotification is set, clear it after 5 seconds
    useEffect(() => {
        let timeoutId;
        if (tempNotification) {
            timeoutId = setTimeout(() => {
                setTempNotification({ title: '', message: '', status: '' });
                setShowNotification(false);
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [tempNotification]);

    // Function to send the parts to scrape to the server
    async function onSubmit(e) {
        try {
            // Set the loading states
            e?.preventDefault();
            setIsLoading(true);

            // Validate and sanitize the data
            let filteredData = validatePartsSearch(partsList, partScrapeLimit);
            if (filteredData === `You can only search up to ${partScrapeLimit} parts at a time.`) {
                setIsLoading(false);
                setTempNotification({
                    title: 'Part Limit Reached',
                    message: `You can only scrape up to ${formatetedPartScrapeLimit} parts at a time. Please lower the amount of parts you are scraping for.`,
                    status: 'error',
                });
                setShowNotification(true);
                return;
            } else if (!filteredData?.length) {
                setIsLoading(false);
                setTempNotification({
                    title: 'Invalid Input',
                    message: 'Please enter a valid part number to scrape.',
                    status: 'error',
                });
                setShowNotification(true);
                return;
            }

            await addPartsToScraperSystem(
                currentUser,
                filteredData,
                scrapeOptions.hasStandardPriority,
                scrapeOptions.useCCP
            );

            setTempNotification({
                title: 'Parts Added',
                message: 'Successfully added parts to scrape queue',
                status: 'success',
            });
            setShowNotification(true);
            setIsLoading(false);
            setPartsList('');
        } catch (error) {
            logError(error, 'WMSDataScrape onSubmit');
            setTempNotification({
                title: 'Error Adding Parts',
                message: 'Failed to add parts to scrape queue, please try again.',
                status: 'error',
            });
            setShowNotification(true);
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Notification
                showSavedNotification={showNotification}
                setShowSavedNotification={setShowNotification}
                title={tempNotification?.title}
                desc={tempNotification?.message}
                status={tempNotification?.status}
            />
            <div className='flex items-center justify-center mt-14 mb-14 p-5'>
                <div className='w-full max-w-4xl bg-gray-50 border-2 border-blue-primary shadow-md rounded-lg overflow-hidden pb-6'>
                    <div>
                        <h2 className='text-3xl font-semibold py-2 mb-6 px-12 text-center text-white bg-blue-primary'>
                            Parts Scrape Input
                        </h2>
                        <div className='relative'>
                            <p className='pl-4 text-gray-600 text-center'>
                                Enter a list of part numbers on new lines.
                                <QuestionMarkCircleIcon
                                    onMouseEnter={() => setIsShowing(true)}
                                    onMouseLeave={() => setIsShowing(false)}
                                    className='h-5 w-5 inline-block'
                                />
                            </p>
                            {isShowing && (
                                <Transition
                                    appear={true}
                                    show={isShowing}
                                    enter='transition-opacity duration-200'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='transition-opacity duration-150'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <div className='absolute right-0 md:-top-30 md:right-44 bg-blue-primary p-4 w-36 text-lg'>
                                        <p className='pl-4 text-white'>
                                            Example:
                                            <br />
                                            12345
                                            <br />
                                            GQ756-2
                                            <br />
                                            G700-2
                                        </p>
                                    </div>
                                </Transition>
                            )}
                        </div>
                        <div className='px-12 py-4'>
                            <textarea
                                onChange={(e) => setPartsList(e.target.value)}
                                style={{ resize: 'both' }}
                                autoFocus
                                className='w-full px-3 py-2 text-base text-gray-700 placeholder-gray-400 border rounded-lg focus:shadow-outline resize-y focus:outline-gray-200'
                                placeholder={`Enter up to ${formatetedPartScrapeLimit} part numbers here`}
                                rows={16}
                                value={partsList}
                            ></textarea>
                        </div>
                        {!isLoading ? (
                            <button
                                className='bg-blue-primary hover:bg-blue-primary text-white font-bold py-2 px-4 rounded mx-auto cursor-pointer w-1/2'
                                onClick={onSubmit}
                                disabled={partsList.length === 0}
                            >
                                Scrape Parts
                            </button>
                        ) : (
                            <LoadingButtonFixedWidth stopLoadingTextChange={true} />
                        )}
                        <section className='flex flex-col px-12 items-start gap-y-1 mt-2'>
                            <article className='flex flex-row gap-2'>
                                <input
                                    className='hover:cursor-pointer'
                                    type='checkbox'
                                    checked={scrapeOptions.hasStandardPriority}
                                    onChange={(e) =>
                                        setScrapeOptions((options) => {
                                            return { ...options, hasStandardPriority: e.target.checked };
                                        })
                                    }
                                />
                                <h1>Standard Priority</h1>
                            </article>
                            <article className='flex flex-row gap-2'>
                                <input
                                    className='hover:cursor-pointer'
                                    type='checkbox'
                                    checked={scrapeOptions.useCCP}
                                    onChange={(e) =>
                                        setScrapeOptions((options) => {
                                            return { ...options, useCCP: e.target.checked };
                                        })
                                    }
                                />
                                <h1>Use CCP</h1>
                            </article>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}
