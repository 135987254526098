import { useState } from 'react';
import HolderHeader from './HolderHeader';
import IndividualResultWrapper from './IndividualResultWrapper';
import { formatDate } from '../../../../../utils/formatDate';
import { formatAsCurrency } from '../../../../../utils/formatAsCurrency';
import { capitalizeWords } from '../../../OrdersTable';
import { PrinterIcon } from '@heroicons/react/24/solid';
import { DocumentTextIcon } from '@heroicons/react/24/outline';

export const PageOrderHolder = ({ object, updateDocumentData }) => {
    const { data, hasParentCompany } = object;
    const [showMore, setShowMore] = useState(false);
    const columns = hasParentCompany ? { lg: 8, md: 5, sm: 3 } : { lg: 7, md: 5, sm: 3 };

    return (
        <div className='flex flex-col w-full rounded-lg  mt-7 last:mb-7'>
            <HolderHeader text={'Orders'} showMore={showMore} setShowMore={setShowMore} hasMore={object.data.length > 10} />
            <section
                className={
                    'w-full grid p-3 gap-3 bg-gray-100 last:rounded-b-lg border-t border-gray-200 grid-cols-3 md:grid-cols-5 ' +
                    (hasParentCompany ? ' lg:grid-cols-8 ' : ' lg:grid-cols-7 ')
                }
            >
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Purchase Order</div>
                </article>
                {hasParentCompany && (
                    <article className='col-span-1 flex flex-col'>
                        <div className='first:flex hidden font-bold'>Company</div>
                    </article>
                )}
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Buyer</div>
                </article>
                <article className={'col-span-1 ' + (hasParentCompany ? ' hidden md:flex flex-col ' : ' flex flex-col ')}>
                    <div className='first:flex hidden font-bold'>Date</div>
                </article>
                <article className='col-span-1 hidden md:flex flex-col'>
                    <div className='first:flex hidden font-bold'>Sales Number</div>
                </article>
                <article
                    className={
                        'col-span-1 ' + (hasParentCompany ? ' hidden lg:flex flex-col ' : ' hidden md:flex flex-col ')
                    }
                >
                    <div className='first:flex hidden font-bold'>Price</div>
                </article>
                <article className='col-span-1 hidden lg:flex flex-col'>
                    <div className='first:flex hidden font-bold'>Status</div>
                </article>
                <article className='col-span-1 hidden lg:flex flex-col'>
                    <div className='first:flex hidden font-bold'>Action</div>
                </article>
            </section>
            {data?.slice(0, showMore ? data?.length : 10).map((sale, index) => {
                const {
                    customerPoNumber: poNumber,
                    salesOrderNumber,
                    lineTotal,
                    createdDate,
                    placedBy,
                    status,
                    accountName,
                } = sale;

                return (
                    <IndividualResultWrapper key={index} table={'Order'} name={salesOrderNumber} columns={columns}>
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{poNumber}</h1>
                        </article>
                        {hasParentCompany && (
                            <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                                <h1 className='truncate leading-normal'>{capitalizeWords(accountName)}</h1>
                            </article>
                        )}
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{placedBy}</h1>
                        </article>
                        <article
                            className={
                                'col-span-1 justify-center overflow-hidden h-full ' +
                                (hasParentCompany ? ' hidden md:flex flex-col ' : ' flex flex-col ')
                            }
                        >
                            <h1 className='truncate leading-normal'>{formatDate(createdDate)}</h1>
                        </article>
                        <article className='col-span-1 hidden md:flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{salesOrderNumber}</h1>
                        </article>
                        <article
                            className={
                                'col-span-1 justify-center overflow-hidden h-full ' +
                                (hasParentCompany ? ' hidden lg:flex flex-col ' : ' hidden md:flex flex-col ')
                            }
                        >
                            <h1 className='truncate leading-normal'>{formatAsCurrency(lineTotal)}</h1>
                        </article>
                        <article className='col-span-1 hidden lg:flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{status}</h1>
                        </article>
                        <article className='col-span-1 hidden lg:flex flex-row justify-start items-center gap-3 h-full'>
                            {sale.releaseNumber && (
                                <button
                                    className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateDocumentData({
                                            id: sale.salesOrderId,
                                            name: sale.releaseNumber,
                                            type: 'release',
                                            releaseId: sale.releaseId,
                                            docUrl: false,
                                        });
                                    }}
                                    style={{
                                        all: 'revert',
                                    }}
                                >
                                    <PrinterIcon className='h-6 w-6 inline-block text-blue-primary' />
                                    <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                        View Shipping Information
                                    </div>
                                </button>
                            )}
                            {sale.invoiceDocumentId && (
                                <button
                                    className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateDocumentData({
                                            id: sale.invoiceId,
                                            name: sale.invoiceNumber,
                                            type: 'invoice',
                                            releaseId: sale.releaseId ? sale.releaseId : null,
                                            docUrl: false,
                                        });
                                    }}
                                    style={{
                                        all: 'revert',
                                    }}
                                >
                                    <PrinterIcon className='h-6 w-6 inline-block text-blue-primary' />
                                    <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                        View Invoice
                                    </div>
                                </button>
                            )}
                            {sale.traceURL && (
                                <button
                                    className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateDocumentData({
                                            id: null,
                                            name: null,
                                            type: null,
                                            releaseId: null,
                                            docUrl: sale.traceURL,
                                        });
                                    }}
                                    style={{
                                        all: 'revert',
                                    }}
                                >
                                    <DocumentTextIcon className='h-6 w-6 inline-block text-blue-primary' />
                                    <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                        Trace
                                    </div>
                                </button>
                            )}
                        </article>
                    </IndividualResultWrapper>
                );
            })}
        </div>
    );
};
