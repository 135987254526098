// import React from 'react';
import React, { useState } from 'react';

export default function LoadingSpinner({ className, spinnerSize, color }) {
    const sizeClass = spinnerSize ? `w-${spinnerSize} h-${spinnerSize}` : 'w-36 h-36';
    const spinnerColor = color ? color : 'border-gray-500';

    return (
        <div role='status'>
            <div className={`flex items-center justify-center ${className}`}>
                <div className={`${sizeClass} border-b-4 ${spinnerColor} rounded-full animate-spin`}></div>
            </div>
        </div>
    );
}

LoadingSpinner.defaultProps = {
    className: 'mt-24',
};
