import React from 'react';
import OrderSection from '../components/historyPageSections/OrderSection';

export default function OrderPage() {
    return (
        <div>
            <div className='mx-auto max-w-10xl px-4 sm:px-6 lg:px-8 mt-12 mb-24'>
                <div className='m-6'>
                    <OrderSection />
                </div>
            </div>
        </div>
    );
}
