import React, { useEffect, useRef, useState } from 'react';
import {
    CameraIcon,
    XMarkIcon,
    BarsArrowDownIcon,
    BarsArrowUpIcon,
    CheckCircleIcon,
    ArrowPathIcon,
} from '@heroicons/react/24/outline';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { maxCameraHeightResolution, maxCameraWidthResolution } from './data';
import { detectMobile, getCameraHeight, getCameraWidth } from './functions';
import MobileCameraButton from './MobileCameraButton';

export default function PhotoUpload(props) {
    let { formData, setFormData } = props;
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [enlargedPhoto, setEnlargedPhoto] = useState(null);
    const [focusBox, setFocusBox] = useState({ x: 0, y: 0, visible: false });
    const [videoStream, setVideoStream] = useState(null);
    const [cameras, setCameras] = useState([]);
    const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
    const isMobile = detectMobile();

    useEffect(() => {
        getAvailableCameras();
        startCamera();
    }, []);

    useEffect(() => {
        if (cameras.length > 0) {
            startCamera();
        }
    }, [currentCameraIndex, cameras]);

    async function startCamera() {
        if (videoRef.current && videoRef.current.srcObject) {
            // Stop all tracks of the current stream
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach((track) => track.stop());

            // Pause the video element before changing the source
            videoRef.current.pause();
            videoRef.current.srcObject = null; // Clear the current source object
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    focusMode: 'continuous',
                    deviceId: cameras[currentCameraIndex]?.deviceId,
                    width: { ideal: maxCameraWidthResolution },
                    height: { ideal: maxCameraHeightResolution },
                },
            });

            setVideoStream(stream);
            videoRef.current.srcObject = stream;

            // Wait for the metadata to be loaded before playing
            videoRef.current.onloadedmetadata = () => {
                videoRef.current.play();
            };
        } catch (err) {
            console.error('Failed to start the camera:', err);
        }
    }

    async function getAvailableCameras() {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');
            setCameras(videoDevices);
        } catch (err) {
            console.error('Failed to get cameras:', err);
        }
    }

    function switchCamera() {
        if (cameras.length > 1) {
            const nextIndex = (currentCameraIndex + 1) % cameras.length;
            setCurrentCameraIndex(nextIndex);
        }
    }

    function handleTakePhoto() {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, getCameraWidth(videoStream), getCameraHeight(videoStream));
        const dataUri = canvasRef.current.toDataURL('image/png');
        setFormData((prev) => ({
            ...prev,
            photos: [...prev.photos, { dataUri, selectedOption: 'Photo', uploaded: false }],
        }));
    }

    function handleMobilePhotoUpload(dataUri) {
        setFormData((prev) => ({
            ...prev,
            photos: [...prev.photos, { dataUri, selectedOption: 'Photo', uploaded: false }],
        }));
    }

    const radioOptions = [
        { id: 'Photo', title: 'Photo' },
        { id: 'PartRecord', title: 'Part Record' },
        { id: 'Tag', title: 'Tag' },
    ];

    function handleRadioChange(index, selectedOption) {
        const updatedPhotos = formData.photos.map((photo, i) => {
            if (i === index) {
                return {
                    ...photo,
                    selectedOption: selectedOption,
                };
            }
            return photo;
        });
        setFormData((prev) => ({
            ...prev,
            photos: updatedPhotos,
        }));
    }

    function handleRemovePhoto(indexToRemove) {
        setFormData((prev) => ({
            ...prev,
            photos: filterPhotosByIndex(prev.photos, indexToRemove),
        }));
    }

    function filterPhotosByIndex(photos, indexToRemove) {
        return photos.filter((photo, index) => index !== indexToRemove);
    }

    function handleEnlargePhoto(dataUri) {
        setEnlargedPhoto(dataUri);
    }

    async function handleClickToFocus(event) {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        // Calculate the focus point
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;

        // Update the focusBox state here after x and y have been initialized
        setFocusBox({ x, y, visible: true });

        const focusPoint = { x: x / videoElement.clientWidth, y: y / videoElement.clientHeight };

        // Check if camera supports manual focus and set it
        const videoTrack = videoElement.srcObject?.getVideoTracks()[0];
        const capabilities = videoTrack.getCapabilities();

        // capabilities.focusDistance will give you the min, max, and step values for focusDistance.

        if (capabilities?.focusMode && capabilities.focusMode.includes('manual')) {
            // Camera supports manual focus
        }
        if (capabilities?.focusMode && capabilities.focusMode.includes('manual')) {
            try {
                await videoTrack.applyConstraints({
                    advanced: [{ focusMode: 'manual', focusDistance: calculateFocusDistance(focusPoint) }],
                });
            } catch (err) {
                console.error('Error applying focus constraints:', err);
            }
        }
        setTimeout(() => {
            setFocusBox((prev) => ({ ...prev, visible: false }));
        }, 500);
    }

    // Dummy function; you might want a more advanced algorithm based on your use-case
    function calculateFocusDistance(focusPoint) {
        const videoTrack = videoRef.current?.srcObject?.getVideoTracks()[0];
        const capabilities = videoTrack.getCapabilities();

        const minDistance = capabilities.focusDistance.min;
        const maxDistance = capabilities.focusDistance.max;
        const step = capabilities.focusDistance.step;

        // Calculate the raw focus distance based on the click.
        let rawDistance = minDistance + focusPoint.y * (maxDistance - minDistance);

        // Normalize the distance to the number of steps.
        let steps = rawDistance / step;

        // Round to the nearest step and then multiply by the step size to get the final distance.
        const calculatedDistance = Math.round(steps) * step;

        // Ensure the calculated distance is within the min and max range.
        const finalDistance = Math.max(minDistance, Math.min(calculatedDistance, maxDistance));

        return finalDistance;
    }

    return (
        <div className='border shadow-md rounded-md bg-gray-50 w-full' style={{ minHeight: isCollapsed ? '0' : '550px' }}>
            <div className='flex justify-between items-center p-2'>
                <h1 className='text-center text-2xl'>Photo Receiving</h1>
                <button
                    onClick={() => {
                        setIsCollapsed((prev) => !prev);
                        if (isCollapsed) {
                            startCamera();
                        }
                    }}
                >
                    {isCollapsed ? (
                        <BarsArrowDownIcon className='h-6 w-6' aria-hidden='true' />
                    ) : (
                        <BarsArrowUpIcon className='h-6 w-6' aria-hidden='true' />
                    )}
                </button>
            </div>
            {!isCollapsed && (
                <>
                    {videoStream ? (
                        <>
                            {isMobile ? (
                                <MobileCameraButton uploadPhoto={handleMobilePhotoUpload} />
                            ) : (
                                <div className='relative mx-auto' style={{ width: '550px', height: '300px' }}>
                                    <video
                                        className='mx-auto'
                                        ref={videoRef}
                                        width='550'
                                        height='300'
                                        onClick={handleClickToFocus}
                                    ></video>
                                    {focusBox.visible && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: focusBox.y - 25, // half of the box size
                                                left: focusBox.x - 25,
                                                width: '75px',
                                                height: '75px',
                                                border: '2px solid yellow',
                                                boxSizing: 'border-box',
                                                opacity: 0.5,
                                            }}
                                        ></div>
                                    )}

                                    <canvas
                                        ref={canvasRef}
                                        style={{ display: 'none' }}
                                        width={getCameraWidth(videoStream)}
                                        height={getCameraHeight(videoStream)}
                                    ></canvas>
                                    <div className='flex justify-center my-4'>
                                        <div className='flex items-center'>
                                            {cameras.length > 1 ? (
                                                <div className='relative group'>
                                                    <button
                                                        className='bg-green-200 border flex items-center justify-center border-green-600 rounded-l-sm text-green-600 hover:bg-green-600 hover:text-white px-3 py-2'
                                                        style={{ height: '45px' }}
                                                        onClick={switchCamera}
                                                    >
                                                        <ArrowPathIcon className='h-5 w-5' aria-hidden='true' />
                                                    </button>
                                                    <div className='w-24 absolute left-0 transform -translate-x-1/2 top-full mt-1 bg-gray-800 text-white text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                                        Switch Camera
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='relative group'>
                                                    <button
                                                        className='bg-gray-300 border flex items-center justify-center border-gray-600 rounded-l-sm text-white px-3 py-2'
                                                        style={{ height: '45px' }}
                                                        onClick={switchCamera}
                                                    >
                                                        <ArrowPathIcon className='h-5 w-5' aria-hidden='true' />
                                                    </button>
                                                    <div className='w-28 absolute left-0 transform -translate-x-1/2 top-full mt-1 bg-gray-800 text-white text-xs rounded-md py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                                        Cannot switch cameras
                                                    </div>
                                                </div>
                                            )}
                                            <button
                                                className='bg-green-200 border flex items-center justify-center border-green-600 rounded-r-sm text-green-600 hover:bg-green-600 hover:text-white px-4 py-2'
                                                style={{ height: '45px' }}
                                                onClick={handleTakePhoto}
                                            >
                                                Take Photo <CameraIcon className='h-7 w-7 ml-2' aria-hidden='true' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className='mb-24'>
                            <LoadingSpinner />
                        </div>
                    )}
                    {formData?.photos?.length > 0 && (
                        <div className='photos grid grid-cols-1 gap-1 mt-56'>
                            {formData?.photos?.map((photo, index) => (
                                <div key={index} className='relative group border flex flex-col md:flex-row'>
                                    {photo.uploaded ? (
                                        <div className='absolute left-2 top-2'>
                                            <CheckCircleIcon className='text-white bg-green-600 rounded-full h-12 w-12' />
                                        </div>
                                    ) : null}
                                    <img
                                        src={photo.dataUri}
                                        alt={`Captured ${index}`}
                                        width='250'
                                        height='100'
                                        onClick={() => handleEnlargePhoto(photo.dataUri)}
                                        className='cursor-pointer hover:opacity-75'
                                    />

                                    <RadioGroup
                                        radioOptions={radioOptions}
                                        onChange={(selectedOption) => handleRadioChange(index, selectedOption)}
                                        name={`photo-${index}-type`}
                                    />

                                    <button
                                        className='absolute top-0 right-0 bg-white bg-opacity-50 rounded-full p-1 group-hover:bg-opacity-100'
                                        onClick={() => handleRemovePhoto(index)}
                                    >
                                        <XMarkIcon
                                            className='h-5 w-5 text-red-500 hover:text-red-800 outline-none ring-none'
                                            aria-hidden='true'
                                        />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            {enlargedPhoto && (
                <div
                    className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'
                    onClick={() => setEnlargedPhoto(null)} // Close when clicking outside the photo
                >
                    <img
                        src={enlargedPhoto}
                        alt='Enlarged'
                        className='max-w-screen max-h-screen'
                        onClick={(e) => e.stopPropagation()} // Prevent the event from propagating when the image is clicked
                    />
                    <button
                        onClick={() => setEnlargedPhoto(null)}
                        className='absolute top-4 right-4 p-2 bg-white rounded-full font-semibold text-xl'
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
}

function RadioGroup({ radioOptions, onChange, name }) {
    const handleRadioSelection = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className='py-1 px-3'>
            <label className='text-base font-semibold text-gray-900'>Where is this going?</label>
            <p className='text-sm text-gray-500 -mt-3'>Select the place you would like this image to be associated with</p>
            <fieldset className='mt-4'>
                <legend className='sr-only'>Where is this going</legend>
                <div className=' flex flex-col items-start '>
                    {radioOptions.map((option) => (
                        <div key={option.id} className='flex items-center'>
                            <input
                                id={`${name}-${option.id}`}
                                name={name}
                                type='radio'
                                value={option.id}
                                defaultChecked={option.id === 'Photo'}
                                onChange={handleRadioSelection}
                                className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                            />
                            <label
                                htmlFor={`${name}-${option.id}`}
                                className='ml-3 block text-sm font-medium leading-6 text-gray-900 mt-2'
                            >
                                {option.title}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    );
}
