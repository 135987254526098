import React, { useState } from 'react';
import NoDataAlert from '../components/NoDataAlert';
import { DocumentTextIcon, PrinterIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../components/Tooltip';
import TableSortButton from './order-history/TableSortButton';

export default function OrdersTable({
    data,
    gettingNextPage,
    handleDocumentClick,
    setLoadingDocument,
    openIframeModal,
    handleFilterSubmit,
    arrowDirections,
    setArrowDirections,
}) {
    const [hoveredOrderIcon, setHoveredOrderIcon] = useState(null);
    const [hoveredInvoiceIcon, setHoveredInvoiceIcon] = useState(null);
    const [hoveredDocumentIcon, setHoveredDocumentIcon] = useState(null);
    const [hoveredStatus, setHoveredStatus] = useState(null);

    if (!gettingNextPage && (data.length === 0 || data.records.length === 0)) {
        return (
            <div className='flex flex-col'>
                <NoDataAlert title={'Orders'} />
            </div>
        );
    }

    return (
        <div className='px-4 sm:px-6 lg:px-8 -mt-4'>
            <div className='mx-auto mt-6 transition-all overflow-x-auto'>
                <table className='w-full table-auto'>
                    <thead>
                        <tr className='bg-blue-primary text-white '>
                            <th scope='col' className='px-3 py-2 w-48 font-semibold '>
                                <div className='flex flex-row justify-between'>
                                    <div>Purchase Order</div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'customerPoNumber'}
                                    />
                                </div>
                            </th>
                            {data.hasParentCompany && (
                                <th scope='col' className='px-3 py-2 w-36 font-semibold '>
                                    <div className='flex flex-row justify-between'>
                                        <div>Company</div>
                                        <TableSortButton
                                            fetchdata={handleFilterSubmit}
                                            arrowDirections={arrowDirections}
                                            setArrowDirections={setArrowDirections}
                                            name={'accountName'}
                                        />
                                    </div>
                                </th>
                            )}
                            <th scope='col' className='px-3 py-3 w-36 font-semibold'>
                                <div className='flex flex-row justify-between'>
                                    <div>Buyer</div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'placedBy'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-3 w-32 font-semibold'>
                                <div className='flex flex-row justify-between'>
                                    <div>Date Ordered</div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'createdDate'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-2 w-36 font-semibold'>
                                Status
                            </th>
                            <th scope='col' className='px-3 py-2 w-36 font-semibold'>
                                <div className='flex flex-row justify-between'>
                                    <div>Sales Number</div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'salesOrderNumber'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-3 w-36 font-semibold'>
                                <div className='flex flex-row justify-between'>
                                    <div>Invoice</div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'invoiceNumber'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-2 w-36 font-semibold'>
                                Total
                            </th>
                            <th scope='col' className='px-3 py-2 w-36 font-semibold'>
                                Expected Ship Date
                            </th>
                            <th scope='col' className='px-3 py-2 w-24 font-semibold'>
                                Carrier
                            </th>
                            <th scope='col' className='px-3 py-2 w-36 font-semibold'>
                                Airway Bill
                            </th>
                        </tr>
                    </thead>
                    {!gettingNextPage ? (
                        <tbody className='bg-white'>
                            {data.records.map((orderLine, personIdx) => (
                                <tr
                                    key={orderLine.salesOrderNumber}
                                    className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}
                                >
                                    <td className='border px-3 py-2 font-semibold text-center'>
                                        {orderLine.customerPoNumber}
                                    </td>
                                    {data.hasParentCompany && (
                                        <td className='border px-3 py-2 text-center'>
                                            {capitalizeWords(orderLine.accountName)}
                                        </td>
                                    )}
                                    <td className='border px-3 py-2 font-semibold text-center'>{orderLine.placedBy}</td>
                                    <td className='border px-2 py-2 text-center'>
                                        {orderLine.createdDate ? orderLine.createdDate : null}
                                    </td>
                                    <td className='border px-4 py-2 relative text-center'>
                                        <QuestionMarkCircleIcon
                                            className='h-4 w-4 absolute top-1 right-1 text-gray-600 hover:text-gray-400 cursor-pointer ml-2'
                                            onMouseEnter={() => {
                                                setHoveredStatus(orderLine.salesOrderId);
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredStatus(null);
                                            }}
                                        />
                                        {hoveredStatus === orderLine.salesOrderId && (
                                            <div
                                                className='absolute flex bg-white shadow-lg rounded-lg p-2 -top-9 right-4 !w-fit'
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {orderLine.statusStep}
                                            </div>
                                        )}
                                        <div className='relative'>
                                            <div>{orderLine.status ? capitalizeWords(orderLine.status) : null}</div>
                                            {orderLine.status === 'Shipped' ? orderLine.dateShipped : null}
                                        </div>
                                    </td>
                                    <td className='border px-1 py-2 font-semibold'>
                                        <div className='flex justify-center items-center'>
                                            <div className='text-center'>{orderLine.salesOrderNumber}</div>
                                            {orderLine.showLink && (
                                                <div
                                                    className='mx-1'
                                                    onMouseEnter={() => {
                                                        setHoveredOrderIcon(orderLine.salesOrderId);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoveredOrderIcon(null);
                                                    }}
                                                    onClick={() => {
                                                        setLoadingDocument(orderLine.salesOrderId);
                                                        handleDocumentClick(
                                                            orderLine.salesOrderId,
                                                            orderLine.salesOrderNumber,
                                                            'order'
                                                        );
                                                    }}
                                                >
                                                    <PrinterIcon className='h-6 w-6 inline-block text-gray-600 hover:text-gray-400 cursor-pointer' />
                                                    {hoveredOrderIcon === orderLine.salesOrderId && (
                                                        <Tooltip text='View Order Information' />
                                                    )}
                                                </div>
                                            )}
                                            <div
                                                className='mx-1'
                                                onMouseEnter={() => {
                                                    setHoveredDocumentIcon(orderLine.releaseId);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredDocumentIcon(null);
                                                }}
                                                onClick={() => {
                                                    openIframeModal(orderLine.traceURL);
                                                }}
                                            >
                                                {orderLine.traceURL && (
                                                    <DocumentTextIcon className='h-6 w-6 inline-block text-gray-600 hover:text-gray-400 cursor-pointer' />
                                                )}
                                                {hoveredDocumentIcon === orderLine.releaseId && orderLine.traceURL && (
                                                    <Tooltip text='Trace' />
                                                )}
                                            </div>
                                        </div>
                                    </td>

                                    <td className='border px-2 py-2 font-semibold'>
                                        <div className='flex justify-center items-center pr-4'>
                                            <div className='text-center'>
                                                {orderLine.invoiceDocumentId ? orderLine.invoiceNumber : null}
                                            </div>
                                            {orderLine.invoiceDocumentId && (
                                                <div
                                                    className='mx-1'
                                                    onMouseEnter={() => {
                                                        setHoveredInvoiceIcon(orderLine.invoiceId);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoveredInvoiceIcon(null);
                                                    }}
                                                    onClick={() => {
                                                        setLoadingDocument(orderLine.invoiceId);
                                                        handleDocumentClick(
                                                            orderLine.invoiceId,
                                                            orderLine.invoiceNumber,
                                                            'invoice',
                                                            orderLine.releaseId ? orderLine.releaseId : null
                                                        );
                                                    }}
                                                >
                                                    {orderLine.invoiceDocumentId && (
                                                        <PrinterIcon className='h-6 w-6 inline-block text-gray-600 hover:text-gray-400 cursor-pointer' />
                                                    )}
                                                    {hoveredInvoiceIcon === orderLine.invoiceId && (
                                                        <Tooltip text='View Invoice' />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className='border px-2 py-2  '>
                                        <div className='flex justify-end'>{orderLine.lineTotal}</div>
                                    </td>
                                    <td className='border px-2 py-2 text-center'>
                                        {orderLine.expectedShipDate ? orderLine.expectedShipDate : null}
                                    </td>
                                    <td className='border px-4 py-2 text-center'>{orderLine.shipCarrier}</td>
                                    <td className='border px-4 py-2 text-center'>
                                        {orderLine.awbURL ? (
                                            <a
                                                href={orderLine.awbURL}
                                                target='_blank'
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                {orderLine.airwayBillNumber}
                                            </a>
                                        ) : (
                                            <span>{orderLine.airwayBillNumber}</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : null}
                </table>
                {gettingNextPage && (
                    <div>
                        <div className='flex justify-center border w-full py-4'>
                            <svg
                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-blue-primary'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z'
                                ></path>
                                <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                ></circle>
                            </svg>
                            <span className='text-blue-primary'>Loading...</span>
                        </div>
                        <div>
                            <div className='h-80 mb-14'></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export function capitalizeWords(input) {
    return input
        .split(' ') // Split the input string into an array of words
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + // Capitalize the first letter of each word
                word.slice(1).toLowerCase() // Make the rest of the word lowercase
        )
        .join(' '); // Join the array of words back into a single string with a space between each word
}
