import React from 'react';
import PackingCamera from './PackingCamera';

export default function CameraUploadAndPhotoArray({releaseData, updateEnlargedPhoto, enlargedPhoto, photos, photoUpload, photoNotification}) {
    if (!releaseData) return null;

    return (
        <div className='flex-1 items-center justify-center px-8 pt-0 pb-8 border-l overflow-y-auto mt-1'>
            <div className='sticky top-0 z-10 flex flex-row justify-between w-full bg-white py-2'>
                <h1 className='text-xl'>{releaseData.releaseNumber && releaseData.releaseNumber.toUpperCase()}</h1>
                {releaseData?.releaseHold && (
                    <div className='font-semibold text-3xl text-white bg-red-600 px-3 py-2 rounded-md'>On Hold</div>
                )}
            </div>
            <PackingCamera photoUpload={photoUpload} />
            <div
                className={
                    'flex items-center justify-center min-h-[24px] w-full mt-2 ' +
                    (photoNotification?.error && ' text-red-600')
                }
            >
                <p>{photoNotification?.message}</p>
            </div>
            {photos?.length > 0 ? (
                <div className='photos grid grid-cols-2 gap-4 mt-5'>
                    {photos.map((photo, index) => (
                        <div key={index} className='relative'>
                            <img
                                src={photo.dataUri}
                                alt={`Captured ${index}`}
                                onClick={() => updateEnlargedPhoto(photo.dataUri)}
                                className='cursor-pointer hover:opacity-75'
                            />
                        </div>
                    ))}
                </div>
            ) : null}
            {enlargedPhoto ? (
                <div
                    className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'
                    onClick={() => updateEnlargedPhoto(null)} // Close when clicking outside the photo
                >
                    <img
                        src={enlargedPhoto}
                        alt='Enlarged'
                        className='max-w-screen max-h-screen'
                        onClick={(e) => e.stopPropagation()} // Prevent the event from propagating when the image is clicked
                    />
                    <button
                        onClick={() => updateEnlargedPhoto(null)}
                        className='absolute top-4 right-4 px-3 py-2 bg-white rounded-full font-semibold text-xl'
                    >
                        Close
                    </button>
                </div>
            ) : null}
        </div>
    );
}
