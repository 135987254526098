import React from 'react';
import { asaAndBoeingCommentDefaults, asaDocumentStatusDefaults, asaLeftSideNewCommentDefaults } from './utils/data';
import { OverridesDropdown, OverridesDropdownTextareaPair, OverridesInput, OverridesToggleInput } from '../FormInputs';

export default function ASADocumentForm({ dynamicallyParseFormData, documentDefaults, formName, showForm, conditionCodes }) {
    return (
        <form
            onChange={(e) => {
                dynamicallyParseFormData(e, formName);
            }}
            className={
                'w-full h-full flex flex-col justify-between gap-4 px-8 duration-75 ' +
                (showForm ? 'opacity-100' : 'opacity-0 pointer-events-none hidden')
            }
        >
            <section className='w-full flex flex-row gap-4'>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesInput
                        text={'3. Reference#:'}
                        name={'referenceNumber'}
                        defaultInput={documentDefaults?.asa?.referenceNumber}
                    />
                    <OverridesDropdown
                        text={'4. Status:'}
                        selectOptions={asaDocumentStatusDefaults}
                        name={'sellerStatus'}
                        defaultOption={'-- None --'}
                        defaultSelectedValue={documentDefaults?.asa?.sellerStatus}
                    />
                    <OverridesInput
                        text={"5A. Seller's Contract #:"}
                        name={'salesOrder'}
                        defaultInput={documentDefaults?.asa?.salesOrder}
                    />
                    <OverridesInput
                        text={'5B Buyers PO #:'}
                        name={'customerPoNumber'}
                        defaultInput={documentDefaults?.asa?.customerPoNumber}
                    />
                    <OverridesInput text={'6. Item:'} name={'lineNumber'} defaultInput={documentDefaults?.asa?.lineNumber} />
                    <OverridesInput
                        text={'7. Description:'}
                        name={'partDescription'}
                        defaultInput={documentDefaults?.asa?.partDescription}
                    />
                    <OverridesInput
                        text={'8. Manufacturer and Part Number'}
                        name={'partNumber'}
                        defaultInput={documentDefaults?.asa?.partNumber}
                    />
                    <OverridesInput text={'9. App Code'} name={'appCode'} defaultInput={documentDefaults?.asa?.appCode} />
                    <OverridesInput
                        text={'10. Qty'}
                        name={'partQuantity'}
                        defaultInput={documentDefaults?.asa?.partQuantity}
                    />
                    <OverridesInput
                        text={'11. Serial/Batch #'}
                        name={'serialNumber'}
                        defaultInput={documentDefaults?.asa?.serialNumber}
                    />
                    <OverridesDropdownTextareaPair
                        dropdownText={'Select a Comment'}
                        defaultOption={`Current Comment - ${documentDefaults?.asa?.leftSideNewComment}`}
                        selectOptions={asaLeftSideNewCommentDefaults}
                        textareaText={'Edit Comment'}
                        name={'leftSideNewComment'}
                        textareaDefaultInput={documentDefaults?.asa?.leftSideNewComment}
                        defaultOptionValue={documentDefaults?.asa?.leftSideNewComment}
                        canEdit={false}
                    />
                </section>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesDropdown
                        text={'12. Status'}
                        selectOptions={conditionCodes}
                        name={'conditionCodeDescription'}
                        defaultOption={documentDefaults?.asa?.conditionCodeDescription}
                        defaultSelectedValue={documentDefaults?.asa?.conditionCodeDescription}
                    />
                    <OverridesDropdownTextareaPair
                        dropdownText={'Select a Remark'}
                        defaultOption={`Current Remark - ${documentDefaults?.asa?.remarks}`}
                        selectOptions={asaAndBoeingCommentDefaults}
                        textareaText={'Edit Remark'}
                        name={'remarks'}
                        textareaDefaultInput={documentDefaults?.asa?.remarks}
                        defaultOptionValue={documentDefaults?.asa?.remarks}
                        canEdit={true}
                    />
                    <OverridesToggleInput
                        text={'13A. Tag Ref:'}
                        defaultToggle={true}
                        name={'tagRef'}
                        defaultInput={documentDefaults?.asa?.tagRef}
                    />
                    <OverridesInput
                        text={'13B. Obtained From:'}
                        name={'obtainedFrom'}
                        defaultInput={documentDefaults?.asa?.obtainedFrom}
                    />
                    <OverridesInput
                        text={'13C. Last Certificated Agency:'}
                        name={'lastCertifiedAgency'}
                        defaultInput={documentDefaults?.asa?.lastCertifiedAgency}
                    />
                    <OverridesInput text={'Name:'} name={'userName'} defaultInput={documentDefaults?.asa?.userName} />
                    <OverridesInput text={'Date:'} name={'date'} defaultInput={documentDefaults?.asa?.date} />
                </section>
            </section>
        </form>
    );
}
