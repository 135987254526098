import React from 'react';

export default function Tooltip({ text }) {
    return (
        <div className='absolute bg-white shadow-lg rounded-lg p-2'>
            <div className='flex flex-col space-y-2'>
                <div className='flex justify-between items-center'>
                    <div className='text-sm font-semibold'>{text}</div>
                </div>
            </div>
        </div>
    );
}
