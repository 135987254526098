import config from '../../utils/config.js';

const ORIGIN = config.origin;

export const postLocationData = async (location, currentUser) => {
    try {
        const response = await fetch(`${ORIGIN}/wms/location-utilization/location-check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.userId}${currentUser.userEmail}`,
            },
            body: JSON.stringify({
                location: location,
                authentication: {
                    ...currentUser,
                },
            }),
        });

        if (!response.ok) {
            return {
                error: 'Bad response',
            };
        }

        const data = await response.json();

        return data;
    } catch (err) {
        return {
            error: err,
        };
    }
};

export const postUtilizationRank = async (location, utilizationRank, currentUser) => {
    try {
        const response = await fetch(`${ORIGIN}/wms/location-utilization/save-utilization-rank`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.userId}${currentUser.userEmail}`,
            },
            body: JSON.stringify({
                location: location,
                utilizationRank: utilizationRank,
                authentication: {
                    ...currentUser,
                },
            }),
        });

        if (!response.ok) {
            return {
                error: 'Bad response',
            };
        }

        const data = await response.json();

        return data;
    } catch (err) {
        return {
            error: err,
        };
    }
};

export const submitToken = async (currentUser, tokenValue) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        token: tokenValue,
    };

    const response = await fetch(`${ORIGIN}/wms/auth-token/update-token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (!response?.ok) {
        throw Error('Error submitting token');
    }

    return;
};

export const getAuthToken = async (currentUser) => {
    const dataToSend = {
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
    };

    const response = await fetch(`${ORIGIN}/wms/auth-token/token-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
    });

    if (response.ok) {
        // { timeSinceUpdate, token }
        return response.json();
    } else {
        throw Error('No token found');
    }
};
