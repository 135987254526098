import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Document8130Form from './Overrides/Document8130Form';
import ASADocumentForm from './Overrides/ASADocumentForm';
import BoeingDocumentForm from './Overrides/BoeingDocumentForm';
import { defaultDarValue, documentKeys, documentNames, documentReferences, overrideDefaults } from './Overrides/utils/data';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PackingSlipForm from './Overrides/PackingSlipForm';
import { DocumentContext } from '../Context/DocumentContextWrapper';
import { TraceContext } from '../Context/TraceContextWrapper';
import { printGeneratedDocuments, submitUpdatedHeaderData } from '../utils/fetchRequests';
import { useAuth } from '../../../context/wms-context';
import { addOverridesToFormData } from '../utils/functions';

export default function GenerateDocumentsModal({
    selectedInventoryName,
    documentParams,
    persistedDocumentParams,
    releaseOrderData,
    releaseId,
}) {
    const { currentUser } = useAuth();
    const {
        documentDefaults,
        updateDocumentDefaults,
        hasPrintedPackingSheet,
        setHasPrintedPackingSheet,
        setShowDocumentGenerationModal,
        errorWithinForm,
        packingSheetLastPrinted,
        setPackingSheetLastPrinted,
    } = useContext(DocumentContext);
    const { updateNotificationState } = useContext(TraceContext);

    const [selectedDocuments, setSelectedDocuments] = useState(handlePreCheckedDocuments());
    const [formOverrides, setFormOverrides] = useState({ ...documentDefaults });
    const [currentPage, setCurrentPage] = useState(0);
    const [currentForm, setCurrentForm] = useState(null);
    const [loading, setLoading] = useState(false);

    const formHolderRef = useRef(null);

    function handlePreCheckedDocuments() {
        let defaultSelectedDocuments = ['defaultCheckboxPage', documentReferences.DOCUMENT_ASA];
        const isInternationalOrder = !releaseOrderData?.isDomesticOrder;

        if (releaseOrderData.darRequired) {
            defaultSelectedDocuments.push(documentKeys.DOCUMENT_8130);
        }
        if (!hasPrintedPackingSheet) {
            defaultSelectedDocuments.push(documentReferences.PACKING_SLIP_UNPRICED);
        }
        if (releaseOrderData?.isBoeingDropship) {
            defaultSelectedDocuments.push(documentReferences.DOCUMENT_BOEING);
        }
        if (isInternationalOrder && !releaseOrderData?.isBoeingDropship && !hasPrintedPackingSheet) {
            defaultSelectedDocuments.push(documentReferences.PACKING_SLIP_PRICED);
        }

        return defaultSelectedDocuments;
    }

    const combineAllFormDataWithOverrides = () => {
        const updatedFormOverrides = _.clone(formOverrides);

        // Go through each document
        for (const documentName in formOverrides) {
            const totalDocumentData = _.clone(documentParams.documentData[documentName]);
            const updatedDocument = _.clone(
                totalDocumentData.find((data) => data.inventoryName === selectedInventoryName) ?? {}
            );
            const overrideDocument = _.clone(formOverrides[documentName]);
            const persistedDocumentOverrides = _.clone(overrideDefaults[documentName]);

            const isPackSheet =
                documentName === documentKeys.PACKING_SLIP_PRICED || documentName === documentKeys.PACKING_SLIP_UNPRICED;
            if (documentName === packingSheetLastPrinted) continue;
            if (isPackSheet && packingSheetLastPrinted) {
                const allPackingData = _.clone(documentParams.documentData[packingSheetLastPrinted]);
                const persistedPackingDocument = _.clone(
                    allPackingData.find((data) => data.inventoryName === selectedInventoryName) ?? {}
                );
                // Go through each key to check for updates
                for (const override in updatedDocument) {
                    if (!(override in persistedDocumentOverrides)) continue;
                    if (persistedPackingDocument[override] !== overrideDocument[override]) {
                        overrideDocument[override] = persistedPackingDocument[override];
                    }
                }
                continue;
            }

            // Go through each key to check for updates
            for (const override in updatedDocument) {
                if (updatedDocument[override] !== overrideDocument[override]) {
                    overrideDocument[override] = updatedDocument[override];
                }
            }

            updatedFormOverrides[documentName] = overrideDocument;
        }

        setFormOverrides(updatedFormOverrides);
    };

    const dynamicallyParseFormData = (event, formName) => {
        const inputType = event.target.type;
        const keyName = event.target.name;
        const value = inputType === 'checkbox' ? event.target.checked : event.target.value;

        const overrides = { ...formOverrides };

        const isDarUpdate = keyName === 'darDisplayName' && formName === documentKeys.DOCUMENT_8130;

        if (isDarUpdate) {
            const darOptions = overrides.form8130.DAR;
            const selectedOption = darOptions?.find((dar) => dar.displayName === value) ?? defaultDarValue;
            const { authorizationName, authorizationNumber, displayName } = selectedOption;

            overrides.form8130.DAR = overrides.form8130.DAR.map((dar) => {
                if (dar.displayName === displayName) {
                    return { ...dar, priority: true };
                }
                return { ...dar, priority: false };
            });
            overrides.form8130.authorizationName = authorizationName;
            overrides.form8130.authorizationNumber = authorizationNumber;
            overrides.form8130.displayName = displayName;
            return;
        }

        // Link both packing sheets together
        const isPackSheet = formName === documentKeys.PACKING_SLIP_PRICED || formName === documentKeys.PACKING_SLIP_UNPRICED;
        if (isPackSheet) {
            overrides[documentKeys.PACKING_SLIP_PRICED][keyName] = value;
            overrides[documentKeys.PACKING_SLIP_UNPRICED][keyName] = value;
            setFormOverrides(() => {
                return overrides;
            });
            return;
        }

        if (formName === 'form8130' && (keyName === 'showLeft' || keyName === 'showRight')) {
            if (keyName === 'showLeft' && overrides.form8130.showRight === true) {
                overrides.form8130.showLeft = true;
                overrides.form8130.showRight = false;
            } else if (keyName === 'showRight' && overrides.form8130.showLeft === true) {
                overrides.form8130.showLeft = false;
                overrides.form8130.showRight = true;
            }
            setFormOverrides(() => {
                return overrides;
            });
            return;
        }

        overrides[formName][keyName] = value;
        setFormOverrides(overrides);
    };

    const handleDocumentSelection = (isChecked, documentKeyValue) => {
        if (isChecked) {
            setSelectedDocuments((documents) => {
                return [...documents, documentKeyValue];
            });
        }

        if (!isChecked) {
            setSelectedDocuments((documents) => {
                return documents.filter((doc) => doc !== documentKeyValue);
            });
        }
    };

    const formatDocumentsToPrint = () => {
        const documentsToPrint = {};

        // Only add key values for forms that have been selected
        selectedDocuments.forEach((formName) => {
            if (formName in documentKeys) {
                const formKey = documentKeys[formName];
                const matchingDocuments = documentParams.documentData[formKey].filter((data) => {
                    if (formKey.includes('packingSheet')) return data; // Include all lines for packing sheets
                    if (data.inventoryName === selectedInventoryName) return data;
                });
                documentsToPrint[formKey] = matchingDocuments;
            }
        });

        return documentsToPrint;
    };

    const addFormSpecificDataToOverridesOnSubmit = () => {
        const updatedOverrides = { ...formOverrides };

        // Add username to ASA
        updatedOverrides.asa.userName = currentUser.authentication.userName;

        setFormOverrides(updatedOverrides);
    };

    // Compare previous pack sheet to the pack sheet submitted
    const getUpdatedPackingSheetData = () => {
        const updatedPackSheet = formOverrides?.packingSheetPriced || formOverrides?.packingSheetUnpriced;

        if (!updatedPackSheet) return;

        const originalPackSheet = persistedDocumentParams?.packingSheetPriced?.find(
            (data) => data.inventoryName === selectedInventoryName
        );

        if (!originalPackSheet || !updatedPackSheet) return;

        const updatedPackSheetData = {
            awb: updatedPackSheet?.awb?.toString(),
            externalComments: updatedPackSheet?.externalComments?.toString(),
            shippedMethod: updatedPackSheet?.shippedMethod?.toString(),
            totalPieces: updatedPackSheet?.totalPieces?.toString(),
            totalWeight: updatedPackSheet?.totalWeight?.toString(),
        };
        const oldPackSheetData = {
            awb: originalPackSheet?.awb?.toString(),
            externalComments: originalPackSheet?.externalComments?.toString(),
            shippedMethod: originalPackSheet?.shippedMethod?.toString(),
            totalPieces: originalPackSheet?.totalPieces?.toString(),
            totalWeight: originalPackSheet?.totalWeight?.toString(),
        };

        const isPackingSheetTheSame = JSON.stringify(oldPackSheetData) === JSON.stringify(updatedPackSheetData);
        if (!isPackingSheetTheSame) return { ...updatedPackSheetData, releaseId: releaseId };
    };

    const updatePersistedPackSheetInfo = () => {
        if (
            selectedDocuments.includes(documentReferences.PACKING_SLIP_PRICED) ||
            selectedDocuments.includes(documentReferences.PACKING_SLIP_UNPRICED)
        ) {
            setHasPrintedPackingSheet(true);
        }

        if (selectedDocuments.includes(documentReferences.PACKING_SLIP_PRICED)) {
            setPackingSheetLastPrinted(documentKeys.PACKING_SLIP_PRICED);
        }
        if (selectedDocuments.includes(documentReferences.PACKING_SLIP_UNPRICED)) {
            setPackingSheetLastPrinted(documentKeys.PACKING_SLIP_UNPRICED);
        }
    };

    const submitFormOverrides = async () => {
        try {
            setLoading(true);

            // Send over updated packing sheet data if there are differences
            const updatedPackingSheetData = getUpdatedPackingSheetData();
            if (updatedPackingSheetData) {
                await submitUpdatedHeaderData(currentUser, updatedPackingSheetData);
            }

            //addFormSpecificDataToOverridesOnSubmit();
            const formattedDocuments = formatDocumentsToPrint();
            const updatedDocumentData = addOverridesToFormData(formOverrides, formattedDocuments, documentDefaults);
            await printGeneratedDocuments(updatedDocumentData, currentUser);
            updatePersistedPackSheetInfo();
            updateDocumentDefaults(updatedDocumentData, selectedInventoryName);
            handleCloseModal();
        } catch (error) {
            updateNotificationState({
                error: true,
                shown: true,
                title: 'Error Loading Documents and Photos',
                message:
                    'There was an error generating PDFs. Please try again and if this issue persists contact your manager.',
            });
        }
        setLoading(false);
    };

    const updatePageCount = (count) => {
        const updatedCount = currentPage + count;
        setCurrentPage(updatedCount);
        setCurrentForm(selectedDocuments[updatedCount]);
        formHolderRef.current.scrollTo(0, 0);
    };

    const handleCloseModal = () => {
        resetValues();
        setShowDocumentGenerationModal(false);
    };

    const resetValues = () => {
        setCurrentForm(null);
        setCurrentPage(0);
    };

    useEffect(() => {
        combineAllFormDataWithOverrides();
    }, []);

    return (
        <>
            <section className='fixed w-screen h-screen top-0 left-0 bg-gray-600 opacity-30 z-10' />
            <section className='fixed top-0 left-0 z-20 flex items-center justify-center w-screen h-screen'>
                <section
                    ref={formHolderRef}
                    className='fixed flex flex-col justify-between bg-white rounded-lg w-[70vw] h-[70vh] max-w-[1450px] max-h-[850px] z-20 duration-100'
                >
                    {loading ? (
                        <section className='w-[100%] h-[100%] flex items-center justify-center'>
                            <div className='w-36 h-36 border-b-4 border-gray-500 rounded-full animate-spin out'></div>
                        </section>
                    ) : null}
                    {!loading ? (
                        <>
                            <header className='top-0 flex flex-row justify-center text-xl bg-white pt-7 pb-7'>
                                <h1>{currentPage === 0 ? 'Generate Documents' : documentNames[currentForm]}</h1>
                                <div className='absolute right-8 flex flex-row gap-8'>
                                    {currentPage === 0 ? '' : `Document ${currentPage}/${selectedDocuments.length - 1}`}
                                    <button
                                        onClick={handleCloseModal}
                                        className='flex rounded-md items-center justify-center bg-gray-200 hover:bg-gray-300 leading-none'
                                    >
                                        <XMarkIcon width={30} height={30} />
                                    </button>
                                </div>
                            </header>
                            <section className='h-full overflow-y-auto'>
                                <SelectDocuments
                                    selectedDocuments={selectedDocuments}
                                    handleDocumentSelection={handleDocumentSelection}
                                    currentPage={currentPage}
                                />
                                <Document8130Form
                                    dynamicallyParseFormData={dynamicallyParseFormData}
                                    formName={documentKeys.DOCUMENT_8130}
                                    showForm={currentForm === documentReferences.DOCUMENT_8130}
                                    documentDefaults={formOverrides}
                                />
                                <ASADocumentForm
                                    dynamicallyParseFormData={dynamicallyParseFormData}
                                    formName={documentKeys.DOCUMENT_ASA}
                                    showForm={currentForm === documentReferences.DOCUMENT_ASA}
                                    documentDefaults={formOverrides}
                                    conditionCodes={documentParams.conditionCodes}
                                />
                                <BoeingDocumentForm
                                    dynamicallyParseFormData={dynamicallyParseFormData}
                                    formName={documentKeys.DOCUMENT_BOEING}
                                    showForm={currentForm === documentReferences.DOCUMENT_BOEING}
                                    documentDefaults={formOverrides}
                                    conditionCodes={documentParams.conditionCodes}
                                />
                                <PackingSlipForm
                                    dynamicallyParseFormData={dynamicallyParseFormData}
                                    formName={documentKeys.PACKING_SLIP_PRICED}
                                    showForm={currentForm === documentReferences.PACKING_SLIP_PRICED}
                                    documentDefaults={
                                        currentForm === documentReferences.PACKING_SLIP_PRICED ? formOverrides : {}
                                    }
                                    shippingValues={releaseOrderData.shippedVia}
                                />
                                <PackingSlipForm
                                    dynamicallyParseFormData={dynamicallyParseFormData}
                                    formName={documentKeys.PACKING_SLIP_UNPRICED}
                                    showForm={currentForm === documentReferences.PACKING_SLIP_UNPRICED}
                                    documentDefaults={
                                        currentForm === documentReferences.PACKING_SLIP_UNPRICED ? formOverrides : {}
                                    }
                                    shippingValues={releaseOrderData.shippedVia}
                                />
                            </section>

                            <footer className='w-full justify-center bottom-0 flex flex-row gap-4 bg-white pb-7 pt-4'>
                                {currentPage > 0 ? (
                                    <button
                                        onClick={() => updatePageCount(-1)}
                                        className='flex items-center justify-center h-fit cursor-pointer min-w-[170px] rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                                    >
                                        Back
                                    </button>
                                ) : null}
                                {currentPage < selectedDocuments.length - 1 ? (
                                    <button
                                        disabled={errorWithinForm}
                                        onClick={() => updatePageCount(1)}
                                        className='flex items-center justify-center h-fit cursor-pointer min-w-[170px] rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                                    >
                                        Next
                                    </button>
                                ) : null}
                                {currentPage === selectedDocuments.length - 1 && selectedDocuments.length > 1 ? (
                                    <button
                                        disabled={errorWithinForm}
                                        onClick={() => submitFormOverrides()}
                                        className='flex items-center justify-center h-fit cursor-pointer min-w-[170px] rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                                    >
                                        Submit
                                    </button>
                                ) : null}
                            </footer>
                        </>
                    ) : null}
                </section>
            </section>
        </>
    );
}

function SelectDocuments({ handleDocumentSelection, currentPage, selectedDocuments }) {
    return (
        <section className={currentPage === 0 ? 'w-1/2 h-full flex items-start px-8' : 'hidden'}>
            <article className='grid grid-rows-2 gap-4 pt-2'>
                {Object.keys(documentNames).map((documentKeyValue, index) => {
                    return (
                        <div key={index} className='col-span-1'>
                            <label className='inline-flex items-center'>
                                <input
                                    type='checkbox'
                                    defaultChecked={selectedDocuments.includes(documentReferences[documentKeyValue])}
                                    onChange={(e) => handleDocumentSelection(e.target.checked, documentKeyValue)}
                                    className='form-checkbox h-7 w-7 text-blue-600'
                                />
                                <span className='ml-2 text-gray-700 text-[20px]'>{documentNames[documentKeyValue]}</span>
                            </label>
                        </div>
                    );
                })}
            </article>
        </section>
    );
}
