import React, { useState } from 'react';

export const OrderResult = ({ sale, setSelectedObject, selectedObject, searchInput }) => {
    const [selected, setSelected] = useState(false);

    const querySaleLink = `/global-search?query=${searchInput}&modal=${sale.salesOrderNumber}&type=Order`;

    return (
        <a
            href={querySaleLink}
            className={
                'w-full py-2 no-underline text-gray-600 text-center hover:cursor-pointer hover:bg-gray-300 ' +
                (selected &&
                    selectedObject.salesOrderNumber &&
                    selectedObject.salesOrderNumber === sale.salesOrderNumber &&
                    'bg-gray-300')
            }
            onMouseEnter={() => {
                setSelectedObject(sale);
                setSelected(true);
            }}
            style={{
                all: 'revert',
            }}
        >
            {sale.salesOrderNumber}
        </a>
    );
};
