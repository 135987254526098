import React from 'react';

export default function SubmitButton({ handleSubmit, isLoading }) {
    return (
        <div className='flex justify-end '>
            {!isLoading ? (
                <button
                    disabled={isLoading}
                    onClick={handleSubmit}
                    type='submit'
                    className='cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                >
                    Save
                </button>
            ) : (
                <button
                    disabled={isLoading}
                    onClick={handleSubmit}
                    type='submit'
                    className='cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                >
                    Saving...
                </button>
            )}
        </div>
    );
}
