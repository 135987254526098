import React, { useState } from 'react';
import config from '../utils/config';
import logError from '../utils/errorUtil';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function DocumentUpload() {
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);

    let params = {
        id: queryParams.get('id'),
        name: queryParams.get('name'),
    };

    const [file, setFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);

    const [dragging, setDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);

    async function handleBtnClick() {
        setIsLoading(true);
        setResult(null);
        const files = [...file];
        const filePromises = files.map(async (file) => {
            let data = await converToToBase64(file);
            return data;
        });
        Promise.all(filePromises).then((data) => {
            const dirInfo = endUserSubmit(params, data).then((res) => {
                setResult(res);
                setIsLoading(false);
                setFile([]);
            });
        });
    }

    const handleFileChange = (e, type) => {
        let targetFiles;

        if (type === 'drop') {
            targetFiles = e.dataTransfer.files;
        } else {
            targetFiles = e.target.files;
        }

        const files = targetFiles;
        setFile((prev) => [...prev, ...files]);
    };

    function converToToBase64(file) {
        return new Promise(async (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileBase64 = e.target.result;
                resolve(fileBase64);
            };
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(file);
        });
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter((prev) => prev + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileChange(e, 'drop');
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter((prev) => prev - 1);
        if (dragCounter === 0) {
            setDragging(false);
        }
    };

    return (
        <div
            className={classNames(dragging ? 'bg-gray-100' : 'bg-white', 'flex flex-col justify-center h-screen')}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragLeave}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <h1 className='text-2xl font-bold text-center text-gray-700 mb-4'>Upload a file</h1>

            <div
                onDragOver={handleDrag}
                onChange={(e) => {
                    handleFileChange(e);
                }}
                onClick={() => {
                    document.getElementById('file-upload').click();
                }}
                className={classNames(
                    dragging ? 'border-blue-primary' : '',
                    'mt-1 justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 w-1/3 mx-auto h-96 flex items-center hover:border-gray-400 focus-within:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 cursor-pointer'
                )}
            >
                <div className='space-y-1 text-center'>
                    <svg
                        className='mx-auto h-16 w-16 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                    >
                        <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                    <div className='flex text-lg text-gray-600'>
                        <label
                            htmlFor='file-upload'
                            className='relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500'
                        >
                            <input id='file-upload' name='file-upload' type='file' className='sr-only' multiple />
                        </label>
                        <p className='pl-1'>Click to Upload a file</p>
                    </div>
                    <p className='text-md text-gray-500'>up to 10MB</p>
                </div>
            </div>
            {file.map((file, index) => {
                return (
                    <div key={index} className='flex items-center justify-between w-1/3 mx-auto mt-4'>
                        <p>{file.name}</p>
                        <button
                            type='button'
                            className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() => {
                                setFile((prev) => {
                                    return prev.filter((item) => item.name !== file.name);
                                });
                            }}
                        >
                            Remove
                        </button>
                    </div>
                );
            })}

            <div className='flex justify-center mt-4'>
                {isLoading ? (
                    <button
                        type='button'
                        className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        <svg
                            className='-ml-1 mr-2 h-5 w-5 animate-spin'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8v8H4z'></path>
                        </svg>
                        Loading
                    </button>
                ) : (
                    <button
                        className='bg-blue-primary hover:bg-blue-secondary text-white font-bold py-2 px-4 rounded-lg w-1/6'
                        onClick={handleBtnClick}
                    >
                        Submit
                    </button>
                )}
            </div>
            {result && (
                <div className='flex justify-center mt-4'>
                    <div
                        className='bg-gray-100 border border-gray-400 text-gray-700 font-semibold text-lg px-4 py-3 rounded relative w-1/6'
                        role='alert'
                    >
                        <span className='block sm:inline'>{result.message}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

async function endUserSubmit(params, files) {
    const ORIGIN = config.origin;

    let dataToSend = {
        id: params.id,
        name: params.name,
        files: files,
    };

    return new Promise((resolve, reject) => {
        try {
            fetch(`${ORIGIN}/sales/end-user-submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
                mode: 'cors',
            })
                .then(function (result) {
                    return result.json();
                })
                .then(function (response) {
                    resolve(response);
                    return;
                })
                .catch((e) => {
                    logError(e, 'endUserSubmit');
                    reject(e);
                });
        } catch (e) {
            reject(e);
        }
    });
}

export default DocumentUpload;
