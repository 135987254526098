import React, { useState, useRef } from 'react';
import LoadingButton from '../../components/LoadingButton';
import SimpleLogo from '../../assets/images/fullLogo.png';
import { validEmailRegex } from '../../utils/validators';
import config from '../../utils/config';
import { Link } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function SignUpPage() {
    const ORIGIN = config.origin;

    const emailRef = useRef();
    const [emailError, setEmailError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [Result, setResult] = useState(null);
    const [managerEmail, setManagerEmail] = useState('');

    const handleEmailFocusOut = () => {
        const validEamil = validEmailRegex(emailRef.current.value);
        if (!validEamil) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const validEmail = validEmailRegex(emailRef.current.value);
        if (!validEmail) {
            setEmailError(true);
            setIsLoading(false);
        } else {
            setEmailError(false);
            signup(emailRef.current.value);
        }
    };

    const signup = () => {
        fetch(`${ORIGIN}/cp/sign-up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress: emailRef.current.value,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                setResult(data.found);
                setManagerEmail(data.emailAddress);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className='flex items-center justify-center h-screen'>
                <div className='p-12 bg-gray-100 rounded-lg w-full md:w-1/2 xl:w-1/3  flex justify-center min-w-min'>
                    <div className=''>
                        <div className='w-full max-w-md space-y-8 pb-10 mx-auto'>
                            <div>
                                <img className='mx-auto h-36 w-auto -mt-8' src={SimpleLogo} alt='Your Company' />
                                <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>Sign Up</h2>
                                <p className='mt-2 text-center text-sm text-gray-600'>
                                    Enter your email below to signup for an account
                                </p>
                            </div>
                            <form className='mt-8 space-y-6'>
                                <input type='hidden' name='remember' defaultValue='true' />
                                <div className='-space-y-px rounded-md '>
                                    <div className='shadow-sm'>
                                        <label htmlFor='email-address' className='sr-only'>
                                            Email address
                                        </label>
                                        <input
                                            ref={emailRef}
                                            onBlur={handleEmailFocusOut}
                                            id='email-address'
                                            name='email'
                                            type='email'
                                            autoComplete='email'
                                            required
                                            className={classNames(
                                                emailError ? 'border-2 border-red-400' : 'border border-gray-300',
                                                'block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-md'
                                            )}
                                            placeholder='Email address'
                                        />
                                    </div>
                                    {emailError && (
                                        <p className='text-red-500 text-xs italic pl-1 -my-20'>Please use a valid email</p>
                                    )}
                                </div>

                                <div>
                                    {!isLoading ? (
                                        <button
                                            onClick={handleSubmit}
                                            type='submit'
                                            disabled={Result}
                                            className='cursor-pointer group relative flex w-full justify-center rounded-md border border-transparent bg-blue-primary py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                        >
                                            <span className='absolute inset-y-0 left-0 flex items-center pl-3'></span>
                                            Sign Up
                                        </button>
                                    ) : (
                                        <LoadingButton />
                                    )}
                                    <p className='text-sm text-center underline py-2 text-blue-primary font-semibold'>
                                        <Link to='/login' className='hover:text-gray-500'>
                                            Back to Login Screen
                                        </Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                        {/* {Result && (
              <div className="text-center text-sm text-gray-600">
                <p className="text-gray-600">{Result}</p>
              </div>
            )} */}
                        {Result === 'user' && (
                            <div className='text-center text-md text-gray-900 border border-blue-primary bg-white shadow-lg py-4 px-4 w-4/5 mx-auto'>
                                <h1 className='text-center text-blue-primary font-semibold text-xl underline'>
                                    You already have an account.{' '}
                                </h1>
                                <p className='text-gray-900'>
                                    If you remember your login please visit the{' '}
                                    <a href='/login' className='text-blue-secondary font-bold hover:underline'>
                                        login page
                                    </a>
                                    . If you have forgotten your password please visit the{' '}
                                    <a href='/forgot-password' className='text-blue-secondary font-bold hover:underline'>
                                        forgot password page{' '}
                                    </a>
                                    to reset your password
                                </p>
                            </div>
                        )}
                        {Result === 'contact' && (
                            <div className='text-center text-lg text-gray-900 border border-blue-primary bg-white shadow-lg py-4 px-8 w-4/5 mx-auto'>
                                <h1 className='text-center text-blue-secondary font-semibold text-xl underline'>
                                    Account access pending
                                </h1>
                                <p className='text-gray-900'>
                                    Your account rep has been notified that you would like access to the Pac air customer
                                    portal. You will receive a temporary password once the account is available.
                                </p>
                            </div>
                        )}
                        {Result === 'none' && (
                            <div className='text-center text-lg text-gray-900 border border-blue-primary bg-white shadow-lg py-4 px-8 w-4/5 mx-auto'>
                                <p className='text-gray-900'>
                                    Thank you for your interest in our portal. With the credentials provided we were unable
                                    to locate you in our system. Please reach out to{' '}
                                    <a className='underline' href={`mailto:${managerEmail}`}>
                                        {managerEmail}
                                    </a>{' '}
                                    for assistance obtaining login credentials.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
