import React from 'react';
import { useAuth } from '../../portal/context/auth-context';

export default function ShippingReviewInfo() {
    const { currentUser } = useAuth();

    let info = currentUser.selectedShipAddress;
    let shipMethod = currentUser.selectedShipMethod;

    return (
        <div className='md:w-5/6 pb-8 border-2 border-gray-400 noDrag'>
            <div className='flex flex-row items-center justify-around bg-blue-primary h-12'>
                <h2 className='text-xl text-white font-semibold'>Shipping Method</h2>
                <h2 className='text-xl text-white font-semibold'>Shipping Information</h2>
            </div>
            <div className='flex flex-row items-start justify-around space-x-8 mx-4'>
                <div className='flex flex-col items-start px-4 w-1/2'>
                    <div className='mt-4 text-sm ml-1'>
                        <p>{info.shipAddressName}</p>
                        <p> {info.street} </p>
                        <p>
                            {info.city}, {info.state} {info.zip}
                        </p>
                        <p>
                            {info.postalCode}, {info.country}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-start px-4 w-1/2'>
                    <div className='mt-4 text-sm ml-1'>
                        <p>{shipMethod.shipMethodName}</p>
                        <p> {shipMethod.shipMethodDescription} </p>
                        <p>{shipMethod.shipCarrier}</p>
                        <p>{shipMethod.accountNumber}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
