import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { defaultStatus, searchStateEnum } from '../data';
import { globalSpecificSearch } from '../../../../utils/commonAPICalls';
import { useAuth } from '../../../../portal/context/auth-context';
import LoadingIndicatorWithText from '../LoadingIndicatorWithText';
import { ResultTemplates } from './ResultTemplates';
import { getFromIndexedDB } from '../../../../utils/IndexedDB';
import { checkSearchState } from '../utils';

export default function ResultModal({ updateDocumentData }) {
    const { currentUser } = useAuth();
    const { LOADING, RESULTS, NORESULTS } = searchStateEnum;
    const modalRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [status, setStatus] = useState(defaultStatus);
    const [resultData, setResultData] = useState();

    const closeModal = () => {
        setResultData(null);
        searchParams.delete('modal');
        searchParams.delete('type');
        setSearchParams(searchParams);
    };

    // Check indexedDB for data, if there is none then fetch data from the backend
    const dataFetchHandler = async (type, search, dataToSend) => {
        try {
            const cacheFetch = await getFromIndexedDB(null, type, search);

            if (cacheFetch && !cacheFetch.error) {
                return {
                    data: [cacheFetch],
                    objectName: type,
                };
            }

            setStatus({ ...status, searchState: LOADING });

            const backendFetch = await globalSpecificSearch(currentUser, dataToSend);
            return backendFetch;
        } catch (error) {
            return {
                error: `Failure to fetch data`,
            };
        }
    };

    // Get modal data and handle errors
    const fetchSpecificData = async (type, search) => {
        const dataToSend = {
            accountId: currentUser.Account.Id,
            userId: currentUser.Id,
            searchTerm: search,
            tableName: type,
        };

        try {
            setModalOpen(true);
            const fetchData = await dataFetchHandler(type, search, dataToSend);

            if (!fetchData || fetchData?.data.length === 0 || fetchData?.error) {
                closeModal();
                setResultData(null);
                setStatus({ ...status, searchState: NORESULTS });
                return;
            }

            setResultData(fetchData);
            setStatus({ ...status, searchState: RESULTS });
        } catch (err) {
            console.error(err);
        }
    };

    // Close modal on click off
    const handleClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target) && !searchParams.get('action')) {
            closeModal();
        }
    };

    useEffect(() => {
        if (searchParams.get('modal') && searchParams.get('type')) {
            let type = searchParams.get('type');
            let search = searchParams.get('modal');
            fetchSpecificData(type, search);
        } else {
            setModalOpen(false);
        }
    }, [searchParams]);

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [searchParams]);

    return (
        <section
            className={
                'items-center justify-center w-screen h-screen absolute top-0 left-0 bg-black/40 z-40 p-2 ' +
                (modalOpen ? ' flex opacity-100 duration-150 ' : ' opacity-0 pointer-events-none ')
            }
        >
            <section
                ref={modalRef}
                className='w-full h-screen max-h-screen lg:w-[700px] lg:h-[600px] bg-white p-5 rounded-md overflow-y-auto'
            >
                {checkSearchState(LOADING, status) && (
                    <LoadingIndicatorWithText
                        staticText={'Loading'}
                        className={'w-full h-full flex flex-col items-center justify-center'}
                    />
                )}
                {checkSearchState(RESULTS, status) && (
                    <ResultTemplates object={resultData} closeModal={closeModal} updateDocumentData={updateDocumentData} />
                )}
            </section>
        </section>
    );
}
