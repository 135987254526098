import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import config from '../../../utils/config';
export default function ApprovalSection({ releaseId, record }) {
    const [isLoading, setIsLoading] = useState(true);
    const [key, setKey] = useState();
    const [response, setResponse] = useState();
    const ORIGIN = config.origin;

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        setKey(query.get('key'));
        if (!key) return;
        const fetchData = async () => {
            const response = await fetch(
                `${ORIGIN}/sales/csl-compliance-update?key=${key}&releaseId=${releaseId}&action=approve&record=${record}`
            );
            const data = await response.json();
            console.log(data);
            setResponse(data);
            setIsLoading(false);
        };
        fetchData();
    }, [key]);

    return (
        <div className='grid h-screen place-items-center text-3xl text-blue-primary font-bold pb-24'>
            {isLoading ? <LoadingSpinner /> : <div>{response.message}</div>}
        </div>
    );
}
