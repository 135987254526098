import { ArrowUpOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function PurchaseOrder({
    setError,
    error,
    setPurchaseOrderNumber,
    setPurchaseOrderNotes,
    file,
    setFile,
    dragging,
    handleFileChange,
    fileTypeError,
    setFileTypeError,
}) {
    const handleFileRemove = (e) => {
        const files = [...file];
        files.splice(e.target.id, 1);
        setFile(files);
    };

    let error2 = false;

    // create a drag and drop area

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFileTypeError(null);
    };

    return (
        <div className='md:w-5/6 px-4 py-5 md:border-2 border-t border-gray-400 sm:p-6'>
            <div className='mt-4'>
                <h3 className='text-xl leading-6 font-medium text-gray-900'>Upload Purchase Order</h3>
                <div className='mt-2'>
                    <p className='text-sm text-gray-500'>Please select the file you wish to upload.</p>
                </div>
            </div>
            <div className='mt-6  '>
                <button
                    onChange={handleFileChange}
                    onDragOver={handleDrag}
                    onClick={() => {
                        document.getElementById('file-upload').click();
                        setFileTypeError(null);
                    }}
                    type='button'
                    className={classNames(
                        dragging ? 'border-blue-primary bg-white' : '',
                        'border-gray-300 relative block w-full rounded-lg border-2 border-dashed  p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2',
                        fileTypeError ? 'border-red-600' : ''
                    )}
                >
                    <ArrowUpOnSquareIcon className='mx-auto h-10 w-10 text-gray-400' />
                    <span className='sr-only'>upload a PDF or Word Document </span>
                    <span className='mt-2 block text-sm font-medium text-gray-500'>Upload a PDF or Word Document </span>
                    {file && !error && (
                        <span className='mt-2 block text-md font-medium text-gray-500 -mb-2 underline'>
                            {file.length} file(s) selected
                        </span>
                    )}
                </button>
            </div>
            {fileTypeError && (
                <p className='mt-2 text-sm text-red-600 pl-2' id='email-error'>
                    {fileTypeError}
                </p>
            )}
            <input
                id='file-upload'
                type='file'
                multiple
                className='hidden'
                accept='.pdf, .docx'
                onChange={(e) => {
                    handleFileChange(e);
                }}
            />
            {file.map((file) => (
                <div className=' flex justify-between items-center space-y-4'>
                    <p key={file.name} className='mt-2 block text-sm font-medium text-gray-500 -mb-2'>
                        {file.name}
                    </p>
                    <TrashIcon id={file.name} onClick={handleFileRemove} className='h-5 w-5 text-gray-500 cursor-pointer' />
                </div>
            ))}
            <div>
                <label
                    htmlFor='poNumber'
                    className={classNames(
                        error ? 'text-red-600' : 'text-gray-700',
                        'block text-sm font-medium text-gray-700 p-1 -mb-0.5 mt-4'
                    )}
                >
                    Purchase Order Number <span className='text-gray-400'>(required)</span>
                </label>
                <input
                    onChange={(e) => {
                        setPurchaseOrderNumber(e.target.value);
                        setError(false);
                    }}
                    type='text'
                    name='poNumber'
                    id='poNumber'
                    disabled={dragging}
                    autoComplete='poNumber'
                    className={classNames(
                        error
                            ? 'placeholder-red-600 border-red-600 focus:ring-red-600'
                            : 'placeholder-gray-700 border-gray-400',
                        'block w-full shadow-sm sm:text-sm rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200',
                        dragging > 0 ? 'bg-gray-200' : ''
                    )}
                    placeholder='Enter Text'
                />
                {error && (
                    <p className='mt-2 text-sm text-red-600 pl-2' id='email-error'>
                        {error}
                    </p>
                )}

                <label
                    htmlFor='poNotes'
                    className={classNames(
                        error2 ? 'text-red-600' : 'text-gray-700',
                        'block text-sm font-medium text-gray-700 p-1 -mb-0.5 mt-4'
                    )}
                >
                    Purchase Order Notes
                </label>
                <textarea
                    onChange={(e) => {
                        setPurchaseOrderNotes(e.target.value);
                        setError(false);
                    }}
                    type='text'
                    name='poNotes'
                    disabled={dragging}
                    rows={2}
                    id='poNotes'
                    autoComplete='poNotes'
                    className={classNames(
                        error2
                            ? 'placeholder-red-600 border-red-600 focus:ring-red-600'
                            : 'placeholder-gray-700 border-gray-400',
                        'block w-full shadow-sm sm:text-sm  rounded-md border border-gray-400 p-3 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200'
                    )}
                    placeholder=''
                />
            </div>
        </div>
    );
}
