import { PrinterIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function InventoryPhoto({ photoData, handlePhotoEnlarge, toggleImageHeroStatus, loading }) {
    const imageUriFromBase64 = `data:image/jpeg;base64,${photoData?.base64}`;

    const printImage = (imageUriFromBase64) => {
        // Create a hidden iframe to load the content for printing
        const printFrame = document.createElement('iframe');
        printFrame.style.position = 'absolute';
        printFrame.style.width = '0';
        printFrame.style.height = '0';
        printFrame.style.border = 'none';

        document.body.appendChild(printFrame);

        // Create a document inside the iframe
        const doc = printFrame.contentWindow.document;
        doc.open();
        doc.write(`
          <html>
            <head>
              <title>Print Image</title>
              <style>
                body {
                  margin: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                }
                img {
                  max-width: 100%;
                  max-height: 100%;
                }
              </style>
            </head>
            <body>
              <img id="imageToPrint" src="${imageUriFromBase64}" alt="Image to print" />
            </body>
          </html>
      `);
        doc.close();

        // Get the image element inside the iframe
        const image = doc.getElementById('imageToPrint');

        // Wait for the image to fully load before printing
        image.onload = () => {
            // Trigger the print function once the image is fully loaded
            printFrame.contentWindow.focus();
            printFrame.contentWindow.print();

            // Remove the iframe after printing
            printFrame.onafterprint = () => {
                document.body.removeChild(printFrame);
            };
        };
    };

    return (
        <div className='relative w-fit'>
            <div className='group'>
                <img
                    className='relative hover:cursor-pointer md:h-32 md:w-32 xl:h-36 xl:w-36 2xl:h-40 2xl:w-40 opacity-80 group-hover:opacity-100 duration-100'
                    src={imageUriFromBase64}
                    onClick={() => handlePhotoEnlarge(imageUriFromBase64, false)}
                />
                {!loading ? (
                    <CheckCircleIcon
                        className={
                            'hover:cursor-pointer absolute w-8 h-8 top-1 right-1 rounded-full duration-100 ' +
                            (photoData.isHero
                                ? 'bg-green-500 text-white hover:bg-green-400'
                                : 'bg-white hover:bg-gray-200 border-2 border-gray-500 text-white hover:text-gray-200')
                        }
                        onClick={() => toggleImageHeroStatus(photoData.name)}
                    />
                ) : null}
            </div>
            <button
                className='flex flex-row items-center hover:opacity-100 justify-center rounded-b border border-gray-300 w-full py-1 hover:!bg-gray-100 hover:!contrast-100 active:!bg-gray-200 gap-2 !outline-none !shadow-none active:transform-none'
                onClick={() => printImage(imageUriFromBase64)}
            >
                Print
                <PrinterIcon className='w-5 h-5' />
            </button>
        </div>
    );
}
