import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../context/wms-context.js';
import logError from '../../utils/errorUtil.js';
import MainContainer from '../components/Trace/MainContainer.js';
import { TraceContextWrapper } from '../components/Trace/Context/TraceContextWrapper.js';
import { formatReleaseData, isValidRlsNumber } from '../components/Trace/utils/functions.js';
import { fetchReleaseData } from '../components/Trace/utils/fetchRequests.js';
import { DocumentContextWrapper } from '../components/Trace/Context/DocumentContextWrapper.js';

export default function WMS_Trace() {
    const inputRef = useRef(null);
    const { currentUser } = useAuth();
    const [releaseNumber, setReleaseNumber] = useState('');
    const [releaseData, setReleaseData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        if (releaseData) {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function (event) {
                window.history.go(1);
            };
        }
        return () => {
            window.onpopstate = null;
        };
    }, [releaseData]);

    useEffect(() => {
        if (releaseData) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [releaseData]);

    function handleBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = 'Do you really want to leave?';
    }

    const resetReleaseData = () => {
        setReleaseData(null);
    };

    const submitReleaseName = async (e) => {
        try {
            const sanitizedInputReleaseNumber = inputRef.current.value.toUpperCase().trim();

            const addRlsPrefix =
                /^\d+$/.test(sanitizedInputReleaseNumber) && sanitizedInputReleaseNumber?.length === 6
                    ? `RLS-${sanitizedInputReleaseNumber}`
                    : sanitizedInputReleaseNumber;

            if (!isValidRlsNumber(addRlsPrefix)) {
                setErrorText('Please enter a valid release number');
                return;
            }

            setReleaseNumber(addRlsPrefix);
            setLoading(true);

            const response = await fetchReleaseData(addRlsPrefix, currentUser);

            if (!response?.success) {
                setErrorText(response.message);
                setLoading(false);
                return;
            }
            const formattedReleaseData = formatReleaseData(response);
            formattedReleaseData.name = addRlsPrefix;
            setReleaseData(formattedReleaseData);
            setLoading(false);
            setErrorText(null);
        } catch (error) {
            logError(error, 'handleSubmit');
            setLoading(false);
            setReleaseData(null);
            setErrorText('Failed to get inventory data');
        }
    };

    return (
        <TraceContextWrapper>
            <DocumentContextWrapper>
                {loading && !releaseData ? (
                    <div className='flex flex-col h-[calc(100vh-64px)] items-center justify-center'>
                        <div className='h-28 w-28 border-b-[5px] border-e-[5px] mb-7 border-gray-500 rounded-full animate-spin out' />
                        <p className='text-xl'>Retreiving Data for {releaseNumber}</p>
                    </div>
                ) : null}
                {!releaseData && !loading ? (
                    <div className='flex h-[calc(100vh-64px)] items-center justify-center'>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submitReleaseName();
                            }}
                            className='flex flex-col items-center relative'
                        >
                            <label htmlFor='releaseNumber' className='mb-2 text-lg'>
                                Release Number
                            </label>
                            <input
                                ref={inputRef}
                                id='releaseNumber'
                                type='text'
                                className='p-2 border border-gray-300 rounded'
                                autoFocus
                            />
                            <button type='submit' className='mt-4 px-4 py-2 bg-blue-primary text-white rounded'>
                                Submit
                            </button>
                            {errorText ? (
                                <h1 className='absolute -bottom-7 text-red-500 whitespace-nowrap'>{errorText}</h1>
                            ) : null}
                        </form>
                    </div>
                ) : null}
                {releaseData ? (
                    <MainContainer
                        invLines={releaseData.inventoryLineInfo}
                        releaseId={releaseData.Id}
                        releaseLink={releaseData.linkToRelease}
                        releaseName={releaseData.name}
                        resetReleaseData={resetReleaseData}
                        releaseComments={releaseData.releaseComments}
                        releaseOrderData={releaseData.releaseOrderData}
                        documentParams={releaseData.documentParams}
                    />
                ) : null}
            </DocumentContextWrapper>
        </TraceContextWrapper>
    );
}
