import { QuoteTemplate } from './QuoteTemplate';
import { OrderTemplate } from './OrderTemplate';
import { SearchModalTemplateWrapper } from './SearchModalTemplateWrapper';

export const ResultTemplates = ({ object, closeModal, updateDocumentData }) => {
    const { data, objectName } = object ?? [];

    return (
        <>
            {objectName === 'Quote' && (
                <SearchModalTemplateWrapper headerText={'Quote'} closeModal={closeModal}>
                    <QuoteTemplate quote={data[0]} updateDocumentData={updateDocumentData} />
                </SearchModalTemplateWrapper>
            )}
            {objectName === 'Order' && (
                <SearchModalTemplateWrapper headerText={'Sale'} closeModal={closeModal}>
                    <OrderTemplate sale={data[0]} updateDocumentData={updateDocumentData} />
                </SearchModalTemplateWrapper>
            )}
        </>
    );
};
