import React, { useRef, useState } from 'react';
import PasswordInput from './passwordFormInputs/PasswordInput';
import NewPasswordInput from './passwordFormInputs/NewPasswordInput';
import config from '../../../utils/config';
import { useAuth } from '../../context/auth-context';
import logError from '../../../utils/errorUtil';

export default function Password() {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();

    const oldPasswordRef = useRef();
    const newPassword1Ref = useRef();
    const newPassword2Ref = useRef();

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setIsLoading(true);
        const oldPassword = oldPasswordRef.current.value;
        const newPassword1 = newPassword1Ref.current.value;
        const newPassword2 = newPassword2Ref.current.value;

        if (!oldPassword || !newPassword1 || !newPassword2) {
            setError('All fields are required');
            return setIsLoading(false);
        }

        if (newPassword1 !== newPassword2) {
            setError('Passwords do not match');
            return setIsLoading(false);
        }

        if (newPassword1.length < 5) {
            setError('Password must be at least 5 characters');
            return setIsLoading(false);
        }

        if (oldPassword === newPassword1) {
            setError('New password must be different from old password');
            return setIsLoading(false);
        }

        if (oldPassword.length < 5) {
            setError('Old password must be at least 5 characters');
            return setIsLoading(false);
        }

        await fetch(`${ORIGIN}/cp/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                userId: currentUser.Id,
                oldPassword,
                newPassword: newPassword1,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.result === 'failure') {
                    setError("Old password doesn't match");
                    setIsLoading(false);
                    return;
                }
                if (data.result !== 'success') {
                    setError('There was an error saving your data. Please try again.');
                    setIsLoading(false);
                    return;
                }

                if (data.result === 'success') {
                    setSuccess(data.result);
                    oldPasswordRef.current.value = '';
                    newPassword1Ref.current.value = '';
                    newPassword2Ref.current.value = '';
                    setIsLoading(false);
                    return;
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);

                logError(err, 'handleSubmit');
            });
        setIsLoading(false);
    };
    return (
        <div>
            <h2 className='text-2xl leading-6 font-medium text-gray-900'>Reset Password</h2>
            <div className='lg:w-1/2 border p-8 mt-6 ml-4 rounded-lg shadow-md'>
                <form>
                    <PasswordInput passwordRef={oldPasswordRef} setError={setError} />
                    <NewPasswordInput inputId={'password1'} passwordRef={newPassword1Ref} setError={setError} />
                    <NewPasswordInput inputId={'password2'} passwordRef={newPassword2Ref} setError={setError} />
                    <div className='flex justify-end'>
                        {!isLoading ? (
                            <button
                                onClick={handleSubmit}
                                type='submit'
                                className='cursor-pointer w-48 mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-primary'
                            >
                                Save
                            </button>
                        ) : (
                            <div className='mt-4 w-48'>
                                <button
                                    disabled
                                    type='button'
                                    className=' cursor-pointer text-white bg-blue-primary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-primary dark:hover:bg-blue-primary dark:focus:ring-blue-800 inline-flex items-center justify-center w-48 '
                                >
                                    <svg
                                        role='status'
                                        className='inline mr-3 w-4 h-4 text-white animate-spin'
                                        viewBox='0 0 100 101'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                            fill='#E5E7EB'
                                        />
                                        <path
                                            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                            fill='currentColor'
                                        />
                                    </svg>
                                    Loading...
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div>
                {success && (
                    <div
                        className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4 w-1/2 ml-4'
                        role='alert'
                    >
                        <strong className='font-bold'>Success!</strong>
                        <br />
                        <p className='block sm:inline'>Your password was successfully reset</p>

                        <span className='absolute top-0 bottom-0 right-0 px-4 py-3'>
                            <svg
                                className='fill-current h-6 w-6 text-green-500'
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 20 20'
                            >
                                <title>Close</title>
                            </svg>
                        </span>
                    </div>
                )}
                {error && (
                    <div
                        className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 w-1/2 ml-4 '
                        role='alert'
                    >
                        <strong className='font-bold'>Error</strong>
                        <br />
                        <p className='block sm:inline'>{error}</p>

                        <span className='absolute top-0 bottom-0 right-0 px-4 py-3'>
                            <svg
                                className='fill-current h-6 w-6 text-green-500'
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 20 20'
                            >
                                <title>Close</title>
                            </svg>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
