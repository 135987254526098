import React from 'react';
import MultiPicklist from './fields/Multipicklist.js';
import Picklist from './fields/Picklist.js';
import SelectionField from './fields/SelectionField.js';
import SearchField from './fields/SearchField.js';
import { InputField, TextAreaField, Checkbox } from './fields/AllFields.js';

export default function Index({ formData, setFormData }) {
    const handleInputChange = (index, newValue) => {
        const updatedFormData = [...formData];
        updatedFormData[index].value = newValue;
        setFormData(updatedFormData);
    };

    if (formData?.length < 1)
        return (
            <div className='text-red-500  flex justify-center items-center mx-auto'>
                There was an error please log out and log back in
            </div>
        );

    return (
        <>
            {formData &&
                formData?.length > 0 &&
                formData.map((field, index) => {
                    const commonProps = {
                        key: index,
                        label: field.label,
                        value: field.value,
                        required: field.required,
                        readOnly: field.readOnly,
                        onChange: (eOrValue) => {
                            console.log('eOrValue', eOrValue);
                            // Check if it's an event (e.g., from input) or just a value (e.g., from the checkbox)
                            const newValue = eOrValue && eOrValue.target ? eOrValue.target.value : eOrValue;
                            handleInputChange(index, newValue);
                        },
                    };

                    switch (field.type) {
                        case 'textarea':
                            return <TextAreaField {...commonProps} />;

                        case 'date':
                            return <InputField {...commonProps} type={field.type} />;

                        case 'picklist':
                            return <Picklist {...commonProps} values={field.picklistValues} />;

                        case 'multipicklist':
                            return <MultiPicklist {...commonProps} values={field.picklistValues} />;

                        case 'boolean':
                            return <Checkbox {...commonProps} />;

                        case 'reference':
                            return <SelectionField {...commonProps} options={field.dropdownData} />;

                        case 'customQuery':
                            return <SearchField {...commonProps} options={field.dropdownData} tableName={field.tableName} />;

                        case 'double':
                            return <InputField {...commonProps} type={'number'} />;
                        case 'string':
                            return <InputField {...commonProps} type={'text'} />;
                        case '':
                            return <div className='my-2 relative'></div>;
                        default:
                            return (
                                <div key={index} className='text-red-500 border flex justify-center items-center'>
                                    Unknown field type
                                </div>
                            );
                    }
                })}
        </>
    );
}
