// create a config object
let config = {
    origin: 'http://localhost:8000',
};

//TODO: add /cp to the origin to make it easier to use

if (window.location.origin !== 'http://localhost:3000') {
    config.origin = window.location.origin;
}

export default config;
