import React from 'react';

export default function DateInputs({ fromDateRef, toDateRef, thirtyDayQuotes }) {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const thirtyDaysAgoFormatted = thirtyDaysAgo.toISOString().split('T')[0];
    return (
        <div>
            <div className='flex justify-end items-center space-x-4 mr-2 mb-5'>
                <div className='flex flex-col'>
                    <label htmlFor='from' className='text-sm font-medium text-gray-700'>
                        From
                    </label>
                    <div className='-mt-1'>
                        <input
                            ref={fromDateRef}
                            type='date'
                            min={thirtyDayQuotes ? thirtyDaysAgoFormatted : ''}
                            name='from'
                            id='from'
                            className='shadow-sm focus:ring-blue-primary focus:border-blue-primary block w-full sm:text-sm border-gray-300 rounded-md border rounded-md px-4 py-2.5'
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='to' className='text-sm font-medium text-gray-700'>
                        To
                    </label>
                    <div className='-mt-1'>
                        <input
                            ref={toDateRef}
                            type='date'
                            min={thirtyDayQuotes ? thirtyDaysAgoFormatted : ''}
                            name='to'
                            id='to'
                            className='shadow-sm focus:ring-blue-primary focus:border-blue-primary block w-full sm:text-sm border-gray-300 rounded-md border rounded-md px-4 py-2.5'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
