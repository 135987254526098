import { packingPageStatusEnum } from "./data";

export const getPackingPageStatusText = (status) => {
    const {LOADING, NO_RELEASE, RELEASE_ERROR} = packingPageStatusEnum;
    switch (status) {
        case LOADING:
            return 'Retrieving Release Information';
        case NO_RELEASE:
            return 'Enter a Release Number';
        case RELEASE_ERROR:
            return 'Error Grabbing Release';
        default:
            return 'Enter a Release Number';
    }
};