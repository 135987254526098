import React, { useState, useRef, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';

function SelectionField({ label, value, onChange, options, required, readOnly }) {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [showOptions, setShowOptions] = useState(false);
    const [isSelected, setIsSelected] = useState(!!value);
    const [isInvalidInput, setIsInvalidInput] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [inputValue, setInputValue] = useState(() => {
        const option = options.find((opt) => opt.Id === value);
        return option ? option.Name : '';
    });
    const [selectedTextValue, setSelectedTextValue] = useState(null);
    const [isErrorInputState, setIsErrorInputState] = useState(false);

    const listRef = useRef();
    const containerRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowOptions(false);
        }
    };

    const handleFocusShift = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('focusin', handleFocusShift);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('focusin', handleFocusShift);
        };
    }, []);
    const handleInputChange = (e) => {
        const newInputValue = e.target.value;
        setInputValue(newInputValue);
        setSelectedTextValue(null);

        // If the input is cleared, reset everything
        if (!newInputValue.trim()) {
            setIsInvalidInput(false);
            setIsSelected(false);
            setShowOptions(false);
            onChange({ target: { value: '' } }); // Notify the parent component with an empty value
            return;
        }

        const newFilteredOptions = options.filter((option) =>
            option.Name.toLowerCase().includes(newInputValue.toLowerCase())
        );

        setFilteredOptions(newFilteredOptions);
        setShowOptions(true);
        if (listRef.current) {
            listRef.current.scrollTo(0);
        }
    };

    const handleOptionClick = (option) => {
        setInputValue(option.Name);
        onChange({ target: { value: option.Id } });
        setIsSelected(true);
        setSelectedTextValue(option.Name);

        setIsInvalidInput(false); // Reset the invalid flag when an option is selected
        setShowOptions(false);
    };
    useEffect(() => {
        const option = options.find((opt) => opt.Id === value);
        const updatedValue = option ? option.Name : '';

        // Only update inputValue if it doesn't match the updatedValue.
        // This prevents overwriting user's input while they're typing.
        if (inputValue !== updatedValue) {
            setInputValue(updatedValue);
        }

        setIsSelected(!!value); // Update selection status based on the new value
    }, [value, options]);

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                if (highlightedIndex < filteredOptions.length - 1) {
                    setHighlightedIndex((prev) => prev + 1);
                    listRef.current.scrollToItem(highlightedIndex + 1, 'center');
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (highlightedIndex > 0) {
                    setHighlightedIndex((prev) => prev - 1);
                    listRef.current.scrollToItem(highlightedIndex - 1, 'center');
                }
                break;
            case 'Enter':
                if (highlightedIndex !== -1) {
                    handleOptionClick(filteredOptions[highlightedIndex]);
                }
                break;
            default:
                break;
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            // If input is blank, reset and exit
            if (!inputValue.trim() && required) {
                setIsInvalidInput(true);
                setShowOptions(false);
                return;
            }
        }, 150);
    };

    // Conditional styling
    let inputClassName = 'mt-1 p-2 w-full border rounded-md';
    if (isSelected) inputClassName += ' ring-2 ring-blue-200';
    if (isErrorInputState) inputClassName += ' border-red-500 ring-2 ring-red-200';

    const Row = ({ index, style }) => (
        <div
            style={style}
            className={`cursor-pointer text-left p-2 ${index === highlightedIndex ? 'bg-gray-300' : ''} hover:bg-gray-200`}
            onMouseDown={(e) => {
                e.preventDefault(); // Prevent input from losing focus
                handleOptionClick(filteredOptions[index]);
            }}
        >
            {filteredOptions[index].Name}
        </div>
    );

    useEffect(() => {
        if (!readOnly && (isInvalidInput || (!showOptions && selectedTextValue !== inputValue && inputValue))) {
            setIsErrorInputState(true);
        } else {
            setIsErrorInputState(false);
        }
    }, [isInvalidInput, showOptions, selectedTextValue, inputValue]);

    useEffect(() => {
        setSelectedTextValue(inputValue);
    }, []);

    return (
        <div className='my-2 relative px-8' ref={containerRef}>
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            <input
                type='text'
                value={inputValue}
                onChange={handleInputChange}
                disabled={readOnly}
                onKeyDown={handleKeyDown} // Add this line to capture key down events
                required={required}
                className={inputClassName}
                onBlur={handleBlur}
                onFocus={() => {
                    setShowOptions(true);
                    setHighlightedIndex(-1); // Reset highlighted index on focus
                }}
            />
            {required && <div className='absolute top-0 right-0 text-red-500 text-xs'>required *</div>}
            {showOptions && filteredOptions.length > 0 && (
                <div className='absolute w-full mt-2 border border-gray-300 rounded-md shadow-lg z-10 bg-white max-h-96'>
                    <List
                        ref={listRef}
                        height={Math.min(300, filteredOptions.length * 40)}
                        itemCount={filteredOptions.length}
                        itemSize={40} // The height of a single option
                        width='100%'
                    >
                        {Row}
                    </List>
                </div>
            )}
            {isErrorInputState && inputValue?.length && !showOptions ? (
                <p className='text-red-600 text-sm'>
                    Please select an item from the dropdown or remove the text from this field
                </p>
            ) : null}
        </div>
    );
}

export default SelectionField;
