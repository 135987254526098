import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../portal/context/auth-context';
import fullLogo from '../../assets/images/simpleLogo.jpg';
import { Link } from 'react-router-dom';
import SearchBar from './global-search/search-bar/SearchBar';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function NavBar() {
    const { logout, currentUser } = useAuth();
    if (currentUser.firstLogin === true) {
        return null;
    }
    return (
        <Disclosure as='nav' className='bg-white shadow'>
            {({ open }) => (
                <>
                    {/* <div className="mx-auto  px-4 sm:px-6 lg:px-8 xl:ml- lg:ml-24 md:ml-12"> */}
                    <div className='mx-auto  px-4 sm:px-6 lg:px-8'>
                        <div className='flex h-16 justify-between'>
                            <div className='flex'>
                                <div className='flex flex-shrink-0 items-center w-16'>
                                    <Link
                                        to='/'
                                        onClick={() => {
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 100);
                                        }}
                                    >
                                        <img className='block h-8 w-auto lg:hidden' src={fullLogo} alt='Your Company' />
                                        <img className='hidden h-12 w-auto lg:block' src={fullLogo} alt='Your Company' />
                                    </Link>
                                </div>
                                <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                                    <Link
                                        to='/'
                                        onClick={() => {
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 100);
                                        }}
                                        className={classNames(
                                            window.location.pathname === '/' ? 'border-blue-primary' : 'border-transparent',
                                            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900'
                                        )}
                                    >
                                        Parts Search
                                    </Link>
                                    <Link
                                        to='/quotes'
                                        onClick={() => {
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 100);
                                        }}
                                        className={classNames(
                                            window.location.pathname === '/quotes'
                                                ? 'border-blue-primary'
                                                : 'border-transparent',
                                            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900'
                                        )}
                                    >
                                        Parts Search History
                                    </Link>
                                    <Link
                                        to='/orders'
                                        onClick={() => {
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 100);
                                        }}
                                        className={classNames(
                                            window.location.pathname === '/orders'
                                                ? 'border-blue-primary'
                                                : 'border-transparent',
                                            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900'
                                        )}
                                    >
                                        My Orders
                                    </Link>
                                </div>
                            </div>
                            <SearchBar />
                            <div className='hidden sm:ml-6 sm:flex sm:items-center'>
                                <div>
                                    <p className='text-sm font-medium text-gray-700 pl-6'>Welcome, {currentUser.Name}</p>
                                </div>

                                <Menu as='div' className='relative ml-3'>
                                    <div>
                                        <Menu.Button className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 cursor-pointer pointer'>
                                            <span className='sr-only'>Open user menu</span>
                                            <div
                                                type='button'
                                                className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                            >
                                                <span className='sr-only'>View notifications</span>
                                                <Cog8ToothIcon className='h-6 w-6' aria-hidden='true' />
                                            </div>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-200'
                                        enterFrom='transform opacity-0 scale-95'
                                        enterTo='transform opacity-100 scale-100'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 scale-100'
                                        leaveTo='transform opacity-0 scale-95'
                                    >
                                        <Menu.Items className='absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to='/settings?tab=Account'
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        My Account
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        onClick={logout}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                        )}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <div className='px-3 text-sm text-center'>
                                    <p>Contact Us: {currentUser.Owner.Name}</p>
                                    <a className='underline text-blue-secondary' href={`mailto:${currentUser.Owner.Email}`}>
                                        {currentUser.Owner.Email}
                                    </a>
                                </div>
                            </div>
                            <div className='-mr-2 flex items-center sm:hidden'>
                                {/* Mobile menu button */}
                                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-primary'>
                                    <span className='sr-only'>Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                                    ) : (
                                        <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className='sm:hidden'>
                        <div className='space-y-1 pt-2 pb-3'>
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <Link to='/'>
                                <Disclosure.Button
                                    as='a'
                                    className={classNames(
                                        window.location.pathname === '/'
                                            ? 'border-blue-secondary bg-blue-50 text-indigo-700'
                                            : 'border-transparent text-gray-500',
                                        'block border-l-4  py-2 pl-3 pr-4 text-base font-medium '
                                    )}
                                >
                                    Parts Search
                                </Disclosure.Button>
                            </Link>
                            <Link to='/quotes'>
                                <Disclosure.Button
                                    as='a'
                                    className={classNames(
                                        window.location.pathname === '/quotes'
                                            ? 'border-blue-secondary bg-blue-50 text-indigo-700'
                                            : 'border-transparent text-gray-500',
                                        'block border-l-4  py-2 pl-3 pr-4 text-base font-medium '
                                    )}
                                >
                                    Parts Search History
                                </Disclosure.Button>
                            </Link>
                            <Link to='/orders'>
                                <Disclosure.Button
                                    as='a'
                                    className={classNames(
                                        window.location.pathname === '/orders'
                                            ? 'border-blue-secondary bg-blue-50 text-indigo-700'
                                            : 'border-transparent text-gray-500',
                                        'block border-l-4  py-2 pl-3 pr-4 text-base font-medium '
                                    )}
                                >
                                    My Orders
                                </Disclosure.Button>
                            </Link>
                            {/* <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Calendar
              </Disclosure.Button> */}
                        </div>
                        <div className='border-t border-gray-200 pt-4 pb-3'>
                            {/* <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    Tom Cook
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    tom@example.com
                  </div>
                </div>
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <Cog8ToothIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div> */}
                            <div className='mt-3 space-y-1'>
                                <Link to='/settings?tab=Account'>
                                    <Disclosure.Button
                                        to='a'
                                        className='block px-4 py-2 w-full text-base text-left font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'
                                    >
                                        My Account
                                    </Disclosure.Button>
                                </Link>
                                <Disclosure.Button
                                    as='a'
                                    href='#'
                                    onClick={logout}
                                    className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'
                                >
                                    Sign out
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
