import React, { useState, useEffect } from 'react';
import config from '../../utils/config';
import { useAuth } from '../../portal/context/auth-context';
import LoadingSpinner from '../../components/LoadingSpinner';
import { formatDate } from '../../utils/formatDate';

export default function RFQDetailsPage() {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handlePrintButton = () => {
        // add a class to the button
        document.getElementById('print').classList.add('hidden');
        // print the page
        window.print();
        // remove the class from the button
        document.getElementById('print').classList.remove('hidden');
    };

    const fetchdata = (id) => {
        fetch(`${ORIGIN}/cp/hx/quotes `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                userId: currentUser.Id,
                quoteId: id,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        // get the url
        const url = window.location.href;
        // get the id from the url
        const id = url.substring(url.lastIndexOf('/') + 1);
        // get the data from the database
        fetchdata(id);
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className='px-4 sm:px-6 lg:px-8 mt-12 mb-24'>
            <div className='sm:flex sm:items-center'>
                <div className='sm:flex-auto'>
                    <h1 className='text-xl font-semibold text-gray-900'>{data.quote[0].Name}</h1>
                    <p className='mt-2 text-sm text-gray-700'>
                        For parts requested on{' '}
                        <time dateTime='2022-08-01'>{formatDate(data.quote[0].inscor__Create_Date__c)}</time>
                    </p>
                </div>
                <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
                    <button
                        onClick={handlePrintButton}
                        type='button'
                        id='print'
                        className='inline-flex items-center justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
                    >
                        Print
                    </button>
                </div>
            </div>
            <div className='-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0 '>
                <table className='min-w-full divide-y divide-gray-300'>
                    <thead>
                        <tr className='bg-blue-primary text-white '>
                            <th scope='col' className='px-3 py-3 font-normal'>
                                Part Number
                            </th>
                            <th scope='col' className=' py-3.5 px-3 text-right text-sm font-semibold  sm:table-cell'>
                                Price per unit
                            </th>
                            <th scope='col' className=' py-3.5 px-3 text-right text-sm font-semibold sm:table-cell'>
                                Quantity
                            </th>
                            <th scope='col' className='py-3.5 pl-3 pr-4 text-right text-sm font-semibold sm:pr-6 md:pr-2'>
                                Total Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.quote[0].lines.map((line) => (
                            <tr key={line.id} className='border-b border-gray-200'>
                                <td className='py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-4'>
                                    <div className='font-medium text-gray-900'>{line.productName}</div>
                                </td>
                                <td className=' py-4 px-3 text-right text-sm text-gray-500 sm:table-cell'>
                                    {line.inscor__Price__c !== '0.00' ? '$' + line.inscor__Price__c : '-'}
                                </td>
                                <td className=' py-4 px-3 text-right text-sm text-gray-500 sm:table-cell'>
                                    {line.inscor__Quantity__c}
                                </td>
                                <td className='py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-4'>
                                    {line.inscor__Price__c !== '0.00'
                                        ? '$' +
                                          //remove the comma from the price and multiply it by the quantity to get the total price for the line item and round it to the nearest whole number ad show 2 decimal places
                                          (
                                              parseFloat(line.inscor__Price__c.replace(/,/g, '')) * line.inscor__Quantity__c
                                          ).toFixed(2)
                                        : '-'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th
                                scope='row'
                                colSpan={3}
                                className='hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-4'
                            >
                                Total
                            </th>
                            <th
                                scope='row'
                                className='pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden'
                            >
                                Total
                            </th>
                            <td className='pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-4'>
                                {data.quoteSum}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}
