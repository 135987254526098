export const loadingSection = {
    ONE_CLICK_BINDER: 'ONE CLICK BINDER',
    PRINT_SELECTED: 'PRINT SELECTED',
};

export const pdfTypes = {
    OWNER_CODE: 'Owner Code',
    PART_RECORD: 'Part Record',
    TRACE_TAG: 'Trace Tag',
    ONE_CLICK_BINDER: '1 Click Binder',
};

export const defaultNotificationState = {
    error: false,
    shown: false,
    message: '',
    title: '',
};

export const chatterModalView = {
    HIDDEN: 'Hidden',
    INITIAL_POPUP: 'Initial Popup',
    USER_INPUT: 'User Input',
    WARNING: 'Warning',
};

export const defaultChatterModalState = {
    view: chatterModalView.HIDDEN,
    messageData: [],
};

export const pdfLoadingState = {
    LOADING: 'Loading',
    LOADED: 'Loaded',
    LOADING_FAILED: 'Loading Failed',
    NO_DATA: 'No Data',
};

export const lineLoadingState = {
    NOT_LOADED: 'Not Loaded',
    LOADING: 'Loading',
    LOADED: 'Loaded',
    FAILED: 'Failed',
};

export const stepStatus = {
    IDLE: 'Idle',
    LOADING: 'Loading',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
};

export const uploadStep = {
    DEFAULT: 'Default',
    SUBMITTING: 'Submitting',
    UPDATING_RELEASE: 'Updating Release Status',
    UPDATING_HERO_IMAGES: 'Updating Hero Images',
    PERSISTING_HERO_IMAGES: 'Persisting Hero Images',
    ADDING_NEW_PHOTOS: 'Adding New Photos',
    ADDING_CHATTER_MESSAGES: 'Adding Chatter Messages',
    COMPLETED: 'Completed',
    RETRY: 'Retry',
};

export const pdfLinkNameKey = {
    [pdfTypes.OWNER_CODE]: 'traceOwnerCodeLink',
    [pdfTypes.PART_RECORD]: 'tracePartRecordLink',
    [pdfTypes.TRACE_TAG]: 'traceTagLink',
    [pdfTypes.ONE_CLICK_BINDER]: 'traceBinderOneClickLink',
};