// import logo from './logo.svg';
import '../App.css';
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import logError from '../utils/errorUtil';

function StripePayment() {
    const [release, setRelease] = useState({});
    const [state, setState] = useState({
        invoice: ``,
        po: ``,
        email: ``,
    });

    // To Do: change hooks.  Release would become an object paymentInfo, and state would become a string with values of error, invoice or release.

    useEffect(() => {
        async function getRelease() {
            const release = await getReleaseId();
            setRelease(release);
            loadPage(release);
        }
        getRelease();
    }, []);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    async function handleClick() {
        const invoice = await getInvoiceInfo(state);
        setRelease(invoice);

        if (invoice.error) {
            setState({
                invoice: `error`,
            });
        } else {
            invoice.buyerEmail = state.email;
            loadPage(invoice);
        }
    }

    function ccFeeDisclaimer() {
        return (
            <div>
                <br />
                <small>
                    <font color='grey'>The rate above is the invoice amount with an additional 3.5% fee.</font>
                </small>
            </div>
        );
    }

    return (
        <div className='sr-root'>
            {state.invoice === `error` && <div>{release.error}</div>}
            {release === `invoice` && state.invoice !== `error` && (
                <div>
                    <form>
                        <label>
                            Invoice Number{' '}
                            <small>
                                <font color='grey'>Do not include the "INVC-"</font>
                            </small>
                            :{` `}
                        </label>
                        <input type='text' name='invoice' value={state.invoice} onChange={handleChange} />
                        <br />
                        <label>PO Number:{` `}</label>
                        <input type='text' name='po' value={state.po} onChange={handleChange} />
                        <br />
                        <label>Email Receipt To:{` `}</label>
                        <input type='text' name='email' value={state.email} onChange={handleChange} />
                    </form>
                    <br />
                    <Button variant='primary' onClick={handleClick}>
                        Proceed to Payment Page
                    </Button>
                    {` `}
                </div>
            )}
            {release !== `invoice` && state.invoice !== `error` && (
                <div className='sr-main'>
                    <div className='sr-payment-summary payment-view'>
                        <h1 className='order-amount text-2xl'>
                            Amount: ${Number(release.releaseValue).toFixed(2)}
                            {release.Name && release.Name.includes(`INVC`) && ccFeeDisclaimer}
                        </h1>
                        <h3 className='text-lg'>Customer: {release.customerName}</h3>
                        <br />
                        <h4 className='text-md'>Purchase Order: {release.customerPONumber}</h4>
                        <h4 className='text-md'>
                            Reference Number: <span id='referenceNo'>{release.name}</span>
                        </h4>
                    </div>
                    <div className='sr-payment-htmlForm payment-view'>
                        <div className='sr-htmlForm-row'>
                            <label htmlFor='card-element'>Payment details</label>
                            <div className='sr-combo-inputs'>
                                <div className='sr-combo-inputs-row'>
                                    <input
                                        type='text'
                                        id='name'
                                        placeholder='Name'
                                        autoComplete='cardholder'
                                        className='sr-input'
                                    />
                                </div>
                                <div className='sr-combo-inputs-row'>
                                    <div className='sr-input sr-card-element' id='card-element'></div>
                                </div>
                            </div>
                            <div className='sr-field-error' id='card-errors' role='alert'></div>
                        </div>
                        <button className='bg-blue-700 px-4 py-3 text-white hover:bg-blue-800 rounded-md' id='submit'>
                            <div className='spinner hidden' id='spinner'></div>
                            <span id='button-text'>{release.type == 'invoice' ? 'Submit Payment' : 'Pre-Authorize'}</span>
                        </button>
                        {release.type === 'invoice' && (
                            <div className='sr-legal-text'>A 3.5% processing fee has been added to this invoice.</div>
                        )}
                        <div className='sr-legal-text'>
                            A {release.type !== 'invoice' && 'pending '}charge for{' '}
                            <span className='order-total'>${Number(release.releaseValue).toFixed(2)}</span> will be placed on
                            your card.
                        </div>
                    </div>
                    <div className='sr-payment-summary hidden completed-view'>
                        <h1>
                            <span className='hold-status'></span>
                        </h1>
                    </div>
                </div>
            )}
        </div>
    );
}

function getInvoiceInfo(state) {
    let origin = window.location.origin;

    return new Promise((resolve, reject) => {
        try {
            fetch(`${origin}/stripe/get-salesforce-invoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    invoice: state.invoice,
                    po: state.po,
                    origin: origin,
                    email: state.email,
                }),
                mode: 'cors',
            })
                .then(function (result) {
                    return result.json();
                })
                .then(function (data) {
                    data.origin = origin;
                    resolve(data);
                    return;
                })
                .catch((e) => {
                    logError(e, 'getInvoiceInfo');
                    reject(e);
                });
        } catch (e) {
            reject(e);
        }
    });
}

async function getReleaseId() {
    let release = window.location.href.split('=')[1];
    if (release.includes(`&`)) release = release.split(`&`)[0];
    let origin = window.location.origin;

    if (release === `invoice`) return `invoice`;
    return new Promise((resolve, reject) => {
        try {
            fetch(`${origin}/stripe/get-salesforce-release`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ release: release, origin: origin }),
                mode: 'cors',
            })
                .then(function (result) {
                    return result.json();
                })
                .then(function (data) {
                    data.origin = origin;
                    resolve(data);
                    return;
                })
                .catch((e) => {
                    logError(e, 'getReleaseId');
                    reject(e);
                });
        } catch (e) {
            reject(e);
        }
    });
}

// A reference to Stripe.js
var stripe;

function loadPage(release) {
    if (release === `invoice`) return `invoice`;
    fetch(`${release.origin}/stripe/create-payment-intent`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(release),
    })
        .then(function (result) {
            return result.json();
        })
        .then(function (data) {
            return setupElements(data);
        })
        .then(function ({ stripe, card, clientSecret }) {
            document.querySelector('#submit').addEventListener('click', function (evt) {
                evt.preventDefault();
                // Initiate payment
                pay(stripe, card, clientSecret);
            });
        })
        .catch((e) => {
            logError(e, 'loadPage');
        });
}

// Set up Stripe.js and Elements to use in checkout form
var setupElements = async function (data) {
    stripe = await loadStripe(data.publicKey);
    var elements = stripe.elements();
    var style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    };

    var card = elements.create('card', { style: style });
    card.mount('#card-element');

    return {
        stripe,
        card,
        clientSecret: data.clientSecret,
    };
};

/*
 * Calls stripe.confirmCardPayment which creates a pop-up modal to
 * prompt the user to enter  extra authentication details without leaving your page
 */
var pay = function (stripe, card, clientSecret) {
    var cardholderName = document.querySelector('#name').value;

    var data = {
        card: card,
        billing_details: {},
    };

    if (cardholderName) {
        data['billing_details']['name'] = cardholderName;
    }

    changeLoadingState(true);

    // Initiate the payment.
    // If authentication is required, confirmCardPayment will display a modal
    stripe
        .confirmCardPayment(clientSecret, { payment_method: data })
        .then(function (result) {
            if (result.error) {
                changeLoadingState(false);
                var errorMsg = document.querySelector('.sr-field-error');
                errorMsg.textContent = result.error.message;
                setTimeout(function () {
                    errorMsg.textContent = '';
                }, 4000);
            } else {
                orderComplete(clientSecret);
            }
        })
        .catch((e) => {
            logError(e, 'pay');
        });
};

/* ------- Post-payment helpers ------- */

/* Shows a success / error message when the payment is complete */
var orderComplete = function (clientSecret) {
    stripe.retrievePaymentIntent(clientSecret).then(function (result) {
        var paymentIntent = result.paymentIntent;
        document.querySelectorAll('.payment-view').forEach(function (view) {
            view.classList.add('hidden');
        });
        document.querySelectorAll('.completed-view').forEach(function (view) {
            view.classList.remove('hidden');
        });
        if (paymentIntent.capture_method == 'manual') {
            document.querySelector('.hold-status').textContent =
                paymentIntent.status === 'requires_capture'
                    ? 'You successfully placed a hold on the card'
                    : 'You did not place a hold on the card';
        }
        if (paymentIntent.capture_method == 'automatic') {
            document.querySelector('.hold-status').textContent =
                paymentIntent.status === 'succeeded' ? 'You successfully charged the card' : 'You did not charge the card';
        }
    });
};

// Show a spinner on payment submission
var changeLoadingState = function (isLoading) {
    if (isLoading) {
        document.querySelector('button').disabled = true;
        document.querySelector('#spinner').classList.remove('hidden');
        document.querySelector('#button-text').classList.add('hidden');
    } else {
        document.querySelector('button').disabled = false;
        document.querySelector('#spinner').classList.add('hidden');
        document.querySelector('#button-text').classList.remove('hidden');
    }
};

export default StripePayment;
