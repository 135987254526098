import React, { useState, useEffect } from 'react';
import config from '../../utils/config';

export default function WMSResetPassword() {
    const [password, setPassword] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const ORIGIN = config.origin;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('id') && queryParams.has('name')) {
            setShowForm(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check password length
        if (password.length < 5) {
            setErrorMessage('Password should be at least 5 characters.');
            return;
        }

        setLoading(true);

        const queryParams = new URLSearchParams(window.location.search);
        const queryParamsObject = {};
        for (let [key, value] of queryParams.entries()) {
            queryParamsObject[key] = value;
        }

        const payload = {
            ...queryParamsObject,
            password,
        };

        const allQueryParams = new URLSearchParams(queryParamsObject).toString();

        try {
            const response = await fetch(`${ORIGIN}/wms/login/reset-password?${allQueryParams}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const responseData = await response.json();
            setResponseMessage(responseData.results || 'Unknown response from server.');
        } catch (error) {
            setResponseMessage('An error occurred while resetting the password.');
        } finally {
            setLoading(false);
        }
    };

    if (!showForm) return null;

    return (
        <div className='flex items-center justify-center min-h-screen bg-gray-100 '>
            {loading ? (
                <div className='flex justify-center items-center'>Loading...</div>
            ) : responseMessage ? (
                <div className='p-8 bg-white shadow-lg rounded-lg text-center'>{responseMessage}</div>
            ) : (
                <form onSubmit={handleSubmit} className='p-8 bg-white shadow-lg flex flex-col rounded-lg w-1/4'>
                    <input
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Enter new password'
                        className='mb-4 w-full p-2 border rounded'
                    />
                    {errorMessage && <p className='text-red-500 mb-2'>{errorMessage}</p>}
                    <button type='submit' className='bg-blue-primary  hover:bg-blue-secondary text-white p-2 rounded'>
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
}
