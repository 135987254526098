// Check to see if the data objects nested in the result array contain data
export const checkForNestedData = async (resultArray) => {
    if (!resultArray) return false;

    for (const objectArray of resultArray) {
        if (objectArray.data.length !== 0) {
            return true;
        }
    }
    return false;
};

// Function to conditionally render based on searchState
export const checkSearchState = (searchState, status) => {
    return status.searchState === searchState;
};

// Control text input into search
export const sanitizeInput = (text) => {
    let trimmedText = text.trim();
    return trimmedText;
};

export const sanitizeCageCode = (text) => {
    // 12) ":"+5 digits: Remove everything after colon and colon.
    const hasCageCode = /:[A-Za-z0-9]{5}$/.test(text);
    if (hasCageCode) {
        let sanitizedText = text.substring(0, text.length - 6);
        return sanitizedText;
    }

    return text;
};
