import React from 'react';
import { ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/react/24/outline';

export default function TableSortButton({ arrowDirections, setArrowDirections, name }) {
    return (
        <div
            onClick={() => {
                setArrowDirections({
                    // set all other directions to null
                    ...Object.keys(arrowDirections).reduce((acc, key) => {
                        acc[key] = { direction: null };
                        return acc;
                    }, {}),

                    [name]: {
                        direction: arrowDirections[name]?.direction === 'asc' ? 'desc' : 'asc',
                    },
                });
            }}
        >
            {arrowDirections[name]?.direction === 'asc' ? (
                <ArrowLongUpIcon className='h-5 w-5 inline-block text-white hover:text-gray-400 cursor-pointer' />
            ) : (
                <ArrowLongDownIcon
                    className={classNames(
                        arrowDirections[name]?.direction !== 'desc' ? 'text-gray-400' : 'text-white',

                        'h-5 w-5 inline-block hover:text-gray-400 cursor-pointer '
                    )}
                />
            )}
        </div>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
