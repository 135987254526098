import { formatDate } from '../../../../../utils/formatDate';
import { formatAsCurrency } from '../../../../../utils/formatAsCurrency';
import { PrinterIcon } from '@heroicons/react/24/solid';

export const QuoteTemplate = ({ quote, updateDocumentData }) => {
    const { createdDate, quoteNumber, status, quoteTotal } = quote;

    let partNumbers = JSON.parse(quote.partNumbers);
    let uniquePartNumbers = [...new Set(partNumbers)];

    return (
        <section className='flex flex-col w-full gap-y-4 mt-3 px-3'>
            <article className='text-xl'>
                <strong>Quote Number</strong>
                <h1>{quoteNumber}</h1>
            </article>
            <article className='text-xl'>
                <strong>Date</strong>
                <h1>{formatDate(createdDate)}</h1>
            </article>
            <article className='text-xl'>
                <strong>Current Status</strong>
                <h1>{status}</h1>
            </article>
            <article className='text-xl'>
                <strong>Total Price</strong>
                <h1>{quoteTotal && quoteTotal > 0 ? formatAsCurrency(quoteTotal) : 'Pending'}</h1>
            </article>
            <article className='text-xl'>
                <strong>Parts</strong>
                <div className='w-full flex flex-row flex-wrap gap-2'>
                    {uniquePartNumbers?.map((part, index) => {
                        return (
                            <a key={index} className='underline w-fit' href={`/?part-search=${part}`}>
                                {part}
                            </a>
                        );
                    })}
                </div>
            </article>
            <article className='text-xl flex flex-col gap-y-2'>
                <strong>Actions</strong>
                <button
                    className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                    onClick={(e) => {
                        e.stopPropagation();
                        updateDocumentData({
                            id: quote.quoteId,
                            name: quoteNumber,
                            type: 'quote',
                            releaseId: null,
                            docUrl: false,
                        });
                    }}
                    style={{
                        all: 'revert',
                    }}
                >
                    <PrinterIcon className='h-6 w-6 inline-block text-blue-primary' />
                    <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                        Print Quote
                    </div>
                </button>
            </article>
        </section>
    );
};
