import { useState } from 'react';
import HolderHeader from './HolderHeader';
import IndividualResultWrapper from './IndividualResultWrapper';
import { formatAsCurrency } from '../../../../../utils/formatAsCurrency';
import { formatDate } from '../../../../../utils/formatDate';
import { PrinterIcon } from '@heroicons/react/24/solid';

export const PageQuoteHolder = ({ object, updateDocumentData }) => {
    const [showMore, setShowMore] = useState(false);
    const columns = { lg: 4, md: 4, sm: 4 };

    return (
        <div className='flex flex-col w-full rounded-lg mt-7 last:mb-7'>
            <HolderHeader text={'Quotes'} showMore={showMore} setShowMore={setShowMore} hasMore={object.data.length > 10} />
            <section className='w-full grid grid-cols-4 p-3 gap-3 bg-gray-100 last:rounded-b-lg border-t border-gray-200'>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Quote Number</div>
                </article>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Quote Total</div>
                </article>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Date</div>
                </article>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Actions</div>
                </article>
            </section>
            {object?.data?.slice(0, showMore ? object.data.length : 10).map((quote, index) => {
                const { quoteNumber, quoteTotal, createdDate } = quote;

                return (
                    <IndividualResultWrapper key={index} table={'Quote'} name={quoteNumber} columns={columns} object={quote}>
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{quoteNumber}</h1>
                        </article>
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            {quoteTotal === 0 ? (
                                <h1 className='truncate leading-normal'>Pending Review</h1>
                            ) : (
                                <h1 className='truncate leading-normal'>{formatAsCurrency(quoteTotal)}</h1>
                            )}
                        </article>
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{formatDate(createdDate)}</h1>
                        </article>
                        <article className='col-span-1 hidden lg:flex flex-row justify-start items-center gap-3 h-full'>
                            <button
                                className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateDocumentData({
                                        id: quote.quoteId,
                                        name: quoteNumber,
                                        type: 'quote',
                                        releaseId: null,
                                        docUrl: false,
                                    });
                                }}
                                style={{
                                    all: 'revert',
                                }}
                            >
                                <PrinterIcon className='h-6 w-6 inline-block text-blue-primary' />
                                <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                    Print Quote
                                </div>
                            </button>
                        </article>
                    </IndividualResultWrapper>
                );
            })}
        </div>
    );
};
