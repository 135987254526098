import React, { useState } from 'react';
import { setShippingDefault } from '../../../utils/commonAPICalls';
import { useAuth } from '../../../portal/context/auth-context';
import EditAddressModal from './EditAddressModal';
import config from '../../../utils/config';
import DeleteAddressModal from './DeleteAddressModal';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { PencilSquareIcon, TrashIcon, StarIcon } from '@heroicons/react/24/outline';

import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import AddNewShippingForm from '../AddNewShippingForm';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ShippingAddress({ shipInfo, setShippingInfo, notificationStatus, setNotificationStatus }) {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;

    const [openEditModal, setOpenEditModal] = useState(false);
    const [editAddress, setEditAddress] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteAddressId, setDeleteAddressId] = useState('');
    const [newShipping, setNewShipping] = useState(false);
    const [loadindDelete, setLoadingDelete] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleMakeDefault = async (shipAddressId) => {
        shipInfo.shipAddresses.map((address) => {
            if (address.shipAddressId === shipAddressId) {
                address.isPrimaryAddress = 1;
            } else {
                address.isPrimaryAddress = 0;
            }

            setShippingInfo({ ...shipInfo });
        });
        await setShippingDefault(currentUser, shipAddressId);

        setNotificationStatus({
            title: 'Successfully Saved',
            desc: 'Your default shipping address has been updated',
            status: 'Success',
            shown: true,
        });
        setTimeout(() => {
            setNotificationStatus({ notificationStatus, shown: false });
        }, 5000);
    };

    const handleMakeEdits = (address) => {
        setEditAddress(address);
        setOpenEditModal(true);
    };

    const saveUpdatedAddress = async (editedAddress) => {
        const response = await fetch(`${ORIGIN}/cp/shipping/update-address`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                ...editedAddress,
                accountName: currentUser.Account.Name,
            }),
        });

        await response.json();

        const updatedAddress = shipInfo.shipAddresses.map((address) => {
            if (address.shipAddressId === editedAddress.shipAddressId) {
                return editedAddress;
            } else {
                return address;
            }
        });
        setShippingInfo({
            ...shipInfo,
            shipAddresses: updatedAddress,
        });

        setOpenEditModal(false);
    };

    const handleDelete = async (shipAddressId) => {
        setLoadingDelete(true);
        const response = await fetch(`${ORIGIN}/cp/shipping/delete-address`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                shipAddressId,
            }),
        });

        const data = await response.json();
        if (data.result === 'success') {
            setShowDeleteModal(false);
        }
        const updatedAddress = shipInfo.shipAddresses.filter((address) => address.shipAddressId !== shipAddressId);
        setShippingInfo({ shipAddresses: updatedAddress });
        setLoadingDelete(false);
    };

    const handleNewAddress = (newAddress) => {
        setShippingInfo({
            shipAddresses: [...shipInfo.shipAddresses, newAddress],
        });
        setNewShipping(false);
    };

    if (!shipInfo.shipAddresses) {
        return (
            <div className='pb-24'>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Ship Addresses</h2>
                <LoadingSpinner />
            </div>
        );
    }
    return (
        <>
            {showDeleteModal && (
                <DeleteAddressModal
                    open={showDeleteModal}
                    setOpen={setShowDeleteModal}
                    handleDelete={handleDelete}
                    deleteAddressId={deleteAddressId}
                    type={'shipping'}
                    loading={loadindDelete}
                />
            )}
            <EditAddressModal
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                editAddress={editAddress}
                setEditAddress={setEditAddress}
                saveUpdatedAddress={saveUpdatedAddress}
            />

            {newShipping && <AddNewShippingForm setNewShipping={setNewShipping} handleNewAddress={handleNewAddress} />}
            <div className='relative'>
                <div className='absolute right-0 -top-2'>
                    <div
                        className='flex flex-row-reverse items-center cursor-pointer bg-blue-primary rounded-md shadow-sm px-4 py-2 font-medium hover:bg-gray-50 border text-sm text-white hover:text-gray-800'
                        onClick={() => setNewShipping(true)}
                    >
                        <span className=''>Add New Address</span>
                    </div>
                </div>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Ship Addresses</h2>
                <div className='mt-6 grid gap-y-6 gap-x-8 md:grid-cols-3 xxl:grid-cols-4 mx-4'>
                    {shipInfo.shipAddresses.map((address) => (
                        <div key={address.shipAddressId}>
                            <div
                                className={classNames(
                                    address.isPrimaryAddress ? 'border-blue-primary shadow-lg' : '',
                                    'sm:col-span-1 border rounded-lg p-2 border-gray-300 items-start pl-6 pt-10  min-h-full h-60 relative shadow-md'
                                )}
                            >
                                {address.isPrimaryAddress ? (
                                    <div className='cursor-pointer absolute top-3 right-3 text-blue-primary hover:text-gray-500'>
                                        <StarIconSolid className='h-6 w-6' />
                                    </div>
                                ) : (
                                    <div
                                        className='cursor-pointer absolute top-3 right-3 text-gray-400 hover:text-gray-500'
                                        onClick={() => handleMakeDefault(address.shipAddressId)}
                                        onMouseEnter={() => setHovered(address.shipAddressId)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <StarIcon className='h-6 w-6' />
                                        {hovered === address.shipAddressId && (
                                            <div className='absolute bg-gray-500 shadow-lg rounded-lg p-2 z-50 w-max right-2'>
                                                <div className='flex flex-col space-y-2'>
                                                    <div className='flex justify-between items-center'>
                                                        <div className='text-sm font-semibold text-white'>
                                                            Set as Default
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className='flex justify-center flex-col mt-2'>
                                    <h3 className='text-md leading-4 font-medium text-gray-900'>
                                        {address.shipAddressName}
                                    </h3>
                                    <p className='mt-2 text-sm text-gray-600'>{address.street}</p>
                                    <p className=' text-sm text-gray-600'>
                                        {address.city}, {address.state} {address.zip}
                                    </p>
                                    <p className='text-sm text-gray-600'>
                                        {address.postalCode}, {address.country}
                                    </p>
                                </div>
                                <div className='flex justify-around space-x-4 text-gray-600 mt-4 absolute bottom-5 cursor-pointer'>
                                    <div onClick={() => handleMakeEdits(address)} className='px-2 py-1 '>
                                        <PencilSquareIcon className='w-6 h-6 text-gray-500' />
                                    </div>
                                    <span className='text-gray-400'>|</span>
                                    <div
                                        onClick={() => {
                                            setShowDeleteModal(true);
                                            setDeleteAddressId(address.shipAddressId);
                                        }}
                                        className=' px-2 py-1 '
                                    >
                                        <TrashIcon className='w-6 h-6 text-gray-500' />
                                    </div>
                                    {/* {!address.isPrimaryAddress && (
                    <>
                      <span className="text-gray-400">|</span>
                      <p
                        onClick={() => handleMakeDefault(address.shipAddressId)}
                        className=" text-gray-800 bg-gray-200 px-3 rounded-full text-sm py-1.5"
                      >
                        make default
                      </p>
                    </>
                  )} */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
