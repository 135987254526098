import { PDFDocument } from 'pdf-lib';

export const camelCaseToTitleCase = (camelCase) => {
    return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
};

export const camelCaseWords = (input) => {
    return input
        .split(' ') // Split the input string into an array of words
        .map(
            (word, index) =>
                index === 0
                    ? word.toLowerCase() // Make the first word all lowercase
                    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter of each subsequent word and make the rest of the word lowercase
        )
        .join(''); // Join the array of words back into a single string without spaces
};

/**
 * @param {string[]} base64Pdfs - An array of base64-encoded PDF strings.
 * @param {Uint8Array[]} base64Pdfs - An array of Uint8Array data.
 * @returns {Promise<string>} - A promise that resolves to a base64-encoded string of the merged PDF.
 */
export async function mergePdfs(base64Pdfs, uint8Array = null) {
    // Create a new PDFDocument for the merged PDF
    const mergedPdf = await PDFDocument.create();
    const arrayData = base64Pdfs ?? uint8Array;

    for (const data of arrayData) {
        // Convert the base64 string to a Uint8Array
        const pdfBytes = uint8Array ? data : base64ToUint8Array(data);

        // Load the PDFDocument from the bytes
        const pdf = await PDFDocument.load(pdfBytes);

        // Copy all pages from the current PDF into the merged PDF
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    // Serialize the merged PDFDocument to bytes (Uint8Array)
    const mergedPdfBytes = await mergedPdf.save();

    // Convert the merged PDF bytes to a base64 string
    const mergedBase64Pdf = uint8ArrayToBase64(mergedPdfBytes);

    return mergedBase64Pdf;
}

export const openDocumentInNewTab = async (documentBase64) => {
    const pdfFile = await PDFDocument.load(documentBase64);
    const savedPdf = await pdfFile.save();

    const blob = new Blob([savedPdf], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);

    // Cleanup
    URL.revokeObjectURL(fileURL);
};

//////////////////////
// Private Functions
//////////////////////

/**
 * Converts a base64 string to a Uint8Array.
 *
 * @param {string} base64 - The base64 string to convert.
 * @returns {Uint8Array} - The resulting Uint8Array.
 */
function base64ToUint8Array(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

/**
 * Converts a Uint8Array to a base64 string.
 *
 * @param {Uint8Array} bytes - The Uint8Array to convert.
 * @returns {string} - The resulting base64 string.
 */
function uint8ArrayToBase64(bytes) {
    let binaryString = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binaryString += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binaryString);
}
