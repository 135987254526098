import React, { useState } from 'react';
import { PlusCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import Tooltip from '../../../components/Tooltip';

export default function WildCardResults({
    results,
    addToBulkSearch,
    removeFromBulkSearch,
    addedItems,
    setAddedItems,
    setHoveredOrderIcon,
    setOrderLine,
    hoveredOrderIcon,
    orderLine,
}) {
    const [isHovered, setIsHovered] = useState(null);

    return (
        <>
            {results?.length >= 1 && (
                <div className='overflow-y-auto h-64 mt-2 border border-gray-300 mx-8 shadow-sm'>
                    <div className='px-12 py-2'>
                        <div className='flex flex-col'>
                            {results.map((result, index) => (
                                <div className='border-b w-full py-2 flex justify-between'>
                                    <div className='flex items-center'>
                                        {addedItems.includes(result) ? (
                                            <div
                                                onMouseEnter={() => {
                                                    setIsHovered(index);
                                                    setHoveredOrderIcon(result);
                                                    setOrderLine(result);
                                                }}
                                                onMouseLeave={() => {
                                                    setIsHovered(null);
                                                    setHoveredOrderIcon(null);
                                                    setOrderLine(null);
                                                }}
                                                onClick={() => {
                                                    removeFromBulkSearch(result);
                                                    setHoveredOrderIcon(null);
                                                    setOrderLine(null);
                                                    setAddedItems((prevItems) =>
                                                        prevItems.filter((prevItem) => prevItem !== result)
                                                    );
                                                }}
                                            >
                                                <ArrowLeftIcon className='h-6 w-6 inline-block text-green-500 hover:text-green-400 cursor-pointer' />
                                                {(hoveredOrderIcon === result || orderLine === result) && (
                                                    <Tooltip text='Part added to bulk search' />
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                onMouseEnter={() => {
                                                    setHoveredOrderIcon(result);
                                                    setOrderLine(result);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredOrderIcon(null);
                                                    setOrderLine(null);
                                                }}
                                                onClick={() => {
                                                    addToBulkSearch(result);
                                                    setHoveredOrderIcon(null);
                                                    setOrderLine(null);
                                                    setAddedItems((prevItems) => [...prevItems, result]);
                                                }}
                                            >
                                                <PlusCircleIcon className='h-6 w-6 inline-block text-gray-600 hover:text-gray-400 cursor-pointer' />
                                                {(hoveredOrderIcon === result || orderLine === result) && (
                                                    <Tooltip text='Add Part to bulk search' />
                                                )}
                                            </div>
                                        )}
                                        <p className='text-gray-600 ml-2'>{result}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
