import React from 'react';

export default function NotFoundInventoryTable({ data, title }) {
    return (
        <div>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 underline text-cent pb-1 mt-4'>
                {title}
            </h1>
            {data.map((part) => (
                <p>{part}</p>
            ))}
        </div>
    );
}
