import React, { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';

export default function DeleteAddressModal({ open, setOpen, deleteAddressId, handleDelete, loading, type }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-lg sm:p-6'>
                                <div>
                                    <div className='mt-3 text-center sm:mt-2'>
                                        <div className='relative'>
                                            <button
                                                className='text-white rounded-full cursor-pointer bg-blue-primary hover:bg-gray-400 w-6 h-6 flex justify-center items-center mt-1 absolute -right-3 -top-3 mr-2'
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <XMarkIcon className='w-5 h-5' />
                                            </button>
                                        </div>
                                        <div className='bg-white text-left sm:rounded-lg'>
                                            <div className='px-4 py-5 sm:p-6'>
                                                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                                    Delete this {type}
                                                </h3>
                                                <div className='mt-2 max-w-xl text-sm text-gray-500'>
                                                    <p>Once you delete this {type}, you will lose it permanently.</p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button
                                                        onClick={() => {
                                                            handleDelete(deleteAddressId);
                                                        }}
                                                        type='button'
                                                        className='inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 font-medium bg-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm text-white'
                                                    >
                                                        {loading ? 'loading...' : <span> Delete {type}</span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
