import React, { useEffect, useState } from 'react';
import OrderSummary from '../../portal/components/OrderSummary';
import PurchaseOrder from '../../portal/components/PurchaseOrder';
import ShippingReviewInfo from '../../portal/components/ShippingReviewInfo';
import { useAuth } from '../../portal/context/auth-context';
import config from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import Notification from '../../components/Notification';
import LoadingSpinner from '../../components/LoadingSpinner';
import NoOrder from '../components/NoOrder';
import logError from '../../utils/errorUtil';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function CheckoutPage() {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;
    const history = useNavigate();

    const [error, setError] = useState(null);
    const [errorModal, setErrorModal] = useState(false);
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(null);
    const [purchaseOrderNotes, setPurchaseOrderNotes] = useState();
    const [file, setFile] = useState([]);
    const [noOrder, setNoOrder] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [quoteLoading, setQuoteLoading] = useState(true);

    const [grandTotal, setGrandTotal] = useState(0);
    const [creditCardFee, setCreditCardFee] = useState(0);

    const [submissionError, setSubmissionError] = useState(null);
    const [fileTypeError, setFileTypeError] = useState(null);
    const [allowRetry, setAllowRetry] = useState(true);

    function converToToBase64(file) {
        return new Promise(async (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileBase64 = e.target.result;
                resolve(fileBase64);
            };
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(file);
        });
    }

    useEffect(() => {
        if (!currentUser.order) {
            history('/');
        }
    }, []);

    useEffect(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const quoteId = urlParams.get('quote');
        if (quoteId) {
            setQuoteLoading(true);
            await fetch(`${ORIGIN}/cp/edit-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
                },
                body: JSON.stringify({
                    quoteId,
                    contactId: currentUser.Id,
                    accountId: currentUser.Account.Id,
                    action: 'checkout',
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    if (data.failure) {
                        setError(data.error);
                        setErrorModal(true);
                        return;
                    }
                    if (data.found.length === 0) {
                        setNoOrder(true);
                        setQuoteLoading(false);
                        return;
                    }
                    currentUser.order = data.found;
                    currentUser.finalQuote = {
                        quoteId: data.quoteId,
                        quoteNumber: data.quoteNumber,
                    };
                    currentUser.orderDetails = data;
                    currentUser.selectedShipMethod = data.selectedShipMethod;
                    currentUser.selectedShipAddress = data.selectedShipAddress;
                    currentUser.surcharges = {
                        aogFee: 0,
                        darFee: 0,
                    };

                    if (data.isAOG) {
                        currentUser.surcharges.aogFee = data.AOGFee;
                        console.log('ISAOG');
                        console.log(currentUser.surcharges.aogFee);
                    }

                    let isDar = data.found.map((item) => item.isDar);
                    if (isDar.includes(true)) {
                        currentUser.surcharges.darFee = data.DARFee;
                    }

                    setQuoteLoading(false);
                })
                .catch((e) => {
                    logError(e, 'CheckoutPage');
                });
        } else {
            setQuoteLoading(false);
        }
    }, []);

    const handleSubmit = () => {
        if (!purchaseOrderNumber) {
            setError('Please enter a purchase order number');
            return;
        }
        setIsLoading(true);

        const files = [...file];
        const filePromises = files.map(async (file) => {
            let data = await converToToBase64(file);
            return data;
        });
        Promise.all(filePromises).then((data) => {
            const order = {
                userId: currentUser.Id,
                accountId: currentUser.Account.Id,
                accountOwnerEmail: currentUser.Owner.Email,
                accountName: currentUser.Account.Name,
                Email: currentUser.Email,
                quoteId: currentUser.finalQuote.quoteId,
                quoteNumber: currentUser.finalQuote.quoteNumber,
                shipMethodId: currentUser.selectedShipMethod.shipMethodId,
                shipMethodName: currentUser.selectedShipMethod.shipMethodName,
                shipAddressId: currentUser.selectedShipAddress.shipAddressId,
                shipAddressName: currentUser.selectedShipAddress.shipAddressName,
                purchaseOrderNumber: purchaseOrderNumber,
                externalComments: purchaseOrderNotes,
                found: [...currentUser.order],
                files: data,
                surcharges: {
                    darFee: Number(currentUser.surcharges.darFee),
                    aogFee: Number(currentUser.surcharges.aogFee),
                    creditCardFee: Number(creditCardFee),
                    total: Number(grandTotal),
                },
            };

            fetch(`${ORIGIN}/cp/submit-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
                },
                body: JSON.stringify(order),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (data.failure) {
                        setIsLoading(false);
                        setErrorModal(true);
                        setSubmissionError(data.failure);
                        setAllowRetry(data.allowRetry);
                        return;
                    }
                    if (data.salesOrderId) {
                        currentUser.generateConfirmation = data.salesOrderId;
                        history(`/`);
                        currentUser.finalQuote = null;
                        currentUser.order = null;

                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((e) => {
                    logError(e, 'handleSubmit');
                });
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileChange(e.dataTransfer.files, 'drop');
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    };

    const handleFileChange = (e, type) => {
        let targetFiles;
        setFileTypeError(null);

        if (type === 'drop') {
            targetFiles = e;
        } else {
            targetFiles = e.target.files;
        }
        setError(false);
        //set error if total file size is greater than 2mb
        const totalSize = [...targetFiles].map((x) => x.size).reduce((a, b) => a + b, 0);
        if (totalSize > 20000000) return setError('Limit file upload size to 20mb');

        // check if file is pdf or word doc
        const fileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];
        const fileTypesString = fileTypes.join(', ');
        let files = [...targetFiles];
        const fileTypesArray = files.map((file) => file.type);
        const isFileTypeValid = fileTypesArray.every((fileType) => fileTypes.includes(fileType));
        if (!isFileTypeValid) return setFileTypeError(`Please upload a valid file type.`);

        setFile((prev) => [...prev, ...files]);
    };

    const handleBack = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const quoteId = urlParams.get('quote');
        console.log('back');
        if (!quoteId) {
            let orderRestore = currentUser.order.map((item) => {
                if (item.selected) {
                    item.unitPrice = Number(item.originalCost);
                }
                return item;
            });

            currentUser.order = orderRestore;
        }

        history('/?editOrder=155638');
    };

    // block the back button

    useEffect(() => {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, '', window.location.href);
        };
    }, []);

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter((prev) => prev + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (quoteLoading) return <LoadingSpinner />;
    if (noOrder) return <NoOrder />;

    return (
        <>
            <div
                onDragEnter={handleDragIn}
                onDrop={handleDrop}
                onDragLeave={() => {
                    setTimeout(() => {
                        setDragCounter(0);
                        setDragging(false);
                    }, 1500);
                }}
                className={classNames(
                    !dragging ? '' : 'bg-gray-300',
                    'flex flex-col md:items-center justify-center w-full h-full'
                )}
            >
                <div className='md:mx-36 md:mb-8'>
                    <div className='md:grid grid-cols-2 mt-12'>
                        <div className='mb-8 md:mb-0'>
                            <OrderSummary
                                grandTotal={grandTotal}
                                setGrandTotal={setGrandTotal}
                                setCreditCardFee={setCreditCardFee}
                            />
                            <div className='italic text-gray-500 text-md p-2'>
                                *All minimum order fees are applied to all parts in the order to equal the minimum order
                                amount. (if applicable)
                            </div>
                        </div>
                        <div className='md:flex md:flex-col items-end space-y-8 w-full'>
                            <PurchaseOrder
                                setError={setError}
                                error={error}
                                purchaseOrderNumber={purchaseOrderNumber}
                                setPurchaseOrderNumber={setPurchaseOrderNumber}
                                purchaseOrderNotes={purchaseOrderNotes}
                                setPurchaseOrderNotes={setPurchaseOrderNotes}
                                file={file}
                                setFile={setFile}
                                dragging={dragging}
                                setDragging={setDragging}
                                handleFileChange={handleFileChange}
                                setDragCounter={setDragCounter}
                                fileTypeError={fileTypeError}
                                setFileTypeError={setFileTypeError}
                            />
                            <ShippingReviewInfo />
                            <div className='flex md:space-x-12 space-x-2 md:w-5/6'>
                                <button
                                    onClick={handleBack}
                                    disabled={errorModal || !allowRetry || isLoading}
                                    className='cursor-pointer bg-white text-blue-primary font-bold md:py-2.5 md:px-4.5 text-lg rounded md:w-80 w-44 hover:bg-blue-primary hover:text-white border border-blue-primary'
                                >
                                    Revise Order
                                </button>
                                {!isLoading ? (
                                    <div>
                                        <button
                                            onClick={handleSubmit}
                                            disabled={errorModal || !allowRetry}
                                            className='cursor-pointer bg-blue-primary text-white font-bold py-2.5 px-4.5 text-lg rounded md:w-80 w-44 hover:bg-blue-secondary'
                                        >
                                            Submit Order
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        disabled
                                        type='button'
                                        className='cursor-pointer w-60 text-white bg-blue-primary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-2.5 text-center mr-2 dark:bg-blue-primary dark:hover:bg-blue-primary dark:focus:ring-blue-800 inline-flex items-center justify-center'
                                    >
                                        <svg
                                            role='status'
                                            className='inline mr-3 w-4 h-4 text-white animate-spin'
                                            viewBox='0 0 100 101'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                                fill='#E5E7EB'
                                            />
                                            <path
                                                d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                                fill='currentColor'
                                            />
                                        </svg>
                                        Submitting Order...
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {errorModal && (
                <Notification
                    showSavedNotification={errorModal}
                    setShowSavedNotification={setErrorModal}
                    title={'There was an error'}
                    desc={submissionError}
                    status={'failure'}
                />
            )}
        </>
    );
}
