import React, { useState } from 'react';
import LoadingButton from '../../components/LoadingButton';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function EnterNewPassWord({ submitNewPassword, email }) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [passwordLengthError, setPasswordLengthError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (password.length < 6) {
            setPasswordError(true);
            setPasswordLengthError('Password must be at least 6 characters');
            setIsLoading(false);
            return;
        } else {
            setPasswordError(false);
        }
        if (password !== confirmPassword) {
            setPasswordError(true);
            setPasswordMatchError('passwords do not match');
            setIsLoading(false);
        } else {
            setPasswordError(false);
            submitNewPassword(email, password);
        }
    };

    return (
        <>
            {' '}
            <div className=''>
                <div className='w-full max-w-md space-y-8 pb-10'>
                    <div>
                        <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900 pb-4'>
                            Enter your new password
                        </h2>
                        {passwordMatchError && (
                            <p className='text-red-500 text-xs italic text-center -mb-4 mt-4'>{passwordMatchError}</p>
                        )}
                        {setPasswordLengthError && (
                            <p className='text-red-500 text-xs italic text-center -mb-6 mt-4'>{passwordLengthError}</p>
                        )}
                    </div>
                    <form className='mt-8 space-y-6'>
                        <div className='-space-y-px rounded-md '>
                            <div className='shadow-sm'>
                                <label htmlFor='password' className='sr-only'>
                                    Password
                                </label>
                                <input
                                    id='password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    name='password'
                                    type='password'
                                    autoComplete='password'
                                    required
                                    className={classNames(
                                        passwordError ? 'border-2 border-red-400' : 'border border-gray-300',
                                        'block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-md'
                                    )}
                                    placeholder='Password'
                                />
                            </div>
                        </div>
                        <div className='-space-y-px rounded-md '>
                            <div className='shadow-sm'>
                                <label htmlFor='password-confirm' className='sr-only'>
                                    Confirm Password
                                </label>
                                <input
                                    id='password-confirm'
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    name='password-confirm'
                                    value={confirmPassword}
                                    type='password'
                                    autoComplete='password'
                                    required
                                    className={classNames(
                                        passwordError ? 'border-2 border-red-400' : 'border border-gray-300',
                                        'block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-md'
                                    )}
                                    placeholder='Confirm Password'
                                />
                            </div>
                        </div>
                        {!isLoading ? (
                            <div>
                                <button
                                    onClick={handleSubmit}
                                    disabled={password === '' || confirmPassword === ''}
                                    type='submit'
                                    className='cursor-pointer group relative flex w-full justify-center rounded-md border border-transparent bg-blue-primary py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                >
                                    Create new password
                                </button>
                            </div>
                        ) : (
                            <LoadingButton />
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
