import React, { useEffect, useState } from 'react';
import ApprovalSection from '../portal/components/csl-compliance/ApprovalSection';
import DenialSection from '../portal/components/csl-compliance/DenialSection';

export default function CSLComplianceVerification() {
    const [queryParams, setQueryParams] = useState({});

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const params = {};
        for (let param of query.entries()) {
            params[param[0]] = param[1];
        }
        setQueryParams(params);
    }, []);

    // create a switch statment to render the correct component based on the action
    const returnComponent = () => {
        switch (queryParams.action) {
            case 'approve':
                return (
                    <ApprovalSection releaseId={queryParams.releaseId} key={queryParams.key} record={queryParams.record} />
                );
            case 'deny':
                return <DenialSection {...queryParams} />;
            case 'whitelist':
                return (
                    <div className='grid h-screen place-items-center text-3xl text-blue-primary font-bold pb-24'>
                        Whitelist
                    </div>
                );
            default:
                return <div className='grid h-screen place-items-center text-3xl text-blue-primary font-bold pb-24'></div>;
        }
    };

    // if (!checkKey())
    //     return (
    //         <div className='grid h-screen place-items-center text-3xl text-blue-primary font-bold pb-24'>Access Denied</div>
    //     );
    return returnComponent();
}
