import React from 'react';
import SettingsTabs from '../components/settingsTabs/SettingsTabs';

export default function SettingsPage() {
    return (
        <div className='bg-gray-50 h-[calc(100vh-64px)] border-t overflow-y-scroll'>
            <SettingsTabs />
        </div>
    );
}
