import React, { useEffect, useRef, useState } from 'react';
import { defaultStatus, searchStateEnum } from '../../data';
import { useAuth } from '../../../../../portal/context/auth-context';
import DetailModal from './DetailModal';
import config from '../../../../../utils/config';
import logError from '../../../../../utils/errorUtil';

export default function ActionModal({ documentData, modalOpen, setModalOpen }) {
    const { currentUser } = useAuth();
    const { LOADING, RESULTS } = searchStateEnum;
    const modalRef = useRef(null);
    const ORIGIN = config.origin;

    const [status, setStatus] = useState(defaultStatus);
    const [resultData, setResultData] = useState(null);
    const [hasBase64, setHasBase64] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => {
            setResultData(null);
        }, 200);
    };

    const handleDocumentClick = async (id, name, type, releaseId) => {
        try {
            setStatus({ ...status, searchState: LOADING });
            setModalOpen(true);
            fetch(`${ORIGIN}/cp/print`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
                },
                body: JSON.stringify({
                    accountId: currentUser.Account.Id,
                    type: type,
                    id: id,
                    name: name,
                    accountEmail: currentUser.Owner.Email,
                    releaseId: releaseId,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    setResultData(data.form);
                    setStatus({ ...status, searchState: RESULTS });
                })
                .catch((err) => logError(err, 'handleDocumentClick'));
        } catch (error) {
            console.error(error);
        }
    };

    // Close modal on click off
    const handleClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (!documentData) return;

        if (documentData.docUrl) {
            setHasBase64(false);
            setResultData(documentData.docUrl);
            setModalOpen(true);
        } else {
            const { id, name, type, releaseId } = documentData;
            setHasBase64(true);
            handleDocumentClick(id, name, type, releaseId);
        }
    }, [documentData]);

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [documentData]);

    return (
        <>
            <DetailModal modalOpen={modalOpen} closeModal={closeModal} hasBase64={hasBase64} info={resultData} />
        </>
    );
}
