import React, { useState, useRef, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useAuth } from '../../../../wms/context/wms-context';
import { searchByValue } from '../../../../utils/commonAPICalls.js';
import LoadingSpinner from '../../../../components/LoadingSpinner';

function SearchField({ label, value, onChange, required, tableName, readOnly }) {
    const { currentUser } = useAuth();

    const [filteredOptions, setFilteredOptions] = useState();
    const [apiData, setApiData] = useState([]); // To store API data
    const [showOptions, setShowOptions] = useState(false);
    const [isSelected, setIsSelected] = useState(!!value);
    const [isInvalidInput, setIsInvalidInput] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [inputValue, setInputValue] = useState(null);
    const [loadingInput, setLoadingInput] = useState(false);
    const [selectedTextValue, setSelectedTextValue] = useState(null);
    const [isErrorInputState, setIsErrorInputState] = useState(false);

    const listRef = useRef();
    const containerRef = useRef(null);

    const closeDropdown = () => {
        setLoadingInput(false);
        setShowOptions(false);
    };

    const handleOutsideClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            closeDropdown();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('focusin', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('focusin', handleOutsideClick);
        };
    }, []);

    const handleInputChange = async (e) => {
        setLoadingInput(true);
        const newInputValue = e.target.value;
        setInputValue(newInputValue);
        setSelectedTextValue(null);

        if (!newInputValue.trim()) {
            setIsInvalidInput(false);
            setIsSelected(false);
            closeDropdown();
            onChange({ target: { value: '' } });
            return;
        }

        // Call the API
        try {
            const dataToSend = {
                tableName: tableName,
                value: newInputValue,
            };

            const response = await searchByValue(dataToSend, currentUser);
            console.log('response', response);
            if (response.data.length > 0) {
                setApiData(response.data);
                setShowOptions(true);
                setFilteredOptions(response.data);
                if (listRef.current) {
                    listRef.current.scrollTo(0);
                }
            } else {
                closeDropdown();
            }
            setLoadingInput(false);
        } catch (error) {
            console.error('Error fetching data', error);
            // Handle error, maybe show user an error message or something similar
        }
    };

    const handleOptionClick = (option) => {
        setInputValue(option.Name);
        onChange({ target: { value: option.Id } });
        setSelectedTextValue(option.Name);

        if (label === 'Part Master') {
            currentUser.inscor__Serialized__c = option.inscor__Serialized__c;
        }
        setIsSelected(true);
        setIsInvalidInput(false); // Reset the invalid flag when an option is selected
        closeDropdown();
    };

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                if (highlightedIndex < filteredOptions.length - 1) {
                    setHighlightedIndex((prev) => prev + 1);
                    listRef.current.scrollToItem(highlightedIndex + 1, 'center');
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (highlightedIndex > 0) {
                    setHighlightedIndex((prev) => prev - 1);
                    listRef.current.scrollToItem(highlightedIndex - 1, 'center');
                }
                break;
            case 'Enter':
                if (highlightedIndex !== -1) {
                    handleOptionClick(filteredOptions[highlightedIndex]);
                }
                break;
            default:
                break;
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            // If input is blank, reset and exit
            if (!inputValue?.trim() && required) {
                setIsInvalidInput(true);
                closeDropdown();
                return;
            }
        }, 150);
    };

    useEffect(() => {
        if (isInvalidInput || (!showOptions && selectedTextValue !== inputValue && inputValue)) {
            setIsErrorInputState(true);
        } else {
            setIsErrorInputState(false);
        }
    }, [isInvalidInput, showOptions, selectedTextValue, inputValue]);

    useEffect(() => {
        setSelectedTextValue(inputValue);
    }, []);

    // Conditional styling
    let inputClassName = 'mt-1 p-2 w-full border rounded-md';
    if (isSelected) inputClassName += ' ring-2 ring-blue-200';
    if (isErrorInputState) inputClassName += ' border-red-500 ring-2 ring-red-200';

    const Row = ({ index, style }) => (
        <div
            style={style}
            className={`cursor-pointer text-left p-2 ${index === highlightedIndex ? 'bg-gray-300' : ''} hover:bg-gray-200`}
            onMouseDown={(e) => {
                e.preventDefault(); // Prevent input from losing focus
                handleOptionClick(filteredOptions[index]);
            }}
        >
            {filteredOptions[index].Name}
        </div>
    );

    return (
        <div className='my-2 relative px-8' ref={containerRef}>
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            <input
                type='text'
                value={inputValue}
                disabled={readOnly}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Add this line to capture key down events
                required={required}
                className={inputClassName}
                onBlur={handleBlur}
                onFocus={() => {
                    setShowOptions(true);
                    setHighlightedIndex(-1); // Reset highlighted index on focus
                }}
            />
            {required && <div className='absolute top-0 right-0 text-red-500 text-xs'>required *</div>}
            {showOptions && apiData.length > 0 && !loadingInput && (
                <div className='absolute w-full mt-2 border border-gray-300 rounded-md shadow-lg z-10 bg-white max-h-96 overflow-scoll'>
                    <List
                        ref={listRef}
                        height={Math.min(300, apiData.length * 30)}
                        itemCount={filteredOptions?.length}
                        itemSize={40}
                        width='100%'
                        className='z-10'
                    >
                        {Row}
                    </List>
                </div>
            )}
            {loadingInput && (
                <div className='absolute w-full mt-2 border border-gray-300 rounded-md shadow-lg z-10 bg-white max-h-96 overflow-scoll pb-16'>
                    <LoadingSpinner />
                </div>
            )}
            {isErrorInputState && inputValue?.length && !showOptions ? (
                <p className='text-red-600 text-sm'>
                    Please select an item from the dropdown or remove the text from this field
                </p>
            ) : null}
        </div>
    );
}

export default SearchField;
