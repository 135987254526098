import React, { useState, useEffect } from 'react';
import TermsAndConditionsModal from './TermsAndConditionModal';
import config from '../../utils/config';
import { useAuth } from '../context/auth-context';
import logError from '../../utils/errorUtil';

export default function TermsAndConditionsBox() {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;

    const [open, setOpen] = useState(false);
    const [shippingText, setShippingText] = useState('');
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                id: 1,
            }),
        };

        fetch(`${ORIGIN}/cp/shipping-policy`, options)
            .then((response) => response.json())
            .then((response) => {
                setShippingText(response.shippingPolicy);
            })
            .catch((err) => logError(err, 'TermsAndConditionsBox'));
    }, []);

    return (
        <>
            {open && <TermsAndConditionsModal setOpen={setOpen} open={open} text={text} title={title} />}
            <div className='md:w-1/3 w-60 mx-auto m-4 bg-gray-100 px-8 py-2 text-blue-primary opacity-90 rounded flex flex-col md:flex-row justify-around shadow-md -mt-4 items-center md:items-start'>
                <div>
                    <a target={'_blank'} href='https://www.pac-air.com/terms'>
                        <h2 className='text-md font-semibold underline cursor-pointer hover:text-black'>
                            Terms and Conditions
                        </h2>
                    </a>
                </div>
                <div
                    onClick={() => {
                        setOpen(true);
                        setText(shippingText);
                        setTitle('Shipping Cut-Off Times');
                    }}
                >
                    <h2 className='text-md font-semibold underline cursor-pointer hover:text-black'>
                        Shipping Cut-Off Times
                    </h2>
                </div>
            </div>
        </>
    );
}
