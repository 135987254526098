import React, { useState, useEffect } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import TooltipIcon from '../../../components/TooltipIcon.js';
import { useAuth } from '../../context/wms-context.js';
import { getAllScanners } from '../../../utils/commonAPICalls.js';

export default function ScannerHeader({ getScannerDocs }) {
    const { currentUser, setCurrentUser } = useAuth();
    const [selectedScanner, setSelectedScanner] = useState(hasValidScanner() || {});
    const [allScanners, setAllScanners] = useState([]);

    function hasValidScanner() {
        if (currentUser?.selectedScanner) return currentUser?.selectedScanner;

        return JSON.parse(localStorage.getItem('selectedScanner'));
    }

    useEffect(() => {
        const fetchScanners = async () => {
            if (localStorage.getItem('selectedScanner' !== null)) {
                const savedScannerString = localStorage.getItem('selectedScanner');
                const savedScanner = savedScannerString && JSON.parse(savedScannerString);

                // Declare loading during scanner fetch
                setAllScanners([savedScanner, { name: '', displayName: 'LOADING SCANNERS' }]);
            } else {
                setAllScanners([{ name: '', displayName: 'LOADING SCANNERS' }]);
            }

            const scanners = await getAllScanners(currentUser, setCurrentUser);
            setAllScanners(scanners);
        };
        fetchScanners();
    }, []);


    useEffect(() => {
        getScannerDocs();
    }, [selectedScanner]);

    const handleScannerChange = (e) => {
        const selectedScanner = allScanners.find((scanner) => scanner.name === e.target.value);
        setSelectedScanner(selectedScanner);
        setCurrentUser((prev) => ({
            ...prev,
            selectedScanner,
        }));
        localStorage.setItem('selectedScanner', JSON.stringify(selectedScanner));
    };

    return (
        <div className='flex space-x-4 p-2 justify-end items-center'>
            <form className='flex flex-col'>
                <select
                    className='p-2 border rounded w-48 disabled:opacity-50'
                    value={selectedScanner?.name || ''}
                    onChange={handleScannerChange}
                >
                    <option value='' disabled>
                        Select a Scanner
                    </option>
                    {allScanners &&
                        allScanners.length > 0 &&

                        allScanners.map((scanner) => (
                            <option key={scanner.name} value={scanner.name}>
                                {scanner.displayName}
                            </option>
                        ))}
                </select>
            </form>

            <button onClick={() => getScannerDocs()} className='p-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm'>
                <TooltipIcon IconComponent={ArrowPathIcon} tooltipText='Refresh' onClick={() => console.log('Upload')} />
            </button>
        </div>
    );
}
