import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { documentKeys, overrideDefaults } from '../GenerateDocuments/Overrides/utils/data';

export const DocumentContext = createContext({
    updateDocumentDefault: () => {},
    documentDefaults: {},
    resetDocumentDefaults: () => {},
    hasPrintedPackingSheet: {},
    setHasPrintedPackingSheet: () => {},
    showDocumentGenerationModal: {},
    setShowDocumentGenerationModal: () => {},
    errorWithinForm: {},
    handleFormError: () => {},
    packingSheetLastPrinted: {},
    setPackingSheetLastPrinted: () => {},
});

export function DocumentContextWrapper({ children }) {
    const [documentDefaults, setDocumentDefaults] = useState(_.clone(overrideDefaults));
    const [hasPrintedPackingSheet, setHasPrintedPackingSheet] = useState(false);
    const [showDocumentGenerationModal, setShowDocumentGenerationModal] = useState(false);
    const [errorWithinForm, setErrorWithinForm] = useState(false);
    const [packingSheetLastPrinted, setPackingSheetLastPrinted] = useState(null);

    const updateDocumentDefaults = (formOverrides, selectedInventoryName) => {
        const defaults = _.clone(overrideDefaults);

        // Go through each document
        for (const documentName in defaults) {
            if (!(documentName in formOverrides)) continue;

            const updatedDocument = formOverrides[documentName].find((data) => data.inventoryName === selectedInventoryName);
            const defaultDocument = _.clone(defaults[documentName]);

            // Go through each key to check for updates
            for (const override in defaultDocument) {
                if (updatedDocument[override] !== defaultDocument[override]) {
                    defaultDocument[override] = updatedDocument[override];
                }
            }

            // Link both packing sheets together
            const isPackSheet =
                documentName === documentKeys.PACKING_SLIP_PRICED || documentName === documentKeys.PACKING_SLIP_UNPRICED;
            if (isPackSheet) {
                defaults[documentKeys.PACKING_SLIP_PRICED] = defaultDocument;
                defaults[documentKeys.PACKING_SLIP_UNPRICED] = defaultDocument;

                continue;
            }
            defaults[documentName] = _.clone(defaultDocument);
        }
        setDocumentDefaults(defaults);
    };

    const handleFormError = (errorState = false) => {
        if (errorState) {
            setErrorWithinForm(true);
            return;
        }

        setErrorWithinForm(false);
    };

    const resetDocumentDefaults = () => {
        setDocumentDefaults(_.clone(overrideDefaults));
        setHasPrintedPackingSheet(false);
        setPackingSheetLastPrinted(null);
    };

    const value = {
        updateDocumentDefaults,
        documentDefaults,
        resetDocumentDefaults,
        hasPrintedPackingSheet,
        setHasPrintedPackingSheet,
        showDocumentGenerationModal,
        setShowDocumentGenerationModal,
        errorWithinForm,
        handleFormError,
        packingSheetLastPrinted,
        setPackingSheetLastPrinted,
    };

    return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
}
