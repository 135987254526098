import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';

export default function MobileNavItem({ name, path }) {
    return (
        <Link to={path}>
            <Disclosure.Button
                as='a'
                className={classNames(
                    window.location.pathname === path
                        ? 'border-blue-secondary bg-blue-50 text-indigo-700'
                        : 'border-transparent text-gray-500',
                    'block border-l-4  py-2 pl-3 pr-4 text-base font-medium '
                )}
            >
                {name}
            </Disclosure.Button>
        </Link>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
