import React, { useEffect } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getWMSFields } from '../../../utils/commonAPICalls';
import { useAuth } from '../../context/wms-context';
import SFDynamicForm from '../sf-dynamic-form/Index.js';
import logError from '../../../utils/errorUtil';

export default function NewLineFormWrapper({ status, setStatus, formData, setFormData }) {
    const { currentUser } = useAuth();

    useEffect(() => {
        setStatus({ ...status, loading: true });
        let data = {
            manifest: {
                ...currentUser.selectedManifest,
            },
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            tableStructure: currentUser.tableStructures,
        };
        console.log('data', data);
        getWMSFields(data, 'get-manifest-line-fields', 'get-manifest-line-dropdowns')
            .then((data) => {
                setFormData(data);
                setStatus({ ...status, loading: false });
                console.log('data', data);
            })
            .catch((error) => {
                logError(error, 'NewLineFormWrapper');
                setStatus({ ...status, loading: false, error: true });
            });
    }, []);

    if (status.loading) return <LoadingSpinner />;
    return (
        <div className='grid grid-cols-1 gap-4 p-2'>
            <SFDynamicForm formData={formData} setFormData={setFormData} cols={1} />
        </div>
    );
}
