import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkPrivateRouteKey } from './commonAPICalls';

export default function PortalPrivateRoute({ children }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramsObject = {};
    queryParams.forEach((value, key) => {
        paramsObject[key] = value;
    });
    const [isValid, setIsValid] = useState(null); // Track validity of the query

    useEffect(() => {
        async function isValidQuery() {
            try {
                // This Private Route currently only accepts this particular token param
                const token = paramsObject?.fahPAIsfo;
                if (token) {
                    const response = await checkPrivateRouteKey(token);
                    setIsValid(response); // Set the state based on the response
                } else {
                    setIsValid(false);
                }
            } catch (error) {
                setIsValid(false);
            }
        }

        isValidQuery();
    }, [location.pathname]);

    // While checking the validity, you can return null or a loading indicator
    if (isValid === null) {
        return null; // Or you can return a loading spinner, etc.
    }

    return isValid === true ? children : <Navigate to='/NotFound' />;
}
