import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../wms/context/wms-context';
import fullLogo from '../../assets/images/simpleLogo.jpg';
import { Link } from 'react-router-dom';
import UptimeStatus from './uptimeStatus/UptimeStatus';
import NavItem from './NavItem';
import MobileNavItem from './MobileNavItem';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function WMSNav() {
    const { logout, currentUser } = useAuth();

    return (
        <Disclosure as='nav' className='bg-white shadow'>
            {({ open }) => (
                <>
                    {/* <div className="mx-auto  px-4 sm:px-6 lg:px-8 xl:ml- lg:ml-24 md:ml-12"> */}
                    <div className='mx-auto  px-4 sm:px-6 lg:px-8'>
                        <div className='flex h-16 justify-between'>
                            <div className='flex'>
                                <div className='flex flex-shrink-0 items-center w-16'>
                                    <Link to='/wms'>
                                        <img className='block h-8 w-auto lg:hidden' src={fullLogo} alt='Your Company' />
                                        <img className='hidden h-12 w-auto lg:block' src={fullLogo} alt='Your Company' />
                                    </Link>
                                </div>
                                <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                                    {currentUser.permissions.includes('Inventory') && (
                                        <NavItem name='Inventory' path='/wms' />
                                    )}
                                    {currentUser.permissions.includes('Locations') && (
                                        <NavItem name='Locations' path='/wms/locations' />
                                    )}
                                    {currentUser.permissions.includes('Receiving') && (
                                        <NavItem name='Receiving' path='/wms/receiving' />
                                    )}
                                    {currentUser.permissions.includes('Picking') && (
                                        <NavItem name='Picking' path='/wms/picking' />
                                    )}
                                    {currentUser.permissions.includes('Shipping') && (
                                        <NavItem name='Shipping' path='/wms/shipping' />
                                    )}
                                    {currentUser.permissions.includes('Packing') && (
                                        <NavItem name='Packing' path='/wms/packing' />
                                    )}
                                    {currentUser.permissions.includes('Location-Utilization') && (
                                        <NavItem name='Location Utilization' path='/wms/location-utilization' />
                                    )}
                                    {currentUser.permissions.includes('Work-Order') && (
                                        <NavItem name='AC Work Order' path='/wms/work-order' />
                                    )}
                                    {currentUser.permissions.includes('Trace') && <NavItem name='Trace' path='/wms/trace' />}
                                    {currentUser.permissions.includes('Trace2') && (
                                        <NavItem name='Trace2' path='/wms/trace2' />
                                    )}
                                    {currentUser.permissions.includes('Data-Scrape') && (
                                        <NavItem name='Data Scrape' path='/wms/data-scrape' />
                                    )}
                                    {currentUser.permissions.includes('Auth-Token') && (
                                        <NavItem name='Auth Token' path='/wms/auth-token' />
                                    )}
                                    {currentUser.permissions.includes('MFA-Key') && (
                                        <NavItem name='MFA Key' path='/wms/mfa-key' />
                                    )}
                                </div>
                            </div>
                            <div className='hidden sm:ml-6 sm:flex sm:items-center'>
                                <UptimeStatus />
                                <div>
                                    <p className='text-sm font-medium text-gray-700 pl-6'>
                                        Welcome, {currentUser.authentication.userName}
                                    </p>
                                </div>

                                <Menu as='div' className='relative ml-3'>
                                    <div>
                                        <Menu.Button className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 cursor-pointer pointer'>
                                            <span className='sr-only'>Open user menu</span>
                                            <div
                                                type='button'
                                                className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                            >
                                                <span className='sr-only'>View notifications</span>
                                                <Cog8ToothIcon className='h-6 w-6' aria-hidden='true' />
                                            </div>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-200'
                                        enterFrom='transform opacity-0 scale-95'
                                        enterTo='transform opacity-100 scale-100'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 scale-100'
                                        leaveTo='transform opacity-0 scale-95'
                                    >
                                        <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                            {currentUser.permissions.includes('admin-settings') && (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            to='/wms/admin-settings'
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Admin Page
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            )}
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        onClick={logout}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        Sign out
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className='-mr-2 flex items-center sm:hidden'>
                                {/* Mobile menu button */}
                                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-primary'>
                                    <span className='sr-only'>Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                                    ) : (
                                        <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className='sm:hidden'>
                        <div className='space-y-1 pt-2 pb-3'>
                            <MobileNavItem name='Inventory' path='/wms' />
                            <MobileNavItem name='Locations' path='/wms/locations' />
                            <MobileNavItem name='Receiving' path='/wms/receiving' />
                            <MobileNavItem name='Picking' path='/wms/picking' />
                            <MobileNavItem name='Shipping' path='/wms/shipping' />
                            <MobileNavItem name='Packing' path='/wms/packing' />
                            <MobileNavItem name='AC Work Order' path='/wms/work-order' />
                            <MobileNavItem name='Trace' path='/wms/trace' />
                            <MobileNavItem name='Trace2' path='/wms/trace2' />
                            <MobileNavItem name='Location Utilization' path='/wms/location-utilization' />
                            <MobileNavItem name='Auth Token' path='/wms/auth-token' />
                            <MobileNavItem name='MFA Key' path='/wms/mfa-key' />
                        </div>
                        <div className='border-t border-gray-200 pt-4 pb-3'>
                            <div className='mt-3 space-y-1'>
                                <Disclosure.Button
                                    as='a'
                                    href='#'
                                    onClick={logout}
                                    className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'
                                >
                                    Sign out
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
