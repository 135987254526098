import React, { useState, useEffect, useContext } from 'react';
import { serverStatusEnum } from '../components/uptimeStatus/data';
import { getServerHeartbeatStatus } from '../components/uptimeStatus/functions';

const WMSContext = React.createContext({
    isLoggedIn: false,
    id: '',
    onlineStatus: serverStatusEnum.ONLINE,
    login: () => {},
    logout: () => {},
    signUp: () => {},
    getUser: () => {},
    setOnlineStatus: () => {},
});

export function useAuth() {
    return useContext(WMSContext);
}

export const WMSContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [onlineStatus, setOnlineStatus] = useState(serverStatusEnum.ONLINE);

    function logout() {
        setCurrentUser(false);
        localStorage.clear();
        return;
    }

    function login(data) {
        try {
            console.log('data--------------------------------------', data);
            setCurrentUser({ ...data });

            const now = new Date();
            const expirationDate = new Date(now.getTime() + 10 * 60 * 60 * 1000);
            localStorage.setItem('expirationDate', expirationDate);

            let { authentication, permissions } = data;

            localStorage.setItem('userId', authentication.userId);
            localStorage.setItem('userEmail', authentication.userEmail);
            localStorage.setItem('userName', authentication.userName);
            localStorage.setItem('company', authentication.userCompany);
            // Only set priority if it exists
            authentication.userPriority && localStorage.setItem('priority', authentication.userPriority);

            localStorage.setItem('locations', JSON.stringify(data.locations.locations));
            localStorage.setItem('warehouses', JSON.stringify(data.locations.warehouses));

            localStorage.setItem('permissions', JSON.stringify(permissions));

            return;
        } catch (error) {}
    }

    // Sets the global server status
    async function getServerStatus() {
        try {
            const serverStatus = await getServerHeartbeatStatus();
            setOnlineStatus(serverStatus);
        } catch (error) {
            setOnlineStatus(serverStatusEnum.OFFLINE);
        }
    }

    function signUp(email, password) {
        return;
    }

    function forgotPassword(email) {
        return;
    }

    // On mount function to get server status
    useEffect(() => {
        getServerStatus();
    }, [currentUser, onlineStatus]);

    useEffect(() => {
        setLoading(true);

        const storedExperationDate = new Date(localStorage.getItem('expirationDate'));

        const storedId = localStorage.getItem('userId');
        const storedEmail = localStorage.getItem('userEmail');
        const storedName = localStorage.getItem('userName');
        const storedLocations = localStorage.getItem('locations');
        const storedWarehouses = localStorage.getItem('warehouses');
        const storedCompany = localStorage.getItem('company');

        const storedPermissions = localStorage.getItem('permissions');

        const now = new Date();
        if (storedId && storedExperationDate >= now) {
            setCurrentUser({
                authentication: {
                    userId: storedId,
                    userEmail: storedEmail,
                    userName: storedName,
                    userCompany: storedCompany,
                },
                locations: {
                    locations: JSON.parse(storedLocations),
                    warehouses: JSON.parse(storedWarehouses),
                },
                permissions: JSON.parse(storedPermissions),
            });
        }
        setLoading(false);
    }, []);

    const contextValue = {
        currentUser,
        onlineStatus,
        setCurrentUser,
        forgotPassword,
        login: login,
        logout: logout,
        signUp: signUp,
        setOnlineStatus,
    };

    return <WMSContext.Provider value={contextValue}>{!loading && props.children}</WMSContext.Provider>;
};

export default WMSContext;
