export const calculateDarFee = async (parts, darFee) => {
    let darFeeTotal = 0;
    let partsThatHaveDarFee = [];

    parts.forEach((part) => {
        if (part.isDar && part.selected) {
            if (partsThatHaveDarFee.includes(part.partNo)) return;
            darFeeTotal += darFee;
            partsThatHaveDarFee.push(part.partNo);
        }
    });

    return darFeeTotal;
};

export const calculateMinOrderFee = async (parts, minOrderFee, aogMinOrderFee, isAog) => {
    let orderFee = 0;
    let partsTotal = 0;

    parts.forEach((part) => {
        if (part.selected) {
            partsTotal += part.unitPrice * part.quantityRequested;
        }
    });

    if (partsTotal < minOrderFee && !isAog) {
        orderFee = minOrderFee;
    }

    if (partsTotal < aogMinOrderFee && isAog) {
        orderFee = aogMinOrderFee;
    }

    if (orderFee < 0) orderFee = 0; // Prevent negative values (shouldn't happen, but just in case)

    return orderFee;
};

export const calculateSubtotal = async (parts, orderDetails) => {
    let subtotal = 0;
    parts.forEach((part) => {
        if (part.selected) {
            subtotal += part.unitPrice * part.quantityRequested;
        }
    });

    if (orderDetails.isAOG) {
        if (orderDetails.minAogOrder > subtotal) {
            subtotal = orderDetails.minAogOrder;
        }
        subtotal += orderDetails.AOGFee;
    } else {
        if (orderDetails.minOrder > subtotal) {
            subtotal = orderDetails.minOrder;
        }
    }

    return subtotal;
};

export const calculatePartsTotal = async (parts) => {
    let partsTotal = 0;

    parts.forEach((part) => {
        if (part.selected) {
            partsTotal += part.unitPrice * part.quantityRequested;
        }
    });

    return partsTotal;
};

export const calculateAogFee = async (parts, orderDetails) => {
    let aogFee = 0;

    if (!orderDetails.isAOG) return 0;

    aogFee = orderDetails.AOGFee;
    return aogFee;
};

export const calculateOrderTotal = async (parts, orderDetails) => {
    let orderTotal = 0;

    const darFee = await calculateDarFee(parts, orderDetails.DARFee);
    const minOrderFee = await calculateMinOrderFee(
        parts,
        orderDetails.minOrder,
        orderDetails.minAogOrder,
        orderDetails.isAOG
    );
    const partsTotal = await calculatePartsTotal(parts);
    const aogFee = await calculateAogFee(parts, orderDetails);

    if (partsTotal < minOrderFee) {
        orderTotal = darFee + minOrderFee + aogFee;
    } else {
        orderTotal = darFee + partsTotal + aogFee;
    }

    return orderTotal;
};
