export const PartDescription = ({ part }) => {
    const { Name, inscor__Keyword__c: keyword, inscor__UOM__c } = part;
    return (
        <>
            <div>
                <strong>Part Name</strong>
                <p>{Name}</p>
            </div>
            <div>
                <strong>Description</strong>
                <p>{keyword}</p>
            </div>
            <div>
                <strong>Unit of Measurement</strong>
                <p>{inscor__UOM__c}</p>
            </div>
        </>
    );
};
