// React
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Public Routes
import NotFound from './utils/NotFound';
import DocumentUpload from './modules/DocumentUpload';
import PhotoUpload from './modules/PhotoUpload';
import ScannerUploadWebsite from './modules/ScannerUpload';
import StripePayment from './modules/StripePayment';
import CSLComplianceVerification from './modules/CSLComplianceVerification';

// Private Routes
import PrivateRoute from './utils/PrivateRoute';
import CmmIpcDocument from './modules/CmmIpcDocument';

// Portal Routes
import PortalPrivateRoute from './utils/PortalPrivateRoute';
import NavBar from './portal/components/NavBar';
import CheckoutPage from './portal/pages/CheckoutPage';
import {
    Dashboard,
    QuotesPage,
    LoginPage,
    OrderPage,
    SignUpPage,
    ForgotPasswordPage,
    SettingsPage,
    OrderDetails,
    RFQDetailsPage,
} from './portal/pages';
import GlobalSearch from './portal/pages/GlobalSearch';

// WMS Routes
import WMSPrivateRoute from './utils/WMSPrivateRoute';
import WMSNav from './wms/components/WMSNav';
import WMS_LOGIN from './wms/pages/WMSLOGIN';
import WMS_Inventory from './wms/pages/WMSInventory';
import WMS_Location from './wms/pages/WMSLocation';
import WMS_Receiving from './wms/pages/WMSReceiving';
import WMS_Print from './wms/pages/WMSPrint';
import WMS_Picking from './wms/pages/WMSPicking';
import WMS_Shipping from './wms/pages/WMSShipping';
import WMS_Packing from './wms/pages/WMSPacking';
import WMSResetPassword from './wms/pages/WMSResetPassword';
import WMS_ADMIN_SETTINGS from './wms/pages/WMSAdminSettings';
import WMS_LocationUtilization from './wms/pages/WMSLocationUtilization';
import WMSEmployeePermissions from './wms/pages/WMSEmployeePermissions';
import WMS_AuthToken from './wms/pages/WMSAuthToken';
import MFAKey from './wms/pages/WMS_MFAKey';
import WMS_DataScrape from './wms/pages/WMSDataScrape';
import WMS_WorkOrder from './wms/pages/WMSWorkOrder';
import WMS_Trace from './wms/pages/WMS_Trace';
import WMS_GuidedInterface from './wms/pages/WMS_GuidedInterface';
import { GuidedInterfaceContextWrapper } from './wms/components/guidedInterface/context/GuidedInterfaceContext';
import { FormContextWrapper } from './wms/components/guidedInterface/context/FormContextWrapper';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Public Routes */}
                <Route exact path='/stripe' element={<StripePayment />}></Route>
                <Route exact path='/receiving/photo-upload' element={<PhotoUpload />}></Route>
                <Route exact path='/csl-compliance-update' element={<CSLComplianceVerification />}></Route>
                <Route exact path='/sales/end-user-upload' element={<DocumentUpload />}></Route>
                <Route exact path='/receiving/scanner-upload' element={<ScannerUploadWebsite />}></Route>

                {/* Private Routes */}
                <Route
                    path='/documents/cmmipc'
                    element={
                        <PrivateRoute path='/'>
                            <CmmIpcDocument />
                        </PrivateRoute>
                    }
                />

                {/* Portal Routes */}
                <Route exact path='/login' element={<LoginPage />}></Route>
                <Route exact path='/sign-up' element={<SignUpPage />}></Route>
                <Route exact path='/forgot-password' element={<ForgotPasswordPage />}></Route>
                <Route
                    path='/'
                    element={
                        <PortalPrivateRoute path='/'>
                            <NavBar />
                            <Dashboard />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/orders'
                    element={
                        <PortalPrivateRoute path='/'>
                            <NavBar />
                            <OrderPage />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/orders/:id'
                    element={
                        <PortalPrivateRoute path='/'>
                            <OrderDetails />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/settings'
                    element={
                        <PortalPrivateRoute path='/'>
                            <NavBar />
                            <SettingsPage />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/quotes'
                    element={
                        <PortalPrivateRoute path='/'>
                            <NavBar />
                            <QuotesPage />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/quotes/:id'
                    element={
                        <PortalPrivateRoute path='/'>
                            <RFQDetailsPage />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/checkout'
                    element={
                        <PortalPrivateRoute path='/'>
                            <NavBar />
                            <CheckoutPage />
                        </PortalPrivateRoute>
                    }
                />
                <Route
                    path='/global-search'
                    element={
                        <PortalPrivateRoute path='/'>
                            <NavBar />
                            <GlobalSearch />
                        </PortalPrivateRoute>
                    }
                />

                {/* // WMS Routes // */}
                <Route exact path='/wms/login' element={<WMS_LOGIN />}></Route>
                <Route exact path='/wms/reset-password' element={<WMSResetPassword />}></Route>
                <Route exact path='/wms/employee-permissions' element={<WMSEmployeePermissions />}></Route>

                <Route
                    exact
                    path='/wms/receiving/print'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMS_Print />
                        </WMSPrivateRoute>
                    }
                ></Route>

                <Route
                    exact
                    path='/wms'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Inventory />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/locations'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Location />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/receiving'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Receiving />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/picking'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Picking />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/shipping'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Shipping />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/packing'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Packing />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/location-utilization'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_LocationUtilization />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/work-order'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_WorkOrder />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/trace'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_Trace />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/trace2'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <GuidedInterfaceContextWrapper>
                                <FormContextWrapper>
                                    <WMS_GuidedInterface />
                                </FormContextWrapper>
                            </GuidedInterfaceContextWrapper>
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/data-scrape'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_DataScrape />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/auth-token'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_AuthToken />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/mfa-key'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <MFAKey />
                        </WMSPrivateRoute>
                    }
                ></Route>
                <Route
                    exact
                    path='/wms/admin-settings'
                    element={
                        <WMSPrivateRoute path='/'>
                            <WMSNav />
                            <WMS_ADMIN_SETTINGS />
                        </WMSPrivateRoute>
                    }
                ></Route>

                {/* Unknown Route */}
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}
