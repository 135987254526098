import React, { useState, useEffect } from 'react';
import InventoryControl from '../components/InventoryControl';
import InventoryTable from '../components/InventoryTable';
import { useAuth } from '../../wms/context/wms-context';

export default function WMS_Inventory() {
    const { currentUser, setCurrentUser } = useAuth();
    const [inventoryItems, setInventoryItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (currentUser.activeInventory) setInventoryItems(currentUser.activeInventory);
    }, []);

    useEffect(() => {
        // set the loading state to the currentUser
        setCurrentUser((prevState) => ({
            ...prevState,
            activeInventory: inventoryItems,
        }));
    }, [inventoryItems]);

    return (
        <div>
            <InventoryControl
                inventoryItems={inventoryItems}
                setInventoryItems={setInventoryItems}
                loading={loading}
                setLoading={setLoading}
                error={error}
                setError={setError}
            />
            <InventoryTable
                inventoryItems={inventoryItems}
                setInventoryItems={setInventoryItems}
                loading={loading}
                setLoading={setLoading}
                setError={setError}
            />
        </div>
    );
}
