import React from 'react';
import { BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/24/outline';

export default function AvailableInventorySortButton({ arrowDirections, setArrowDirections, name, sortData }) {
    return (
        <div
            onClick={() => {
                const newDirection = arrowDirections[name].direction === 'asc' ? 'desc' : 'asc';

                setArrowDirections({
                    ...Object.keys(arrowDirections).reduce((acc, key) => {
                        acc[key] = { direction: null };
                        return acc;
                    }, {}),
                    [name]: { direction: newDirection },
                });

                // Instead of fetching data, sort the already available data
                sortData(name, newDirection);
            }}
        >
            {arrowDirections[name].direction === 'desc' ? (
                <BarsArrowUpIcon className='h-5 w-5 inline-block text-white hover:text-gray-400 cursor-pointer' />
            ) : (
                <BarsArrowDownIcon
                    className={classNames(
                        arrowDirections[name].direction !== 'asc' ? 'text-gray-400' : 'text-white',
                        'h-5 w-5 inline-block hover:text-gray-400 cursor-pointer '
                    )}
                />
            )}
        </div>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
