export const serverLifeLine = 'Ping';

export const serverStatusEnum = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
};

export const serverPingTimingEnum = {
    HEALTHY_INTERVAL: 10000,
    UNHEALTHY_INTERVAL: 1000,
}