export const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    const ampm = hours >= 12 ? ' PM' : ' AM';
    const hour = hours % 12;
    const hourString = hour ? hour : 12;
    const minuteString = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hourString + ':' + minuteString + ampm;

    return year + '-' + month + '-' + day + ',' + ' ' + strTime;
};
