import React, { useState } from 'react';

export const QuoteResult = ({ quote, setSelectedObject, selectedObject, searchInput }) => {
    const [selected, setSelected] = useState(false);

    const queryQuoteLink = `/global-search?query=${searchInput}&modal=${quote.quoteNumber}&type=Quote`;

    return (
        <a
            href={queryQuoteLink}
            className={
                'w-full py-2 no-underline text-gray-600 text-center hover:cursor-pointer hover:bg-gray-300 ' +
                (selected && selectedObject.quoteNumber && selectedObject.quoteNumber === quote.quoteNumber && 'bg-gray-300')
            }
            onMouseEnter={() => {
                setSelectedObject(quote);
                setSelected(true);
            }}
            style={{
                all: 'revert',
            }}
        >
            {quote.quoteNumber}
        </a>
    );
};
