import React, { useState } from 'react';
import LoadingButtonFixedWidth from '../../components/LoadingButtonFixedWidth';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';

export default function BulkSearch({
    data,
    setData,
    onSubmit,
    isLoading,
    setNoPartsFound,
    buttonDisabled,
    partNumberLimit,
}) {
    const [isShowing, setIsShowing] = useState(false);
    const formattedPartNumberLimit = partNumberLimit.toLocaleString();

    return (
        <div>
            <h2 className='text-3xl font-semibold py-2 mb-6 px-12 text-center text-white bg-blue-primary'>Parts Search</h2>
            <div className='relative'>
                <p className='pl-4 text-gray-600 text-center'>
                    Enter a list of part numbers on new lines.{' '}
                    <QuestionMarkCircleIcon
                        onMouseEnter={() => setIsShowing(true)}
                        onMouseLeave={() => setIsShowing(false)}
                        className='h-5 w-5 inline-block'
                    />
                </p>
                {isShowing && (
                    <Transition
                        appear={true}
                        show={isShowing}
                        enter='transition-opacity duration-200'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity duration-150'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='absolute right-0 md:-top-30 md:right-10 bg-blue-primary p-4 w-36 text-lg'>
                            <p className='pl-4  text-white'>
                                Example:
                                <br />
                                12345
                                <br />
                                GQ756-2
                                <br />
                                G700-2
                            </p>
                        </div>
                    </Transition>
                )}
            </div>
            <div className='px-12 py-4'>
                <textarea
                    onChange={(e) => {
                        setData(e.target.value);
                        setNoPartsFound(false);
                    }}
                    style={{ resize: 'both' }}
                    className='w-full px-3 py-2 text-base text-gray-700 placeholder-gray-400 border rounded-lg focus:shadow-outline resize-y focus:outline-gray-200 '
                    placeholder={`Enter up to ${formattedPartNumberLimit} part numbers here`}
                    rows={12}
                    value={data}
                ></textarea>
            </div>
            {!isLoading ? (
                <button
                    className='bg-blue-primary hover:bg-blue-primary text-white font-bold py-2 px-4 rounded mx-auto cursor-pointer w-1/2'
                    onClick={() => {
                        onSubmit();
                    }}
                    disabled={data.length === 0 || buttonDisabled}
                >
                    Find Parts
                </button>
            ) : (
                <LoadingButtonFixedWidth />
            )}
        </div>
    );
}
