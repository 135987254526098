import React, { useState } from 'react';
import { useAuth } from '../../../../portal/context/auth-context';
import config from '../../../../utils/config';
import PersonalInformation from './PersonalInformation';
import CompanyInfo from './CompanyInfo';
import SubmitButton from './SubmitButton';
import LoadingSpinner from '../../../../components/LoadingSpinner';

export default function ContactInformation({ userAccountInfo, setUserAccountInfo, setNotificationStatus }) {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;

    const [isLoading, setIsLoading] = useState(false);
    const [inputError, setInputError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const dataToSend = {
            userAccountInfo,
            userId: currentUser.Id,
        };

        const { contactData } = userAccountInfo;

        // Array returns [object, Name, Title, Email, Phone]
        const contactDataArray = Object.values(contactData).filter((val) => val);

        // Check for empty or null values
        if (contactDataArray.includes('') || contactDataArray.length < 5) {
            setNotificationStatus({
                status: 'fail',
                title: 'Failed to save',
                desc: 'Please fill out all required fields.',
                shown: true,
            });
            setIsLoading(false);
            setInputError(true);
            return;
        }

        const response = await fetch(`${ORIGIN}/cp/update-contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        });
        const data = await response.json();

        if (data.message) {
            setNotificationStatus({
                status: 'fail',
                title: 'Failed to save',
                desc: 'There was an error saving your data. Please try again.',
                shown: true,
            });
        }

        if (data.result === 'success') {
            setNotificationStatus({
                status: 'success',
                title: 'Saved',
                desc: 'Your account has been successfully updated',
                shown: true,
            });
        }

        //check for 500 error
        if (data.error) {
            setNotificationStatus({
                status: 'fail',
                title: 'Failed to save',
                desc: 'There was an error saving your data. Please try again.',
                shown: true,
            });
        }

        setIsLoading(false);
    };

    if (!userAccountInfo || !userAccountInfo.contactData) {
        return (
            <div className='pb-24'>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Contact Information</h2>
                <LoadingSpinner />
            </div>
        );
    }

    console.log(userAccountInfo);
    return (
        <>
            <h2 className='text-2xl leading-6 font-medium text-gray-900'>Contact Information</h2>
            <div className='space-y-6 sm:space-y-5 sm:pt-6 w-4/6 p-4'>
                <div className='space-y-6 sm:space-y-4'>
                    <PersonalInformation
                        userAccountInfo={userAccountInfo}
                        setUserAccountInfo={setUserAccountInfo}
                        inputError={inputError}
                    />
                    <CompanyInfo
                        userAccountInfo={userAccountInfo}
                        setUserAccountInfo={setUserAccountInfo}
                        inputError={inputError}
                    />
                    <SubmitButton handleSubmit={handleSubmit} isLoading={isLoading} />
                </div>
            </div>
        </>
    );
}
