import { FixedSizeList as List } from 'react-window';

function RenderSuggestions({ suggestions, highlightedIndex, onClick }) {
    const ITEM_SIZE = 35; // This is the height of each row (adjust based on your styling)

    const Row = ({ index, style }) => {
        const suggestion = suggestions[index];
        return (
            <div
                key={index}
                style={style}
                onClick={() => onClick(suggestion)}
                className={`cursor-pointer p-2 ${index === highlightedIndex ? 'bg-gray-300' : 'hover:bg-gray-200'}`}
            >
                {suggestion.Name}
            </div>
        );
    };

    return (
        <div className='absolute bg-white border rounded w-48 mt-20'>
            <List
                height={300} // Adjust based on how tall you want your container to be
                itemCount={suggestions.length}
                itemSize={ITEM_SIZE}
                width='100%'
            >
                {Row}
            </List>
        </div>
    );
}

export default RenderSuggestions;
