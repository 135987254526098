import React from 'react';
import { pdfLoadingState } from '../utils/data';
import { DocumentIcon, LinkIcon } from '@heroicons/react/24/outline';
import { openPdf } from '../utils/functions';

export default function PdfSection({ inventoryLine, updateSelectedPdfs }) {
    return (
        <>
            <h1 className='text-lg mb-3'>Documents</h1>
            <article className='flex flex-col gap-y-3'>
                {inventoryLine.pdfs?.map((pdfData, index) => {
                    if (pdfData.status === pdfLoadingState.NO_DATA || !pdfData.link) {
                        return (
                            <li className='w-full flex flex-row gap-2'>
                                <h1 className='whitespace-nowrap'>No {pdfData.name} URL Found</h1>
                            </li>
                        );
                    }

                    if (pdfData.status === pdfLoadingState.LOADING) {
                        return (
                            <li className='w-full flex flex-row gap-2'>
                                <input defaultChecked type='checkbox' disabled />
                                <h1 className='whitespace-nowrap'>{pdfData.name}</h1>
                                <div className='w-5 h-5 border-b-[2px] border-e-[2px] border-gray-500 rounded-full animate-spin out' />
                            </li>
                        );
                    }

                    if (pdfData.status === pdfLoadingState.LOADING_FAILED && pdfData.link) {
                        return (
                            <li key={index} className='w-full flex flex-row items-center'>
                                <input
                                    defaultChecked
                                    className='hover:cursor-pointer'
                                    type='checkbox'
                                    onClick={(e) => {
                                        updateSelectedPdfs(e.target.checked, pdfData);
                                    }}
                                />
                                <a
                                    href={pdfData.link}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='group flex flex-row items-center hover:cursor-pointer ml-2'
                                >
                                    <h1 className='group-hover:text-blue-secondary whitespace-nowrap duration-100'>
                                        {pdfData.name}
                                    </h1>
                                    <LinkIcon className='h-4 w-4 group-hover:text-blue-secondary duration-100 ml-1' />
                                </a>
                            </li>
                        );
                    }

                    return (
                        <li key={index} className='w-full flex flex-row gap-2'>
                            <input
                                defaultChecked
                                className='hover:cursor-pointer'
                                type='checkbox'
                                onClick={(e) => {
                                    updateSelectedPdfs(e.target.checked, pdfData);
                                }}
                            />
                            <h1 className='whitespace-nowrap'>{pdfData.name}</h1>
                            <div
                                className='hover:cursor-pointer'
                                onClick={() => {
                                    openPdf(pdfData.data);
                                }}
                            >
                                <DocumentIcon className='w-5 h-5' />
                            </div>
                        </li>
                    );
                })}
            </article>
            <article className='w-1/2 flex justify-end items-end'></article>
        </>
    );
}
