import React, { useContext, useState } from 'react';
import _ from 'lodash';
import InventoryLineItem from './InventoryLineItem/InventoryLineItem';
import CameraContainer from './CameraContainer';
import { TraceContext } from './Context/TraceContextWrapper';
import { useAuth } from '../../context/wms-context';
import { LinkIcon } from '@heroicons/react/24/outline';
import Notification from '../../../components/Notification';
import SubmissionBoard from './SubmissionBoard';
import { lineLoadingState, stepStatus, uploadStep } from './utils/data';
import { addImagesToInventoryLines, formatReleaseComments, updateHeroImages } from './utils/functions';
import { printAllPickingLabels, sendChatterMessage, submitReleaseData } from './utils/fetchRequests';
import EnlargedPhoto from './EnlargedPhoto';
import ConfirmRejectPopup from './ConfirmRejectPopup';
import ChatterModalWrapper from './ChatterModalWrapper/ChatterModalWrapper';
import GenerateDocumentsModal from './GenerateDocuments/GenerateDocumentsModal';
import { DocumentContext } from './Context/DocumentContextWrapper';

export default function MainContainer({
    invLines,
    releaseId,
    releaseLink,
    releaseName,
    releaseComments,
    releaseOrderData,
    resetReleaseData,
    documentParams,
}) {
    const {
        photosAdded,
        photosChanged,
        notificationState,
        updateNotificationState,
        updateSubmissionStatus,
        updatePhotoStatusesForSubmission,
        resetContextVariables,
        chatterModalState,
        removeSuccessFullyChangedPhotos,
    } = useContext(TraceContext);
    const { resetDocumentDefaults, showDocumentGenerationModal } = useContext(DocumentContext);
    const { currentUser } = useAuth();
    const authData = {
        userId: currentUser.authentication.userId,
        userEmail: currentUser.authentication.userEmail,
        userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
    };

    const [inventoryLines, setInventoryLines] = useState(invLines);
    const [selectedInventoryName, setSelectedInventoryName] = useState(null);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingPickingLabels, setLoadingPickingLabels] = useState(false);
    const [enlargedPhoto, setEnlargedPhoto] = useState({
        photo: null,
        print: false,
    });

    const hasHeroImages = inventoryLines.some((inventory) => inventory?.photos?.some((photo) => photo?.isHero));

    const commentData = formatReleaseComments(releaseComments);

    // Updates specified inventory line with the passed through state
    const updateInventoryLine = (name, updatedState) => {
        const loadingState = inventoryLines.map((inventoryData) => {
            if (inventoryData.name === name) return { ...updatedState };

            return inventoryData;
        });
        setInventoryLines(loadingState);
    };

    // Handles the logic for being able to click and show inventory lines
    const manageShownInventoryLines = (name) => {
        const currentlyLoading = inventoryLines.some(
            (inventoryData) => inventoryData.documentsAndPhotosLoaded === lineLoadingState.LOADING
        );
        const shownState = inventoryLines.map((inventoryData, index) => {
            const { shown, documentsAndPhotosLoaded } = inventoryData;
            const isLoadingAndShown = shown && documentsAndPhotosLoaded === lineLoadingState.LOADING;

            if (currentlyLoading) return { ...inventoryData };

            if (inventoryData.name === name) {
                if (isLoadingAndShown) return { ...inventoryData, shown: true };

                if (!shown === true) {
                    setSelectedInventoryName(inventoryData.name);
                }

                updateNotificationState({ ...notificationState, shown: false });

                return { ...inventoryData, shown: !shown };
            }

            return { ...inventoryData, shown: false };
        });

        setInventoryLines(shownState);

        if (!shownState.some((inventoryData) => inventoryData.shown)) setSelectedInventoryName(null);
    };

    // Adds photos to currently selected inventory line
    const handlePhotoSubmission = (photoData) => {
        const updatedinventoryLines = inventoryLines.map((inventoryData) => {
            if (inventoryData.name == selectedInventoryName) {
                return {
                    ...inventoryData,
                    photos: [...inventoryData.photos, ...photoData],
                };
            }

            return inventoryData;
        });

        setInventoryLines(updatedinventoryLines);
    };

    // Functionality ran when clicking a photo inside an inventory line
    const handlePhotoEnlarge = (photoUri, print = false) => {
        setEnlargedPhoto({
            photo: photoUri,
            print: print,
        });
    };

    const handlePrintAllPickingLabels = async () => {
        try {
            setLoadingPickingLabels(true);
            await printAllPickingLabels(currentUser, releaseName);
        } catch (error) {
            updateNotificationState({
                error: true,
                shown: true,
                title: 'Error Loading Picking Labels',
                message: `There was an error loading the picking labels for ${releaseName}. Please try again and if this issue persists contact your manager.`,
            });
        } finally {
            setLoadingPickingLabels(false);
        }
    };

    // Updates the release header status as the first step
    const releaseHeaderuploadStep = async () => {
        const isAccepted = inventoryLines.some((data) => !data.isAccepted) ? false : true;

        const releaseUpdate = await submitReleaseData(releaseId, isAccepted, authData);

        if (!releaseUpdate?.success) {
            updateSubmissionStatus(uploadStep.UPDATING_RELEASE, stepStatus.FAILURE);
            throw Error(releaseUpdate?.message ?? 'Unable to update release header status');
        }
        updateSubmissionStatus(uploadStep.UPDATING_RELEASE, stepStatus.SUCCESS);
    };

    // Handle additional hero images versus persisted hero images
    const updatePhotoStatus = (status) => {
        const isPersistingOnly = !photosChanged?.length;

        // Handling situation where both statuses share the same function
        if (!isPersistingOnly) updateSubmissionStatus(uploadStep.UPDATING_HERO_IMAGES, status); // Adding new hero images
        if (isPersistingOnly) updateSubmissionStatus(uploadStep.PERSISTING_HERO_IMAGES, status); // No new hero images just persisting the old ones
    };

    // Updates the inventory line with the updated hero images
    const heroImageuploadStep = async () => {
        const isAccepted = inventoryLines.some((data) => !data.isAccepted) ? false : true;

        let photoUpdateErrors = [];

        const updatedPhotosToUpload = await updateHeroImages(
            authData,
            inventoryLines,
            photoUpdateErrors,
            photosChanged,
            updatePhotoStatusesForSubmission,
            isAccepted,
            releaseId
        );

        if (photoUpdateErrors.length) {
            updatePhotoStatus(stepStatus.FAILURE);
            removeSuccessFullyChangedPhotos();
            throw Error(photoUpdateErrors.join('| '));
        }

        updatePhotoStatus(stepStatus.SUCCESS);
        return updatedPhotosToUpload;
    };

    // Updates the inventory line with any newly added photos
    const newPhotouploadStep = async () => {
        const isAccepted = inventoryLines.some((data) => !data.isAccepted) ? false : true;

        let photoAdditionErrors = [];

        await addImagesToInventoryLines(
            authData,
            inventoryLines,
            photoAdditionErrors,
            updatePhotoStatusesForSubmission,
            photosAdded,
            isAccepted,
            releaseId
        );

        if (photoAdditionErrors.length) {
            updateSubmissionStatus(uploadStep.ADDING_NEW_PHOTOS, stepStatus.FAILURE);
            throw Error(photoAdditionErrors.join('| '));
        }
        updateSubmissionStatus(uploadStep.ADDING_NEW_PHOTOS, stepStatus.SUCCESS);
    };

    const addMessagesToChatterStep = async () => {
        const chatterMessageData = chatterModalState.messageData;

        for (const chatterMessage of chatterMessageData) {
            const response = await sendChatterMessage(currentUser, { messageData: chatterMessage });

            if (!response?.success) {
                updateSubmissionStatus(uploadStep.ADDING_CHATTER_MESSAGES, stepStatus.FAILURE);
                throw Error('Failure to add message to Chatter');
            }
        }

        updateSubmissionStatus(uploadStep.ADDING_CHATTER_MESSAGES, stepStatus.SUCCESS);
    };

    // Refreshes all stored state variables and takes the user back to the input page
    const resetStoredVariables = () => {
        setSelectedInventoryName(null);
        resetContextVariables();
        resetReleaseData();
        resetDocumentDefaults();
    };

    return (
        <section className='flex flex-col w-full px-10 pb-8 pt-4'>
            <header className='relative flex w-full items-center justify-center pt-4 pb-6 text-2xl text-center'>
                <button
                    className='absolute top-0 left-0 cursor-pointer w-fit rounded-lg border border-transparent bg-gray-300 hover:bg-gray-400 text-black px-3 py-1'
                    onClick={() => resetStoredVariables()}
                >
                    Close
                </button>
                <button
                    disabled={loadingPickingLabels}
                    className='absolute top-0 right-0 flex items-center justify-center h-[42px] cursor-pointer min-w-[238px] w-fit rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                    onClick={() => handlePrintAllPickingLabels()}
                >
                    {loadingPickingLabels ? (
                        <div className='w-5 h-5 border-b-[2px] border-e-[2px] border-gray-200 rounded-full animate-spin out' />
                    ) : (
                        'Print Picking Labels'
                    )}
                </button>
                <a
                    href={releaseLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-fit flex flex-row items-center justify-center group text-2xl hover:text-blue-secondary duration-100 hover:cursor-pointer'
                    onClick={(e) => e.stopPropagation()}
                >
                    {releaseName.toUpperCase()}
                    <LinkIcon className='h-5 w-5 ml-1 group-hover:text-blue-secondary duration-100' />
                </a>
            </header>
            <section className='w-full flex flex-row h-full'>
                <section className='w-[70%] flex flex-col items-center justify-start pr-10'>
                    <article className='w-full flex flex-col gap-5 mb-6'>
                        {commentData.map((data) => {
                            return (
                                <div className='w-full flex flex-col gap-1'>
                                    <h1 className='whitespace-nowrap w-full border-b border-gray-200 pb-1 text-[14px]'>
                                        {data.title}
                                    </h1>
                                    <h1 className='w-full border-gray-200 pb-1 text-[14px]'>{data.value}</h1>
                                </div>
                            );
                        })}
                    </article>
                    <article className='w-full'>
                        {inventoryLines.map((inventoryLine, i) => {
                            return (
                                <InventoryLineItem
                                    key={i}
                                    updateInventoryLine={updateInventoryLine}
                                    manageShownInventoryLines={manageShownInventoryLines}
                                    inventoryLine={inventoryLine}
                                    handlePhotoEnlarge={handlePhotoEnlarge}
                                    selectedInventoryName={selectedInventoryName}
                                />
                            );
                        })}
                    </article>
                </section>
                <CameraContainer
                    selectedInventoryName={selectedInventoryName}
                    isLoading={inventoryLines.some(
                        (inventoryData) => inventoryData.documentsAndPhotosLoaded === lineLoadingState.LOADING
                    )}
                    handlePhotoSubmission={handlePhotoSubmission}
                    handlePhotoEnlarge={handlePhotoEnlarge}
                />
                <EnlargedPhoto enlargedPhoto={enlargedPhoto} handlePhotoEnlarge={handlePhotoEnlarge} />
            </section>
            <footer className='w-full flex flex-col items-center justify-center mt-5 gap-4'>
                <button
                    type='button'
                    disabled={
                        !inventoryLines.some((inventory) => inventory.isAccepted !== undefined && !inventory.isAccepted) &&
                        !inventoryLines.every((inventory) => inventory.isAccepted)
                    }
                    className='cursor-pointer w-fit rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                    onClick={() => setSubmitModalOpen(true)}
                >
                    Submit
                </button>
            </footer>
            <Notification
                desc={notificationState.message}
                title={notificationState.title}
                status={notificationState.error ? 'failure' : 'success'}
                showSavedNotification={notificationState.shown ?? false}
                setShowSavedNotification={() => updateNotificationState({ ...notificationState, shown: false })}
            />
            <ConfirmRejectPopup
                submitModalOpen={submitModalOpen}
                setSubmitModalOpen={setSubmitModalOpen}
                inventoryLines={inventoryLines}
                setSubmitting={setSubmitting}
                isRejectingRelease={inventoryLines.some((data) => !data.isAccepted)}
            />
            <ChatterModalWrapper
                updateInventoryLine={updateInventoryLine}
                inventoryLine={inventoryLines?.find((line) => line.name === selectedInventoryName)}
                userName={currentUser.authentication.userName}
                releaseOrderData={releaseOrderData}
            />
            <SubmissionBoard
                releaseName={releaseName}
                releaseHeaderuploadStep={releaseHeaderuploadStep}
                heroImageuploadStep={heroImageuploadStep}
                newPhotouploadStep={newPhotouploadStep}
                addMessagesToChatterStep={addMessagesToChatterStep}
                resetStoredVariables={resetStoredVariables}
                containsAnyHeroImages={hasHeroImages}
                isOpen={submitting}
            />
            {showDocumentGenerationModal ? (
                <GenerateDocumentsModal
                    selectedInventoryName={selectedInventoryName}
                    documentParams={_.clone(documentParams)}
                    persistedDocumentParams={_.clone(documentParams)}
                    releaseOrderData={releaseOrderData}
                    releaseId={releaseId}
                />
            ) : null}
        </section>
    );
}
