import React from 'react';
import Modal from '../../../components/Modal';

export default function ConfirmRejectPopup({ submitModalOpen, setSubmitModalOpen, isRejectingRelease, setSubmitting }) {
    return (
        <Modal
            open={submitModalOpen}
            setOpen={setSubmitModalOpen}
            title={`Are you sure you want to ${isRejectingRelease ? 'reject' : 'accept'} this release?`}
            confirmText={isRejectingRelease ? 'Reject' : 'Accept'}
            confirmButtonStyling={
                'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 ' +
                (isRejectingRelease ? '!bg-red-500 hover:!bg-red-600' : '!bg-green-500 hover:!bg-green-600')
            }
            cancelText={'Cancel'}
            onConfirm={() => {
                setSubmitting(true);
            }}
        />
    );
}
