import {
    AdjustmentsHorizontalIcon,
    BuildingStorefrontIcon,
    KeyIcon,
    UserCircleIcon,
    IdentificationIcon,
} from '@heroicons/react/24/outline';

export const defaultNotificationStatus = {
    status: '',
    title: '',
    desc: '',
    shown: false,
};

export const accountNavEnum = {
    ACCOUNT: 'Account',
    CONTACT: 'Contact-Information',
    ADDRESS: 'Shipping-Addresses',
    METHOD: 'Shipping-Methods',
    PASSWORD: 'Password',
};

const { ACCOUNT, ADDRESS, METHOD, PASSWORD, CONTACT } = accountNavEnum;

export const navigationStart = [
    {
        name: 'Account',
        param: ACCOUNT,
        icon: UserCircleIcon,
    },
    {
        name: 'Contact Information',
        param: CONTACT,
        icon: IdentificationIcon,
    },
    {
        name: 'Shipping Addresses',
        param: ADDRESS,
        icon: BuildingStorefrontIcon,
    },
    {
        name: 'Shipping Methods',
        param: METHOD,
        icon: AdjustmentsHorizontalIcon,
    },
    {
        name: 'Password',
        param: PASSWORD,
        icon: KeyIcon,
    },
];

export const dummyOrderData = [
    {
        date: '12/12/2019',
        PO: '239364',
        PO_date: '01/11/2020',
        amount: 0,
        total: 90,
    },
    {
        date: '05/02/2020',
        PO: '241361',
        PO_date: '04/02/2020',
        amount: 0,
        total: 666,
    },
    {
        date: '12/12/2019',
        PO: '241909',
        PO_date: '06/11/2020',
        amount: 0,
        total: 3552,
    },
    {
        date: '12/12/2019',
        PO: '239364',
        PO_date: '01/11/2020',
        amount: 0,
        total: 90,
    },
    {
        date: '05/02/2020',
        PO: '241361',
        PO_date: '04/02/2020',
        amount: 0,
        total: 666,
    },
    {
        date: '12/12/2019',
        PO: '241909',
        PO_date: '06/11/2020',
        amount: 0,
        total: 3552,
    },
    {
        date: '12/12/2019',
        PO: '239364',
        PO_date: '01/11/2020',
        amount: 0,
        total: 90,
    },
    {
        date: '05/02/2020',
        PO: '241361',
        PO_date: '04/02/2020',
        amount: 0,
        total: 666,
    },
    {
        date: '12/12/2019',
        PO: '241909',
        PO_date: '06/11/2020',
        amount: 0,
        total: 3552,
    },
    {
        date: '12/12/2019',
        PO: '239364',
        PO_date: '01/11/2020',
        amount: 0,
        total: 90,
    },
    {
        date: '05/02/2020',
        PO: '241361',
        PO_date: '04/02/2020',
        amount: 0,
        total: 666,
    },
    {
        date: '12/12/2019',
        PO: '241909',
        PO_date: '06/11/2020',
        amount: 0,
        total: 3552,
    },
];
