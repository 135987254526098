import React from 'react';
import PlaceholderText from './PlaceholderText';

export const PlaceholderResults = () => {
    return (
        <>
            <section className='text-xl w-full bg-slate-50 p-3 rounded-lg mt-7'>
                <PlaceholderText bgColor={'bg-gray-400'} width={'w-[45%]'} />
            </section>
            <div className='flex flex-col w-full rounded-lg mt-7 last:mb-7'>
                <div className='w-full py-2 text-2xl text-gray-600 text-start px-3 bg-gray-100 rounded-t-lg'>
                    <PlaceholderText bgColor={'bg-gray-400'} width={'w-[450px]'} />
                </div>
                <section className='w-full grid grid-cols-4 px-3 py-4 gap-3 bg-gray-100 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                </section>
                <section className='w-full grid grid-cols-4 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-4 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-4 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-4 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-4 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
            </div>
            <div className='flex flex-col w-full rounded-lg mt-7 last:mb-7'>
                <div className='w-full py-2 text-2xl text-gray-600 text-start px-3 bg-gray-100 rounded-t-lg'>
                    <PlaceholderText bgColor={'bg-gray-400'} width={'w-[450px]'} />
                </div>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-gray-100 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                </section>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
            </div>
            <div className='flex flex-col w-full rounded-lg mt-7 last:mb-7'>
                <div className='w-full py-2 text-2xl text-gray-600 text-start px-3 bg-gray-100 rounded-t-lg'>
                    <PlaceholderText bgColor={'bg-gray-400'} width={'w-[450px]'} />
                </div>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-gray-100 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText bgColor={'bg-gray-400'} />
                    </article>
                </section>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
                <section className='w-full grid grid-cols-3 px-3 py-4 gap-3 bg-white hover:bg-slate-50 last:rounded-b-lg border-t border-gray-200'>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                    <article className='col-span-1 flex flex-col'>
                        <PlaceholderText />
                    </article>
                </section>
            </div>
        </>
    );
};
