import React from 'react';
import { MinusCircleIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../wms/context/wms-context';
import config from '../../utils/config';
import TooltipIcon from '../../components/TooltipIcon';
import { FixedSizeList as List } from 'react-window';
import logError from '../../utils/errorUtil';

export default function LocationsTable({ allLocations, filteredLocations, loading, setLoading, setFilteredLocations }) {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;

    const currentLocations = filteredLocations.length > 0 ? filteredLocations : allLocations;

    const handlePrintIndividual = (location) => {
        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            locations: [location],
        };
        fetch(`${ORIGIN}/wms/location/print-location`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((data) => {
                // render the blob as pdf in a new tab
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                fileURL && window.open(fileURL);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                logError(err, 'handlePrintIndividual');
            });
    };

    const handleDelete = (location) => {
        if (filteredLocations.length >= 1) {
            setFilteredLocations((prev) => prev.filter((loc) => loc.Id !== location.Id));
        }
        // setFilteredLocations to allLocations minus the location
        else {
            setFilteredLocations(allLocations.filter((loc) => loc.Id !== location.Id));
        }
    };

    const Row = ({ index, style }) => {
        const location = currentLocations[index];
        return (
            <div style={style}>
                <table className='table-auto w-full overflow-hidden'>
                    <tbody>
                        <tr key={location.Id}>
                            <td className='border px-4 py-2'>
                                <div className='flex justify-between '>
                                    <p>{location.Name}</p>
                                    <div className='flex flex-row space-x-2'>
                                        <TooltipIcon
                                            onClick={() => handlePrintIndividual(location)}
                                            IconComponent={PrinterIcon}
                                            tooltipText='Print individual location'
                                        />
                                        <TooltipIcon
                                            onClick={() => handleDelete(location)}
                                            IconComponent={MinusCircleIcon}
                                            tooltipText='Remove location'
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    console.count('LocationsTable');
    return (
        <div className='overflow-x-hidden'>
            {/* Header */}
            <table className='table-auto w-full overflow-x-hidden'>
                <thead className='bg-blue-primary'>
                    <tr>
                        <th className='px-4 py-2 text-white'>Location Name</th>
                    </tr>
                </thead>
            </table>
            {/* Virtualized List */}
            <List
                height={600} // Height of the container
                itemCount={currentLocations.length}
                itemSize={40} // Estimated height of each row (adjust accordingly)
                width='100%' // Width of the container
            >
                {Row}
            </List>
        </div>
    );
}
