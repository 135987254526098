import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, LinkIcon } from '@heroicons/react/24/outline';

export default function PdfEmbed({ base64, open, setOpen, orderConformation }) {
    // scroll to top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex  items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6'>
                                <div>
                                    {orderConformation && (
                                        <div className=''>
                                            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                                                <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                                            </div>
                                            <div className='mt-3 text-center sm:mt-5'>
                                                <Dialog.Title
                                                    as='h3'
                                                    className='text-lg leading-6 font-medium text-gray-900'
                                                >
                                                    Order Confirmation
                                                </Dialog.Title>
                                                <div className='mt-2'>
                                                    <p className='text-sm text-gray-500'>
                                                        Your order has been placed. Please check your email for a
                                                        confirmation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className='mt-3 text-center sm:mt-5'>
                                        <div className='shadow'>
                                            <embed
                                                className='pdfembedheight w-full'
                                                src={`data:application/pdf;base64,${base64}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <button
                                        type='button'
                                        className=' w-1/2 mx-auto justify-center rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 sm:text-sm'
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
