import React, { useState } from 'react';
import LoginForm from '../components/LoginForm';
import fullLogoSrc from '../../assets/images/fullLogo.png';
import { useAuth } from '../../portal/context/auth-context';
import { useNavigate } from 'react-router-dom';
import config from '../../utils/config';
import loginBackground from '../../assets/images/loginBackground.png';

export default function LoginPage() {
    // const ORIGIN = window.location.origin;
    const ORIGIN = config.origin;
    const { login } = useAuth();
    const history = useNavigate();
    const [userAuthError, setUserAuthError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [serverError, setServerError] = useState(false);

    const createUserLogin = (data, remembered, firstLogin) => {
        login(data, remembered, firstLogin);
        history('/');
    };

    const sendData = async (data, remembered) => {
        try {
            await fetch(`${ORIGIN}/cp/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emailAddress: data.email,
                    password: data.password,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        createUserLogin(result.success, remembered, result.firstLogin);
                        history('/');
                    }
                    if (result.failure) {
                        setUserAuthError(result.failure);
                    }
                });
            // .catch((error) => {
            //   console.error('Error:', error);
            // });
        } catch (error) {
            setServerError(true);
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className='flex min-h-screen'>
                <LoginForm
                    submitData={sendData}
                    userAuthError={userAuthError}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setUserAuthError={setUserAuthError}
                    serverError={serverError}
                />
                <div className='relative hidden w-0 flex-1 lg:block'>
                    <img className='absolute inset-0 h-full w-full object-cover' src={loginBackground} alt='' />
                    <img
                        className='absolute right-24 top-10 h-auto object-contain w-3/5'
                        width={780}
                        src={fullLogoSrc}
                        alt=''
                    />
                </div>
            </div>
        </>
    );
}
