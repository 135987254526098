import React, { useContext } from 'react';
import ChatterModal from './ChatterModal';
import { TraceContext } from '../Context/TraceContextWrapper';
import { chatterModalView } from '../utils/data';
import Modal from '../../../../components/Modal';

export default function ChatterModalWrapper({ inventoryLine, userName, updateInventoryLine, releaseOrderData }) {
    const { chatterModalState, addDataToChatter, updateChatterModalView } = useContext(TraceContext);

    const addUserInputToChatterData = (userInput) => {
        addDataToChatter({
            inventoryName: inventoryLine?.name,
            inventoryId: inventoryLine?.id,
            partId: inventoryLine?.productId,
            keyword: inventoryLine?.keyword,
            relatedOrderId: releaseOrderData.id,
            userName,
            userInput,
        });
        updateChatterModalView(chatterModalView.HIDDEN);
        updateInventoryLine(inventoryLine?.name, { ...inventoryLine, isAccepted: false });
    };

    const cancelUserInputModal = () => {
        updateChatterModalView(chatterModalView.WARNING);
    };

    // Popup view flow is controlled by this object
    const chatterModalViewOptions = {
        [chatterModalView.HIDDEN]: null,
        [chatterModalView.USER_INPUT]: (
            <ChatterModal
                submitInput={addUserInputToChatterData}
                lineName={inventoryLine?.name}
                orderName={releaseOrderData.name}
                cancelInput={cancelUserInputModal}
                isOpen={chatterModalState.view === chatterModalView.USER_INPUT}
            />
        ),
        [chatterModalView.WARNING]: (
            <WarningPopup chatterModalState={chatterModalState} updateChatterModalView={updateChatterModalView} />
        ),
    };

    return chatterModalViewOptions[chatterModalState.view];
}

function InitialPopup({ chatterModalState, updateChatterModalView }) {
    const changeModalState = (isOpen) => {
        if (!isOpen) updateChatterModalView(chatterModalView.HIDDEN);
    };

    return (
        <Modal
            open={chatterModalState.view === chatterModalView.INITIAL_POPUP}
            setOpen={changeModalState}
            title={`Are you sure you want to reject this Inventory Line?`}
            confirmText={'Reject'}
            confirmButtonStyling={
                'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 !bg-red-500 hover:!bg-red-600'
            }
            cancelText={'Cancel'}
            onConfirm={() => {
                updateChatterModalView(chatterModalView.USER_INPUT);
            }}
        />
    );
}

function WarningPopup({ chatterModalState, updateChatterModalView }) {
    const changeModalState = (isOpen) => {
        if (!isOpen) updateChatterModalView(chatterModalView.HIDDEN);
    };

    return (
        <Modal
            open={chatterModalState.view === chatterModalView.WARNING}
            setOpen={changeModalState}
            title={'Are you sure you want to cancel?'}
            body={'Canceling will remove the rejected status from the current line.'}
            confirmText={'Back'}
            confirmButtonStyling={
                'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0'
            }
            cancelText={'Confirm Cancel'}
            cancelButtonStyling={
                'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-1 !bg-red-500 hover:!bg-red-600'
            }
            onConfirm={() => {
                updateChatterModalView(chatterModalView.USER_INPUT);
            }}
        />
    );
}
