import React from 'react';

export default function Options({ handleSubmit, locationName }) {
    return (
        <div className='options'>
            <h5 className='text-2xl mb-2 text-center'>{locationName ?? ''}</h5>
            <h5 className='text-2xl mb-8 text-center'>Select Utilization Rank</h5>
            <button className='w-64 bg-red-200  my-8 py-3 rounded-md text-xl' onClick={handleSubmit} value='Full'>
                Full
            </button>
            <button className='w-64 bg-orange-200  my-8 py-3 rounded-md text-xl' onClick={handleSubmit} value='Half'>
                Half Full
            </button>
            <button className='w-64 bg-green-200 my-8 py-3 rounded-md text-xl' onClick={handleSubmit} value='Empty'>
                Empty
            </button>
        </div>
    );
}
