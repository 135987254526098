export const validEmailRegex = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validePassword = (password) => {
    return password.length >= 5;
};

export const validatePartsSearch = (data, searchLimit) => {
    const dataSplit = data.split('\n');

    // see if there is a colon in the string
    // if there is, check to see if either side it 5 characters long, and remove it, unless both sides are 5 characters long
    const colonData = [];
    dataSplit.map((item) => {
        if (item.includes(':')) {
            const splitItem = item.split(':').map((part) => part.replace(/\s+/g, ''));
            if (splitItem[0].length === 5 && splitItem[1].length === 5) {
                colonData.push(splitItem[0]);
                colonData.push(splitItem[1]);
            } else if (splitItem[0].length === 5) {
                colonData.push(splitItem[1]);
            } else if (splitItem[1].length === 5) {
                colonData.push(splitItem[0]);
            } else {
                for (let items of splitItem) {
                    colonData.push(items);
                }
            }
        } else {
            colonData.push(item);
        }
    });

    //remove characters
    const regex = new RegExp(/[^a-zA-Z0-9-\s]/g);
    const cleanData = colonData.map((item) => {
        return item.replace(regex, '-').replace(/\s/g, '');
    });

    // remove duplicates
    const uniqueData = [...new Set(cleanData)];

    //remove empty strings
    let filteredData = uniqueData.filter((item) => item !== '');

    if (filteredData.length > searchLimit) {
        // this is the return message to the onSubmit function to indicate that the user has entered too many parts
        return `You can only search up to ${searchLimit} parts at a time.`;
    }

    return filteredData;
};
