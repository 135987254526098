import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NoDataAlert from '../../components/NoDataAlert';
import { formatDate } from '../../../utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../portal/context/auth-context';
import TableSortButton from '../order-history/TableSortButton';
import {
    // leave all the below unused imports & const states in here for now
    ArrowsPointingOutIcon,
    ArrowsPointingInIcon,
    PrinterIcon,
    ShoppingCartIcon,
    ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';
export default function RFQTable({
    data,
    gettingNextPage,
    setQuoteData,
    handleQuoteClick,
    hasSearched,
    handleFilterSubmit,
    arrowDirections,
    setArrowDirections,
}) {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const [hoveredQuote, setHoveredQuote] = useState(null);
    const [hoveredOrder, setHoveredOrder] = useState(null);
    const [hoveredCheckout, setHoveredCheckout] = useState(null);

    useEffect(() => {
        data.quote.map((quote) => {
            let splitPartNumbers = quote.partNumbers.split('');
            if (splitPartNumbers.length > 60) {
                quote.isExpanded = true;
                setQuoteData((prevData) => {
                    return {
                        ...prevData,
                        quote: prevData.quote.map((lineItem) => {
                            if (quote.quoteId === lineItem.quoteId) {
                                return {
                                    ...lineItem,
                                    isExpanded: true,
                                };
                            }
                            return lineItem;
                        }),
                    };
                });
            }
        });
    }, [gettingNextPage]);

    const pushToCheckout = (quoteId) => {
        currentUser.order = null;
        navigate(`/checkout/?quote=${quoteId}`);
    };

    if (!gettingNextPage && (data.length === 0 || data.quote.length === 0)) {
        return (
            <div className='flex flex-col'>
                <NoDataAlert
                    title={'Quotes'}
                    text={'Please note quotes are only valid for 30 days and will not appear if after this.'}
                />
            </div>
        );
    }
    return (
        <div className='px-4 sm:px-6 lg:px-8 -mt-4'>
            <div className='mx-auto mt-6 transition-all'>
                <table className='w-full table-auto'>
                    <thead>
                        <tr className='bg-blue-primary text-white '>
                            <th scope='col' className='px-3 py-2 w-36 font-semibold'>
                                <div className='flex flex-row justify-between'>
                                    <div>
                                        <p>Quote Number</p>
                                    </div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'quoteNumber'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-3 font-normal w-48'>
                                <div className='flex flex-row justify-between'>
                                    <div>
                                        <p>Date</p>
                                    </div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'createdDate'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-3 font-normal text-left'>
                                Part Numbers {hasSearched && ' (Filtered)'}
                            </th>
                            <th scope='col' className='px-3 py-3 font-normal text-left w-48'>
                                {hasSearched && ' (Filtered)'}
                                <div className='flex flex-row justify-between'>
                                    <div>
                                        <p>Total</p>
                                    </div>
                                    <TableSortButton
                                        fetchdata={handleFilterSubmit}
                                        arrowDirections={arrowDirections}
                                        setArrowDirections={setArrowDirections}
                                        name={'quoteTotal'}
                                    />
                                </div>
                            </th>
                            <th scope='col' className='px-3 py-3 font-normal text-right w-36'>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    {!gettingNextPage ? (
                        <tbody className='bg-white'>
                            {data.quote.map((rfqLine, personIdx) => (
                                <tr key={rfqLine.Name} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                                    <td className='border px-4 py-2 font-semibold'> {rfqLine.Name}</td>
                                    <td className='border px-4 py-2'>
                                        {' '}
                                        {rfqLine.inscor__Create_Date__c ? formatDate(rfqLine.inscor__Create_Date__c) : null}
                                    </td>
                                    <td className='border px-4 py-2 relative '>
                                        <div className='flex justify-start pr-4'>
                                            {!rfqLine.isExpanded ? (
                                                <>
                                                    <div className='w-full'>{rfqLine.partNumbers}</div>
                                                </>
                                            ) : (
                                                <div className='w-full'>
                                                    {rfqLine.partNumbers.split('').slice(0, 60)}

                                                    <span className='text-blue-primary cursor-pointer'>...</span>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {rfqLine.isExpanded && (
                                                <ArrowsPointingOutIcon
                                                    onClick={() => {
                                                        setQuoteData((prevData) => {
                                                            return {
                                                                ...prevData,
                                                                quote: prevData.quote.map((lineItem) => {
                                                                    if (rfqLine.quoteId === lineItem.quoteId) {
                                                                        return {
                                                                            ...lineItem,
                                                                            isExpanded: false,
                                                                        };
                                                                    }
                                                                    return lineItem;
                                                                }),
                                                            };
                                                        });
                                                    }}
                                                    className='h-5 w-5 text-blue-primary absolute top-2 right-2  hover:text-blue-secondary cursor-pointer'
                                                />
                                            )}
                                            {!rfqLine.isExpanded && rfqLine.partNumbers.split('').length > 60 && (
                                                <ArrowsPointingInIcon
                                                    onClick={() => {
                                                        setQuoteData((prevData) => {
                                                            return {
                                                                ...prevData,
                                                                quote: prevData.quote.map((lineItem) => {
                                                                    if (rfqLine.quoteId === lineItem.quoteId) {
                                                                        return {
                                                                            ...lineItem,
                                                                            isExpanded: true,
                                                                        };
                                                                    }
                                                                    return lineItem;
                                                                }),
                                                            };
                                                        });
                                                    }}
                                                    className='h-5 w-5 text-blue-primary absolute top-2 right-2 hover:text-blue-secondary cursor-pointer'
                                                />
                                            )}
                                        </div>
                                    </td>

                                    <td className='border px-4 py-2  '>
                                        <div className='flex justify-end'>{rfqLine.inscor__Quote_Total__c}</div>
                                    </td>
                                    <td className='border px-4 py-2 '>
                                        <div className='flex justify-end items-center space-x-2'>
                                            <div
                                                onClick={() => {
                                                    handleQuoteClick(rfqLine.Id, rfqLine.quoteNumber);
                                                    handleFilterSubmit(0, 'reset');
                                                }}
                                                onMouseEnter={() => {
                                                    setHoveredQuote(rfqLine.Id);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredQuote(null);
                                                }}
                                            >
                                                <PrinterIcon className='h-6 w-6 text-gray-800 hover:text-gray-400 cursor-pointer' />

                                                {hoveredQuote === rfqLine.Id && (
                                                    <div className='absolute bg-white shadow-lg rounded-lg p-2 z-50'>
                                                        <div className='flex flex-col space-y-2'>
                                                            <div className='flex justify-between items-center'>
                                                                <div className='text-sm font-semibold'>Print Quote</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div
                                                onMouseEnter={() => {
                                                    setHoveredCheckout(rfqLine.Id);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredCheckout(null);
                                                }}
                                            >
                                                <Link
                                                    onClick={() => {
                                                        currentUser.order = null;
                                                    }}
                                                    to={`/?quoteId=${rfqLine.Id}`}
                                                >
                                                    <ShoppingCartIcon className='h-6 w-6 text-gray-800 hover:text-gray-400 cursor-pointer' />
                                                </Link>
                                                {hoveredCheckout === rfqLine.Id && (
                                                    <div className='absolute bg-white shadow-lg rounded-lg p-2 z-50'>
                                                        <div className='flex flex-col space-y-2'>
                                                            <div className='flex justify-between items-center'>
                                                                <div className='text-sm font-semibold'>
                                                                    Edit or Buy Parts
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {/* TODO: Fix the logic to make this make more sense
                                            TODO: Leave this and the components above in here for now.
                                            <div
                                                onMouseEnter={() => {
                                                    setHoveredOrder(rfqLine.Id);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredOrder(null);
                                                }}
                                            >
                                                <div
                                                    onClick={() => {
                                                        if (!rfqLine.disableCheckout) pushToCheckout(rfqLine.Id);
                                                    }}
                                                    disabled={rfqLine.disableCheckout}
                                                >
                                                    <ArrowLeftOnRectangleIcon
                                                        style={{ transform: 'rotate(180deg)' }}
                                                        className={`h-6 w-6 text-gray-800 hover:text-gray-400 cursor-pointer rotate-180 ${
                                                            rfqLine.disableCheckout ? 'opacity-10' : ''
                                                        }`}
                                                    />
                                                </div>
                                                {hoveredOrder === rfqLine.Id && (
                                                    <div className='absolute bg-white shadow-lg rounded-lg p-2 z-50'>
                                                        <div className='flex flex-col space-y-2'>
                                                            <div className='flex justify-between items-center'>
                                                                {' '}
                                                                <div className='text-sm font-semibold'>
                                                                    {rfqLine.disableCheckout
                                                                        ? 'Not available for this order'
                                                                        : 'Checkout'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div> */}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : null}
                </table>

                {gettingNextPage && (
                    <div>
                        <div className='flex justify-center border w-full py-4'>
                            <svg
                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-blue-primary'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z'
                                ></path>
                                <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                ></circle>
                            </svg>
                            <span className='text-blue-primary'>Loading...</span>
                        </div>
                        <div>
                            <div className='h-80 mb-14'></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
