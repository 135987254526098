import React, { useState, useEffect } from 'react';
import LoginForm from '../components/auth/LoginForm';
import fullLogoSrc from '../../assets/images/fullLogo.png';
import { useAuth } from '../../wms/context/wms-context';
import { useNavigate } from 'react-router-dom';
import config from '../../utils/config';
import loginBackground from '../../assets/images/loginBackground.png';
import logError from '../../utils/errorUtil';

export default function WMS_LOGIN() {
    // const ORIGIN = window.location.origin;
    const ORIGIN = config.origin;
    const { login } = useAuth();
    const history = useNavigate();
    const [userAuthError, setUserAuthError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);

    const createUserLogin = (data) => {
        login(data);

        let redirectRoute = 'wms';

        if (!data.permissions.includes('Inventory')) {
            redirectRoute = 'wms/' + data.permissions[0].toLowerCase();
        }
        history(`/${redirectRoute}`);
    };

    const sendData = async (data, remembered) => {
        try {
            await fetch(`${ORIGIN}/wms/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: data.email,
                    password: data.password,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    createUserLogin(result);
                });
            // .catch((error) => {
            // console.error('Error:', error);
            // logError(error, 'WMS_LOGIN');
            // });
        } catch (error) {
            setServerError(true);
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className='flex min-h-screen flex-row-reverse'>
                <LoginForm
                    submitData={sendData}
                    userAuthError={userAuthError}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setUserAuthError={setUserAuthError}
                    serverError={serverError}
                />
                <div className='relative hidden w-0 flex-1 lg:block'>
                    <img className='absolute inset-0 h-full w-full object-cover' src={loginBackground} alt='' />
                    <img
                        className='absolute right-24 top-10 h-auto object-contain w-3/5'
                        width={780}
                        src={fullLogoSrc}
                        alt=''
                    />
                </div>
            </div>
        </>
    );
}
