import React, { useState } from 'react';
import {
    PrinterIcon,
    PencilSquareIcon,
    ArrowRightCircleIcon,
    MinusCircleIcon,
    ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAuth } from '../../wms/context/wms-context';
import config from '../../utils/config';
import TooltipIcon from '../../components/TooltipIcon';
import ConfirmationModal from './ConfirmationModal';
import ActionPanel from './receiving/ActionPanel';
import logError from '../../utils/errorUtil';

export default function InventoryTable({ inventoryItems, setInventoryItems, loading, setLoading, setError }) {
    const { currentUser, setCurrentUser } = useAuth();
    const ORIGIN = config.origin;

    const [confirmationModal, setConfirmationModal] = useState(false);
    const [itemToMove, setItemToMove] = useState({});
    const [actionPanel, setActionPanel] = useState(false);

    const handlePrintIndividual = (item) => {
        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: [item],
        };
        fetch(`${ORIGIN}/wms/inventory/print-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((data) => {
                // render the blob as pdf in a new tab
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                fileURL && window.open(fileURL);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                logError(err, 'handlePrintIndividual');
            });
    };

    const handleDelete = (item) => {
        // Remove the item from the inventory items
        setInventoryItems((prev) => ({
            ...prev,
            inventory: prev.inventory.filter((inventoryItem) => inventoryItem.Id !== item.Id),
        }));
    };

    // Middle function to not let modal popup if no destination or warehouse is selected
    const handleMoveOne = (item) => {
        if (!item?.destination?.location.Id) {
            setError('Please select a location destination.');
            return;
        } else if (!item?.destination?.warehouse.Id) {
            setError('Please select a warehouse destination.');
            return;
        }

        setItemToMove(item);
        setConfirmationModal(true);
    };

    const moveOne = (item) => {
        setLoading(true);
        setError('');
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: [item],
        };

        fetch(`${ORIGIN}/wms/inventory/move-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Error moving single part from server.');
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);
                setInventoryItems((prev) => ({
                    ...prev,
                    inventory: [...prev.inventory.filter((inventoryItem) => inventoryItem.Id !== item.Id), data[0]],
                }));
                setItemToMove({});
            })
            .catch((err) => {
                logError(err, 'moveOne');
                setLoading(false);
                setError('Error moving part. Please try again.');
                setItemToMove({});
            });
    };

    const handleInventoryPanel = (item) => {
        setActionPanel(true);
        setCurrentUser((prevState) => ({
            ...prevState,
            receiving_inventoryId: item.Id,
            receiving_inventoryLine: item,
        }));
    };

    const closeActionPanel = () => {
        setActionPanel(false);
        setCurrentUser({ ...currentUser, receiving_inventoryId: null, receiving_inventoryLine: null });
        currentUser.receiving_inventoryId = null;
        currentUser.receiving_inventoryLine = null;
    };

    const handleViewExternalLink = (item) => {
        // new tab
        window.open(item.url);
    };

    if (loading) return <LoadingSpinner />;

    return (
        <>
            <ConfirmationModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                callBack={moveOne}
                item={itemToMove}
                title={'Are you sure you want to move inventory'}
                text={'This will move the inventory selected to the new location'}
                buttonText={'Move'}
            />
            {actionPanel && (
                <ActionPanel status={actionPanel} setStatus={setActionPanel} closeActionPanel={closeActionPanel} />
            )}
            <div className='mt-10'>
                <div>
                    <table className='table-auto w-full'>
                        <thead className='bg-blue-primary'>
                            <tr>
                                <th className='px-4 py-2 text-white'>Part Number</th>
                                <th className='px-4 py-2 text-white'>
                                    Description <span className='text-gray-200 text-sm'>(keyword)</span>
                                </th>
                                <th className='px-4 py-2 text-white'>Condition Code</th>
                                <th className='px-4 py-2 text-white'>Owner Code</th>
                                <th className='px-4 py-2 text-white'>Name</th>
                                <th className='px-4 py-2 text-white'>Quantity</th>
                                <th className='px-4 py-2 text-white'>Quantity Available</th>
                                <th className='px-4 py-2 text-white'>Current Location</th>
                                <th className='px-4 py-2 text-white'>Destination Location</th>
                                <th className='px-4 py-2 text-white'>Destination Warehouse</th>
                                <th className='px-4 py-2 text-white'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inventoryItems.inventory &&
                                inventoryItems.inventory.map((item, index) => (
                                    <tr
                                        key={item.PartNumber}
                                        className={`${index % 2 !== 0 ? 'bg-gray-100' : ''} ${
                                            item.ScrapObsolete ? 'bg-red-300' : ''
                                        }`}
                                    >
                                        <td className='border px-4 py-2'>{item.PartNumber}</td>
                                        <td className='border px-4 py-2'>{item.Keyword}</td>
                                        <td className='border px-4 py-2'>{item.ConditionCode}</td>
                                        <td className='border px-4 py-2'>{item.OwnerCode}</td>
                                        <td className='border px-4 py-2'>{item.Name}</td>
                                        <td className='border px-4 py-2'>{item.Quantity}</td>
                                        <td className='border px-4 py-2'>{item.QuantityAvailable}</td>
                                        <td className='border px-4 py-2'>{item.current.location.Name}</td>
                                        <td className='border px-4 py-2'>{item.destination.location.Name}</td>
                                        <td className='border px-4 py-2'>{item.destination.warehouse.Name}</td>
                                        <td className='border px-4 py-2'>
                                            <div className='flex flex-row space-x-2'>
                                                <TooltipIcon
                                                    onClick={() => handleInventoryPanel(item)}
                                                    IconComponent={PencilSquareIcon}
                                                    tooltipText='Manage Inventory'
                                                />

                                                <TooltipIcon
                                                    onClick={() => handleViewExternalLink(item)}
                                                    IconComponent={ArrowTopRightOnSquareIcon}
                                                    tooltipText='View Inventory (External)'
                                                />

                                                <TooltipIcon
                                                    onClick={() => handlePrintIndividual(item)}
                                                    IconComponent={PrinterIcon}
                                                    tooltipText='Print Barcode'
                                                />
                                                <TooltipIcon
                                                    onClick={() => handleDelete(item)}
                                                    IconComponent={MinusCircleIcon}
                                                    tooltipText='Remove/Clear'
                                                />

                                                <TooltipIcon
                                                    onClick={() => {
                                                        setError('');
                                                        handleMoveOne(item);
                                                    }}
                                                    IconComponent={ArrowRightCircleIcon}
                                                    tooltipText='Move One'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
