import React from 'react';
import { OverridesDataList, OverridesInput, OverridesTextarea } from '../FormInputs';
import { documentKeys, inputVerificationTypes } from './utils/data';

export default function PackingSlipForm({ dynamicallyParseFormData, documentDefaults, formName, showForm, shippingValues }) {
    const sortedShippingValues = shippingValues.sort();

    return (
        <form
            onChange={(e) => {
                dynamicallyParseFormData(e, formName);
            }}
            className={
                'w-full h-full flex flex-col justify-between gap-4 px-8 duration-75 ' +
                (showForm ? 'opacity-100' : 'opacity-0 pointer-events-none hidden')
            }
        >
            <section className='w-full flex flex-row gap-4'>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesTextarea
                        text={'RLS Header External Comments:'}
                        name={'externalComments'}
                        defaultInput={
                            formName === documentKeys.PACKING_SLIP_PRICED
                                ? documentDefaults?.packingSheetPriced?.externalComments
                                : documentDefaults?.packingSheetUnpriced?.externalComments
                        }
                    />
                    <OverridesInput
                        text={'Total Weight:'}
                        name={'totalWeight'}
                        defaultInput={
                            formName === documentKeys.PACKING_SLIP_PRICED
                                ? documentDefaults?.packingSheetPriced?.totalWeight
                                : documentDefaults?.packingSheetUnpriced?.totalWeight
                        }
                        inputVerification={inputVerificationTypes.NUMBERS_ONLY}
                        errorText={'Please only input numbers in this field'}
                    />
                </section>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesDataList
                        text={'Shipped Via:'}
                        defaultOption={'--Select--'}
                        selectOptions={sortedShippingValues}
                        defaultSelectedValue={
                            formName === documentKeys.PACKING_SLIP_PRICED
                                ? documentDefaults?.packingSheetPriced?.shippedMethod
                                : documentDefaults?.packingSheetUnpriced?.shippedMethod
                        }
                        name={'shippedMethod'}
                    />
                    <OverridesInput
                        text={'AWB'}
                        name={'awb'}
                        defaultInput={
                            formName === documentKeys.PACKING_SLIP_PRICED
                                ? documentDefaults?.packingSheetPriced?.awb
                                : documentDefaults?.packingSheetUnpriced?.awb
                        }
                    />
                    <OverridesInput
                        text={'# of Pieces:'}
                        name={'totalPieces'}
                        defaultInput={
                            formName === documentKeys.PACKING_SLIP_PRICED
                                ? documentDefaults?.packingSheetPriced?.totalPieces
                                : documentDefaults?.packingSheetUnpriced?.totalPieces
                        }
                        inputVerification={inputVerificationTypes.NUMBERS_ONLY}
                        errorText={'Please only input numbers in this field'}
                    />
                </section>
            </section>
        </form>
    );
}
