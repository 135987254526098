import React, { useEffect, useState } from 'react';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline';
import ScannerHeader from './ScannerHeader';
import ScannerDocs from './ScannerDocs';
import config from '../../../utils/config';
import { useAuth } from '../../context/wms-context';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default function ScannerUpload({ setFormData, formData }) {
    const ORIGIN = config.origin;
    const { currentUser, setCurrentUser } = useAuth();

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(true);

    useEffect(() => {
        if (!currentUser?.selectedScanner?.name) {
            // get it from local storage(selectedScanner) and set it to the currentUser
            const selectedScanner = JSON.parse(localStorage.getItem('selectedScanner'));
            setCurrentUser((prev) => ({
                ...prev,
                selectedScanner,
            }));
            currentUser.selectedScanner = selectedScanner;
        }
        getScannerDocs();
    }, []);

    const getScannerDocs = async () => {
        if (!currentUser?.selectedScanner?.name) return;

        setLoadingFiles(true);
        setFormData((prev) => {
            return {
                ...prev,
                scans: [],
            };
        });
        const response = await fetch(`${ORIGIN}/wms/receiving/get-docs-on-scanner`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authentication: {
                    userId: currentUser.authentication.userId,
                    userEmail: currentUser.authentication.userEmail,
                    userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
                },
                scanner: currentUser.selectedScanner,
            }),
        });

        const data = await response.json();

        Promise.resolve(data);
        setLoadingFiles(false);

        data.dropboxFiles.forEach((file) => {
            file.selectedOption = 'PartRecord';
            file.uploaded = false;
        });

        setFormData((prev) => {
            return {
                ...prev,
                scans: data.dropboxFiles,
            };
        });
    };

    return (
        <div className='border shadow-md rounded-md bg-gray-50 w-full px-3'>
            <div className='flex justify-between items-center'>
                <h1 className='text-center text-2xl my-2'>Scanner Uploads</h1>
                <button className='p-2' onClick={() => setIsCollapsed((prev) => !prev)}>
                    {isCollapsed ? (
                        <BarsArrowDownIcon className='h-6 w-6' aria-hidden='true' />
                    ) : (
                        <BarsArrowUpIcon className='h-6 w-6' aria-hidden='true' />
                    )}
                </button>
            </div>

            {!isCollapsed && (
                <div>
                    <ScannerHeader getScannerDocs={getScannerDocs} />
                    {formData?.scans?.length === 0 && !loadingFiles && (
                        <div className='flex justify-center items-center h-64'>No Files Found, Scan or Refresh</div>
                    )}
                    {loadingFiles && (
                        <div className='flex justify-center items-center h-64 mb-24'>
                            <LoadingSpinner />
                        </div>
                    )}

                    <ScannerDocs formData={formData} setFormData={setFormData} />
                </div>
            )}
        </div>
    );
}
