import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export default function SearchBox({ searchRef, placeholder, width, submitFn }) {
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                submitFn();
            }}
        >
            <div>
                <div className={`relative mt-1 rounded-md shadow-sm w-110  w-${width || ' w-24'}`}>
                    <input
                        ref={searchRef}
                        type='text'
                        name='account-number'
                        id='account-number'
                        className='block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                        placeholder={placeholder}
                    />
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                        <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </div>
                </div>
            </div>
        </form>
    );
}
