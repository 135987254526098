import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../portal/context/auth-context';
import config from '../../../utils/config';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import AddNewShippingMethod from '../AddNewShippingMethod';
import DeleteAddressModal from './DeleteAddressModal';
import EditMethodModal from './EditMethodModal';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { StarIcon } from '@heroicons/react/24/outline';

export default function ShippingMethod({ shipInfo, setShippingInfo, notificationStatus, setNotificationStatus }) {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;

    const [newMethodModal, setNewMethodModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteMethodId, setDeleteMethodId] = useState('');
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editMethod, setEditMethod] = useState('');
    const [hovered, setHovered] = useState(false);
    const [errorAddingAddress, setErrorAddingAddress] = useState(false);

    const handleNewMethod = (newMethod) => {
        setShippingInfo({
            ...shipInfo,
            shipMethods: [...shipInfo.shipMethods, newMethod],
        });
    };

    const handleDelete = (methodId) => {
        // Functionality?
        fetch(`${ORIGIN}/cp/shipping/delete-method`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                shipMethodId: methodId.shipMethodId,
                shipMethodName: methodId.shipMethodName,
            }),
        });

        const newMethods = shipInfo.shipMethods.filter((method) => method.shipMethodId !== methodId.shipMethodId);
        setShippingInfo({ ...shipInfo, shipMethods: newMethods });
        setShowDeleteModal(false);
    };

    const handleEditMethod = async (updatedMethod) => {
        const response = await fetch(`${ORIGIN}/cp/shipping/update-method`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                ...updatedMethod,
                accountName: currentUser.Account.Name,
            }),
        });

        await response.json();

        const newMethods = shipInfo.shipMethods.map((method) => {
            if (method.shipMethodId === updatedMethod.shipMethodId) {
                return updatedMethod;
            } else {
                return method;
            }
        });
        setShippingInfo({ ...shipInfo, shipMethods: newMethods });

        setOpenEditModal(false);
    };

    const handleMakeDefault = async (method) => {
        const newMethods = shipInfo.shipMethods.map((oldMethod) => {
            if (oldMethod.shipMethodId === method) {
                return { ...oldMethod, isPrimaryMethod: 1 };
            } else {
                return { ...oldMethod, isPrimaryMethod: 0 };
            }
        });
        setShippingInfo({ ...shipInfo, shipMethods: newMethods });
        await fetch(`${ORIGIN}/cp/shipping/update-default-method`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                shipMethodId: method,
                accountId: currentUser.Account.Id,
            }),
        });

        setNotificationStatus({
            title: 'Successfully Saved',
            desc: 'Your default shipping method has been updated',
            status: 'Success',
            shown: true,
        });
        setTimeout(() => {
            setNotificationStatus({ notificationStatus, shown: false });
        }, 5000);
    };

    useEffect(() => {
        if (errorAddingAddress) {
            setNotificationStatus({
                title: 'There was a problem',
                desc: 'Your new method could not be saved please try again',
                status: 'failure',
                shown: true,
            });
        }
    }, [errorAddingAddress]);

    if (!shipInfo.shipMethods) {
        return (
            <div className='pb-24'>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Shipping Methods</h2>
                <LoadingSpinner />
            </div>
        );
    }
    if (!currentUser.availableShipMethods) {
        currentUser.availableShipMethods = shipInfo.availableShipMethods;
    }

    return (
        <>
            {showDeleteModal && (
                <DeleteAddressModal
                    open={showDeleteModal}
                    setOpen={setShowDeleteModal}
                    handleDelete={handleDelete}
                    deleteAddressId={deleteMethodId}
                    type={'method'}
                />
            )}
            {newMethodModal && (
                <AddNewShippingMethod
                    open={newMethodModal}
                    setOpen={setNewMethodModal}
                    handleNewAddress={handleNewMethod}
                    errorAddingAddress={errorAddingAddress}
                    setErrorAddingAddress={setErrorAddingAddress}
                />
            )}
            <div className='relative'>
                <EditMethodModal
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    editAddress={editMethod}
                    setEditAddress={setEditMethod}
                    saveUpdatedAddress={handleEditMethod}
                />
                <div className='absolute right-0 -top-2'>
                    <div
                        className='flex flex-row-reverse items-center cursor-pointer bg-blue-primary rounded-md shadow-sm px-4 py-2 hover:bg-gray-50 border font-medium text-sm text-white hover:text-gray-800'
                        onClick={() => setNewMethodModal(true)}
                    >
                        <span className=''>Add New Method</span>
                    </div>
                </div>
                <h2 className='text-2xl leading-6 font-medium text-gray-900'>Ship Methods</h2>
                <div className='mt-6 grid gap-y-6 gap-x-8 md:grid-cols-3 xxl:grid-cols-4 mx-4'>
                    {shipInfo.shipMethods.map((method) => (
                        <div key={method.shipMethodId}>
                            <div
                                className={classNames(
                                    method.isPrimaryMethod ? 'border-blue-primary shadow-lg' : '',
                                    'sm:col-span-1 border rounded-lg p-2 border-gray-300 items-start pl-6 pt-10  min-h-full h-60 relative shadow-md'
                                )}
                            >
                                {method.isPrimaryMethod ? (
                                    <div className='cursor-pointer absolute top-3 right-3 text-blue-primary hover:text-gray-500'>
                                        <StarIconSolid className='h-6 w-6' />
                                    </div>
                                ) : (
                                    <div
                                        className='cursor-pointer absolute top-3 right-3 text-gray-400 hover:text-gray-500'
                                        onClick={() => handleMakeDefault(method.shipMethodId)}
                                        onMouseEnter={() => setHovered(method.shipMethodId)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <StarIcon className='h-6 w-6' />
                                        {hovered === method.shipMethodId && (
                                            <div className='absolute bg-gray-500 shadow-lg rounded-lg p-2 z-50 w-max right-2'>
                                                <div className='flex flex-col space-y-2'>
                                                    <div className='flex justify-between items-center'>
                                                        <div className='text-sm font-semibold text-white'>
                                                            Set as Default
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className='flex justify-center flex-col mt-4'>
                                    <h3 className='text-md leading-4 font-medium text-gray-900'>
                                        {method.shipMethodDescription}
                                    </h3>
                                    <p className='mt-2  text-sm text-gray-600'>{method.shipCarrier}</p>
                                    <p className='text-sm text-gray-600'>{method.shipMethodName}</p>
                                    <p className='text-sm text-gray-600'>
                                        Account: <span className='font-semibold'>{method.accountNumber}</span>
                                    </p>
                                </div>
                                <div className='flex justify-around space-x-4 text-gray-600 mt-4 absolute bottom-5 cursor-pointer'>
                                    <div
                                        onClick={() => {
                                            setOpenEditModal(true);
                                            setEditMethod(method);
                                        }}
                                        className='px-2 py-1 '
                                    >
                                        <PencilSquareIcon className='w-6 h-6 text-gray-500' />
                                    </div>
                                    <span className='text-gray-400'>|</span>
                                    <div
                                        onClick={() => {
                                            setShowDeleteModal(true);
                                            setDeleteMethodId(method);
                                        }}
                                        className=' px-2 py-1 '
                                    >
                                        <TrashIcon className='w-6 h-6 text-gray-500' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
