import React, { useRef, useEffect, useState } from 'react';

function TooltipIcon({ IconComponent, tooltipText, onClick }) {
    const tooltipRef = useRef(null);
    const [tooltipPosition, setTooltipPosition] = useState('left');

    // Check to see if the tooltip is going off the screen
    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const rightEdge = window.innerWidth;

            if (tooltipRect.right > rightEdge) {
                setTooltipPosition('right');
            } else {
                setTooltipPosition('left');
            }
        }
    }, []);

    return (
        <div className='relative group hover:cursor-pointer'>
            <IconComponent className='h-6 w-6' onClick={onClick} />
            <span
                ref={tooltipRef}
                className={`absolute mt-2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 z-50 pointer-events-none ${
                    tooltipPosition === 'right' ? 'right-0 mr-5' : 'left-0 ml-5'
                }`}
            >
                {tooltipText}
            </span>
        </div>
    );
}

export default TooltipIcon;
