import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { defaultStatus, searchStateEnum } from '../data';
import { DropdownDefaultText } from './DropdownDefaultText';
import { useAuth } from '../../../../portal/context/auth-context';
import { globalSearch } from '../../../../utils/commonAPICalls';
import { ResultToDisplay } from './Results';
import { Descriptions } from './Descriptions';
import { checkForNestedData, checkSearchState, sanitizeCageCode, sanitizeInput } from '../utils';

export default function SearchBar() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { DEFAULT, LOADING, RESULTS, NORESULTS } = searchStateEnum;

    const [searchInput, setSearchInput] = useState('');
    const [clicked, setClicked] = useState(false);
    const [status, setStatus] = useState(defaultStatus);
    const [resultsArray, setResultsArray] = useState(null);
    const [selectedObject, setSelectedObject] = useState();
    const [searchTimer, setSearchTimer] = useState(null);

    const inputFormRef = useRef(null);
    const inputRef = useRef(null);

    const refreshResults = () => {
        setResultsArray(null);
        setSelectedObject(null);
        setStatus({ ...status, searchState: DEFAULT });
    };

    // Delay search to account for rapid input
    const timedSearch = (input) => {
        clearTimeout(searchTimer);
        const delaySearch = setTimeout(() => {
            searchSubmit(input);
        }, 150);
        setSearchTimer(delaySearch);
    };

    // Function that fetches data
    const searchFunction = async (search, formSubmit) => {
        const dataToSend = {
            accountId: currentUser.Account.Id,
            userId: currentUser.Id,
            searchTerm: sanitizeCageCode(search),
            limit: 10,
        };

        try {
            setResultsArray(null);

            if (formSubmit) {
                setClicked(false);
            }

            setStatus({ ...status, searchState: LOADING });

            const searchedData = await globalSearch(currentUser, dataToSend);
            const dataCheck = await checkForNestedData(searchedData);

            if (!dataCheck) {
                setResultsArray(null);
                setStatus({ ...status, searchState: NORESULTS });
            } else {
                setResultsArray(searchedData);
                setStatus({ ...status, searchState: RESULTS });
            }

            return dataCheck;
        } catch (err) {
            console.error(err);
        }
    };

    // Function that runs on input and on form submit
    const searchSubmit = async (input, formSubmit) => {
        try {
            let searchQuery = sanitizeInput(input);

            if (searchQuery.length === 0) {
                refreshResults();
                return;
            }

            if (!formSubmit) {
                await searchFunction(searchQuery, formSubmit);
                return;
            }

            if (searchParams.get('query')) {
                setClicked(false);
                setSearchParams((params) => {
                    params.set('query', input);
                    return params;
                });
            } else {
                setClicked(false);
                navigate(`/global-search?query=${input}`);
            }

            refreshResults();
            inputRef.current.value = '';
            inputRef.current.blur();
        } catch (err) {
            console.error(err);
        }
    };

    // Close search popup on click off
    const handleClick = (e) => {
        if (inputFormRef.current && !inputFormRef.current.contains(e.target)) {
            setClicked(false);
        }
    };

    // Handle edge case for a delayed load after input is empty
    useEffect(() => {
        if (searchInput === '') {
            setResultsArray(null);
            setSelectedObject(null);
            setStatus((s) => {
                return { ...s, searchState: searchStateEnum.DEFAULT };
            });
        }
    }, [searchInput]);

    useEffect(() => {
        if (searchParams.get('query')) {
            setSearchInput(searchParams.get('query'));
        }
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [searchParams]);

    return (
        <form
            ref={inputFormRef}
            className='flex w-fit h-full items-center justify-center relative'
            onSubmit={(e) => {
                e.preventDefault();
                searchSubmit(searchInput, true);
            }}
        >
            <div
                className={
                    'relative flex items-center justify-center h-10 z-10 hover:cursor-text ' +
                    (clicked
                        ? 'border-blue-primary rounded-t-lg border border-b-0 pb-[1px] w-full md:w-144'
                        : 'border-gray-200 border duration-100 rounded-lg w-full md:w-96 ')
                }
                onClick={() => {
                    inputRef.current.focus();
                    setClicked(true);
                }}
            >
                <input
                    ref={inputRef}
                    className='flex pl-3 pr-10 w-full outline-none rounded-full z-10 bg-white overflow-ellipsis'
                    placeholder='Search for orders, quotes, parts, part orders and invoices.'
                    onChange={(e) => {
                        timedSearch(e.target.value);
                        setSearchInput(e.target.value);
                    }}
                />
            </div>
            <button
                disabled={sanitizeInput(searchInput).length === 0}
                className={
                    'flex absolute right-1 items-center justify-center text-white h-8 w-8 rounded-lg z-10 leading-none border-none hover:cursor-pointer disabled:cursor-default ' +
                    ' bg-blue-primary hover:bg-blue-900 disabled:opacity-60 disabled:cursor-default disabled:hover:bg-blue-primary '
                }
                type='submit'
                style={{
                    all: 'revert',
                }}
            >
                <svg
                    className=''
                    fill='none'
                    height='20'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    viewBox='0 0 24 24'
                    width='24'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <circle cx='11' cy='11' r='8' />
                    <line x1='21' x2='16.65' y1='21' y2='16.65' />
                </svg>
            </button>
            <section
                className={
                    'absolute flex items-start justify-center border-l border-r border-b rounded-b-lg overflow-hidden bg-white z-20 ' +
                    (clicked
                        ? ' w-144 opacity-100 top-[49px] border-blue-primary '
                        : ' duration-100 h-0 w-96 invisible opacity-0 bottom-0 border-gray-200 ') +
                    (checkSearchState(RESULTS, status) ? ' flex-row ' : ' flex-col ')
                }
            >
                <DropdownDefaultText searchState={status.searchState} input={searchInput} />
                <article className='flex flex-col items-start justify-start w-full max-h-72 overflow-y-auto'>
                    {checkSearchState(RESULTS, status) &&
                        resultsArray?.map((resultObject, index) => {
                            return (
                                <ResultToDisplay
                                    key={index}
                                    object={resultObject}
                                    setSelectedObject={setSelectedObject}
                                    selectedObject={selectedObject}
                                    searchInput={searchInput}
                                />
                            );
                        })}
                </article>
                <article className='flex flex-col w-full max-h-72 overflow-y-auto px-3 gap-y-2 '>
                    {selectedObject && checkSearchState(RESULTS, status) && <Descriptions object={selectedObject} />}
                </article>
            </section>
        </form>
    );
}
