import React, { useState, useEffect } from 'react';
import config from '../../utils/config';
import { useAuth } from '../../portal/context/auth-context';
import LoadingSpinner from '../../components/LoadingSpinner';
import { formatDate } from '../../utils/formatDate';

export default function OrderDetails() {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [id, setId] = useState('');

    //get query string from url
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');

    const handlePrintButton = () => {
        fetch(`${ORIGIN}/cp/print`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                type: 'order',
                id: id,
                name: data.records[0].salesOrderNumber,
            }),
        })
            //then open the pdf in a new tab its not json
            .then((res) => res.blob())
            .then((blob) => {
                // open in a new tab
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                //name of the file
                a.name = 'order.pdf';
                a.click();
            })
            .catch((err) => console.log(err));
    };

    const fetchdata = (id) => {
        fetch(`${ORIGIN}/cp/hx/orders `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                userId: currentUser.Id,
                salesOrderId: id,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        const url = window.location.href;
        const id = url.substring(url.lastIndexOf('/') + 1);
        const id2 = id.split('?')[0];
        setId(id2);
        fetchdata(id2);
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className='px-4 sm:px-6 lg:px-8 mt-12'>
            {status === 'success' && (
                <div
                    className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4'
                    role='alert'
                >
                    <span className='block sm:inline'>Your order has been placed successfully.</span>
                </div>
            )}
            <div className='sm:flex sm:items-center'>
                <div className='sm:flex-auto'>
                    <h1 className='text-xl font-semibold text-gray-900'>{data.records[0].salesOrderNumber}</h1>
                    <p className='mt-2 text-sm text-gray-700'>
                        For parts requested on <time dateTime='2022-08-01'> {formatDate(data.records[0].createdDate)}</time>
                    </p>
                </div>
                <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
                    <button
                        onClick={handlePrintButton}
                        type='button'
                        id='print'
                        className='inline-flex items-center justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
                    >
                        Print
                    </button>
                </div>
            </div>
            <div className='-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0 '>
                <table className='min-w-full divide-y divide-gray-300'>
                    <thead>
                        <tr className='bg-blue-primary text-white '>
                            <th scope='col' className='px-3 py-3 font-normal'>
                                Part Number
                            </th>
                            <th scope='col' className=' py-3.5 px-3 text-right text-sm font-semibold  sm:table-cell'>
                                Price per unit
                            </th>
                            <th scope='col' className=' py-3.5 px-3 text-right text-sm font-semibold sm:table-cell'>
                                Quantity
                            </th>
                            <th scope='col' className='py-3.5 pl-3 pr-4 text-right text-sm font-semibold sm:pr-6 md:pr-2'>
                                Total Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.records.map((line) => (
                            <tr key={line.id} className='border-b border-gray-200'>
                                <td className='py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-4'>
                                    <div className='font-medium text-gray-900'>{line.partNumber}</div>
                                </td>
                                <td className=' py-4 px-3 text-right text-sm text-gray-500 sm:table-cell'>
                                    {line.price !== '0.00' ? line.price : '-'}
                                </td>
                                <td className=' py-4 px-3 text-right text-sm text-gray-500 sm:table-cell'>
                                    {line.quantityOrdered}
                                </td>
                                <td className='py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-4'>
                                    {line.lineTotal}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th
                                scope='row'
                                colSpan={3}
                                className='hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-4'
                            >
                                Total
                            </th>
                            <th
                                scope='row'
                                className='pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden'
                            >
                                Total
                            </th>
                            <td className='pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-4'>
                                {data.totalValue}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}
