import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export default function AdvancedOptionsDropdown({
    handleSelectAll,
    advancedOptionSelection,
    handleSelectAllCart,
    handleSelectAllRFQ,
    handleBuyAllAvailable,
    rfqAvailable,
    cartAvailable,
}) {
    return (
        <div>
            <Menu as='div' className='relative inline-block text-left'>
                <div>
                    <Menu.Button className='inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-2 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
                        Advanced Options
                        <ChevronDownIcon
                            className='ml-1 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                            aria-hidden='true'
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <div className='py-1'>
                            {cartAvailable && rfqAvailable && (
                                <div className='cursor-pointer hover:bg-gray-100' onClick={handleSelectAll}>
                                    {!advancedOptionSelection.allSelected ? (
                                        <p className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                                            Select All (Cart & RFQ)
                                        </p>
                                    ) : (
                                        <p className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                                            De-Select All Cart & RFQ
                                        </p>
                                    )}
                                </div>
                            )}
                            {cartAvailable && (
                                <div className='cursor-pointer hover:bg-gray-100' onClick={handleSelectAllCart}>
                                    {advancedOptionSelection.allCartSelected ? (
                                        <p className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                                            De-Select All Cart
                                        </p>
                                    ) : (
                                        <p className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                                            Select All Cart
                                        </p>
                                    )}
                                </div>
                            )}
                            {rfqAvailable && (
                                <div className='cursor-pointer hover:bg-gray-100'>
                                    {advancedOptionSelection.allRFQSelected ? (
                                        <p
                                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                            onClick={handleSelectAllRFQ}
                                        >
                                            De-Select All RFQ
                                        </p>
                                    ) : (
                                        <p
                                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                            onClick={handleSelectAllRFQ}
                                        >
                                            Select All RFQ
                                        </p>
                                    )}
                                </div>
                            )}
                            {cartAvailable && (
                                <div className='cursor-pointer hover:bg-gray-100'>
                                    {advancedOptionSelection.allStockSelected ? (
                                        <p
                                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                            onClick={handleBuyAllAvailable}
                                        >
                                            De-Select All Stock Available
                                        </p>
                                    ) : (
                                        <p
                                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                            onClick={handleBuyAllAvailable}
                                        >
                                            Select All Stock Available
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
