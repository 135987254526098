import React, { useState, useEffect } from 'react';
import { useAuth } from '../../wms/context/wms-context';
import config from '../../utils/config';
import LoadingSpinner from '../../components/LoadingSpinner';
import logError from '../../utils/errorUtil';

export default function WMSPrint() {
    const ORIGIN = config.origin;
    const { currentUser } = useAuth();
    const [loadingLabel, setLoadingLabel] = useState(false);

    // grab all the query params
    const urlParams = new URLSearchParams(window.location.search);
    const inventoryId = urlParams.get('inventoryId');
    const inventoryName = urlParams.get('name');
    useEffect(() => {
        if ((inventoryId != '') & (inventoryName != '')) {
            handlePrintIndividual();
        }
    }, [inventoryId, inventoryName]);

    const handlePrintIndividual = () => {
        setLoadingLabel(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: [
                {
                    Id: inventoryId,
                    Name: inventoryName,
                },
            ],
        };
        fetch(`${ORIGIN}/wms/inventory/print-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((data) => {
                const blob = new Blob([data], { type: 'application/pdf' });

                const fileURL = URL.createObjectURL(blob);

                // Embed the PDF in an iframe and trigger the print dialog

                const printIframe = document.createElement('iframe');
                printIframe.src = fileURL;
                printIframe.style.visibility = 'hidden';
                document.body.appendChild(printIframe);
                printIframe.onload = function () {
                    printIframe.contentWindow.print();
                };

                setLoadingLabel(false);
            })
            .catch((err) => {
                setLoadingLabel(false);
                logError(err, 'handlePrintIndividual');
            });
    };

    if (loadingLabel) return <LoadingSpinner />;

    return (
        <div>
            <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-8'
                onClick={() => handlePrintIndividual()}
            >
                Re-Print
            </button>
        </div>
    );
}
