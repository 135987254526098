import React, { useEffect, useState } from 'react';
import { OverridesDropdown, OverridesInput, OverridesTextarea, OverridesCheckBox } from '../FormInputs';
import { defaultDarValue } from './utils/data';

export default function Document8130Form({ dynamicallyParseFormData, documentDefaults, formName, showForm }) {
    const [showLeft, setShowLeft] = useState(documentDefaults?.form8130?.showLeft);
    const [showRight, setShowRight] = useState(documentDefaults?.form8130?.showRight);

    const getPriorityDarValue = () => {
        const darValues = documentDefaults?.form8130?.DAR ?? null;

        const priorityDAR = darValues?.length ? darValues?.find((dar) => dar.priority === true) : null;
        return priorityDAR?.displayName ?? defaultDarValue.displayName;
    };

    const handleToggleLeftOrRight = (toggleSide) => {
        if (toggleSide === 'left') {
            setShowLeft(true);
            setShowRight(false);
        } else if (toggleSide === 'right') {
            setShowLeft(false);
            setShowRight(true);
        }
    };

    useEffect(() => {
        setShowLeft(documentDefaults?.form8130?.showLeft);
        setShowRight(documentDefaults?.form8130?.showRight);
    }, [documentDefaults]);

    return (
        <form
            onChange={(e) => {
                dynamicallyParseFormData(e, formName);
            }}
            className={
                'w-full h-full flex flex-col justify-between gap-4 px-8 duration-75 ' +
                (showForm ? 'opacity-100' : 'opacity-0 pointer-events-none hidden')
            }
        >
            <section className='w-full flex flex-row gap-4'>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesInput
                        text={'4. FAA CRS#'}
                        name={'faaAddress'}
                        defaultInput={documentDefaults?.form8130?.faaAddress}
                    />
                    <OverridesInput
                        text={'10. Serial Number:'}
                        name={'serialNumber'}
                        defaultInput={documentDefaults?.form8130?.serialNumber}
                    />
                    <OverridesCheckBox
                        text={'Show left'}
                        name={'showLeft'}
                        defaultInput={showLeft}
                        onChange={() => {
                            handleToggleLeftOrRight('left');
                        }}
                    />
                    <OverridesCheckBox
                        text={'13a. Approved design data and are in a condition for safe operation'}
                        name={'approvedDesign'}
                        disabled={!showLeft}
                        defaultInput={documentDefaults?.form8130?.approvedDesign}
                    />
                    <OverridesCheckBox
                        text={'13a. Non-approved design data specified in Block 12'}
                        name={'nonApprovedDesign'}
                        disabled={!showLeft}
                        defaultInput={documentDefaults?.form8130?.nonApprovedDesign}
                    />
                    <OverridesDropdown
                        text={'Dar'}
                        defaultOption={defaultDarValue.displayName}
                        selectOptions={documentDefaults?.form8130?.DAR?.map((dar) => dar.displayName) ?? []}
                        defaultSelectedValue={getPriorityDarValue()}
                        name={'darDisplayName'}
                    />
                    <OverridesTextarea
                        text={'12. Remarks:'}
                        name={'remarks'}
                        defaultInput={documentDefaults?.form8130?.remarks}
                    />
                </section>
                <section className='w-1/2 flex flex-col gap-4'>
                    <OverridesInput
                        text={'5. Work Order/Contract/Invoice Number'}
                        name={'customerPoNumber'}
                        defaultInput={documentDefaults?.form8130?.customerPoNumber}
                    />
                    <OverridesInput
                        text={'9. Quantity:'}
                        name={'quantity'}
                        defaultInput={documentDefaults?.form8130?.quantity}
                    />
                    <OverridesInput
                        text={'11. Status/Work:'}
                        name={'conditionCodeDescription'}
                        defaultInput={documentDefaults?.form8130?.conditionCodeDescription}
                    />
                    <OverridesCheckBox
                        text={'Show right'}
                        name={'showRight'}
                        defaultInput={showRight}
                        onChange={() => {
                            handleToggleLeftOrRight('right');
                        }}
                    />
                    <OverridesCheckBox
                        text={'14a. 14 CFR 43.9 Return to Service'}
                        name={'fourteenCfr'}
                        disabled={!showRight}
                        defaultInput={documentDefaults?.form8130?.fourteenCfr}
                    />
                    <OverridesCheckBox
                        text={'14a. Other regulation specified in Block 12'}
                        name={'otherRegulationSpecified'}
                        disabled={!showRight}
                        defaultInput={documentDefaults?.form8130?.otherRegulationSpecified}
                    />
                </section>
            </section>
        </form>
    );
}
