import React, { useContext, useState } from 'react';
import { FormContext } from '../context/FormContextWrapper';
import { isInputValid } from './utils/functions';

const inputHolderClassName = 'w-full flex flex-col justify-start items-start gap-1 h-fit rounded-md bg-gray-100';
const inputClassName = 'flex items-center justify-start w-full rounded-md p-2 h-full gap-3';
export function OverridesCheckBox({ text, defaultInput = false, name, disabled = false, onChange = () => {} }) {
    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
            </article>
            <article className={inputClassName}>
                <input
                    className='disabled:outline-gray-200'
                    type='checkbox'
                    disabled={disabled}
                    checked={defaultInput}
                    onChange={onChange}
                    name={name}
                />
            </article>
        </section>
    );
}

export function OverridesDate({ text, defaultInput = '', name }) {
    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
            </article>
            <article className={inputClassName}>
                <input
                    defaultValue={defaultInput}
                    type='date'
                    name={name}
                    className='w-full border border-gray-400 rounded-md px-3 py-1 focus:border-black duration-100'
                />
            </article>
        </section>
    );
}

export function OverridesDropdown({ text, selectOptions, defaultOption, name, defaultSelectedValue = '' }) {
    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
            </article>
            <article className={inputClassName}>
                <select
                    name={name}
                    defaultValue={defaultSelectedValue}
                    className='w-full px-3 py-1 rounded-md border-gray-400 focus:border-black duration-100 hover:bg-gray-100 bg-white cursor-pointer border'
                >
                    <option value={''}>{defaultOption}</option>
                    {selectOptions.map((value, index) => {
                        return (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        );
                    })}
                </select>
            </article>
        </section>
    );
}

export function OverridesInput({ text, defaultInput = '', name, inputVerification = null, errorText }) {
    const { handleFormError } = useContext(FormContext);
    const [error, setError] = useState(false);

    const checkValidity = (value) => {
        if (!inputVerification) return;

        if (!isInputValid(value, inputVerification)) {
            setError(true);
            handleFormError(true);
            return;
        }

        setError(false);
        handleFormError(false);
    };

    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
            </article>
            <article className={inputClassName}>
                <input
                    defaultValue={defaultInput}
                    name={name}
                    onChange={(e) => checkValidity(e.target.value)}
                    className={
                        'w-full px-3 py-1 rounded-md border duration-100 hover:bg-gray-100 ' +
                        (error ? 'border-red-500' : 'border-gray-400 focus:border-black')
                    }
                />
                {error ? <h1 className='text-red-600'>{errorText}</h1> : null}
            </article>
        </section>
    );
}

export function OverridesTextarea({ text, defaultInput = '', name }) {
    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
            </article>
            <article className={inputClassName}>
                <textarea
                    defaultValue={defaultInput}
                    name={name}
                    className='w-full border border-gray-400 rounded-md px-3 py-3 focus:border-black duration-100 resize-none h-[96px]'
                />
            </article>
        </section>
    );
}

export function OverridesToggleInput({ text, defaultInput = '', defaultToggle = false, name }) {
    const [checked, setChecked] = useState(defaultToggle);

    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
                <input type='checkbox' defaultChecked={defaultToggle} onChange={(e) => setChecked(e.target.checked)} />
            </article>
            <article className={inputClassName}>
                <input
                    defaultValue={defaultInput}
                    name={name}
                    disabled={!checked}
                    className='w-full border border-gray-400 rounded-md px-3 py-1 focus:border-black duration-100 disabled:bg-gray-400 disabled:border-gray-500 disabled:cursor-not-allowed'
                />
            </article>
        </section>
    );
}

export function OverridesDropdownTextareaPair({
    dropdownText,
    textareaText,
    textareaDefaultInput = '',
    selectOptions,
    defaultOption,
    defaultSelectedValue = '',
    defaultOptionValue = '',
    canEdit = false,
    name,
    addEmptyOption = false,
    emptyOptionName = '',
}) {
    const [textareaValue, setTextareaValue] = useState(textareaDefaultInput);

    const onChange = (e) => {
        setTextareaValue(e.target.value);
    };

    return (
        <>
            <section className={inputHolderClassName}>
                <article className={inputClassName}>
                    <p>{dropdownText}</p>
                </article>
                <article className={inputClassName}>
                    <select
                        name={name}
                        defaultValue={defaultSelectedValue}
                        onChange={(e) => onChange(e)}
                        className='w-full px-3 py-1 rounded-md border-gray-400 focus:border-black duration-100 hover:bg-gray-100 bg-white cursor-pointer border'
                    >
                        <option value={defaultOptionValue}>{defaultOption}</option>
                        {addEmptyOption ? <option value={''}>{emptyOptionName || ''}</option> : null}
                        {selectOptions.map((value, index) => {
                            return (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            );
                        })}
                    </select>
                </article>
            </section>
            {canEdit ? (
                <section className={inputHolderClassName}>
                    <article className={inputClassName}>
                        <p>{textareaText}</p>
                    </article>
                    <article className={inputClassName}>
                        <textarea
                            defaultValue={textareaDefaultInput}
                            name={name}
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                            className='w-full border border-gray-400 rounded-md px-3 py-3 focus:border-black duration-100 resize-none h-[96px]'
                        />
                    </article>
                </section>
            ) : null}
        </>
    );
}

export function OverridesDataList({ text, selectOptions, name, defaultSelectedValue }) {
    const [error, setError] = useState(false);

    const checkValidity = (value) => {
        if (!selectOptions?.includes(value) && value !== '') {
            setError(true);
        } else {
            setError(false);
        }
    };

    return (
        <section className={inputHolderClassName}>
            <article className={inputClassName}>
                <p>{text}</p>
            </article>
            <article className={inputClassName}>
                <input
                    name={name}
                    onChange={(e) => checkValidity(e.target.value)}
                    className={
                        'w-full px-3 py-1 rounded-md border duration-100 hover:bg-gray-100 ' +
                        (error ? ' border-red-500' : ' border-gray-400 focus:border-black')
                    }
                    defaultValue={defaultSelectedValue}
                    list='datalist'
                />
                <datalist id={'datalist'}>
                    {selectOptions?.map((value, index) => {
                        return (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        );
                    })}
                </datalist>
                {error ? <h1 className='text-red-600'>Input must be in options provided</h1> : null}
            </article>
        </section>
    );
}
