import React, { useState, useEffect } from 'react';
import LargeActionModal from '../LargeActionModal';
import { useAuth } from '../../context/wms-context';
import ScannerUpload from './ScannerUpload';
import Camera from '../Camera';
import { PrinterIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import config from '../../../utils/config';
import logError from '../../../utils/errorUtil';
import { useSearchParams } from 'react-router-dom';

export default function ActionPanel({ status, closeActionPanel, canClose, isReceiving, setStatus }) {
    const ORIGIN = config.origin;
    const { currentUser, setCurrentUser } = useAuth();
    const [searchParams] = useSearchParams();

    const [inventoryId, setInventoryId] = useState(currentUser?.receiving_inventoryId);
    const [inventoryData, setInventoryData] = useState(currentUser?.receiving_inventoryLine);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [formData, setFormData] = useState({
        photos: [],
        scans: [],
    });
    const [hasRun, setHasRun] = useState(false);
    const [barcodePrintURL, setBarcodePrintURL] = useState(null);

    useEffect(() => {
        if (!inventoryId) {
            if (searchParams.has('inventoryId') && searchParams.has('editinventory')) {
                const inventoryIdParam = searchParams.get('inventoryId');
                setInventoryId(inventoryIdParam);
            }
            if (currentUser?.receiving_inventoryId) {
                setInventoryId(currentUser?.receiving_inventoryId);
            }

        }
    }, [inventoryId, currentUser]);

    useEffect(() => {
        if (inventoryId && !hasRun) {
            fetchInventoryData(inventoryId);
        }
    }, [inventoryId]);

    const fetchInventoryData = (inventoryId, count = 0) => {
        fetch(`${ORIGIN}/wms/receiving/get-inventory-line-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({
                inventoryId: inventoryId,
                authentication: {
                    userId: currentUser.authentication.userId,
                    userEmail: currentUser.authentication.userEmail,
                    userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
                },
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 500) {
                        // Process the response to get the error message
                        return res.json().then((errorData) => {
                            setStatus({
                                ...status,
                                loading: false,
                                error: true,
                                errorMessage: errorData.message || 'Internal Server Error',
                            });
                        });
                    }
                }
                if (res.headers.get('content-type')?.includes('application/json')) {
                    return res.json();
                } else {
                    return {
                        message: 'error',
                    };
                }
            })
            .then((data) => {
                if (data?.message === 'error' || count > 5) {
                    setStatus({
                        ...status,
                        loading: false,
                        error: true,
                        errorMessage: 'Error loading inventory.',
                    });
                    setHasRun(true);
                    return;
                }

                if (!data?.partNumber) {
                    // Due to server delay in the sync we recurvisely call the function 5 times
                    // add a delay of 1 second before calling the function again
                    setTimeout(() => {
                        fetchInventoryData(inventoryId, count + 1);
                    }, 1000);
                } else {
                    const receiving_inventoryLine = {
                        Id: data.Id,
                        PartNumber: data.partNumber,
                        OwnerCode: data.ownerCode,
                        Name: data.Name,
                        url: data.url,
                        traceUrlPictures: data.traceUrlPictures
                    }
                    setInventoryData(receiving_inventoryLine);
                    setCurrentUser((prevState) => ({
                        ...prevState,
                        receiving_inventoryLine,
                    }));
                }
            })
            .catch((err) => logError(err, 'ActionPanel'));
    }


    const clearAndUpdatePrintIframeData = (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const barcodePrintURL = window.URL.createObjectURL(blob);

        if (isReceiving) {
            setBarcodePrintURL(barcodePrintURL);
        } else {
            barcodePrintURL && window.open(barcodePrintURL);
        }
    };

    const handlePrintIndividual = (item) => {
        setLoadingLabel(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: [item],
        };
        fetch(`${ORIGIN}/wms/inventory/print-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((data) => {
                clearAndUpdatePrintIframeData(data);
                setLoadingLabel(false);
            })
            .catch((err) => {
                setLoadingLabel(false);
                logError(err, 'handlePrintIndividual');
            });
    };

    useEffect(() => {
        if (inventoryData && isReceiving && !hasRun && !searchParams.has('editinventory')) {
            setHasRun(true);
            handlePrintIndividual({
                Id: inventoryId,
                Name: inventoryData?.Name,
            });
        }
    }, [inventoryData]);

    // Delete temporary iframe holder on component unmount
    useEffect(() => {
        return () => {
            setBarcodePrintURL(null);
        };
    }, []);

    return (
        <div className=' mx-auto px-4 sm:px-6 lg:px-8 border h-screen'>
            <LargeActionModal
                inventoryId={inventoryId}
                inventoryData={inventoryData}
                status={status}
                closeAction={closeActionPanel}
                canClose={canClose}
                isReceiving={searchParams.get('editinventory') ? false : isReceiving}
                formData={formData}
                setFormData={setFormData}
                setStatus={setStatus}
            >
                <div className='relative'>
                    {inventoryData?.Name && inventoryData?.url ? (
                        <h1 className='text-2xl font-bold text-center underline text-blue-primary hover:text-blue-secondary  mx-auto p-2 w-fit h-12'>
                            <a target='_blank' href={`${inventoryData?.url}`}>
                                {inventoryData?.Name}
                            </a>
                        </h1>
                    ) : (
                        <h1 className='text-2xl font-bold text-center underline text-black  bg-gray-200 rounded mx-auto p-2 w-fit h-12 animate-pulse'>
                            Loading Inventory...
                        </h1>
                    )}
                    <div className='md:absolute top-0 right-0 '>
                        <button
                            onClick={() =>
                                handlePrintIndividual({
                                    Id: inventoryId,
                                    Name: inventoryData?.Name,
                                })
                            }
                            disabled={inventoryId == null || inventoryId === undefined}
                            className='bg-blue-primary hover:bg-blue-secondary text-white font-bold py-2 px-4 rounded-md flex cursor-pointer justify-center items-center'
                        >
                            Print Barcode
                            {loadingLabel ? (
                                <ArrowPathIcon className='animate-spin h-5 w-5 ml-2 text-gray-300' />
                            ) : (
                                <PrinterIcon className='h-6 w-6 ml-2' aria-hidden='true' />
                            )}{' '}
                        </button>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row justify-center md:space-x-4  space-y-2 md:space-y-0 p-4 items-start'>
                    <ScannerUpload inventoryId={inventoryId} setFormData={setFormData} formData={formData} />
                    <Camera inventoryId={inventoryId} formData={formData} setFormData={setFormData} />
                </div>
            </LargeActionModal>
            {barcodePrintURL ? (
                <iframe src={barcodePrintURL} onLoad={(e) => e.target.contentWindow.print()} className='hidden h-0 w-0' />
            ) : null}
        </div>
    );
}
