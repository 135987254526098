import React from 'react';
import InventoryPhoto from './InventoryPhoto';
import { lineLoadingState } from '../utils/data';

export default function PhotosSection({ inventoryLine, handlePhotoEnlarge, toggleImageHeroStatus }) {
    return (
        <section className='h-fit max-h-[360px] relative w-full border-t-2 py-8 px-5 gap-8 overflow-y-auto grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-9'>
            <PhotoRenderer
                inventoryLine={inventoryLine}
                handlePhotoEnlarge={handlePhotoEnlarge}
                toggleImageHeroStatus={toggleImageHeroStatus}
            />
        </section>
    );
}

function PhotoRenderer({ inventoryLine, handlePhotoEnlarge, toggleImageHeroStatus }) {
    if (inventoryLine?.documentsAndPhotosLoaded === lineLoadingState.NOT_LOADED) {
        return null;
    }
    if (!inventoryLine?.photos?.length && inventoryLine?.documentsAndPhotosLoaded === lineLoadingState.LOADING) {
        return (
            <div className='flex flex-row gap-3'>
                <h1 className='whitespace-nowrap'>Retrieving Photos</h1>
                <div className='w-5 h-5 border-b-[2px] border-gray-500 rounded-full animate-spin out' />
            </div>
        );
    }
    if (!inventoryLine?.photos?.length && inventoryLine?.documentsAndPhotosLoaded === lineLoadingState.LOADED) {
        if (inventoryLine?.tracePicturesLink) {
            return <h1 className='text-red-600 whitespace-nowrap'>Failed to get photos from INV Line Photos Link</h1>;
        }
        if (!inventoryLine?.tracePicturesLink) {
            return <h1>No Photos Found</h1>;
        }
    }
    if (!inventoryLine?.photos?.length && inventoryLine?.documentsAndPhotosLoaded === lineLoadingState.FAILED) {
        return <h1 className='text-red-600'>Failed To Load Photos</h1>;
    }
    if (inventoryLine?.photos?.length) {
        return inventoryLine.photos?.map((photoData, index) => {
            return (
                <InventoryPhoto
                    key={index}
                    photoData={photoData}
                    handlePhotoEnlarge={handlePhotoEnlarge}
                    toggleImageHeroStatus={toggleImageHeroStatus}
                    loading={inventoryLine.loadingBinder}
                />
            );
        });
    }

    return <h1>No Photos Found</h1>;
}