import React, { useContext, useEffect, useState } from 'react';
import { serverPingTimingEnum, serverStatusEnum } from './data';
import WMSContext from '../../context/wms-context';
import { getServerHeartbeatStatus } from './functions';

export default function UptimeStatus() {
    const {ONLINE, OFFLINE} = serverStatusEnum;
    const {HEALTHY_INTERVAL, UNHEALTHY_INTERVAL} = serverPingTimingEnum;

    const wmsContext = useContext(WMSContext);
    const { onlineStatus, setOnlineStatus } = wmsContext;
    const [pingTiming, setPingTiming] = useState(HEALTHY_INTERVAL);

    // Check server for response, update time between checks based on response
    // Runs on interval
    const updateServerStatus = async () => {
        const serverStatus = await getServerHeartbeatStatus();
        setOnlineStatus(serverStatus);

        // Ping more frequently to check status
        if (serverStatus !== ONLINE) {
            setPingTiming(UNHEALTHY_INTERVAL);
            return;
        }

        setPingTiming(HEALTHY_INTERVAL);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            updateServerStatus();
        }, pingTiming);

        return () => clearTimeout(timer);
    }, [pingTiming]);

    return (
        <>
            {onlineStatus === ONLINE && (
                <span className='inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700'>
                    <svg className='h-1.5 w-1.5 fill-green-500' viewBox='0 0 6 6' aria-hidden='true'>
                        <circle cx={3} cy={3} r={3} />
                    </svg>
                    Online
                </span>
            )}
            {onlineStatus === OFFLINE && (
                <span className='inline-flex items-center gap-x-1.5 rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700'>
                    <svg className='h-1.5 w-1.5 fill-red-500' viewBox='0 0 6 6' aria-hidden='true'>
                        <circle cx={3} cy={3} r={3} />
                    </svg>
                    Offline
                </span>
            )}
        </>
    );
}
