import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import LoadingIndicatorWithText from '../../LoadingIndicatorWithText';

export default function DetailModal({ modalOpen, closeModal, hasBase64, info }) {
    const iframeRef = useRef(null);
    const [iframeMounted, setIframeMounted] = useState(false);

    return (
        <Transition.Root show={modalOpen} as={Fragment}>
            <Dialog
                as='div'
                className='relative z-50'
                onClose={() => {
                    closeModal();
                    if (iframeMounted) setIframeMounted(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex  items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6'>
                                <div className='h-[80vh]'>
                                    {hasBase64 && info && (
                                        <div className='mt-3 text-center sm:mt-5'>
                                            <div className='shadow'>
                                                <embed
                                                    className='pdfembedheight w-full'
                                                    src={`data:application/pdf;base64,${info}`}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!hasBase64 && info && (
                                        <div className={iframeMounted ? 'h-full w-full' : 'hidden'}>
                                            <iframe
                                                ref={iframeRef}
                                                className={'h-[100%] w-full'}
                                                src={info}
                                                title='Iframe Example'
                                                onLoad={() => {
                                                    setIframeMounted(true);
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(!info || (!hasBase64 && !iframeMounted)) && modalOpen && (
                                        <LoadingIndicatorWithText
                                            staticText={'Loading'}
                                            className={'w-full h-full mt-6 flex flex-col items-center justify-center'}
                                        />
                                    )}
                                </div>
                                <div className='mt-6'>
                                    <button
                                        type='button'
                                        className=' w-1/2 mx-auto justify-center rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-primary/50'
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
