export const filterTypes = {
    RELEASED: 'Released',
    ON_HOLD: 'On Hold',
    PENDING_SALES: 'Pending Sales',
    AIR_CERT: 'Air-Cert',
    IN_PICKING: 'In Picking',
    PICKED: 'Picked',
    IN_QC: 'In QC',
    QCD: "QC'd",
    IN_SHIPPING: 'In Shipping',
    PPWK: 'PPWK',
};

export const holdStatuses = {
    NO_HOLD: 'No Hold',
    ON_HOLD: 'On Hold',
    APPROVED: 'Approved',
};

export const releaseStatuses = {
    In_Picking: 'In Picking',
    Picked: 'Picked',
    'In QC': 'In QC',
    QCd: "QC'd",
    'In Shipping': 'In Shipping',
    'Pending Sales': 'Pending Sales',
    Released: 'Released',
};

export const tableHeaderValues = [
    { text: 'Sales Order', key: 'salesOrderName', allowSort: true },
    { text: 'Release Number', key: 'name', allowSort: true },
    { text: 'Created Date', key: 'createdDateSort', allowSort: true },
    { text: 'AOG', key: 'AOG', allowSort: true },
    { text: 'DAR', key: 'isDar', allowSort: true },
    { text: 'Ship Method', key: 'shipMethodName', allowSort: true },
    { text: 'Customer/Vendor', key: 'customerName', allowSort: false },
    { text: 'Internal Comments', key: 'internalComments', allowSort: true },
    { text: 'Status', key: 'status', allowSort: true },
    { text: 'Credit Status', key: 'creditStatus', allowSort: true },
    { text: 'Export Status', key: 'exportStatus', allowSort: true },
    { text: 'Accepted', key: 'completedReleaseLineCount', allowSort: false },
    { text: 'To Review', key: 'reviewNeededReleaseLineCount', allowSort: false },
    { text: 'Line Count', key: 'releaseLineCount', allowSort: false },
    { text: 'Remaining', key: 'remainingLines', allowSort: false },
];

export const defaultArrowDirections = {
    name: { direction: null },
    salesOrderName: { direction: null },
    createdDateSort: { direction: 'asc' },
    AOG: { direction: null },
    isDar: { direction: null },
    shipMethodName: { direction: null },
    customerName: { direction: null },
    internalComments: { direction: null },
    status: { direction: null },
    creditStatus: { direction: null },
    exportStatus: { direction: null },
};
