import React from 'react';
import { RFQSection } from '../components/historyPageSections';

export default function QuotesPage() {
    return (
        <div>
            <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 mt-12 mb-24'>
                <div className='m-6'>
                    <RFQSection />
                </div>
            </div>
        </div>
    );
}
