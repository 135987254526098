// React & Modules
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

// Pages and Components
import { useAuth } from '../../context/wms-context';
import { GuidedInterfaceContext } from '../../components/guidedInterface/context/GuidedInterfaceContext';
import { defaultArrowDirections, filterTypes, releaseStatuses, tableHeaderValues } from './data';
import LoadingSpinner from '../../../components/LoadingSpinner';
import {
    fetchReleaseData,
    getSanitizedInput,
    isReleaseFiltered,
    updateEmployeeFilters,
    validateReleaseNumber,
} from './functions';
import ReleaseLines from '../../components/guidedInterface/ReleaseLines';
import TableSortButton from '../../components/guidedInterface/components/TableSortButton';

export default function WMS_GuidedInterface() {
    const { currentUser } = useAuth();
    const { setShowNotification } = useContext(GuidedInterfaceContext);
    const [releaseData, setReleaseData] = useState(null);
    const [releaseDataCopy, setReleaseDataCopy] = useState(null);
    const [selectedReleaseNumber, setSelectedReleaseNumber] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentSearch, setCurrentSearch] = useState('');
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(null);
    const [arrowDirections, setArrowDirections] = useState(defaultArrowDirections);

    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !event.target.matches('.filter-button')
            ) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const clearSelectedRelease = () => {
        setSelectedReleaseNumber(null);
    };

    const handleFilterChange = (filterValue) => {
        let updatedFilters;
        if (filters.includes(filterValue)) {
            // Remove the filter
            updatedFilters = filters.filter((value) => value !== filterValue);
        } else {
            // Add the filter
            updatedFilters = [...filters, filterValue];
        }

        setFilters(updatedFilters);

        updateEmployeeFilters(currentUser, updatedFilters);
    };

    const renderSortButton = (columnName) => {
        return (
            <TableSortButton arrowDirections={arrowDirections} setArrowDirections={setArrowDirections} name={columnName} />
        );
    };

    const getReleaseData = async () => {
        try {
            setLoading(true);
            const { employeeFilters, formattedReleases } = await fetchReleaseData(currentUser);
            setReleaseData(formattedReleases);
            setReleaseDataCopy(formattedReleases);
            setLoading(false);
            setFilters(employeeFilters);

            inputRef?.current?.focus();
        } catch (error) {
            console.log(error);
            setError(true);
            setShowNotification({
                shown: true,
                status: 'error',
                title: 'Error Getting Releases',
                message: 'An error has occured returning all releases. Try searching individually or refreshing the page.',
            });
        }
    };

    const handleReleaseSelection = (releaseNumber) => {
        setSelectedReleaseNumber(releaseNumber);
        setShowModal(null);
    };

    const getSelectedReleaseData = () => {
        const data = releaseData?.find((data) => data.name === selectedReleaseNumber);

        return data ?? {};
    };

    const handleReleaseSearch = async () => {
        try {
            if (currentSearch === '') return;
            if (error) {
                setLoading(true);
                const sanitizedSearch = getSanitizedInput(currentSearch);
                const data = await validateReleaseNumber(currentUser, sanitizedSearch);
                if (!data.length) {
                    throw Error();
                }

                setSelectedReleaseNumber(sanitizedSearch);
                resetAllVariables();
                return;
            }

            const matchingReleases = releaseData.filter((data) =>
                data.name.toUpperCase().includes(currentSearch.toUpperCase())
            );

            if (matchingReleases?.length > 1) {
                setFilters([]);
                setShowNotification({
                    shown: true,
                    status: 'success',
                    title: 'Multiple Results Found',
                    message: 'Multiple Releases match your search and are displayed below.',
                });
            }

            if (matchingReleases?.length === 1) {
                setSelectedReleaseNumber(matchingReleases[0].name);
            }
        } catch (error) {
            setShowNotification({
                shown: true,
                status: 'error',
                title: 'Error Searching For Release',
                message: `No results found for ${currentSearch}. Please check the validity of your input and if the issue continues contact your manager.`,
            });
            setLoading(false);
            return;
        }
    };

    const clearSorting = () => {
        setArrowDirections({
            // set all other directions to null
            ...Object.keys(arrowDirections).reduce((acc, key) => {
                acc[key] = { direction: null };
                return acc;
            }, {}),
        });

        // Re-render the order of the items in the table by resetting the releaseData state
        setReleaseData([...releaseDataCopy]);
        setArrowDirections(defaultArrowDirections);
    };

    const resetAllVariables = () => {
        setSelectedReleaseNumber(null);
        setReleaseData(null);
        setError(false);
        setFilters([]);
        setLoading(false);
        setCurrentSearch('');
    };

    const handleClearFilters = () => {
        setFilters([]);
        updateEmployeeFilters(currentUser, []);
    };

    useEffect(() => {
        getReleaseData();
    }, [selectedReleaseNumber]);

    return (
        <>
            {selectedReleaseNumber ? (
                <ReleaseLines
                    releaseName={selectedReleaseNumber}
                    clearSelectedRelease={clearSelectedRelease}
                    releaseData={getSelectedReleaseData(selectedReleaseNumber)}
                />
            ) : (
                <section className='h-[calc(100vh-64px)] pb-10'>
                    <header className='flex flex-col items-center justify-center w-full gap-4 pt-4 pb-4'>
                        <section className='flex flex-row items-center justify-between w-full'>
                            <form onSubmit={(e) => e.preventDefault()} className='flex flex-row items-center gap-2'>
                                <h1 className='ml-4'>Input Release:</h1>
                                <input
                                    ref={inputRef}
                                    className='px-2 py-1 border border-gray-700 rounded-md outline-none focus:ring focus:ring-blue-200 focus:border-black hover:bg-gray-100'
                                    onChange={(e) => setCurrentSearch(e.target.value)}
                                    value={currentSearch}
                                    disabled={loading}
                                />
                                <button
                                    className='w-8 p-1 rounded-md bg-blue-primary hover:bg-blue-900'
                                    onClick={() => handleReleaseSearch()}
                                >
                                    <MagnifyingGlassIcon className='text-white' />
                                </button>
                            </form>
                            <div className='flex justify-center w-full'>
                                <article className='relative inline-block text-left' ref={dropdownRef}>
                                    <div>
                                        <button
                                            type='button'
                                            className='filter-button inline-flex justify-center px-4 py-2 bg-white border border-gray-700 rounded focus:outline-none focus:ring focus:ring-blue-200'
                                            onClick={() => setDropdownOpen(!dropdownOpen)}
                                        >
                                            Filter by Status
                                            {dropdownOpen ? (
                                                <ChevronUpIcon className='w-5 h-5 ml-2' />
                                            ) : (
                                                <ChevronDownIcon className='w-5 h-5 ml-2' />
                                            )}
                                        </button>
                                    </div>

                                    {dropdownOpen && (
                                        <div className='absolute z-20 w-56 mt-2 bg-white border border-gray-300 rounded shadow-lg'>
                                            <div className='p-2'>
                                                <button
                                                    className='w-full px-2 py-1 mb-2 text-sm text-left text-gray-700 bg-gray-100 rounded hover:bg-gray-200'
                                                    onClick={() => handleClearFilters()}
                                                >
                                                    Clear Filters
                                                </button>
                                                {Object.values(filterTypes).map((filterOption, index) => (
                                                    <label key={index} className='flex items-center mb-1'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-checkbox h-4 w-4'
                                                            checked={filters.includes(filterOption)}
                                                            onChange={() => handleFilterChange(filterOption)}
                                                        />
                                                        <span className='ml-2 text-sm'>{filterOption}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </article>
                            </div>
                            <article className='flex flex-col items-end justify-center gap-2'>
                                {/* Time tracking section */}
                            </article>
                        </section>
                        <section className='flex items-center justify-center w-full'>
                            <h1 className='text-3xl'>Release Data</h1>
                            {Object.values(arrowDirections).some((direction) => direction.direction !== null) && (
                                <button
                                    className='w-24 p-1 ml-4 rounded-md text-white bg-blue-primary hover:bg-blue-900'
                                    onClick={() => clearSorting()}
                                >
                                    Reset Sort
                                </button>
                            )}
                        </section>
                    </header>
                    <section>
                        <table className='w-full table-auto'>
                            <thead className='sticky top-0 z-10'>
                                <tr className='bg-blue-primary'>
                                    {tableHeaderValues.map((data, index) => {
                                        const { text, key, allowSort } = data;
                                        return (
                                            <th
                                                key={index}
                                                className='p-2 font-medium text-left text-white border-b dark:border-slate-600'
                                            >
                                                <div className='flex items-center justify-between'>
                                                    <p>{text}</p>
                                                    {allowSort && renderSortButton(key)}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            {loading || (!releaseData && !selectedReleaseNumber) ? (
                                <>
                                    {!error ? (
                                        <section className='absolute top-0 left-0 flex items-center justify-center w-screen h-screen'>
                                            <LoadingSpinner className={'h-fit'} />
                                        </section>
                                    ) : null}
                                </>
                            ) : (
                                <tbody>
                                    {releaseData
                                        .sort((a, b) => {
                                            for (const [key, value] of Object.entries(arrowDirections)) {
                                                if (value.direction !== null) {
                                                    const direction = value.direction === 'asc' ? 1 : -1;
                                                    const aValue = a[key];
                                                    const bValue = b[key];

                                                    // Handle null or undefined values explicitly
                                                    if (aValue === null || aValue === undefined) return 1 * direction; // `a` is null, so `a` should come after `b`
                                                    if (bValue === null || bValue === undefined) return -1 * direction; // `b` is null, so `b` should come after `a`

                                                    // Regular comparison for non-null values
                                                    if (aValue > bValue) return direction;
                                                    if (aValue < bValue) return -direction;
                                                }
                                            }
                                            return 0;
                                        })
                                        .map((data) => {
                                            if (filters.length > 0 && isReleaseFiltered(data, filters)) {
                                                return null;
                                            }

                                            if (
                                                !data.name.toUpperCase().includes(currentSearch.toUpperCase()) &&
                                                currentSearch !== ''
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <ReleaseItem
                                                    key={data.name}
                                                    data={data}
                                                    handleReleaseSelection={handleReleaseSelection}
                                                    showModal={showModal}
                                                    setShowModal={setShowModal}
                                                    arrowDirections={arrowDirections}
                                                />
                                            );
                                        })}
                                </tbody>
                            )}
                        </table>
                    </section>
                </section>
            )}
        </>
    );
}

function ReleaseItem({ data, handleReleaseSelection, showModal, setShowModal }) {
    let rowClass = 'border-b p-2 text-left ';
    const shippingMethods = ['Will Call', 'DB SCHENKER', 'EMO TRANS', 'Customer Pickup'];
    let rowStyling = '';

    if (data.AOG) {
        rowStyling = 'bg-green-200 hover:bg-green-300';
        rowClass += 'border-slate-500';
    } else if (data.isDar === 'Yes' && data.salesOrderName) {
        rowStyling = 'bg-red-200 hover:bg-red-300';
        rowClass += 'border-slate-500';
    } else if (shippingMethods.includes(data.shipMethodName)) {
        rowStyling = 'bg-yellow-200 hover:bg-yellow-300';
        rowClass += 'border-slate-500';
    } else {
        rowStyling = 'even:bg-gray-100 hover:bg-gray-200';
        rowClass += 'border-slate-200';
    }

    const remainingLines = data.releaseLineCount - data.completedReleaseLineCount - data.reviewNeededReleaseLineCount;

    const renderInternalComments = () => {
        if (!data.internalComments) {
            return null;
        }

        const commentText = data.internalComments;
        const isLongComment = commentText.length > 60;
        const displayText = isLongComment ? commentText.substring(0, 60) + '...' : commentText;

        const eventHandlers = isLongComment
            ? {
                  onMouseEnter: (e) => {
                      const rect = e.currentTarget.getBoundingClientRect();
                      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
                      const modalHeightEstimate = 150; // Adjust this estimate as needed

                      const shouldDisplayAbove = rect.bottom + modalHeightEstimate > viewportHeight;

                      setShowModal({
                          name: data.name,
                          position: shouldDisplayAbove ? 'above' : 'below',
                      });
                  },
                  onMouseLeave: () => setShowModal(null),
              }
            : {};

        return (
            <span className='relative cursor-pointer' {...eventHandlers}>
                {displayText}
                {showModal && showModal.name === data.name && (
                    <div
                        className={`absolute ${
                            showModal.position === 'above' ? 'bottom-full mb-2' : 'top-full mt-2'
                        } left-0 p-2 max-w-[30vw] bg-gray-100 border border-gray-300 rounded shadow-lg z-50`}
                    >
                        {data.internalComments}
                    </div>
                )}
            </span>
        );
    };

    return (
        <tr className={`cursor-pointer ${rowStyling}`} onClick={() => handleReleaseSelection(data.name)}>
            <td className={rowClass + ' whitespace-nowrap'}>
                <a
                    onClick={(e) => e.stopPropagation()}
                    className='text-blue-800 underline hover:text-blue'
                    target='_blank'
                    href={data.salesOrderLink}
                >
                    {data.salesOrderName}
                </a>
            </td>
            <td className={rowClass}>
                <a
                    onClick={(e) => e.stopPropagation()}
                    className='text-blue-800 underline hover:text-blue'
                    target='_blank'
                    href={data.link}
                >
                    {data.name}
                </a>
            </td>
            <td className={rowClass}>{data.createdDate}</td>
            <td className={rowClass}>{data.AOG ? 'Yes' : 'No'}</td>
            <td className={rowClass}>{data.isDar}</td>
            <td className={rowClass}>{data.shipMethodName || 'N/A'}</td>
            <td className={rowClass}>{data.customerName}</td>
            <td className={`${rowClass} mx-auto overflow-visible`}>{renderInternalComments()}</td>

            <td className={rowClass}>{releaseStatuses[data.status]}</td>
            <td className={rowClass}>{data.creditStatus}</td>
            <td className={rowClass}>{data.exportStatus}</td>
            <td className={rowClass + ' text-center text-green-500 '}>{data.completedReleaseLineCount}</td>
            <td className={rowClass + ' text-center text-red-500 '}>{data.reviewNeededReleaseLineCount}</td>
            <td className={rowClass + ' text-center'}>{data.releaseLineCount}</td>
            <td className={rowClass + ' text-center'}>{remainingLines}</td>
        </tr>
    );
}
