import React, { useEffect, useState } from 'react';

function MultiPicklist({ label, values, onChange, index, required, readOnly }) {
    const [availableOptions, setAvailableOptions] = useState(values);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedAvailable, setSelectedAvailable] = useState([]);
    const [selectedSelected, setSelectedSelected] = useState([]);

    const availableRef = React.useRef(null);
    const selectedRef = React.useRef(null);

    // Automatically moves the values that should be pre-selected over
    useEffect(() => {
        // Check the values that are already selected
        const selectedValues = values.filter((opt) => opt.preSelected);
        setSelectedOptions(selectedValues);

        // Remove the selected values from the available options
        const newAvailableOptions = values.filter((opt) => !opt.preSelected);
        setAvailableOptions(newAvailableOptions);

        // Notify parent component about the change
        onChange(selectedValues);

        // Clear native selection
        clearNativeSelection(availableRef.current);
    }, [values]);

    const moveToSelected = () => {
        const newSelectedOptions = [
            ...selectedOptions,
            ...selectedAvailable.map((value) => availableOptions.find((opt) => opt.value === value)),
        ];
        const newAvailableOptions = availableOptions.filter((opt) => !selectedAvailable.includes(opt.value));

        setSelectedOptions(newSelectedOptions);
        setAvailableOptions(newAvailableOptions);
        setSelectedAvailable([]); // Reset the selection

        // Notify parent component about the change
        onChange(newSelectedOptions);

        // Clear native selection
        clearNativeSelection(availableRef.current);
    };

    const moveToAvailable = () => {
        const newAvailableOptions = [
            ...availableOptions,
            ...selectedSelected.map((value) => selectedOptions.find((opt) => opt.value === value)),
        ];
        const newSelectedOptions = selectedOptions.filter((opt) => !selectedSelected.includes(opt.value));

        setSelectedOptions(newSelectedOptions);
        setAvailableOptions(newAvailableOptions);
        setSelectedSelected([]); // Reset the selection

        // Notify parent component about the change
        onChange(newSelectedOptions);

        // Clear native selection
        clearNativeSelection(selectedRef.current);
    };
    const clearNativeSelection = (selectRef) => {
        for (let option of selectRef.options) {
            option.selected = false;
        }
    };

    return (
        <div key={index} className='my-2 mx-auto relative'>
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            <div className='flex w-full '>
                <select
                    multiple
                    ref={availableRef}
                    disabled={readOnly}
                    required={required}
                    className='mt-1 p-2 w-auto min-w-[100px] border rounded-md '
                    size='5'
                    onChange={(e) => setSelectedAvailable(Array.from(e.target.selectedOptions).map((opt) => opt.value))}
                >
                    {availableOptions.map((opt, index) => (
                        <option key={index} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </select>
                {required && <div className='absolute top-0 right-0 text-red-500 text-xs'>required *</div>}
                <div className='flex flex-col items-center justify-center mx-2 space-y-2'>
                    <button
                        className='w-8 h-8 font-bold text-2xl bg-gray-100 flex justify-center items-center rounded-md pb-1 hover:bg-gray-300'
                        onClick={moveToSelected}
                    >
                        &gt;
                    </button>
                    <button
                        className='w-8 h-8 font-bold text-2xl bg-gray-100 flex justify-center items-center rounded-md pb-1 hover:bg-gray-300'
                        onClick={moveToAvailable}
                    >
                        &lt;
                    </button>
                </div>
                <select
                    multiple
                    disabled={readOnly}
                    ref={selectedRef}
                    className='mt-1 p-2 w-auto min-w-[100px] border rounded-md '
                    size='5'
                    onChange={(e) => setSelectedSelected(Array.from(e.target.selectedOptions).map((opt) => opt.value))}
                >
                    {selectedOptions.map((opt, index) => (
                        <option key={index} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default MultiPicklist;
