import { useState } from 'react';

export default function ErrorPage() {
    const [count, setCount] = useState(5);

    setTimeout(() => {
        setCount(count - 1);
    }, 1000);

    setTimeout(() => {
        window.location = '/';
    }, 5000);

    return (
        <>
            <div className='flex min-h-full flex-col'>
                <main className='mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8'>
                    <p className='text-base font-semibold leading-8 text-blue-primary'>404</p>
                    <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                        There was an error processing your request.
                    </h1>
                    <p className='mt-6 text-base leading-7 text-gray-600'>
                        Sorry, we couldn’t find the page you’re looking for.
                    </p>
                    <div className='mt-10'>
                        <a href='/' className='text-lg font-semibold leading-7 text-blue-primary hover:text-blue-secondary'>
                            Your page will refresh in {count}
                        </a>
                    </div>
                </main>
            </div>
        </>
    );
}
