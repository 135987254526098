import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../wms/context/wms-context';
import config from '../../utils/config';
import ConfirmationModal from './ConfirmationModal';
import RenderSuggestions from './RenderSuggestion';
import logError from '../../utils/errorUtil';

function InventoryControl({ inventoryItems, setInventoryItems, loading, setLoading, error, setError }) {
    const { currentUser } = useAuth();
    const ORIGIN = config.origin;

    const suggestionRef = useRef();

    const [inventory, setInventory] = useState('');
    const [locations, setLocations] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [partNumberSuggestions, setPartNumberSuggestions] = useState([]); // State to hold filtered suggestions
    const [locationDestination, setLocationDestination] = useState('');
    const [warehouseDestination, setWarehouseDestination] = useState('');
    const [showLocationsSuggestions, setShowLocationsSuggestions] = useState(false);

    const [showSuggestions, setShowSuggestions] = useState(false); // State to control when to show suggestions
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);

    const [highlightedLocationIndex, setHighlightedLocationIndex] = useState(-1);
    const [highlightedLocationDestIndex, setHighlightedLocationDestIndex] = useState(-1);

    const clearAll = () => {
        setInventoryItems([]);
    };
    const handlePrintAll = () => {
        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: inventoryItems.inventory,
        };
        fetch(`${ORIGIN}/wms/inventory/print-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((data) => {
                if (inventoryItems.inventory.length >= 100 || inventoryItems.inventory.length === 0) {
                    setRemoveModal(true);
                }
                // render the blob as pdf in a new tab
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                fileURL && window.open(fileURL);
                setLoading(false);
            })
            .catch((err) => {
                logError(err, 'handlePrintIndividual');
                setLoading(false);
            });
    };

    const handleMoveAll = () => {
        if (!inventoryItems?.inventory[0].destination.location.Id) {
            setError('Please add a destination first');
            return;
        }

        if (!inventoryItems?.inventory[0].destination.warehouse.Id) {
            setError('Please add a warehouse first');
            return;
        }

        setConfirmationModal(true);
    };

    const moveAll = () => {
        setLoading(true);
        setError('');
        if (!inventoryItems?.inventory[0].destination.location.Id) {
            setError('Please add a destination first');
            setLoading(false);
            return;
        }
        if (!inventoryItems?.inventory[0].destination.warehouse.Id) {
            setError('Please add a warehouse first');
            setLoading(false);
            return;
        }
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: inventoryItems.inventory,
        };
        const errorMessage = 'Error moving part. Please try again.';

        fetch(`${ORIGIN}/wms/inventory/move-inventory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Error moving parts.');
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);

                if (!data?.length) {
                    setError(errorMessage);
                    return;
                }
                setInventoryItems((prev) => ({
                    ...prev,
                    inventory: data,
                }));
            })
            .catch((err) => {
                logError(err, 'moveAll');
                setLoading(false);
                setError(errorMessage);
            });
    };

    const handleAddInventory = (e, invNum) => {
        e && e.preventDefault();
        if (!inventory && !invNum) return setError('Please enter an inventory');

        const inventoryArgument = inventory || invNum;
        const inventoryToFetch = inventoryArgument.toLowerCase().includes('inv-')
            ? inventoryArgument
            : `INV-${inventoryArgument}`;

        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            inventory: {
                Name: inventoryToFetch,
            },
        };

        fetch(`${ORIGIN}/wms/inventory/get-inventory-by-line`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                // if there is an error, set the error and return
                if (data.error) {
                    setError(data.error);
                    setLoading(false);
                    return;
                }

                // Doesn't add the inventory to the table if it already exists
                if (inventoryItems?.inventory?.length) {
                    setInventoryItems((prev) => {
                        if (prev.inventory.find((item) => item.Id === data.inventory[0].Id)) {
                            return prev;
                        }

                        return {
                            ...prev,
                            inventory: [...prev?.inventory, ...data.inventory],
                        };
                    });
                } else {
                    setInventoryItems(data);
                }

                setLoading(false);
                setInventory('');
            })
            .catch((err) => {
                logError(err, 'handleAddInventory');
                setLoading(false);
            });
    };

    const handleAddInventoryByLocation = (e, location) => {
        if (e) e.preventDefault();
        if (!locations) return setError('Please enter a location');

        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            location: {
                Name: location || locations,
            },
        };

        fetch(`${ORIGIN}/wms/inventory/get-inventory-by-location`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.inventory?.length === 0) {
                    setError('No inventory found for that location');
                    setLoading(false);
                    return;
                }

                // Doesn't add the inventory if it already exists
                if (inventoryItems?.inventory?.length) {
                    setInventoryItems((prev) => {
                        data.inventory = data.inventory.filter((item) => {
                            if (!prev.inventory.find((inv) => inv.Id === item.Id)) {
                                return item;
                            }
                        });

                        return {
                            ...prev,
                            inventory: [...prev?.inventory, ...data.inventory],
                        };
                    });
                } else {
                    setInventoryItems(data);
                }

                setLoading(false);
                setLocations('');
            })
            .catch((err) => {
                logError(err, 'handleAddInventoryByLocation');
                setLoading(false);
            });
    };

    const handleAddInventoryByPartNumber = (e) => {
        e && e.preventDefault();
        if (!partNumber || partNumber.length < 2) {
            return setError('Please enter a part number');
        }

        setLoading(true);
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            partNumber: partNumber,
        };

        fetch(`${ORIGIN}/wms/inventory/get-inventory-by-part-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.parts.length === 0) {
                    setError('No inventory found for that part number');
                    setLoading(false);
                    return;
                }

                // Doesn't show the part number in the suggestions if it already exists
                if (inventoryItems?.inventory?.length) {
                    data.parts = data.parts.filter((part) => {
                        if (!inventoryItems.inventory.find((item) => item.Id === part.Id)) {
                            return part;
                        }
                    });
                }

                setPartNumberSuggestions(data);

                setLoading(false);
            })
            .catch((err) => {
                logError(err, 'handleAddInventoryByPartNumber');
                setLoading(false);
            });
    };

    const handleInputChange = (event) => {
        setLocationDestination(event.target.value);
        setShowSuggestions(true); // Show suggestions when input changes
        setError(''); // Clear error message
    };

    const handleSuggestionClick = (location) => {
        setShowSuggestions(false); // Hide suggestions after selection
        setLocationDestination(location.Name);
        handleLocationDestination(null, location.Name);
    };

    const filteringLocations = currentUser.locations.locations.filter((location) =>
        location?.Name?.toLowerCase().includes(locationDestination.toLowerCase())
    );

    const handleLocationDestination = (e, location2) => {
        if (e) e.preventDefault();

        setWarehouseDestination(''); // reset warehouse destination

        if (!locationDestination) return setError('Please enter a location');
        if (!inventoryItems?.inventory?.length) return setError('Please add inventory first');
        let locationId = '';
        // loop through all inventory and find the one that matches the location
        // then update the destination location

        currentUser.locations.locations.forEach((location) => {
            if (location.Name === locationDestination || location.Name === location2) {
                locationId = location.Id;
            }
        });

        setInventoryItems((prev) => ({
            ...prev,
            inventory: prev.inventory.map((item) => ({
                ...item,
                destination: {
                    location: {
                        Name: location2 || locationDestination,
                        Id: locationId,
                    },
                    warehouse: {
                        Id: warehouseDestination.Id,
                        Name: warehouseDestination.Name,
                    },
                },
            })),
        }));

        setLocationDestination('');
    };

    const handleLocationsInputChange = (event) => {
        setLocations(event.target.value);
        setShowLocationsSuggestions(true); // Show suggestions when input changes
    };

    const handleLocationsSuggestionClick = (location) => {
        setShowLocationsSuggestions(false); // Hide suggestions after selection
        setLocations(location.Name);
        handleAddInventoryByLocation(null, location.Name);
    };
    const filteringLocationsForInput = currentUser.locations.locations.filter((location) =>
        location?.Name?.toLowerCase().includes(locations.toLowerCase())
    );

    const remove100 = () => {
        setInventoryItems((prev) => ({
            ...prev,
            inventory: prev.inventory.slice(100),
        }));
        setRemoveModal(false);
    };

    const handleWarehouseChange = (e) => {
        // find the warehouse in the currentUser.locations.warehouses
        // then update the destination warehouse
        const warehouse = currentUser.locations.warehouses.find((warehouse) => warehouse.Id === e.target.value);

        setWarehouseDestination(e.target.value);
        if (inventoryItems?.inventory?.length) {
            setInventoryItems((prev) => ({
                ...prev,
                inventory: prev.inventory.map((item) => ({
                    ...item,
                    destination: {
                        ...item.destination,
                        warehouse: {
                            Id: warehouse.Id,
                            Name: warehouse.Name,
                        },
                    },
                })),
            }));
        }
    };

    const handleKeyDown = (e, highlightedIndex, suggestions, setter, clickHandler) => {
        let newIndex = -1;

        if (e.key === 'ArrowDown' && highlightedIndex < suggestions.length - 1) {
            newIndex = highlightedIndex + 1;
        } else if (e.key === 'ArrowUp' && highlightedIndex > 0) {
            newIndex = highlightedIndex - 1;
        } else if (e.key === 'Enter' && highlightedIndex > -1) {
            clickHandler(suggestions[highlightedIndex]);
        }

        setter(newIndex);
    };

    useEffect(() => {
        // Function to handle the click event
        function handleClickOutside(event) {
            // If the click is outside the suggestionRef, hide the suggestion
            if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
                setPartNumberSuggestions([]);
            }
        }

        // Attach the click event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClick = (part, index) => {
        if (loading) return;
        setInventory(part.Name);
        handleAddInventory(null, part.Name);
        // remove the part number suggestions
        setPartNumberSuggestions((prev) => ({
            ...prev,
            parts: [...prev.parts.slice(0, index), ...prev.parts.slice(index + 1)],
        }));
    };

    return (
        <div ref={suggestionRef}>
            <ConfirmationModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                callBack={moveAll}
                title={'Are you sure you want to move all inventory'}
                text={'This will move all inventory shown to the selected location'}
                buttonText={'Move All'}
            />
            <ConfirmationModal
                open={removeModal}
                setOpen={setRemoveModal}
                callBack={remove100}
                title={'Remove First 100 Inventory Items'}
                text={
                    'You can only print 100 at a time, would you like to remove the first 100 from the inventory list and print the next 100?'
                }
                buttonText={'Remove'}
            />
            <div className='flex justify-between items-center mt-8 mx-8'>
                <div className='flex flex-col lg:flex-row space-x-4'>
                    <div className='flex flex-col'>
                        <form className='flex flex-col' onSubmit={handleAddInventoryByPartNumber}>
                            <label className='text-gray-700'>
                                Part Number <span className='text-gray-400'>(9833-14)</span>
                            </label>
                            <input
                                type='text'
                                onFocus={() => setError(false)}
                                placeholder='Type Part Number'
                                className='p-2 border rounded w-48'
                                value={partNumber}
                                onChange={(e) => setPartNumber(e.target.value)}
                            />
                        </form>
                        {partNumberSuggestions && partNumberSuggestions.parts && (
                            <div className='relative '>
                                <div className='absolute z-10 mt-2 bg-white border border-gray-300 shadow-sm w-fit'>
                                    <table className='table-auto w-full'>
                                        <thead>
                                            <tr className='bg-blue-primary text-white'>
                                                <th className='border px-2 py-2 '>Part Number</th>
                                                <th className='border px-2 py-2'>Location</th>
                                                <th className='border px-2 py-2'>Name</th>
                                                <th className='border px-2 py-2'>Owner Code</th>
                                                <th className='border px-2 py-2'>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partNumberSuggestions.parts.map((part, index) => (
                                                <tr
                                                    key={index}
                                                    className='cursor-pointer hover:bg-gray-100'
                                                    onClick={() => {
                                                        handleClick(part, index);
                                                    }}
                                                >
                                                    <td className='border px-4 py-2'>{part.PartNumber}</td>
                                                    <td className='border px-4 py-2'>{part.Location}</td>
                                                    <td className='border px-4 py-2'>{part.Name}</td>
                                                    <td className='border px-4 py-2'>{part.ownerCodeName}</td>
                                                    <td className='border px-4 py-2'>{part.quantity}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col'>
                        <form className='flex flex-col' onSubmit={handleAddInventory}>
                            <label className='text-gray-700'>
                                Inventory <span className='text-gray-400'>(INV-200214)</span>
                            </label>
                            <input
                                type='text'
                                onFocus={() => setError(false)}
                                placeholder='Type or Scan Inventory'
                                className='p-2 border rounded w-48'
                                value={inventory}
                                onChange={(e) => setInventory(e.target.value)}
                            />
                        </form>
                    </div>
                    <div className='flex flex-col'>
                        <form onSubmit={handleAddInventoryByLocation} className='flex flex-col'>
                            <label className='text-gray-700'>Locations</label>
                            <input
                                type='text'
                                onFocus={() => setError(false)}
                                placeholder='Type or Scan Location'
                                className='p-2 border rounded w-48'
                                value={locations}
                                onChange={(e) => handleLocationsInputChange(e)}
                                onKeyDown={(e) =>
                                    handleKeyDown(
                                        e,
                                        highlightedLocationIndex,
                                        filteringLocationsForInput,
                                        setHighlightedLocationIndex,
                                        handleLocationsSuggestionClick
                                    )
                                }
                            />
                            {showLocationsSuggestions && locations && (
                                <RenderSuggestions
                                    suggestions={filteringLocationsForInput}
                                    highlightedIndex={highlightedLocationIndex}
                                    onClick={handleLocationsSuggestionClick}
                                />
                            )}
                        </form>
                    </div>
                    <div className='flex flex-col'>
                        <form onSubmit={handleLocationDestination} className='flex flex-col'>
                            <label className='text-gray-700'>Location Destination</label>

                            <input
                                type='text'
                                onFocus={() => setError(false)}
                                placeholder='Type or Scan Destination Location'
                                className='p-2 border rounded w-48'
                                value={locationDestination}
                                onChange={(e) => handleInputChange(e)}
                                onKeyDown={(e) =>
                                    handleKeyDown(
                                        e,
                                        highlightedLocationDestIndex,
                                        filteringLocations,
                                        setHighlightedLocationDestIndex,
                                        handleSuggestionClick
                                    )
                                }
                            />

                            {showSuggestions && locationDestination && (
                                <RenderSuggestions
                                    suggestions={filteringLocations}
                                    highlightedIndex={highlightedLocationDestIndex}
                                    onClick={handleSuggestionClick}
                                />
                            )}
                        </form>
                    </div>

                    <div className='flex flex-col'>
                        <form className='flex flex-col'>
                            <label className='text-gray-700'>Warehouse Destination</label>

                            <select
                                className='p-2 border rounded w-48 cursor-pointer'
                                value={warehouseDestination}
                                onChange={(e) => {
                                    handleWarehouseChange(e);
                                }}
                            >
                                <option disabled value=''>
                                    Select Warehouse
                                </option>
                                {currentUser.locations.warehouses.map((warehouse, index) => (
                                    <option key={index} value={warehouse.Id}>
                                        {warehouse.Name}
                                    </option>
                                ))}
                            </select>
                        </form>
                    </div>
                </div>
                <div>
                    <p className={`text-red-500 text-md italic ${error ? 'visible' : 'invisible'}`}>{error}</p>
                </div>
                <div className='flex space-x-4'>
                    <button
                        disabled={loading || !inventoryItems?.inventory?.length}
                        className='bg-blue-primary hover:bg-blue-secondary text-white text-xl font-bold py-2 px-4 rounded cursor-pointer'
                        onClick={clearAll}
                    >
                        Clear All
                    </button>
                    <button
                        disabled={loading || !inventoryItems?.inventory?.length}
                        className='bg-blue-primary hover:bg-blue-secondary text-white text-xl font-bold py-2 px-4 rounded cursor-pointer'
                        onClick={() => handlePrintAll(inventoryItems)}
                    >
                        Print All
                    </button>
                    <button
                        disabled={loading || !inventoryItems?.inventory?.length}
                        className='bg-blue-primary hover:bg-blue-secondary text-white text-xl font-bold py-2 px-4 rounded cursor-pointer'
                        onClick={() => handleMoveAll()}
                    >
                        Move All
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InventoryControl;
