import React, { useState, useEffect, useRef } from 'react';
import { postLocationData, postUtilizationRank } from '../utils/common-api-calls';
import { useAuth } from '../context/wms-context';
import Options from '../components/location-utilization/Options';
import Notification from '../../components/Notification';

export default function WMSLocationUtilization() {
    const [locationName, setLocationName] = useState('');
    const [error, setError] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [locationId, setLocationId] = useState('');

    const { currentUser } = useAuth();

    // This code allows the input to be focused when the page loads to the input element
    const inputRef = useRef(null);

    // useEffect hook to focus the input when showOptions becomes false
    useEffect(() => {
        if (!showOptions) {
            // Check if the input element is currently mounted
            if (inputRef.current) {
                // Focus the input element
                inputRef.current.focus();
            }
        }
    }, [showOptions]); // Dependency array ensures this effect runs only when showOptions changes

    const validateLocation = async (e) => {
        e.preventDefault();

        setError('');
        setShowOptions(false);

        const returnedLocation = await postLocationData(locationName, currentUser.authentication);

        if (!returnedLocation?.isValidLocation || returnedLocation?.error) {
            setError('Invalid location');
            return;
        }

        setLocationId(returnedLocation.locationId);
        setShowOptions(true);
    };

    const submitUtilizationRank = (e) => {
        const { data, error } = postUtilizationRank(locationId, e.target.value, currentUser.authentication);
        setShowOptions(false);
        setLocationName('');

        if (error) setError(error);
    };

    return (
        <div className='flex h-[calc(100vh-64px)] items-center justify-center'>
            {error && (
                <Notification
                    title='There was an error'
                    desc={error}
                    status='error'
                    showSavedNotification={true}
                    setShowSavedNotification={setError}
                />
            )}
            {showOptions && <Options locationName={locationName} handleSubmit={submitUtilizationRank} />}
            {!showOptions && (
                <form onSubmit={validateLocation} className='flex flex-col items-center'>
                    <label htmlFor='releaseNumber' className='mb-2 text-lg'>
                        Scan Location
                    </label>
                    <input
                        id='location'
                        type='text'
                        value={locationName}
                        onChange={(e) => setLocationName(e.target.value)}
                        className='p-2 border border-gray-300 rounded'
                        ref={inputRef} // Attach the ref to the input element
                    />
                    <button type='submit' className='mt-4 px-4 py-2 bg-blue-primary text-white rounded w-56'>
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
}
