export const asaDocumentStatusDefaults = [
    'Type Certificate Holder',
    'Prodcution Certificate Holder',
    'Supplemental Type Certificate Holder',
    'Parts Manufacturing Authority',
    'Technical Standard Order Holder',
    'Manufactured to an established U.S. Specification',
    'Repair Station (Domestic or Foreign)',
    'U.S. Certificated Air Carrier',
    'FAA Certificated Individual (State Type)',
    'Foreign Certificated Air Carrier',
    'Foreign Manufacturer',
    'Surplus Parts Supplier',
    'New Parts Distributor',
    'Parts Broker (New Used or Surplus Parts)',
    'Other (Specify)',
];

export const asaAndBoeingCommentDefaults = [
    'The material shipped against the above Purchase Order is in the condition stated on this material certification and/or maintenance release tag and was manufactured by the O.E.M. or an authorized licensee. It was not obtained from any Government or military services. All used parts were not subjected to severe stress or heat (as in major engine failure, accident, or fire).',
    'The material shipped against the above Purchase Order is in the condition stated on this material certification and/or maintenance release tag and was manufactured by the O.E.M. or an authorized licensee. It was not obtained from any US Government or military services. All used parts were not subjected to severe stress or heat (as in major engine failure, accident, or fire).',
];

export const asaLeftSideNewCommentDefaults = [
    'THE FOLLOWING SIGNATURE ATTESTS THAT THE PART(S) OR MATERIAL(S) IDENTIFIED ABOVE WAS (WERE) MANUFACTURED BY A FAA PRODUCTION APPROVAL HOLDER (PAH), OR TO AN INDUSTRY COMMERCIAL STANDARD.',
    'THE FOLLOWING SIGNATURE ATTESTS THAT THE PART(S) OR MATERIAL(S) IDENTIFIED ABOVE WAS MANUFACTURED TO AN INDUSTRY COMMERCIAL STANDARD.',
];

export const asaDocumentRemarkDefault =
    'The material shipped against the above Purchase Order is in the condition stated on this material certification and/or maintenance release tag and was manufactured by the O.E.M. or an authorized licensee. It was not obtained from any Government or military services. All used parts were not subjected to severe stress or heat (as in major engine failure, accident, or fire).';

export const form8130CommentDefaultSelection =
    'Airworthiness Approval. The product(s) / article(s) shipped under this approval were produced by';

export const form8130CommentDefaults = [
    'Airworthiness Approval. The product(s) / article(s) shipped under this approval were produced by',
];

export const form8130RemarkDefault =
    'Airworthiness Approval. The product(s) / article(s) shipped under this approval were produced by';

export const documentNames = {
    DOCUMENT_8130: 'Form 8130',
    DOCUMENT_ASA: 'ASA Certificate',
    DOCUMENT_BOEING: 'Boeing',
    PACKING_SLIP_PRICED: 'Packing Slip (Priced)',
    PACKING_SLIP_UNPRICED: 'Packing Slip (Unpriced)',
};

export const documentKeys = {
    DOCUMENT_8130: 'form8130',
    DOCUMENT_ASA: 'asa',
    DOCUMENT_BOEING: 'boeing',
    PACKING_SLIP_PRICED: 'packingSheetPriced',
    PACKING_SLIP_UNPRICED: 'packingSheetUnpriced',
};

export const documentReferences = {
    DOCUMENT_8130: 'DOCUMENT_8130',
    DOCUMENT_ASA: 'DOCUMENT_ASA',
    DOCUMENT_BOEING: 'DOCUMENT_BOEING',
    PACKING_SLIP_PRICED: 'PACKING_SLIP_PRICED',
    PACKING_SLIP_UNPRICED: 'PACKING_SLIP_UNPRICED',
};

export const defaultDarValue = {
    authorizationName: 'No DAR Available',
    authorizationNumber: 'No DAR Available',
    displayName: 'No DAR Available',
    priority: true,
};

export const defaultFormStructure = {
    boeing: {},
    asa: {},
    form8130: {},
    packingSheetPriced: {},
    packingSheetUnpriced: {},
};

export const overrideDefaults = {
    boeing: {
        comment: '',
        customerPoNumber: '',
        lastCertifiedAgency: '',
        obtainedFrom: '',
        PAH: '',
        sellerContractNumber: '',
    },
    asa: {
        comment: '',
        customerPoNumber: '',
        lastCertifiedAgency: '',
        obtainedFrom: '',
        referenceNumber: '',
        remarks: asaDocumentRemarkDefault,
        salesOrder: '',
        userName: '',
        sellerStatus: '',
    },
    form8130: {
        faaAddress: '',
        remarks: '',
        statusWork: '',
    },
    packingSheetPriced: {
        awb: '',
        externalComments: '',
        packingSlipComments: '',
        shippedMethod: '',
        totalWeight: '',
        totalPieces: '',
    },
    packingSheetUnpriced: {
        awb: '',
        externalComments: '',
        packingSlipComments: '',
        shippedMethod: '',
        totalWeight: '',
        totalPieces: '',
    },
};

export const inputVerificationTypes = {
    TEXT_ONLY: 'Text Only',
    NUMBERS_ONLY: 'Numbers Only',
};