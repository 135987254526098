import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import config from '../../utils/config';
import LoadingSpinner from '../../components/LoadingSpinner';
import logError from '../../utils/errorUtil';
import Notification from '../../components/Notification';

export default function WMSEmployeePermissions() {
    const location = useLocation();
    const ORIGIN = config.origin;
    const [permissionsList, setPermissionsList] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(true);
    const [userName, setUserName] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [notification, setNotification] = useState({
        title: '',
        desc: '',
        status: '',
    });

    useEffect(() => {
        // Function to parse query parameters
        function getQueryParams(query) {
            return query
                .replace(/^\?/, '')
                .split('&')
                .reduce((json, pair) => {
                    const [key, value] = pair.split('=');
                    json[key] = decodeURIComponent(value);
                    return json;
                }, {});
        }

        const queryParams = getQueryParams(location.search);
        const { id, name } = queryParams;

        // Check if both id and name exist
        if (id && name) {
            // Send them to the server
            sendDataToServer(id, name);
            setId(id);
        }
    }, [location.search]);

    useEffect(() => {
        // When the error message changes, clear it after 5 seconds
        if (showNotification) {
            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showNotification]);

    // Function to send data to server
    const sendDataToServer = async (id, name) => {
        try {
            const response = await fetch(`${ORIGIN}/wms/get-employee-permissions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, name }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            const employee = data.results[0];
            const permissions = employee.permissions || [];
            const wmsPermissions = employee.WMS_Permissions__c || [];
            const employeeName = employee.inscor__Employee_Name__c || '';

            setPermissionsList(permissions);
            setSelectedPermissions(wmsPermissions);
            setUserName(employeeName);
            setLoading(false);
        } catch (error) {
            logError(error, 'sendDataToServer');
        }
    };

    // Handle the change event of the select
    const handlePermissionsCheckbox = (value, isChecked) => {
        if (isChecked) {
            setSelectedPermissions((prevValues) => [...prevValues, value]);
        } else {
            setSelectedPermissions((prevValues) => prevValues.filter((v) => v !== value));
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${ORIGIN}/wms/update-employee-permissions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    permissions: selectedPermissions,
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setShowNotification(true);
            setNotification({
                title: 'Permissions saved successfully',
                status: 'success',
            });
            setLoading(false);
        } catch (error) {
            logError(error, 'handleSave');
            setLoading(false);
            setShowNotification(true);
            setNotification({
                title: 'Error saving permissions',
                desc: 'Please try again later',
                status: 'failure',
            });
        }
    };

    if (loading) return <LoadingSpinner />;
    return (
        <div className='flex flex-col items-center justify-center h-screen'>
            {showNotification && (
                <Notification
                    showSavedNotification={showNotification}
                    setShowSavedNotification={setShowNotification}
                    title={notification?.title}
                    desc={notification?.desc}
                    status={notification?.status}
                />
            )}
            <h1 className='mb-4 text-3xl font-bold text-center underline'>{userName}</h1>
            <div>
                <div className='grid grid-cols-2 gap-4'>
                    {permissionsList.map((option, index) => (
                        <div key={index} className='p-4 text-lg'>
                            <label className='flex items-center text-lg space-x-2' key={index}>
                                <input
                                    type='checkbox'
                                    className='mr-2 w-4 h-4'
                                    value={option.label}
                                    checked={selectedPermissions.includes(option.label)}
                                    onChange={(e) => handlePermissionsCheckbox(option.label, e.target.checked)}
                                />
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <button
                    className='px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-700'
                    onClick={() => handleSave()}
                >
                    Save
                </button>
            </div>
        </div>
    );
}
