import { inputVerificationTypes } from './data';

export const isInputValid = (input, verificationType) => {
    if (verificationType === inputVerificationTypes.NUMBERS_ONLY) {
        return hasOnlyNumbers(input);
    }

    if (verificationType === inputVerificationTypes.TEXT_ONLY) {
        return hasOnlyText(input);
    }
};

export const hasOnlyNumbers = (value) => {
    if (value === '') return true;
    return /^\d+$/.test(value);
};

export const hasOnlyText = (value) => {
    if (value === '') return true;
    return /^[A-Za-z]+$/.test(value);
};
