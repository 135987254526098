import React, { useEffect, useRef, useState } from 'react';
import { getAuthToken, submitToken } from '../utils/common-api-calls';
import { useAuth } from '../context/wms-context';

export default function WMSAuthToken() {
    const defaultErrorStatus = {
        error: false,
        message: '',
    };
    const defaultAuthToken = {
        token: '',
        timeSinceUpdate: '',
    };
    const { currentUser } = useAuth();
    const inputRef = useRef('');
    const [authToken, setAuthToken] = useState(defaultAuthToken);
    const [errorStatus, setErrorStatus] = useState(defaultErrorStatus);

    const handleError = (text) => {
        setErrorStatus({
            error: true,
            message: text,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const tokenInput = inputRef.current.value;
            // Handle token of insufficient length
            if (tokenInput.length < 320) {
                handleError('Please submit a valid token.');
                return;
            }
            // Handle duplicate entry
            if (tokenInput === authToken.token) {
                handleError('Duplicate token entered.');
                return;
            }

            await submitToken(currentUser, tokenInput);

            setAuthToken({ token: tokenInput, timeSinceUpdate: '0' });
            setErrorStatus({
                error: false,
                message: 'Token submitted successfully.',
            });
        } catch (error) {
            handleError('Error submitting token.');
        }
    };

    const fetchTokenData = async () => {
        try {
            const tokenData = await getAuthToken(currentUser);
            setAuthToken(tokenData);
        } catch (error) {
            setAuthToken({
                token: 'No Token Found',
                timeSinceUpdate: '',
            });
        }
    };

    useEffect(() => {
        fetchTokenData();
    }, []);

    return (
        <section className='px-8 md:px-5 w-full flex items-start justify-center pt-10 h-[calc(100vh-64px)] '>
            <section className='lg:w-[890px] flex flex-col items-start justify-start overflow-y-auto'>
                <h1 className='text-lg'>Current stored token value:</h1>
                <p className='w-[100%] text-wrap break-all'>{authToken.token}</p>
                {authToken && (
                    <h3 className='text-lg mt-2'>
                        <span className='text-2xl'>{authToken.timeSinceUpdate}</span> Days Since Last Update
                    </h3>
                )}
                <h1 className='text-lg mt-4'>Steps for grabbing a login token on Chrome:</h1>
                <ol className='list-decimal list-inside'>
                    <li>
                        Open up this link{' '}
                        <a
                            className='text-blue-primary font-semibold underline hover:text-blue-primary/70'
                            href='https://store.dasi.com/login.aspx'
                            target='_blank'
                        >
                            Here
                        </a>
                    </li>
                    <li>Enter in the correct credentials into the form.</li>
                    <li>Select the 'Remember me' checkbox if it is not already checked and click 'Log in'</li>
                    <li>Once you are successfully logged in right click the webpage and select 'Inspect'.</li>
                    <li>Click Application from the tabs at the top.</li>
                    <li>Select 'Cookies', then click 'https://store.dasi.com'.</li>
                    <li>
                        Select the cookie with the name 'NOPCOMMERCE.AUTH' then copy the value and paste it into the input
                        field below.
                    </li>
                    <li>Click submit token.</li>
                </ol>
                <form onSubmit={(e) => handleSubmit(e)} className='flex flex-col items-center w-full mt-4 px-1 outline-none'>
                    <input
                        ref={inputRef}
                        type='text'
                        autoFocus
                        className='p-2 border border-gray-300 rounded-md w-full !outline-none'
                    />
                    <button type='submit' className='mt-4 px-4 py-2 bg-blue-primary text-white rounded-md'>
                        Submit Token
                    </button>
                    <p className={'mt-2 ' + (errorStatus.error ? 'text-red-600' : 'text-blue-primary')}>
                        {errorStatus.message}
                    </p>
                </form>
                <p id='status-display'></p>
            </section>
        </section>
    );
}
