import React, { useState, useRef, useEffect } from 'react';
import SimpleLogo from '../../assets/images/fullLogo.png';
import { validEmailRegex } from '../../utils/validators';
import LoadingButton from '../../components/LoadingButton';
import { Link } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ForgotPassword({ submitEmail, isLoading, setIsLoading, stepOneError }) {
    const emailRef = useRef();
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        //get the email in the query string
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        if (email) {
            emailRef.current.value = email;
        }
    }, []);

    const handleEmailFocusOut = () => {
        const validEamil = validEmailRegex(emailRef.current.value);
        if (!validEamil) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const validEmail = validEmailRegex(emailRef.current.value);
        if (!validEmail) {
            setEmailError(true);
            setIsLoading(false);
        } else {
            setEmailError(false);
            submitEmail(emailRef.current.value);
        }
    };

    return (
        <>
            <div className=''>
                <div className='w-full max-w-md space-y-8 pb-10'>
                    <div>
                        <img className='mx-auto h-36 w-auto -mt-8' src={SimpleLogo} alt='Your Company' />
                        <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>
                            Forgot your password?
                        </h2>
                        <p className='mt-2 text-center text-sm text-gray-600'>
                            Enter your email to receive a verification code
                        </p>
                    </div>
                    <form className='mt-8 space-y-6'>
                        <input type='hidden' name='remember' defaultValue='true' />
                        <div className='-space-y-px rounded-md '>
                            <div className='shadow-sm'>
                                <label htmlFor='email-address' className='sr-only'>
                                    Email address
                                </label>
                                <input
                                    ref={emailRef}
                                    onBlur={handleEmailFocusOut}
                                    id='email-address'
                                    name='email'
                                    type='email'
                                    autoComplete='email'
                                    required
                                    className={classNames(
                                        emailError ? 'border-2 border-red-400' : 'border border-gray-300',
                                        'block w-full appearance-none rounded-md  border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-md'
                                    )}
                                    placeholder='Email address'
                                />
                            </div>
                            {emailError && (
                                <p className='text-red-500 text-xs italic pl-1 -my-20'>Please use a valid email</p>
                            )}
                        </div>

                        <div>
                            {!isLoading ? (
                                <button
                                    onClick={handleSubmit}
                                    type='submit'
                                    className='group relative flex w-full justify-center rounded-md border border-transparent bg-blue-primary py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                >
                                    <span className='absolute inset-y-0 left-0 flex items-center pl-3'></span>
                                    Send Verification Code
                                </button>
                            ) : (
                                <LoadingButton />
                            )}
                            <p className='text-sm text-center underline py-2 text-blue-primary font-semibold'>
                                <Link to='/login' className='hover:text-gray-500'>
                                    Back to Login Screen
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
                {stepOneError && (
                    <div
                        className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex flex-col'
                        role='alert'
                    >
                        <strong className='font-bold'>Error!</strong>
                        <span className='block sm:inline'>{stepOneError}</span>
                    </div>
                )}
            </div>
        </>
    );
}
