import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export default function IframeEmbed({ open, setOpen, url }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex  items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6'>
                                <div>
                                    <div className='mt-3 text-center sm:mt-5 bg-gray-100'>
                                        <div className=''>
                                            <iframe className='h-screen w-full' src={url} title='Iframe Example' />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <button
                                        type='button'
                                        className=' w-1/2 mx-auto justify-center rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 sm:text-sm'
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        Go back
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
