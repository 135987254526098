import React, { useState } from 'react';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline';

export default function PasswordInput({ passwordRef, setError }) {
    const [viewPassword, setViewPassword] = useState(false);
    const handleViewPassword = () => {
        const passwordInput = document.getElementById('password');
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            setViewPassword(true);
        } else {
            passwordInput.type = 'password';
            setViewPassword(false);
        }
    };

    return (
        <div className='sm:grid sm:grid-cols-3 sm:items-center sm:border-gray-200 sm:pb-5'>
            <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                Old Password
            </label>
            <div className='mt-1 sm:col-span-2 sm:mt-0 relative'>
                <input
                    ref={passwordRef}
                    onFocus={() => setError('')}
                    onBlur={() => setError('')}
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='border border-gray-300 h-9 block w-full appearance-none rounded-md px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-primary focus:outline-none focus:ring-blue-primary sm:text-sm'
                />
                {!viewPassword ? (
                    <EyeSlashIcon
                        className='absolute right-0 -top-1 m-3 h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500 '
                        onClick={() => {
                            handleViewPassword();
                        }}
                    />
                ) : (
                    <EyeIcon
                        className='absolute right-0 -top-1 m-3 h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500 '
                        onClick={() => {
                            handleViewPassword();
                        }}
                    />
                )}
            </div>
        </div>
    );
}
