import React, { useEffect, useState } from 'react';

function Picklist({ label, values, onChange, index, required, readOnly }) {
    const [availableOptions, setAvailableOptions] = useState(values);

    useEffect(() => {
        if (required) {
            setAvailableOptions(values);
        } else {
            const noneSelected = { value: '', label: '--None--' };

            setAvailableOptions([noneSelected, ...values]);
        }
    }, [required, values]);

    return (
        <div key={index} className='my-2 mx-auto relative'>
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            <div className='flex w-full '>
                <select
                    disabled={readOnly}
                    required={required}
                    className='mt-1 p-2 w-auto min-w-[200px] border rounded-md '
                    onChange={(e) => onChange(Array.from(e.target.selectedOptions).map((opt) => opt.value))}>
                    {availableOptions.map((opt, index) => (
                        <option key={index} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default Picklist;
