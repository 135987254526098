import { MinusCircleIcon, ArrowTopRightOnSquareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import TooltipIcon from '../../../components/TooltipIcon';

export default function ScannerDocs({ formData, setFormData }) {
    const radioOptions = [
        { id: 'PartRecord', title: 'Part Record' },
        { id: 'Tag', title: 'Tag' },
        { id: 'Photo', title: 'Photo' },
    ];

    const handleRadioSelection = (file, value) => {
        // updated the files selectedOption
        setFormData((prev) => {
            const updatedScans = prev.scans.map((item) => {
                if (item.name === file.name) {
                    return { ...item, selectedOption: value };
                }
                return item;
            });
            return {
                ...prev,
                scans: updatedScans,
            };
        });
    };

    return (
        <div>
            {formData?.scans &&
                formData?.scans?.map((file, index) => {
                    return (
                        <div
                            key={index}
                            className='relative grid grid-cols-4 gap-4 items-center border-b border-gray-300 py-2 my-2'
                        >
                            {file.uploaded ? (
                                <div className='absolute left-0 top-0'>
                                    <CheckCircleIcon className='text-white bg-green-600 rounded-full h-8 w-8' />
                                </div>
                            ) : null}
                            <div className='flex flex-col'>
                                <h1 className='text-xl'>{file.name}</h1>
                            </div>
                            <div className='col-span-2 justify-self-center'>
                                <RadioGroup
                                    radioOptions={radioOptions}
                                    onChange={(value) => handleRadioSelection(file, value)}
                                    name={`location-${index}`}
                                    selectedOption={file.selectedOption}
                                />
                            </div>
                            <div className='flex space-x-4 justify-end'>
                                <a href={file.link} target='_blank' rel='noopener noreferrer' className='cursor-pointer'>
                                    <button className='p-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm'>
                                        <TooltipIcon IconComponent={ArrowTopRightOnSquareIcon} tooltipText='View Document' />
                                    </button>
                                </a>
                                <button
                                    onClick={() => {
                                        setFormData((prev) => {
                                            const newArray = prev.scans.filter((item) => item.name !== file.name);
                                            return { ...prev, scans: newArray };
                                        });
                                    }}
                                    className='p-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm'
                                >
                                    <TooltipIcon IconComponent={MinusCircleIcon} tooltipText='Remove' />
                                </button>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}

function RadioGroup({ radioOptions, onChange, name, selectedOption }) {
    const handleRadioSelection = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className='py-1 px-3'>
            <fieldset className='mt-4'>
                <div className='flex flex-col md:flex-row md:space-x-4 items-start md:items-end'>
                    {radioOptions.map((option) => (
                        <div key={option.id} className='flex items-center'>
                            <input
                                id={`${name}-${option.id}`}
                                name={name}
                                type='radio'
                                value={option.id}
                                checked={selectedOption === option.id}
                                onChange={handleRadioSelection}
                                className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                            />
                            <label
                                htmlFor={`${name}-${option.id}`}
                                className='ml-3 block text-sm font-medium leading-6 text-gray-900 mt-2'
                            >
                                {option.title}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    );
}
