import React from 'react';
import { Link } from 'react-router-dom';

export default function NavItem({ name, path }) {
    return (
        <Link
            to={path}
            className={classNames(
                window.location.pathname === path ? 'border-blue-primary' : 'border-transparent',
                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-900'
            )}
        >
            {name}
        </Link>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
