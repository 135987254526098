import React from 'react';

export default function Pagination({ pageOffset, nextPage, prevPage, quoteData, gettingNextPage }) {
    let total = quoteData.perPage * (pageOffset + 1);

    if (total > quoteData.totalCount) {
        total = quoteData.totalCount;
    }
    return (
        <div className='flex space-x-8 items-center justify-center mt-6'>
            <div>
                <button
                    onClick={prevPage}
                    disabled={pageOffset === 0 || gettingNextPage}
                    type='button'
                    className='cursor-pointer inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-blue-primary bg-white border hover:bg-blue-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                    Previous
                </button>
            </div>
            <div>
                <p>
                    {total === 0 ? 0 : quoteData.perPage * (pageOffset + 1) - (quoteData.perPage - 1)}-{total} of{' '}
                    {quoteData.totalCount}
                </p>
            </div>
            <div>
                <button
                    onClick={nextPage}
                    disabled={quoteData.perPage * (pageOffset + 1) >= quoteData.totalCount || gettingNextPage}
                    type='button'
                    className='cursor-pointer inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-blue-primary bg-white border hover:bg-blue-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                    Next
                </button>
            </div>
        </div>
    );
}
