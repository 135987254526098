import React from 'react';

export default function CompanyInfo({ userAccountInfo }) {
    return (
        <>
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label htmlFor='street-address' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Account Representative
                </label>
                <div className='block font-normal text-gray-700 sm:mt-px sm:pt-1'>
                    {userAccountInfo?.accountData.salespersonName}
                </div>
            </div>
        </>
    );
}
