import React from 'react';
import { searchStateEnum } from '../data';

// Dynamic handler for user feedback text
export const DropdownDefaultText = ({ searchState, input }) => {
    if (searchState === searchStateEnum.RESULTS) {
        return null;
    }

    return (
        <h1 className='w-full py-2 px-2 text-gray-600 text-center'>
            {searchState === searchStateEnum.DEFAULT && 'Type to search'}
            {searchState === searchStateEnum.LOADING && 'Gathering Results'}
            {searchState === searchStateEnum.NORESULTS && (
                <>
                    No results found for <strong>{input}</strong>
                </>
            )}
        </h1>
    );
};
