import React, { useState } from 'react';
import PackingCamera from '../wms/components/packing/PackingCamera';

export default function PhotoUpload() {
    const [count, setCount] = useState(0);
    const [warning, setWarning] = useState('');
    const [showCamera, setShowCamera] = useState(true);
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    let params = {
        id: queryParams.get('id'),
        name: queryParams.get('name'),
        type: queryParams.get('type'),
        part: queryParams.get('part'),
        owner: queryParams.get('owner'),
    };

    async function handleTakePhoto({ dataUri }) {
        setShowCamera(false);
        await uploadPhoto(dataUri, params, count, setCount, setWarning);
        setShowCamera(true);
    }

    return (
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <div className='picture-text'>
                <div className='picture-text'>{params.type}</div>
                <div className='picture-text right'>{params.part}</div>
            </div>
            <div className='picture-text'>
                <div className='picture-text'>Captured: {count}</div>
                <div className='picture-text right'>{params.name}</div>
            </div>
            <div className='picture-text red'>{warning}</div>
            {showCamera ? <PackingCamera photoUpload={handleTakePhoto} /> : null}
        </div>
    );
}
async function uploadPhoto(dataUri, params, count, setCount, setWarning) {
    return new Promise((resolve, reject) => {
        try {
            fetch(`${origin}/receiving/picture`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dataUri: dataUri, params: params }),
                mode: 'cors',
            })
                .then(function (result) {
                    return result.json();
                })
                .then(function (response) {
                    if (response) {
                        setCount(count + 1);
                        setWarning('');
                    } else setWarning('Warning: Failed To Upload!');
                    resolve(response);
                    return;
                });
        } catch (e) {
            setWarning('Warning: Failed To Upload!');
            reject(e);
        }
    });
}
