import config from '../../../utils/config';

const ORIGIN = config.origin;

export const addPartsToScraperSystem = async (currentUser, filteredData, hasStandardPriority, useCCP) => {
    // Send the data to the server
    const response = await fetch(`${ORIGIN}/wms/parts-scrape/add-parts-to-scrape`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            authentication: {
                ...currentUser.authentication,
            },
            parts: filteredData,
            hasStandardPriority,
            useCCP,
        }),
    });

    if (!response.ok) {
        throw new Error(`Failed to add parts to scrape queue: ${response.status} | ${response.statusText}`);
    }
};
