import { defaultCameraHeightResolution, defaultCameraWidthResolution } from './data';

// Determine user camera height based on video stream
export const getCameraHeight = (stream) => {
    const settings = stream?.getTracks()[0]?.getSettings();

    return settings?.height ?? defaultCameraHeightResolution;
};

// Determine user camera width based on video stream
export const getCameraWidth = (stream) => {
    const settings = stream?.getTracks()[0]?.getSettings();

    return settings?.width ?? defaultCameraWidthResolution;
};

export const detectMobile = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};
