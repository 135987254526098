import { CameraIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function MobileCameraButton({ uploadPhoto, buttonStyle = null, buttonText = null }) {
    const captureCameraData = (event) => {
        if (!event?.target?.files?.length) {
            return;
        }

        const pictureToAdd = event.target.files[0];

        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');

            const imageBase64String = base64String;

            const dataUri = `data:image/png;base64,${imageBase64String}`;

            uploadPhoto(dataUri);
        };

        reader.readAsDataURL(pictureToAdd);
    };

    return (
        <label
            className={
                buttonStyle ??
                'mx-auto px-2 py-2 my-4 bg-green-200 border flex border-green-600 rounded-sm text-green-600 hover:bg-green-600 hover:text-white w-48 justify-center text-lg duration-150 hover:cursor-pointer'
            }
        >
            {buttonText ?? 'Take Photo'}
            <CameraIcon className='h-7 w-7 ml-2' />
            <input
                type='file'
                accept='image/*'
                capture='environment'
                className='hidden'
                onChange={(e) => captureCameraData(e)}
            />
        </label>
    );
}
