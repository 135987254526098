import { useState } from 'react';
import HolderHeader from './HolderHeader';
import IndividualResultWrapper from './IndividualResultWrapper';

export const PagePartHolder = ({ object, checkForPart }) => {
    const [showMore, setShowMore] = useState(false);
    const columns = { lg: 3, md: 3, sm: 3 };

    return (
        <div className='flex flex-col w-full rounded-lg mt-7 last:mb-7'>
            <HolderHeader text={'Parts'} showMore={showMore} setShowMore={setShowMore} hasMore={object.data.length > 10} />
            <section className='w-full grid grid-cols-3 p-3 gap-3 bg-gray-100 last:rounded-b-lg border-t border-gray-200'>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Part Name</div>
                </article>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Description</div>
                </article>
                <article className='col-span-1 flex flex-col'>
                    <div className='first:flex hidden font-bold'>Unit of Measurement</div>
                </article>
            </section>
            {object.data.slice(0, showMore ? object.data.length : 10).map((part, index) => {
                const { Name, inscor__Keyword__c: keyword, inscor__UOM__c } = part;

                return (
                    <IndividualResultWrapper
                        key={index}
                        table={'Part'}
                        name={Name}
                        columns={columns}
                        checkForPart={checkForPart}
                    >
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{Name}</h1>
                        </article>
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{keyword}</h1>
                        </article>
                        <article className='col-span-1 flex flex-col justify-center overflow-hidden h-full'>
                            <h1 className='truncate leading-normal'>{inscor__UOM__c}</h1>
                        </article>
                    </IndividualResultWrapper>
                );
            })}
        </div>
    );
};
