import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DenialModal from './DenialModal';
import config from '../../../utils/config';

export default function DenialSection(props) {
    const { releaseId, name } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState();
    const [response, setResponse] = useState();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null); // Add a state to handle errors
    const ORIGIN = config.origin;

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        setKey(query.get('key'));
    }, [key]);

    const fetchData = async () => {
        setIsLoading(true);
        console.log('Fetching data...');
        setError(null); // Reset the error state on each fetch
        if (!key) {
            setError('Access Denied'); // Set error message if no key is found
            setIsLoading(false);

            return;
        }
        try {
            const response = await fetch(
                `${ORIGIN}/sales/csl-compliance-update?key=${key}&releaseId=${releaseId}&action=deny`
            );
            if (!response.ok) {
                // Check if response status is not okay
                setError(`Error: ${response.status}`);
                setIsLoading(false);
                return;
            }
            const data = await response.json();
            console.log(data);
            setResponse(data);
        } catch (e) {
            setError('Network error, please try again.'); // Updated error message
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='grid h-screen place-items-center text-blue-primary font-bold pb-24'>
            <DenialModal open={open} setOpen={setOpen} fetchData={fetchData} />
            {error && <div className='text-center text-red-600'>{error}</div>} {/* Display error message */}
            {!isLoading && !response && !error && (
                <div>
                    <h1 className='text-center p-2 text-3xl w-4/5 mx-auto text-gray-700'>{name}</h1>
                    <h2 className='text-center pb-6 font-normal text-xl w-4/5 mx-auto text-gray-500'>
                        By clicking below you will trigger the CSL compliance denial automation
                    </h2>
                    <button
                        onClick={() => setOpen(true)}
                        className='bg-red-700 text-white font-bold text-md rounded w-96 px-4 py-2 hover:bg-blue-medium mx-auto'
                    >
                        Deny Release for CSL Compliance Check
                    </button>
                </div>
            )}
            {isLoading && <LoadingSpinner />}
            {response && !error && <div className='text-center text-2xl'>{response.message}</div>}
        </div>
    );
}
