import config from '../../../utils/config';
import { serverLifeLine, serverStatusEnum } from './data';

// Ping server for response
export const getServerHeartbeatStatus = async () => {
    const ORIGIN = config.origin;

    try {
        const heartbeat = await fetch(`${ORIGIN}/wms/heartbeat/server`, {
            method: 'GET',
        });

        // Check for positive response status
        if (heartbeat?.status !== 204) {
            return serverStatusEnum.OFFLINE;
        }

        return serverStatusEnum.ONLINE
    } catch (error) {
        return serverStatusEnum.OFFLINE;
    }
};
