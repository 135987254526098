import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';

export default function ChatterModal({ submitInput, cancelInput, lineName, isOpen, orderName }) {
    const [userInput, setUserInput] = useState('');

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog className={'relative z-10'} onClose={() => {}}>
                <section className='fixed w-screen h-screen top-0 left-0 bg-gray-500 opacity-75 z-10' />
                <section className='fixed top-0 left-0 z-20 flex items-center justify-center w-screen h-screen'>
                    <section className='fixed flex flex-col justify-between bg-white rounded-lg px-8 py-7 w-[550px] h-[400px] z-20'>
                        <header className='w-full flex items-center justify-center'>
                            <h1 className='text-xl'>Add Chatter To {orderName}</h1>
                        </header>
                        <textarea
                            onChange={(e) => setUserInput(e.currentTarget.value)}
                            className='border border-gray-400 rounded-md h-full mt-6 mb-1 py-2 px-3 resize-none'
                            placeholder='Enter custom message here.'
                        />
                        <p className='text-[14px] mb-3'>
                            The following line information will be added to this message: INV Line, Part Number, Keyword,
                            Current User.
                        </p>
                        <footer className='flex flex-row gap-5'>
                            <button
                                type='button'
                                className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:!bg-gray-50 sm:col-start-1 sm:mt-0'
                                onClick={() => cancelInput()}
                            >
                                Cancel
                            </button>
                            <button
                                type='button'
                                disabled={!userInput?.length}
                                className={
                                    'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 !bg-red-500 hover:!bg-red-600 disabled:pointer-events-none'
                                }
                                onClick={() => {
                                    submitInput(userInput);
                                }}
                            >
                                Reject Line And Submit Chatter
                            </button>
                        </footer>
                    </section>
                </section>
            </Dialog>
        </Transition.Root>
    );
}
