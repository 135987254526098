import React, { useState, useEffect } from 'react';
import Account from './Account';
import ShippingAddress from './ShippingAddress';
import ShippingMethods from './ShippingMethods';
import Password from './Password';
import { GetShippingInfo, billingDataFetch, getUserAccountInfo } from '../../../utils/commonAPICalls';
import { useAuth } from '../../context/auth-context';
import { useSearchParams } from 'react-router-dom';
import Notification from '../../../components/Notification';
import { accountNavEnum, defaultNotificationStatus, navigationStart } from './data';
import ContactInformation from './ContactInformation';
import config from '../../../utils/config';
import DetailModal from '../global-search/Modal/ActionModal/DetailModal';
import logError from '../../../utils/errorUtil';

function iconClassNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function SettingsTabs() {
    const { currentUser, logout } = useAuth();
    const { ACCOUNT, ADDRESS, METHOD, PASSWORD, CONTACT } = accountNavEnum;
    const ORIGIN = config.origin;

    const [searchParams, setSearchParams] = useSearchParams();
    const [notificationStatus, setNotificationStatus] = useState(defaultNotificationStatus);
    const [shippingInfo, setShippingInfo] = useState({});
    const [userAccountInfo, setUserAccountInfo] = useState(null);
    const [userOrderInfo, setUserOrderInfo] = useState();
    const [currentPage, setCurrentPage] = useState(ACCOUNT);
    const [accountTerm, setAccountTerm] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [modalDetails, setModalDetails] = useState(null);

    // Grab data for `Print Statement` modal
    const getModalDetailsFn = async () => {
        try {
            // setModalOpen(true);
            fetch(`${ORIGIN}/cp/print`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
                },
                body: JSON.stringify({
                    accountId: currentUser.Account.Id,
                    type: 'account',
                    data: userOrderInfo,
                    accountEmail: currentUser.Owner.Email,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    setModalDetails(data.form);
                })
                .catch((err) => {
                    logError(err, 'getModalDetailsFn');
                });
        } catch (error) {
            console.error(error);
        }
    };

    // Populate Account tab
    const getOrderInfo = async () => {
        try {
            const dataToSend = {
                accountId: currentUser.Account.Id,
            };

            const orderFetch = await billingDataFetch(dataToSend, currentUser);

            if (!orderFetch || orderFetch?.error) {
                setUserOrderInfo(null);
                return;
            }

            setUserOrderInfo(orderFetch);
        } catch (error) {
            console.error(error);
        }
    };

    // Populate Contact Information tab
    const getUserInfo = async () => {
        const controller = new AbortController();
        const signal = controller.signal;

        const data = await getUserAccountInfo(currentUser, signal);
        if (data.message === 'Auth Token Not Recognized.') {
            logout();
        }
        if (data.error) {
            setNotificationStatus({
                status: 'fail',
                title: 'Failed to load',
                desc: 'There was an error loading your data. Please try again.',
                shown: false,
            });
        }
        if (!data) {
            return;
        }

        setUserAccountInfo(data);
        setAccountTerm(data?.accountData?.accountTerms);
        return () => {
            controller.abort();
        };
    };

    const updateSearchParams = (param) => {
        searchParams.set('tab', param);
        setSearchParams(searchParams);
    };

    // Fetch all data on load
    useEffect(() => {
        getOrderInfo();
        getUserInfo();
        GetShippingInfo(currentUser)
            .then((res) => {
                setShippingInfo(res);
            })
            .catch((err) => logError(err, 'SettingsTabs'));
    }, []);

    // Navigate using search parameters
    useEffect(() => {
        let currentTab = searchParams.get('tab');
        if (currentTab) {
            setCurrentPage(currentTab);
        } else {
            searchParams.set('tab', ACCOUNT);
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    return (
        <div className='lg:grid lg:grid-cols-12 lg:gap-x-2 px-4 py-8'>
            <DetailModal
                modalOpen={showModal}
                closeModal={() => {
                    setShowModal(false);
                }}
                hasBase64
                info={modalDetails}
            />
            <aside className='py-6 px-2 lg:col-span-2 lg:py-0 lg:px-0'>
                <nav className='space-y-1 sticky top-8'>
                    {navigationStart.map((item) => {
                        const { param, name } = item;
                        return (
                            <div
                                onClick={() => {
                                    updateSearchParams(param);
                                    setNotificationStatus({ ...notificationStatus, shown: false });
                                }}
                                key={name}
                                className={iconClassNames(
                                    currentPage === param
                                        ? 'bg-gray-200 text-blue-primary hover:text-blue-primary hover:bg-gray-300'
                                        : 'text-gray-900 hover:text-gray-900 hover:bg-gray-300 ',
                                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium hover:cursor-pointer ease-in-out duration-100'
                                )}
                                aria-current={currentPage === param ? 'page' : undefined}
                            >
                                <item.icon
                                    className={iconClassNames(
                                        currentPage === param
                                            ? 'text-blue-primary group-hover:text-blue-primary'
                                            : 'text-gray-400 group-hover:text-gray-500',
                                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                    )}
                                    aria-hidden='true'
                                />
                                <span className='truncate pointer-events-none'>{name}</span>
                            </div>
                        );
                    })}
                </nav>
            </aside>
            <div className='space-y-6 sm:px-6 lg:col-span-10 lg:px-0'>
                <div className='shadow overflow-hidden rounded-md bg-white pt-6 pb-12 px-4 sm:px-6 h-[100%]'>
                    {currentPage === ACCOUNT && (
                        <Account
                            userOrderInfo={userOrderInfo}
                            setUserOrderInfo={setUserOrderInfo}
                            notificationStatus={notificationStatus}
                            setNotificationStatus={setNotificationStatus}
                            accountTerm={accountTerm}
                            openModal={() => {
                                setShowModal(true);
                                getModalDetailsFn();
                            }}
                        />
                    )}
                    {currentPage === CONTACT && (
                        <ContactInformation
                            userAccountInfo={userAccountInfo}
                            setUserAccountInfo={setUserAccountInfo}
                            notificationStatus={notificationStatus}
                            setNotificationStatus={setNotificationStatus}
                        />
                    )}
                    {currentPage === ADDRESS && (
                        <ShippingAddress
                            shipInfo={shippingInfo}
                            setShippingInfo={setShippingInfo}
                            notificationStatus={notificationStatus}
                            setNotificationStatus={setNotificationStatus}
                        />
                    )}
                    {currentPage === METHOD && (
                        <ShippingMethods
                            shipInfo={shippingInfo}
                            setShippingInfo={setShippingInfo}
                            notificationStatus={notificationStatus}
                            setNotificationStatus={setNotificationStatus}
                        />
                    )}
                    {currentPage === PASSWORD && <Password />}
                    <Notification
                        showSavedNotification={notificationStatus.shown}
                        setShowSavedNotification={(bool) => {
                            setNotificationStatus({ ...notificationStatus, shown: bool });
                        }}
                        title={notificationStatus.title}
                        desc={notificationStatus.desc}
                        status={notificationStatus.status}
                    />
                </div>
            </div>
        </div>
    );
}
