import React, { useState, useEffect, useContext } from 'react';

const AuthContext = React.createContext({
    isLoggedIn: false,
    id: '',

    login: () => {},
    logout: () => {},
    signUp: () => {},
    getUser: () => {},
    updateCurrentUser: () => {},
});

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(false);
    const [loading, setLoading] = useState(true);

    function updateCurrentUser(newUser) {
        const now = new Date();

        setCurrentUser(newUser, now);
        localStorage.setItem('date', now);
        updateUserLocalStorage(newUser);
    }

    function logout() {
        setCurrentUser(false);
        localStorage.clear();
        return;
    }

    function updateUserLocalStorage(data, now, firstLogin, expirationDate) {
        localStorage.setItem('Id', data.Id);
        localStorage.setItem('accountId', data.Account.Id);
        localStorage.setItem('accountName', data.Account.Name);

        localStorage.setItem('Name', data.Name);
        now && localStorage.setItem('date', now);
        expirationDate && localStorage.setItem('expirationDate', expirationDate);
        firstLogin && localStorage.setItem('firstLogin', firstLogin);
        localStorage.setItem('Email', data.Email);
        localStorage.setItem('accountOwner', data.Owner.Name);
        localStorage.setItem('accountOwnerEmail', data.Owner.Email);
        localStorage.setItem('noQuote', data.CP_Allow_Portal_No_Quote__c);
        localStorage.setItem('customerType', data.Account.inscor__Customer_Type1__c);
    }

    function login(data, remembered, firstLogin) {
        try {
            setCurrentUser({ ...data, firstLogin: firstLogin });

            const now = new Date();
            let expirationDate;
            if (remembered) {
                expirationDate = new Date(now.getTime() + 999900000);
            } else {
                expirationDate = new Date(now.getTime() + 15770000000);
            }

            updateUserLocalStorage(data, now, firstLogin, expirationDate);
            return;
        } catch (error) {}
    }

    function signUp(email, password) {
        return;
    }

    function forgotPassword(email) {
        return;
    }

    useEffect(() => {
        setLoading(true);

        const storedId = localStorage.getItem('Id');
        const storedName = localStorage.getItem('Name');
        const storedEmail = localStorage.getItem('Email');
        const storedFirstLogin = localStorage.getItem('firstLogin');
        const storedAccountId = localStorage.getItem('accountId');
        const storedAccountName = localStorage.getItem('accountName');
        const storedAccountOwner = localStorage.getItem('accountOwner');
        const storedAccountOwnerEmail = localStorage.getItem('accountOwnerEmail');
        const storedNoQuote = localStorage.getItem('noQuote');
        const storedCustomerType = localStorage.getItem('customerType');
        const storedExperationDate = new Date(localStorage.getItem('expirationDate'));
        const now = new Date();
        if (storedId && storedExperationDate >= now) {
            setCurrentUser({
                Id: storedId,
                Name: storedName,
                firstLogin: storedFirstLogin,
                Email: storedEmail,
                allowNoQuote: storedNoQuote,
                Account: {
                    Id: storedAccountId,
                    Name: storedAccountName,
                    inscor__Customer_Type1__c: storedCustomerType,
                },
                Owner: {
                    Name: storedAccountOwner,
                    Email: storedAccountOwnerEmail,
                },
            });
        }
        setLoading(false);
    }, []);

    const contextValue = {
        currentUser,
        setCurrentUser,
        forgotPassword,
        login: login,
        logout: logout,
        signUp: signUp,
        updateCurrentUser: updateCurrentUser,
    };

    return <AuthContext.Provider value={contextValue}>{!loading && props.children}</AuthContext.Provider>;
};

export default AuthContext;
