import React, { useContext, useEffect, useState } from 'react';
import PhotosSection from './PhotosSection';
import PdfSection from './PdfSection';
import { TraceContext } from '../Context/TraceContextWrapper';
import { chatterModalView, lineLoadingState, pdfLoadingState } from '../utils/data';
import config from '../../../../utils/config';
import { DocumentContext } from '../Context/DocumentContextWrapper';

export function InventoryLineItemDataHolder({
    inventoryLine,
    handlePhotoEnlarge,
    updateInventoryLine,
    mergeSelectedPdfsAndPrint,
    printBarcode,
}) {
    const { handleIsHeroChange, updateChatterModalView, removeDataFromChatter } = useContext(TraceContext);
    const { setShowDocumentGenerationModal } = useContext(DocumentContext);

    const [selectedPdfs, setSelectedPdfs] = useState(
        inventoryLine?.pdfs?.filter((pdfData) => pdfData.status !== pdfLoadingState.NO_DATA)
    );

    const origin = config.origin;
    const addScansAndPhotosLink = `${origin}/wms/receiving?inventoryId=${inventoryLine.id}&editinventory=true`;

    const toggleImageHeroStatus = (photoName) => {
        const updatedPhotos = inventoryLine.photos?.map((photoData) => {
            if (photoData.name === photoName) {
                return {
                    ...photoData,
                    isHero: !photoData.isHero,
                };
            }

            return { ...photoData };
        });

        const updatedPhoto = updatedPhotos.find((photo) => photo.name === photoName);
        handleIsHeroChange(updatedPhoto);
        updateInventoryLine(inventoryLine.name, { ...inventoryLine, photos: updatedPhotos });
    };

    const rejectInventoryLine = () => {
        updateChatterModalView(chatterModalView.USER_INPUT);
    };

    const acceptInventoryLine = () => {
        updateInventoryLine(inventoryLine.name, { ...inventoryLine, isAccepted: true });
    };

    const clearInventoryLineAcceptance = () => {
        updateInventoryLine(inventoryLine.name, { ...inventoryLine, isAccepted: undefined });
        removeDataFromChatter(inventoryLine.name);
    };

    const updateSelectedPdfs = (checked, pdfData) => {
        let updatedPdfSelection = [...selectedPdfs];

        if (checked) updatedPdfSelection.push(pdfData);

        if (!checked) updatedPdfSelection = updatedPdfSelection.filter((pdf) => pdf.name !== pdfData.name);

        setSelectedPdfs(updatedPdfSelection);
    };

    useEffect(() => {
        if (inventoryLine.documentsAndPhotosLoaded !== lineLoadingState.LOADING && inventoryLine.shown) {
            setSelectedPdfs(inventoryLine?.pdfs?.filter((pdfData) => pdfData.status !== pdfLoadingState.NO_DATA));
        }
    }, [inventoryLine.documentsAndPhotosLoaded]);

    return (
        <section
            className={'flex w-full border-x-2 ' + (inventoryLine?.shown ? 'h-fit border-b-2 ' : 'overflow-hidden h-[0px]')}
        >
            <section className='w-full h-full overflow-visible'>
                <article className='h-fit flex flex-row w-full px-10 p-4'>
                    <article className='flex flex-col w-1/3'>
                        <h1 className='text-lg mb-3'>Line information</h1>
                        <ul className='list-disc w-full'>
                            <li>Part Name: {inventoryLine.productName ?? ''}</li>
                            <li>Serial Number: {inventoryLine.serialNumber ?? ''}</li>
                            <li>Keyword: {inventoryLine.keyword ?? ''}</li>
                            <li>Condition Code: {inventoryLine.conditionCode ?? ''}</li>
                            <li>Trace: {inventoryLine.trace ?? ''}</li>
                            <li>Trace Type: {inventoryLine.traceType ?? ''}</li>
                            <li>Tags: {inventoryLine.tags ?? ''}</li>
                            <li>Quantity Allocated: {inventoryLine.quantity ?? ''}</li>
                            <li>Source: {inventoryLine.source ?? ''}</li>
                            <li>
                                <a
                                    href={addScansAndPhotosLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='group flex flex-row items-center hover:cursor-pointer text-blue-secondary underline whitespace-nowrap duration-100 w-fit'
                                >
                                    Add Scans
                                </a>
                            </li>
                            {inventoryLine?.traceFullBinderLink ? (
                                <li>
                                    <a
                                        href={inventoryLine?.traceFullBinderLink}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='group flex flex-row items-center hover:cursor-pointer text-blue-secondary underline whitespace-nowrap duration-100 w-fit'
                                    >
                                        Owner Code Full Binder
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </article>
                    <article className='flex flex-col w-1/3'>
                        <PdfSection inventoryLine={inventoryLine} updateSelectedPdfs={updateSelectedPdfs} />
                    </article>
                    <article className='flex flex-col gap-7 items-end w-1/3'>
                        <AcceptedStatusButtons
                            inventoryLine={inventoryLine}
                            acceptInventoryLine={acceptInventoryLine}
                            rejectInventoryLine={rejectInventoryLine}
                            clearInventoryLineAcceptance={clearInventoryLineAcceptance}
                            selectedPdfs={selectedPdfs}
                        />
                        <div className='flex flex-col gap-3 items-end'>
                            {inventoryLine?.pdfs?.some((pdfData) => pdfData.link) ? (
                                <button
                                    type='button'
                                    disabled={
                                        !selectedPdfs.length ||
                                        !inventoryLine.pdfs.some(
                                            (inventoryLine) => inventoryLine.data || inventoryLine.link
                                        ) ||
                                        inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING
                                    }
                                    className='flex items-center justify-center h-fit cursor-pointer min-w-[170px] w-fit rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                                    onClick={() => mergeSelectedPdfsAndPrint(selectedPdfs)}
                                >
                                    {inventoryLine.loadingPdf ? (
                                        <div className='w-5 h-5 border-b-[2px] border-e-[2px] border-white rounded-full animate-spin out' />
                                    ) : (
                                        'Print Selected Documents'
                                    )}
                                </button>
                            ) : null}
                            <button
                                type='button'
                                disabled={inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING}
                                className='flex items-center justify-center h-fit cursor-pointer min-w-[170px] rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                                onClick={() => printBarcode()}
                            >
                                {inventoryLine.loadingBarcode ? (
                                    <div className='w-5 h-5 border-b-[2px] border-e-[2px] border-white rounded-full animate-spin out' />
                                ) : (
                                    'Print Inventory Label'
                                )}
                            </button>
                            <button
                                disabled={inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING}
                                className='flex items-center justify-center h-fit cursor-pointer min-w-[170px] rounded-lg border border-transparent bg-blue-primary px-4 py-1 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 disabled:cursor-default'
                                onClick={() => setShowDocumentGenerationModal(true)}
                            >
                                Generate Documents
                            </button>
                        </div>
                    </article>
                </article>
                <article className='max-h-[150px] relative flex flex-col justify-between w-full px-10 pb-8 overflow-y-auto'>
                    <h1 className='sticky top-0 left-0 text-lg mb-3 w-full bg-white'>Comments</h1>
                    <h1>{inventoryLine?.comments}</h1>
                </article>
                <PhotosSection
                    inventoryLine={inventoryLine}
                    handlePhotoEnlarge={handlePhotoEnlarge}
                    toggleImageHeroStatus={toggleImageHeroStatus}
                />
            </section>
        </section>
    );
}

function AcceptedStatusButtons({ inventoryLine, acceptInventoryLine, rejectInventoryLine, clearInventoryLineAcceptance }) {
    const hasHeroImages = inventoryLine?.photos?.some((photo) => photo?.isHero);
    const loading = inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING;

    if (inventoryLine.isAccepted === null || inventoryLine.isAccepted === undefined) {
        return (
            <div className='flex flex-row gap-3'>
                <button
                    disabled={inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING}
                    className='px-4 py-1 rounded-lg border border-red-700 bg-red-600 hover:bg-red-500 text-white transition-colors duration-150 focus:outline-none disabled:cursor-default'
                    onClick={() => rejectInventoryLine()}
                >
                    Reject Line
                </button>
                <div className='relative'>
                    <button
                        disabled={inventoryLine.documentsAndPhotosLoaded === lineLoadingState.LOADING || !hasHeroImages}
                        className='peer px-4 py-1 rounded-lg border border-green-700 bg-green-600 hover:bg-green-500 text-white transition-colors duration-150 focus:outline-none disabled:cursor-default'
                        onClick={() => acceptInventoryLine()}
                    >
                        Accept Line
                    </button>
                    {!hasHeroImages && !loading ? (
                        <article className='absolute flex flex-col items-end duration-75 z-10 peer-disabled:peer-hover:opacity-100 opacity-0 -top-12 -right-10 pointer-events-none'>
                            <div className=' bg-black text-white pt-1 pb-2 px-3 whitespace-nowrap rounded-md'>
                                Must have atleast one hero image selected
                            </div>
                            <svg
                                className='rotate-180 -mt-[10px] mr-14 fill-black'
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                            >
                                <path
                                    className='fill-black'
                                    d='M6 16a1 1 0 0 1-.8-1.6l6-8a1 1 0 0 1 1.6 0l6 8A1 1 0 0 1 18 16z'
                                />
                            </svg>
                        </article>
                    ) : null}
                </div>
            </div>
        );
    }

    return (
        <div className='flex flex-row gap-3'>
            <button
                className='px-4 py-1 rounded-lg border border-gray-600 bg-gray-500 hover:bg-gray-400 text-white transition-colors duration-150 focus:outline-none'
                onClick={() => clearInventoryLineAcceptance()}
            >
                Clear
            </button>
        </div>
    );
}
