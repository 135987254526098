import React, { useState, useEffect } from 'react';
import { useAuth } from '../../portal/context/auth-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import config from '../../utils/config';
import FirstTimeLogin from '../components/FirstTimeLogin';
import BulkSearch from '../components/BulkSearch';
import AvailableInventory from '../components/available-inventory/AvailableInventory';
import NoPartsFound from '../components/NoPartsFoundAlert';
import RfqSubmissionModal from '../../portal/components/dashboard/RfqSubmissionModal';
import LoadingSpinner from '../../components/LoadingSpinner';
import PdfEmbed from '../../components/PdfEmbed';
import { validatePartsSearch } from '../../utils/validators';
import LoadingIndicatorWithText from '../components/global-search/LoadingIndicatorWithText';
import WildCardSearch from '../components/wildcard-search/WildCardSearch';
import { clearIndexedDB } from '../../utils/IndexedDB';
import logError from '../../utils/errorUtil';

export default function Dashboard() {
    const { currentUser, logout } = useAuth();
    const ORIGIN = config.origin;
    const history = useNavigate();

    const [data, setData] = useState([]);
    const [serverError, setServerError] = useState(false);

    const [response, setResponse] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [wildcardIsLoading, setWildcardIsLoading] = useState(false);
    const [quoteLoading, setQuoteLoading] = useState(false);

    const [noPartsFound, setNoPartsFound] = useState(false);
    const [open, setOpen] = useState(false);
    const [pdf, sefPdf] = useState('');
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [loadingConfirmation, setLoadingConfirmation] = useState(false);
    const [results, setResults] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [noQuote, setNoQuote] = useState(false);

    const [partQueryParam, setPartQueryParam] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const bulkSearchLimit = 1000;

    useEffect(() => {
        //check the query params from quoteId
        const urlParams = new URLSearchParams(window.location.search);
        const quoteId = urlParams.get('quoteId');
        if (quoteId) {
            setQuoteLoading(true);
            fetch(`${ORIGIN}/cp/edit-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
                },
                body: JSON.stringify({
                    quoteId,
                    contactId: currentUser.Id,
                    accountId: currentUser.Account.Id,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.failure) {
                        setQuoteLoading(false);
                        setServerError(true);
                        return;
                    }
                    if (data.found.length === 0) {
                        setQuoteLoading(false);
                        setNoPartsFound(data);
                        return;
                    }
                    setResponse(data);
                    setQuoteLoading(false);
                })
                .catch((err) => logError(err, 'Dashboard'));
        }
    }, []);

    useEffect(() => {
        clearIndexedDB();
        const urlParams = new URLSearchParams(window.location.search);
        // const orderId = urlParams.get('orderConfirmation');
        const orderNumber = urlParams.get('orderName');
        if (currentUser.generateConfirmation) {
            setLoadingConfirmation(true);
            handleOrderConfirmationPdf(currentUser.generateConfirmation, orderNumber, 'order');
        }
    }, []);

    const handleOrderConfirmationPdf = (id, name, type) => {
        fetch(`${ORIGIN}/cp/print`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                type: type,
                id: id,
                name: name,
                accountEmail: currentUser.Owner.Email,
                sendOrderConfirmation: true,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                sefPdf(data.form);
                setOpenPdfModal(true);
            })

            .then(() => {
                setIsLoading(false);
            })
            .catch((err) => logError(err, 'handleOrderConfirmationPdf'))
            .finally(() => {
                setLoadingConfirmation(false);
                currentUser.generateConfirmation = null;
            });
    };

    const onSubmit = async (e, parts) => {
        e && e.preventDefault();
        setIsLoading(true);
        setServerError(false);
        setNoPartsFound(false);
        setButtonDisabled(true);

        let filteredData = validatePartsSearch(parts ?? data, bulkSearchLimit);
        if (filteredData === `You can only search up to ${bulkSearchLimit} parts at a time.`) {
            setNoPartsFound({
                title: 'Please refine your search to 1,000 part numbers.',
                notFound: [],
            });
            setIsLoading(false);
            setButtonDisabled(false);
            return;
        }

        const dataToSend = {
            parts: filteredData,
            contactId: currentUser.Id,
            accountId: currentUser.Account.Id,
        };
        currentUser.order = null;
        currentUser.quoteId = null;
        currentUser.orderDetails = null;
        currentUser.finalQuote = null;

        fetch(`${ORIGIN}/cp/parts-search `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Auth Token Not Recognized.') {
                    logout();
                }
                if (data.failure) {
                    setIsLoading(false);
                    setServerError(true);
                    setButtonDisabled(false);
                    return;
                }
                if (data.found.length === 0) {
                    setIsLoading(false);
                    setNoPartsFound(data);
                    setButtonDisabled(false);
                    return;
                }
                if (data !== 0) {
                    setNoQuote(data.noQuote);
                    setResponse(data);
                    setIsLoading(false);
                    setButtonDisabled(false);
                } else {
                    setResponse({ error: 'No data found' });
                    setIsLoading(false);
                    setButtonDisabled(false);
                }
            })

            .catch((err) => logError(err, 'onSubmit'));
    };

    const addToBulkSearch = async (results) => {
        const resultsArray = Array.isArray(results) ? results : [results];
        for (const result of resultsArray) {
            setData((prevData) => {
                const newData = prevData.length > 0 ? `${prevData}\n${result}` : result;
                return newData;
            });
        }
    };

    const removeFromBulkSearch = async (items) => {
        const itemsArray = Array.isArray(items) ? items : [items];
        for (const item of itemsArray) {
            setData((prevData) => {
                const newData = prevData
                    .split('\n')
                    .filter((part) => part !== item)
                    .join('\n');
                return newData;
            });
        }
    };

    const wildcardOnSubmit = async (data) => {
        setWildcardIsLoading(true);
        setServerError(false);
        setNoPartsFound(false);
        setButtonDisabled(true);

        const dataToSend = {
            parts: data,
        };

        currentUser.order = null;
        currentUser.quoteId = null;
        currentUser.orderDetails = null;
        currentUser.finalQuote = null;

        fetch(`${ORIGIN}/cp/parts-search/wildcard `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Auth Token Not Recognized.') {
                    logout();
                }
                if (data.failure) {
                    setWildcardIsLoading(false);
                    setServerError(true);
                    setButtonDisabled(false);
                    return;
                }
                if (data.searchLength) {
                    setWildcardIsLoading(false);
                    setNoPartsFound({
                        title: data.searchLength,
                        notFound: [],
                    });
                    setButtonDisabled(false);
                    return;
                }
                if (data.found.length === 0) {
                    setResults(data.found);
                    setWildcardIsLoading(false);
                    setButtonDisabled(false);
                    return;
                }
                if (data !== 0) {
                    setResults(data.found);
                    setWildcardIsLoading(false);
                    setButtonDisabled(false);
                } else {
                    setResponse({ error: 'No data found' });
                    setWildcardIsLoading(false);
                    setButtonDisabled(false);
                }
            })

            .catch((err) => logError(err, 'wildcardOnSubmit'));
    };

    useEffect(() => {
        if (currentUser.order) {
            setResponse({
                found: currentUser.order,
                notFound: [],
                quoteId: currentUser.quoteId,
            });
        }
    }, [currentUser.order]);

    useEffect(() => {
        //get query string
        const urlParams = new URLSearchParams(window.location.search);
        const rfqComplete = urlParams.get('nsdg');

        if (rfqComplete === 'ggtf4351001') {
            setOpen(true);
        }
    }, []);

    // Get query search params
    useEffect(() => {
        if (searchParams.get('part-search')) {
            let partSearch = searchParams.get('part-search');
            onSubmit(null, partSearch);
            setPartQueryParam(partSearch);
        } else if (partQueryParam) {
            setPartQueryParam(null);
            setNoPartsFound(null);
        }
    }, [searchParams]);

    useEffect(() => {
        if (currentUser.firstLogin === true) {
            return <FirstTimeLogin email={currentUser.email} />;
        }
    }, [currentUser]);

    if (currentUser.firstLogin === true) {
        return <FirstTimeLogin email={currentUser.email} />;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const rfqComplete = urlParams.get('nsgqwe');
    if (rfqComplete === '155638') {
        history('/');
    }

    if (quoteLoading) {
        return (
            <div className='flex flex-col items-center justify-center h-screen pb-48  animate-pulse'>
                <div className='text-2xl font-bold'>Loading Quote...</div>
            </div>
        );
    }

    if (loadingConfirmation) return <LoadingSpinner />;

    return (
        <div>
            {open && <RfqSubmissionModal open={open} setOpen={setOpen} />}
            {pdf && <PdfEmbed base64={pdf} setOpen={setOpenPdfModal} open={openPdfModal} orderConformation={true} />}
            {!response && !partQueryParam && (
                <div className='p-5 flex flex-col md:flex-row justify-center items-start md:space-x-8 max-w-screen-xl mx-auto mt-10 lg:mt-28'>
                    <div className='w-full md:w-1/2 pb-12 border-2 bg-gray-50 border-blue-primary shadow-md flex-shrink-0 mb-6 md:mb-0'>
                        <BulkSearch
                            data={data}
                            setData={setData}
                            onSubmit={onSubmit}
                            isLoading={isLoading}
                            setNoPartsFound={setNoPartsFound}
                            buttonDisabled={buttonDisabled}
                            rowAmount={12}
                            partNumberLimit={bulkSearchLimit}
                        />
                    </div>
                    <div className='w-full md:w-1/2 pb-5 border-2 bg-gray-50 border-blue-primary shadow-md flex-shrink-0'>
                        <WildCardSearch
                            data={data}
                            onSubmit={wildcardOnSubmit}
                            wildcardIsLoading={wildcardIsLoading}
                            setNoPartsFound={setNoPartsFound}
                            results={results}
                            addToBulkSearch={addToBulkSearch}
                            buttonDisabled={buttonDisabled}
                            setButtonDisabled={setButtonDisabled}
                            removeFromBulkSearch={removeFromBulkSearch}
                        />
                    </div>
                </div>
            )}
            {!response && partQueryParam && <LoadingIndicatorWithText noPartsFound={noPartsFound} />}
            {response && (
                <div className='mx-auto max-w-9xl px-4 sm:px-6 lg:px-8 mt-12 mb-24'>
                    <AvailableInventory
                        response={response}
                        setResponse={setResponse}
                        currentUser={currentUser}
                        noQuote={noQuote}
                    />
                </div>
            )}
            {noPartsFound && !partQueryParam && (
                <NoPartsFound data={noPartsFound} title={'Searched parts currently not in stock'} />
            )}
        </div>
    );
}
