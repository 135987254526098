import React from 'react';
import { formatAsCurrency } from '../../../../../utils/formatAsCurrency';

export const QuoteDescription = ({ quote }) => {
    let partNumbers = JSON.parse(quote.partNumbers);
    let uniquePartNumbers = [...new Set(partNumbers)];

    return (
        <>
            <div>
                <strong>Name</strong>
                <p>{quote.quoteNumber}</p>
            </div>
            <div>
                <strong>Quote Total</strong>
                {quote.quoteTotal === 0 ? <p>Pending Review</p> : <p>{formatAsCurrency(quote.quoteTotal)}</p>}
            </div>
            <div className='flex flex-col items-start justify-start'>
                <strong>Parts</strong>
                <div className='w-full flex flex-row flex-wrap gap-2'>
                    {uniquePartNumbers?.map((part, index) => {
                        return (
                            <a key={index} className='underline w-fit' href={`/?part-search=${part}`}>
                                {part}
                            </a>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
