import { PartDescription } from './PartDescription';
import { QuoteDescription } from './QuoteDescription';
import { OrderDescription } from './OrderDescription';

// Handles the dynamic display of item descriptions on the right hand side of the global search dropdown
export const Descriptions = ({ object }) => {
    return (
        <>
            {object.objectName === 'Quote' && <QuoteDescription quote={object} />}
            {object.objectName === 'Order' && <OrderDescription sale={object} />}
            {object.objectName === 'Part' && <PartDescription part={object} />}
        </>
    );
};
