import React, { useState, useEffect } from 'react';
import EnterEmailForgotPassword from '../components/EnterEmailForgotPassword';
import EnterVerificationCodeForgotPassword from '../components/EnterVerificationCodeForgotPassword';
import EnterNewPassWord from '../components/EnterNewPassWord';
import config from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import logError from '../../utils/errorUtil';

export default function ForgotPasswordPage() {
    const ORIGIN = config.origin;

    const { login } = useAuth();
    const history = useNavigate();

    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [hasCodeFromEmail, setHasCodeFromEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [verificationLoading, setVerificationLoading] = useState(false);
    const [stepOneError, setStepOneError] = useState('');
    const [stepTwoError, setStepTwoError] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const email = urlParams.get('email');
        if (code && email) {
            setHasCodeFromEmail(code);
            setStep(2);
            setEmail(email);
            submitVerificationCode(code, email);
        }
    }, []);

    const nextStep = () => {
        setStep(step + 1);
        console.log(step);
    };

    const submitEmail = (email) => {
        fetch(`${ORIGIN}/cp/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ emailAddress: email }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.verificationCode) {
                    nextStep();
                    setEmail(email);
                }
                if (data.none) {
                    setStepOneError(data.none);
                    setIsLoading(false);
                }
                if (data.multiple) {
                    setStepOneError(data.multiple);
                    setIsLoading(false);
                }
                if (data.failure) {
                    setStepOneError(data.failure);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setStepOneError('Something went wrong. Please try again.');
                setIsLoading(false);
                logError(err, 'submitEmail');
            });
    };

    const submitVerificationCode = (verificationCode, email) => {
        fetch(`${ORIGIN}/cp/forgot-password/verification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress: email,
                verificationCode: verificationCode,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.codeMatch === true && data.withinTimeframe === true) {
                    setStep(3);
                } else {
                    setStepTwoError('Verification code is incorrect or expired.');
                    setVerificationLoading(false);
                }
            })
            .catch((e) => {
                logError(e, 'submitVerificationCode');
            });
    };

    const submitNewPassword = async (emailAddress, password) => {
        await fetch(`${ORIGIN}/cp/forgot-password/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress: emailAddress,
                password: password,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    login(data.success, false, false);
                    nextStep();
                    history('/');
                }
            })
            .catch((e) => {
                logError(e, 'submitNewPassword');
            });
    };

    const whatToDisplay = () => {
        switch (step) {
            case 1:
                return (
                    <EnterEmailForgotPassword
                        submitEmail={submitEmail}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        stepOneError={stepOneError}
                    />
                );
            case 2:
                return (
                    <EnterVerificationCodeForgotPassword
                        submitVerificationCode={submitVerificationCode}
                        stepTwoError={stepTwoError}
                        setStepTwoError={setStepTwoError}
                        verificationLoading={verificationLoading}
                        setVerificationLoading={setVerificationLoading}
                        hasCode={hasCodeFromEmail}
                        email={email}
                    />
                );
            case 3:
                return <EnterNewPassWord submitNewPassword={submitNewPassword} email={email} />;
            default:
                return <EnterEmailForgotPassword nextStep={nextStep} />;
        }
    };

    return (
        <>
            <div className='flex items-center justify-center h-screen'>
                <div className='p-12 bg-gray-100 rounded-lg w-full md:w-1/2 xl:w-1/3  flex justify-center min-w-min'>
                    {whatToDisplay()}
                </div>
            </div>
        </>
    );
}
