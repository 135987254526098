import React, { useContext, useState } from 'react';
import { isInputValid } from './Overrides/utils/functions';
import { DocumentContext } from '../Context/DocumentContextWrapper';

export function OverridesCheckBox({ text, defaultInput = false, name, disabled = false, onChange }) {
    return (
        <div className='w-full flex flex-col justify-start items-start gap-1 h-[62px]'>
            <p>{text}</p>
            <input
                className='disabled:outline-gray-200'
                type='checkbox'
                disabled={disabled}
                checked={defaultInput}
                onChange={onChange}
                name={name}
            />
        </div>
    );
}

export function OverridesDate({ text, defaultInput = '', name }) {
    return (
        <div className='w-full flex flex-col gap-1'>
            <p>{text}</p>
            <input
                defaultValue={defaultInput}
                type='date'
                name={name}
                className='w-full border border-gray-900 rounded-md px-3 py-1 focus:border-black duration-100'
            />
        </div>
    );
}

export function OverridesDropdown({ text, selectOptions, defaultOption, name, defaultSelectedValue }) {
    return (
        <div className='w-full flex flex-col gap-1 h-[62px]'>
            <p>{text}</p>
            <select
                name={name}
                defaultValue={defaultSelectedValue}
                className='w-full px-3 py-1 rounded-md border-gray-900 focus:border-black duration-100 hover:bg-gray-100 cursor-pointer'
            >
                <option value={''}>{defaultOption}</option>
                {selectOptions.map((value) => {
                    return <option value={value}>{value}</option>;
                })}
            </select>
        </div>
    );
}

export function OverridesInput({ text, defaultInput = '', name, inputVerification = null, errorText }) {
    const { handleFormError } = useContext(DocumentContext);
    const [error, setError] = useState(false);

    const checkValidity = (value) => {
        if (!inputVerification) return;

        if (!isInputValid(value, inputVerification)) {
            setError(true);
            handleFormError(true);
            return;
        }

        setError(false);
        handleFormError(false);
    };

    return (
        <div className='w-full flex flex-col gap-1'>
            <p>{text}</p>
            <input
                defaultValue={defaultInput}
                name={name}
                onChange={(e) => checkValidity(e.target.value)}
                className={
                    'w-full px-3 py-1 rounded-md border duration-100 hover:bg-gray-100 ' +
                    (error ? 'border-red-500' : 'border-gray-900 focus:border-black')
                }
            />
            {error ? <h1 className='text-red-600'>{errorText}</h1> : null}
        </div>
    );
}

export function OverridesTextarea({ text, defaultInput = '', name }) {
    return (
        <div className='w-full flex flex-col gap-1'>
            <p>{text}</p>
            <textarea
                defaultValue={defaultInput}
                name={name}
                className='w-full border border-gray-900 rounded-md px-3 py-3 focus:border-black duration-100 resize-none h-[96px]'
            />
        </div>
    );
}

export function OverridesToggleInput({ text, defaultInput = '', defaultToggle = false, name }) {
    const [checked, setChecked] = useState(defaultToggle);

    return (
        <div className='w-full flex flex-col gap-1'>
            <div className='flex flex-row gap-3'>
                <p>{text}</p>
                <input type='checkbox' defaultChecked={defaultToggle} onChange={(e) => setChecked(e.target.checked)} />
            </div>
            <input
                defaultValue={defaultInput}
                name={name}
                disabled={!checked}
                className='w-full border border-black rounded-md px-3 py-1 focus:border-black duration-100 disabled:bg-gray-400 disabled:border-gray-500 disabled:cursor-not-allowed'
            />
        </div>
    );
}

export function OverridesDropdownTextareaPair({
    dropdownText,
    textareaText,
    textareaDefaultInput = '',
    selectOptions,
    defaultOption,
    defaultSelectedValue,
    defaultOptionValue = '',
    canEdit = false,
    name,
    addEmptyOption = false,
    emptyOptionName = '',
}) {
    const [textareaValue, setTextareaValue] = useState(textareaDefaultInput);

    const onChange = (e) => {
        setTextareaValue(e.target.value);
    };

    return (
        <>
            <div className='w-full flex flex-col gap-1'>
                <p>{dropdownText}</p>
                <select
                    name={name}
                    defaultValue={defaultSelectedValue}
                    onChange={(e) => onChange(e)}
                    className='w-full px-3 py-1 rounded-md border-gray-900 focus:border-black duration-100 hover:bg-gray-100 cursor-pointer'
                >
                    <option value={defaultOptionValue}>{defaultOption}</option>
                    {addEmptyOption ? <option value={''}>{emptyOptionName || ''}</option> : null}
                    {selectOptions.map((value) => {
                        return <option value={value}>{value}</option>;
                    })}
                </select>
            </div>
            {canEdit ? (
                <div className='w-full flex flex-col gap-1'>
                    <p>{textareaText}</p>
                    <textarea
                        defaultValue={textareaDefaultInput}
                        name={name}
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                        className='w-full border border-gray-900 rounded-md px-3 py-3 focus:border-black duration-100 resize-none h-[96px]'
                    />
                </div>
            ) : null}
        </>
    );
}

export function OverridesDataList({ text, selectOptions, name, defaultSelectedValue }) {
    const [error, setError] = useState(false);

    const checkValidity = (value) => {
        if (!selectOptions.includes(value) && value !== '') {
            setError(true);
        } else {
            setError(false);
        }
    };

    return (
        <div className='w-full flex flex-col gap-1'>
            <p>{text}</p>
            <input
                name={name}
                onChange={(e) => checkValidity(e.target.value)}
                className={
                    'w-full px-3 py-1 rounded-md border duration-100 hover:bg-gray-100 ' +
                    (error ? 'border-red-500' : 'border-gray-900 focus:border-black')
                }
                defaultValue={defaultSelectedValue}
                list='datalist'
            />
            <datalist id={'datalist'}>
                {selectOptions.map((value) => {
                    return <option value={value}>{value}</option>;
                })}
            </datalist>
            {error ? <h1 className='text-red-600'>Input must be in options provided</h1> : null}
        </div>
    );
}
