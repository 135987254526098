import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function DocModal({ text, title, setOpen, open }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto '>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 '>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6'>
                                <div className='relative'>
                                    <button
                                        className='text-white rounded-full cursor-pointer bg-blue-primary hover:bg-gray-400 w-6 h-6 flex justify-center items-center mt-1 absolute -right-3 -top-2 mr-2'
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <XMarkIcon className='w-5 h-5' />
                                    </button>
                                </div>
                                <div>
                                    <div className='mt-3 text-center sm:mt-5'>
                                        <Dialog.Title
                                            as='h3'
                                            className='text-2xl font-medium leading-6 text-gray-900 underline'
                                        >
                                            {title}
                                        </Dialog.Title>

                                        <div className='text-left px-12 my-2 py-2'>
                                            <div className='flex justify-between'>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: text,
                                                    }}
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
