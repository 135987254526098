import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { handleReceivingTraceScans, handleReceivingTracePhotos } from '../../utils/commonAPICalls';
import { useAuth } from '../context/wms-context';
import { serverStatusEnum } from './uptimeStatus/data';
import { getServerHeartbeatStatus } from './uptimeStatus/functions';
export default function LargeActionModalWorkOrder({
    status,
    closeAction,
    children,
    workOrderName,
    canClose = true,
    formData,
    setFormData,
}) {
    const { currentUser } = useAuth();
    const defaultPhotoUploadStatus = {
        error: false,
        message: null,
    };

    const [photoUploadStatus, setPhotoUploadStatus] = useState(defaultPhotoUploadStatus);
    const [isLoading, setIsLoading] = useState(false);
    const isDisabled = () => {
        const { photos, scans } = formData;
        const hasPhotos = photos?.length >= 1;
        const hasScans = scans?.length >= 1;
        if (isLoading) return true;

        if (!hasPhotos && !hasScans) {
            return true;
        }

        return false;
    };

    const serverFailMessage = 'Server unavailable, please try again in 10-60 seconds';

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                event.stopPropagation();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if (status) {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function (event) {
                window.history.go(1);
            };
        }
        return () => {
            window.onpopstate = null;
        };
    }, [status]);

    useEffect(() => {
        if (status) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [status]);

    function handleBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = 'Do you really want to leave?';
    }

    const handleSubmit = async () => {
        const onlineStatusCheck = await getServerHeartbeatStatus();

        if (onlineStatusCheck === serverStatusEnum.OFFLINE) {
            setPhotoUploadStatus({ error: true, message: serverFailMessage });
            return;
        }
        setIsLoading(true);
        setPhotoUploadStatus({ error: false, message: 'Submitting...' });

        // Persistent copy of form data
        let formDataCopyForScans = formData;
        let error = false;

        if (formData?.scans?.length) {
            const dataWithScans = { workOrderName, scans: formData.scans };
            const isSuccessfullyUploaded = await handleReceivingTraceScans(dataWithScans, currentUser, true);

            if (!isSuccessfullyUploaded) {
                setPhotoUploadStatus({ error: true, message: 'Failed uploading scans.' });
                error = true;
            }

            if (isSuccessfullyUploaded) {
                const updatedScans = formDataCopyForScans.scans.map((formScan) => {
                    return { ...formScan, uploaded: true };
                });
                formDataCopyForScans = { ...formData, scans: updatedScans };
                setFormData(formDataCopyForScans);
            }
        }

        if ((!formData?.photos || formData.photos?.length === 0) && !error) {
            setIsLoading(false);
            closeAction();
            return;
        }

        // Persistent copy of form data
        let formDataCopyForPhotos = formDataCopyForScans;

        // Upload photos one by one
        for (const photo of formDataCopyForPhotos.photos) {
            const dataWithPhoto = { workOrderName, photos: [photo] };
            const isSuccessfullyUploaded = await handleReceivingTracePhotos(dataWithPhoto, currentUser, true);

            if (!isSuccessfullyUploaded) {
                setPhotoUploadStatus({ error: true, message: 'Failed uploading photo.' });
                error = true;
            }

            if (isSuccessfullyUploaded) {
                const photoIndex = formDataCopyForPhotos.photos.findIndex((index) => index.dataUri === photo.dataUri);
                const updatedPhotos = formDataCopyForPhotos.photos.map((formPhoto, index) => {
                    if (index === photoIndex) {
                        return { ...formPhoto, uploaded: true };
                    }
                    return formPhoto;
                });
                formDataCopyForPhotos = { ...formDataCopyForPhotos, photos: updatedPhotos };

                setFormData(formDataCopyForPhotos);
            }
        }

        if (!error) {
            closeAction();
            setIsLoading(false);
        }
    };

    let modalHeightAndWidth = 'max-w-screen min-h-screen';

    return (
        <Transition.Root show={status} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={() => closeAction()}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel
                                className={`relative transform bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all  w-full ${modalHeightAndWidth} sm:p-8`}
                            >
                                <div>{children}</div>
                                <div className=' flex flex-row justify-end items-center space-x-4 p-4'>
                                    {photoUploadStatus.message ? (
                                        <p className={photoUploadStatus.error ? 'text-red-600' : 'text-black'}>
                                            {photoUploadStatus.message}
                                        </p>
                                    ) : null}
                                    {canClose && (
                                        <button
                                            type='button'
                                            className='w-24  rounded-md border border-transparent bg-gray-500 px-3 py-1.5 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                            onClick={() => closeAction()}
                                        >
                                            Close
                                        </button>
                                    )}
                                    <button
                                        type='button'
                                        disabled={isDisabled()}
                                        className='w-24 cursor-pointer rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                        onClick={() => handleSubmit()}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
