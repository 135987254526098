export function InputField({ label, type, value, onChange, index, required, readOnly }) {
    return (
        <div key={index} className='my-2 px-8 relative'>
            {required && <div className='absolute top-0 right-0 text-red-500 text-xs'>required *</div>}
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            <input
                required={required}
                disabled={readOnly}
                type={type}
                value={value || ''}
                onChange={onChange}
                className='mt-1 p-2 w-full border rounded-md disabled:bg-gray-100'
            />
        </div>
    );
}

export function TextAreaField({ label, value, onChange, index, required, readOnly }) {
    return (
        <div key={index} className='my-2 px-8 relative'>
            {required && <div className='absolute top-0 right-0 text-red-500 text-xs'>required *</div>}
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            <textarea
                required={required}
                disabled={readOnly}
                value={value || ''}
                onChange={onChange}
                className='mt-1 p-2 w-full border rounded-md'
            ></textarea>
        </div>
    );
}

export function Checkbox({ label, value, onChange, required, readOnly }) {
    return (
        <div className='flex items-start justify-center space-x-2 p-8 relative'>
            {required && <div className='absolute top-5 right-20 text-red-500 text-xs'>required *</div>}
            <label className='text-sm font-medium text-gray-700'>{label}</label>
            <input
                type='checkbox'
                disabled={readOnly}
                required={required}
                checked={value || ''}
                onChange={(e) => onChange(e.target.checked)}
                className='border rounded-md h-6 w-6 hover:border-gray-400 mb-1'
            />
        </div>
    );
}
