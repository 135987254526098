import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

/*
open/setOpen  (both required): boolean to control the modal opening and closing
    - Can also be passed in as a function, but requires the parent component to set the state to false instead of the modal
status (optional): 
    - success: green check icon
    - error: red exclamation icon
    - question: blue question mark icon
title (optional): title of the modal as large text at the top
body (optional): body of the modal as smaller text below the title in a lighter gray color
confirmText (optional): text for the confirm button (default is 'Confirm')
cancelText (optional): text for the cancel button (default is 'Cancel')
onConfirm (required): callback function to run when the confirm button is clicked

Notes:
- You don't need to have this component set as {open && <Modal ... />}, the component will handle the opening and closing itself
- Refer to the LargeActionModal.js file if you want to see a staggered body text example with line breaks
*/

export default function Modal({
    open,
    setOpen,
    status,
    title,
    body,
    confirmText,
    cancelText,
    onConfirm,
    confirmButtonStyling = null,
    cancelButtonStyling = null,
}) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog className='relative z-10' initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                                <div>
                                    {status === 'success' && (
                                        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                                            <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                                        </div>
                                    )}
                                    {status === 'error' && (
                                        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
                                            <ExclamationCircleIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                                        </div>
                                    )}
                                    {status === 'question' && (
                                        <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100'>
                                            <QuestionMarkCircleIcon className='h-6 w-6 text-blue-600' aria-hidden='true' />
                                        </div>
                                    )}
                                    <div className='mt-3 item-center sm:mt-5 text-center ml-auto'>
                                        {title && (
                                            <Dialog.Title as='h2' className='text-lg font-semibold leading-6 text-gray-900'>
                                                {title}
                                            </Dialog.Title>
                                        )}
                                        {body && (
                                            <div className='mt-2 flex justify-center'>
                                                <p className='text-md text-gray-500'>{body}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                    <button
                                        type='button'
                                        className={
                                            confirmButtonStyling ??
                                            ' inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2'
                                        }
                                        onClick={() => {
                                            setOpen(false);
                                            onConfirm();
                                        }}
                                    >
                                        {confirmText || 'Confirm'}
                                    </button>
                                    <button
                                        type='button'
                                        className={
                                            cancelButtonStyling ??
                                            'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                        }
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        {cancelText || 'Cancel'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
