import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { handleReceivingTraceScans, handleReceivingTracePhotos, updateReceiptStatus } from '../../utils/commonAPICalls';
import { useAuth } from '../context/wms-context';
import { serverStatusEnum } from './uptimeStatus/data';
import { getServerHeartbeatStatus } from './uptimeStatus/functions';
import Modal from '../../components/Modal.js';
import logError from '../../utils/errorUtil.js';

export default function LargeActionModal({
    status,
    closeAction,
    children,
    inventoryId,
    canClose = true,
    formData,
    setFormData,
    isReceiving,
    inventoryData,
}) {
    const { currentUser } = useAuth();
    const defaultPhotoUploadStatus = {
        error: false,
        message: null,
    };

    const SCAN_TYPES = {
        PHOTO: 'Photo',
        PART_RECORD: 'PartRecord',
        TAG: 'Tag',
    };

    const [modalData, setModalData] = useState({
        open: false,
        title: 'Are you sure you want to submit?',
        body: '',
        onConfirm: '',
        confirmText: 'Submit',
    });
    const [dataSubmitted, setDataSubmitted] = useState(0);

    const [photoUploadStatus, setPhotoUploadStatus] = useState(defaultPhotoUploadStatus);
    const [isLoading, setIsLoading] = useState(false);
    const isDisabled = () => {
        const { photos, scans } = formData;
        const hasScannerPhotos = scans?.some((scan) => scan?.selectedOption === SCAN_TYPES.PHOTO);
        const hasPhotos = photos?.length >= 1;
        const hasDropboxPhotos = inventoryData?.traceUrlPictures;
        if (!inventoryId) return true;
        if (isLoading) return true;
        if (isReceiving) {
            // First check scans for attached photos
            if (hasScannerPhotos) return false;
            // Receiving page must have atleast 1 photo
            if (!hasPhotos) return true;
        } else {
            if (!hasPhotos) {
                // Inventory Page from link must have photos on the inventory to upload only scans
                // Check for has photos in link
                if (!hasDropboxPhotos) return true;
                // Inventory page can be submitted with either a photo or a scan
                if (!scans.length) return true;
            }
        }

        return false;
    };

    const serverFailMessage = 'Server unavailable, please try again in 10-60 seconds';

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                event.stopPropagation();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if (status) {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function (event) {
                window.history.go(1);
            };
        }
        return () => {
            window.onpopstate = null;
        };
    }, [status]);

    useEffect(() => {
        if (status) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [status]);

    useEffect(() => {
        let timer;
        if (dataSubmitted === 1) {
            timer = setTimeout(() => {
                // Find scans and photos that have not been uploaded
                const scansNotUploaded = formData.scans.filter((scan) => !scan.uploaded);
                const photosNotUploaded = formData.photos.filter((photo) => !photo.uploaded);

                // Build the formData structure with the scans and photos that have not been uploaded
                const formDataNotUploaded = {
                    scans: scansNotUploaded,
                    photos: photosNotUploaded,
                };

                // If there are scans or photos that did not upload, retry them, otherwise close the modal
                if (scansNotUploaded?.length || photosNotUploaded?.length) {
                    setModalData({
                        open: true,
                        status: 'error',
                        title: 'Some scans or photos were not uploaded.',
                        body: 'Would you like to retry uploading them?',
                        onConfirm: () => handleSubmit(formDataNotUploaded),
                        confirmText: 'Retry',
                    });
                } else {
                    setIsLoading(false);
                    setDataSubmitted(0);
                    closeAction();
                }
            }, 6000);
        } else if (dataSubmitted > 1) {
            setPhotoUploadStatus({
                error: true,
                message: (
                    <div className='text-center'>
                        <div>Failed uploading retry scans or photos.</div>
                        <div>Please refer to the INV link above to confirm submissions, or close and retry.</div>
                    </div>
                ),
            });
            logError(
                `Error within send photos following a retry. INV: ${
                    currentUser?.receiving_inventoryLine?.Name ?? 'undefined'
                }`,
                'LargeActionModal'
            );
            setDataSubmitted(0);
        }
        if (timer) {
            return () => clearTimeout(timer);
        }
    }, [dataSubmitted]);

    function handleBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = 'Do you really want to leave?';
    }

    const preSubmitModal = () => {
        let partRecordNames = [];
        let tagNames = [];
        let photoNames = [];

        formData.scans.forEach((scan) => {
            if (scan.selectedOption === SCAN_TYPES.PART_RECORD) {
                partRecordNames.push(scan.name);
            }
            if (scan.selectedOption === SCAN_TYPES.TAG) {
                tagNames.push(scan.name);
            }
            if (scan.selectedOption === SCAN_TYPES.PHOTO) {
                photoNames.push(scan.name);
            }
        });

        const totalUploadCount = partRecordNames.length + tagNames.length + photoNames.length;

        const mapScanNames = (scanNames) => {
            return (
                <ul className='ml-3'>
                    {scanNames.map((name, index) => (
                        <li key={index}>- {name}</li>
                    ))}
                </ul>
            );
        };

        const body = (
            <div className='flex flex-col items-center'>
                <div className='text-left'>
                    <span className='font-bold underline'>{partRecordNames.length}</span> Part Record Scan
                    {partRecordNames.length != 1 ? 's' : ''}:{mapScanNames(partRecordNames)}
                    <div className='border-t border-gray-300 mt-1' />
                    <span className='font-bold underline'>{tagNames.length}</span> Tag Scan
                    {tagNames.length != 1 ? 's' : ''}:{mapScanNames(tagNames)}
                    <div className='border-t border-gray-300 mt-1' />
                    <span className='font-bold underline'>{photoNames.length}</span> Photo Scan
                    {photoNames.length != 1 ? 's' : ''}:{mapScanNames(photoNames)}
                    <div className='flex-row border-t mt-2 border-gray-300' />
                    <span className='font-bold underline text-red-500 mt-2'>{totalUploadCount}</span> Total scans
                </div>

                <div className='mt-2 flex justify-center'>Are you sure you want to submit?</div>
            </div>
        );

        setModalData({
            open: true,
            status: 'question',
            title: 'Confirm the following scan submission:',
            body: body,
            onConfirm: handleSubmit,
            confirmText: 'Submit',
        });
    };

    const handleSubmit = async (uploadData = formData) => {
        const onlineStatusCheck = await getServerHeartbeatStatus();

        if (onlineStatusCheck === serverStatusEnum.OFFLINE) {
            setPhotoUploadStatus({ error: true, message: serverFailMessage });
            return;
        }
        setIsLoading(true);
        setPhotoUploadStatus({ error: false, message: 'Submitting...' });
        let basicData = {};

        if (isReceiving) {
            basicData = {
                inventoryId: inventoryId,
                partNumber: currentUser?.selectedMaidLine?.inscor__Part_Master__rName,
                ownerCode: currentUser?.selectedMaidLine?.inscor__Owner_Code__rName,
                inventoryName: currentUser?.receiving_inventoryLine?.Name,
                otherDataYouMightNeed: {
                    receiving_inventoryLine: {
                        ...currentUser.receiving_inventoryLine,
                    },
                    manifestLine: {
                        ...currentUser.selectedMaidLine,
                    },
                    scanner: {
                        ...currentUser.selectedScanner,
                    },
                },
            };
        } else {
            basicData = {
                inventoryId: currentUser?.receiving_inventoryLine?.Id,
                partNumber: currentUser?.receiving_inventoryLine?.PartNumber,
                ownerCode: currentUser?.receiving_inventoryLine?.OwnerCode,
                inventoryName: currentUser?.receiving_inventoryLine?.Name,
            };
        }

        if (!basicData?.inventoryId) {
            setPhotoUploadStatus({ error: true, message: 'Failed getting inventory information from server.' });
            return;
        }

        // Persistant copy of form data
        let uploadDataCopyForScans = uploadData;
        let error = false;

        if (uploadData?.scans?.length) {
            const dataWithScans = { ...basicData, scans: uploadData.scans };
            const isSuccessfullyUploaded = await handleReceivingTraceScans(dataWithScans, currentUser);

            if (!isSuccessfullyUploaded) {
                setPhotoUploadStatus({ error: true, message: 'Failed uploading scans.' });
                error = true;
            }

            if (isSuccessfullyUploaded) {
                const updatedScans = uploadDataCopyForScans.scans.map((formScan) => {
                    return { ...formScan, uploaded: true };
                });
                uploadDataCopyForScans = { ...uploadData, scans: updatedScans };
                setFormData(uploadDataCopyForScans);
            }
        }

        // Note: This must happen before the photos so that the receipt status is updated even if there are no photos.
        await updateReceiptStatus(basicData.inventoryId, currentUser);

        if ((!uploadData?.photos || uploadData.photos?.length === 0) && !error) {
            setIsLoading(false);
            closeAction();
            return;
        }

        // Persistant copy of form data
        let uploadDataCopyForPhotos = uploadDataCopyForScans;

        // Upload photos one by one
        for (const photo of uploadDataCopyForPhotos.photos) {
            const dataWithPhoto = { ...basicData, photos: [photo] };
            const isSuccessfullyUploaded = await handleReceivingTracePhotos(dataWithPhoto, currentUser);

            if (!isSuccessfullyUploaded) {
                setPhotoUploadStatus({ error: true, message: 'Failed uploading photo.' });
                error = true;
            }

            if (isSuccessfullyUploaded) {
                const photoIndex = uploadDataCopyForPhotos.photos.findIndex((index) => index.dataUri === photo.dataUri);
                const updatedPhotos = uploadDataCopyForPhotos.photos.map((formPhoto, index) => {
                    if (index === photoIndex) {
                        return { ...formPhoto, uploaded: true };
                    }
                    return formPhoto;
                });
                uploadDataCopyForPhotos = { ...uploadDataCopyForPhotos, photos: updatedPhotos };

                setFormData(uploadDataCopyForPhotos);
            }
        }
        setFormData(uploadDataCopyForPhotos);
        setDataSubmitted(dataSubmitted + 1);

        const notUploadedPhotos = uploadDataCopyForPhotos.photos.filter((photo) => !photo.uploaded);
        const notUploadedScans = uploadDataCopyForPhotos.scans.filter((scan) => !scan.uploaded);

        if (!error && !notUploadedPhotos.length && !notUploadedScans.length) {
            closeAction();
            setIsLoading(false);
        }
    };

    return (
        <Transition.Root show={status} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={() => closeAction()}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel
                                className={`relative transform bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all  w-full max-w-screen min-h-screen sm:p-8`}
                            >
                                <div>{children}</div>
                                <Modal
                                    open={modalData.open}
                                    // Closes the modal after a half second
                                    setOpen={(value) => {
                                        if (!value || modalData.onConfirm) {
                                            setModalData({
                                                open: false,
                                                status: 'question',
                                                title: 'Are you sure you want to submit?',
                                                body: '',
                                                onConfirm: '',
                                                confirmText: 'Submit',
                                            });
                                        }
                                    }}
                                    status={modalData.status}
                                    title={modalData.title}
                                    body={modalData.body}
                                    confirmText={modalData.confirmText}
                                    onConfirm={modalData.onConfirm}
                                />
                                <div className=' flex flex-row justify-end items-center space-x-4 p-4'>
                                    {photoUploadStatus.message ? (
                                        <p className={photoUploadStatus.error ? 'text-red-600' : 'text-black'}>
                                            {photoUploadStatus.message}
                                        </p>
                                    ) : null}
                                    {canClose && (
                                        <button
                                            type='button'
                                            className='w-24  rounded-md border border-transparent bg-gray-500 px-3 py-1.5 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                            onClick={() => closeAction()}
                                        >
                                            Close
                                        </button>
                                    )}
                                    <button
                                        type='button'
                                        disabled={isDisabled()}
                                        className='w-24 cursor-pointer rounded-md border border-transparent bg-blue-primary px-3 py-1.5 text-md font-medium text-white shadow-sm hover:bg-gray-300 hover:text-blue-primary focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2'
                                        onClick={() => preSubmitModal()}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
