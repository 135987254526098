import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { formatAsCurrency } from '../../../../../utils/formatAsCurrency';
import { formatDate } from '../../../../../utils/formatDate';
import { capitalizeWords } from '../../../OrdersTable';
import { PrinterIcon } from '@heroicons/react/24/solid';

export const OrderTemplate = ({ sale, updateDocumentData }) => {
    const {
        customerPoNumber: poNumber,
        salesOrderNumber,
        lineTotal,
        createdDate,
        placedBy,
        status,
        accountName,
        invoiceNumber,
        shipCarrier,
        awbURL,
        airwayBillNumber,
    } = sale;

    return (
        <section className='flex flex-col lg:flex-row justify-between w-full mt-3 px-3 gap-y-4 '>
            <section className='flex flex-col gap-y-4 w-full'>
                <article className='text-xl'>
                    <strong>PO Number</strong>
                    <h1>{poNumber}</h1>
                </article>
                {accountName && (
                    <article className='text-xl'>
                        <strong>Company</strong>
                        <h1>{capitalizeWords(accountName)}</h1>
                    </article>
                )}
                <article className='text-xl'>
                    <strong>Buyer</strong>
                    <h1>{placedBy}</h1>
                </article>
                <article className='text-xl'>
                    <strong>Sales Number</strong>
                    <h1>{salesOrderNumber}</h1>
                </article>
                {invoiceNumber && (
                    <article className='text-xl'>
                        <strong>Invoice Number</strong>
                        <h1>{invoiceNumber}</h1>
                    </article>
                )}
            </section>
            <section className='flex flex-col gap-y-4 w-full'>
                <article className='text-xl'>
                    <strong>Date</strong>
                    <h1>{formatDate(createdDate)}</h1>
                </article>
                <article className='text-xl'>
                    <strong>Price</strong>
                    <h1>{formatAsCurrency(lineTotal)}</h1>
                </article>
                <article className='text-xl'>
                    <strong>Status</strong>
                    <h1>{status}</h1>
                </article>
                {shipCarrier && (
                    <article className='text-xl'>
                        <strong>Carrier</strong>
                        <h1>{shipCarrier}</h1>
                    </article>
                )}
                {awbURL && (
                    <article className='text-xl flex flex-col'>
                        <strong>Airway Bill</strong>
                        <a className='underline' href={awbURL}>
                            {airwayBillNumber}
                        </a>
                    </article>
                )}
                <article className='text-xl flex flex-col gap-y-2'>
                    {(sale.releaseNumber || sale.invoiceDocumentId || sale.traceURL) && <strong>Actions</strong>}
                    <article className='flex flex-row gap-3 items-center justify-start'>
                        {sale.releaseNumber && (
                            <button
                                className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateDocumentData({
                                        id: sale.salesOrderId,
                                        name: sale.releaseNumber,
                                        type: 'release',
                                        releaseId: sale.releaseId,
                                        docUrl: false,
                                    });
                                }}
                                style={{
                                    all: 'revert',
                                }}
                            >
                                <PrinterIcon className='h-6 w-6 inline-block text-blue-primary' />
                                <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                    View Shipping Information
                                </div>
                            </button>
                        )}
                        {sale.invoiceDocumentId && (
                            <button
                                className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateDocumentData({
                                        id: sale.invoiceId,
                                        name: sale.invoiceNumber,
                                        type: 'invoice',
                                        releaseId: sale.releaseId ? sale.releaseId : null,
                                        docUrl: false,
                                    });
                                }}
                                style={{
                                    all: 'revert',
                                }}
                            >
                                <PrinterIcon className='h-6 w-6 inline-block text-blue-primary' />
                                <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                    View Invoice
                                </div>
                            </button>
                        )}
                        {sale.traceURL && (
                            <button
                                className='group relative flex items-center justify-center w-8 h-8 border duration-100 border-solid border-blue-primary rounded-lg bg-white hover:bg-blue-primary/20 cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    updateDocumentData({
                                        id: null,
                                        name: null,
                                        type: null,
                                        releaseId: null,
                                        docUrl: sale.traceURL,
                                    });
                                }}
                                style={{
                                    all: 'revert',
                                }}
                            >
                                <DocumentTextIcon className='h-6 w-6 inline-block text-blue-primary' />
                                <div className='z-20 hidden group-hover:flex absolute !text-[#212529] -bottom-8 bg-white px-2 py-1 shadow-lg rounded-lg text-md pointer-events-none whitespace-nowrap font-sans font-semibold'>
                                    Trace
                                </div>
                            </button>
                        )}
                    </article>
                </article>
            </section>
        </section>
    );
};
