import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { loadingPartTextArray } from '../../../components/LoadingButtonFixedWidth';
import { useNavigate } from 'react-router-dom';
import NoPartsFound from '../NoPartsFoundAlert';

export default function LoadingIndicatorWithText({ staticText, className, noPartsFound, textClassName }) {
    const [loadingText, setLoadingText] = useState('Loading');
    const navigate = useNavigate();

    const redirectAfterTimer = () => {
        setTimeout(() => {
            navigate(-1);
        }, 3000);
    };

    useEffect(() => {
        if (staticText) {
            return;
        }
        if (noPartsFound) {
            setLoadingText('Redirecting');
            redirectAfterTimer();
        }
        let i = 0;
        let interval = setInterval(
            () => {
                if (i === loadingPartTextArray.length) {
                    return () => clearInterval(interval);
                }
                setLoadingText(loadingPartTextArray[i]);
                i++;
            },
            noPartsFound ? 3000 : 5000
        );

        return () => clearInterval(interval);
    }, [noPartsFound]);

    return (
        <section className={className}>
            {!noPartsFound && <LoadingSpinner className={'mt-0'} />}
            <h1 className={`text-xl w-full text-center mt-6 ${textClassName} ${noPartsFound && ' mb-10 '} `}>
                {staticText ?? loadingText}
            </h1>
            {noPartsFound && <NoPartsFound data={noPartsFound} title={'Searched parts currently not in stock'} />}
        </section>
    );
}

LoadingIndicatorWithText.defaultProps = {
    staticText: null,
    className: 'absolute flex flex-col w-screen h-[calc(100vh-64px)] items-center justify-center',
};
