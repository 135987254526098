import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import OrdersTable from '../OrdersTable';
import config from '../../../utils/config';
import { useAuth } from '../../../portal/context/auth-context';
import SearchBox from '../search-pagionation/SearchBox';
import DateInputs from '../search-pagionation/DateInputs';
import Pagination from '../search-pagionation/Pagination';
import { getSalesOrderFilter } from '../../../utils/commonAPICalls';
import PdfEmbed from '../../../components/PdfEmbed';
import IframeEmbed from '../IframeEmbed';
import logError from '../../../utils/errorUtil';

export default function OrderSection() {
    const [isLoading, setIsLoading] = useState(true);
    const ORIGIN = config.origin;
    const { currentUser, logout } = useAuth();
    const [data, setData] = useState([]);

    const [gettingNextPage, setGettingNextPage] = useState(false);
    const [pageOffset, setPageOffset] = useState(0);
    const [hasSearched, setHasSearched] = useState(false);

    const [loadingDocument, setLoadingDocument] = useState('');
    const [pdf, sefPdf] = useState('');
    const [open, setOpen] = useState(false);

    const [openIframe, setOpenIframe] = useState(false);
    const [traceLinkUrl, setTraceLinkUrl] = useState(null);

    const [sortBy, setSortBy] = useState('');
    const [orderBy, setOrderBy] = useState('');

    const fromDateRef = useRef();
    const toDateRef = useRef();
    const searchRef = useRef();
    const [searchKey, setSearchKey] = useState('');
    const [arrowDirections, setArrowDirections] = useState({
        customerPoNumber: {
            direction: 'null',
        },
        placedBy: {
            direction: 'null',
        },
        createdDate: {
            direction: 'null',
        },
        salesOrderNumber: {
            direction: 'null',
        },
        dateShipped: {
            direction: 'null',
        },
        accountName: {
            direction: 'null',
        },
        invoiceNumber: {
            direction: 'null',
        },
    });

    const fetchdata = (refetch = false, sortField = null, sortOrder = null) => {
        if (refetch) {
            setGettingNextPage(true);
            fromDateRef.current.value = '';
            toDateRef.current.value = '';
            searchRef.current.value = '';
            setHasSearched(false);

            setArrowDirections({
                // set all other directions to null
                ...Object.keys(arrowDirections).reduce((acc, key) => {
                    acc[key] = { direction: null };
                    return acc;
                }, {}),
            });
        }

        const now = new Date();
        const offset = now.getTimezoneOffset();

        fetch(`${ORIGIN}/cp/hx/orders `, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                timezoneDifferenceInMinutes: offset,
                accountId: currentUser.Account.Id,
                userId: currentUser.Id,
                sortBy: sortField,
                orderBy: sortOrder,
                limit: 100,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Auth Token Not Recognized.') {
                    logout();
                }
                setData(data);
                setIsLoading(false);
                setGettingNextPage(false);
            })
            .catch((err) => logError(err, 'fetchdata'));
    };

    const handleDocumentClick = (id, name, type, releaseId) => {
        fetch(`${ORIGIN}/cp/print`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${currentUser.Id}${currentUser.Email}`,
            },
            body: JSON.stringify({
                accountId: currentUser.Account.Id,
                type: type,
                id: id,
                name: name,
                accountEmail: currentUser.Owner.Email,
                releaseId: releaseId,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                sefPdf(data.form);
                setOpen(true);
                setLoadingDocument('');
                searchRef.current.value = searchKey;
            })
            .catch((err) => logError(err, 'handleDocumentClick'));
    };

    useEffect(() => {
        const abortController = new AbortController();
        fetchdata();

        return () => {
            setIsLoading(false);
            abortController.abort();
        };
    }, []);
    const handleNextPage = () => {
        setPageOffset((prev) => prev + 1, null, sortBy, orderBy);
        handleFilterSubmit(pageOffset + 1, null, sortBy, orderBy);
    };

    const handlePrevPage = () => {
        setPageOffset((prev) => prev - 1, null, sortBy, orderBy);
        handleFilterSubmit(pageOffset - 1, null, sortBy, orderBy);
    };

    const handleFilterSubmit = async (offset, reset, sortBy, orderBy) => {
        setSortBy(sortBy);
        setOrderBy(orderBy);
        setGettingNextPage(true);
        if (reset) {
            setPageOffset(0);
        }
        if (!sortBy) {
            setArrowDirections({
                // set all other directions to null
                ...Object.keys(arrowDirections).reduce((acc, key) => {
                    acc[key] = { direction: null };
                    return acc;
                }, {}),
            });
        }
        let isoFromDate = '';
        let isoToDate = '';
        const fromDate = fromDateRef.current?.value;
        const toDate = toDateRef.current?.value;
        const search = searchRef.current?.value;
        setSearchKey(search);

        if (fromDate !== '') {
            isoFromDate = new Date(fromDate).toISOString();
        }
        if (toDate !== '') {
            isoToDate = new Date(toDate).toISOString();
        }

        const dataToSend = {
            accountId: currentUser.Account.Id,
            userId: currentUser.Id,
            fromDate: isoFromDate,
            toDate: isoToDate,
            searchTerm: search,
            pageOffset: offset,
            sortBy: sortBy,
            orderBy: orderBy,
        };

        const filteredData = await getSalesOrderFilter(currentUser, dataToSend);
        setHasSearched(true);
        setData(filteredData);
        setGettingNextPage(false);
    };

    const openIframeModal = (url) => {
        setOpenIframe(true);
        setTraceLinkUrl(url);
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (loadingDocument) {
        return (
            <div className='flex flex-col items-center justify-center h-[550px] pt-64 animate-pulse'>
                <div className='text-2xl font-bold'>Preparing Document...</div>
            </div>
        );
    }
    return (
        <>
            {pdf && <PdfEmbed base64={pdf} setOpen={setOpen} open={open} />}
            {traceLinkUrl && <IframeEmbed setOpen={setOpenIframe} open={openIframe} url={traceLinkUrl} />}
            <div className='flex flex-col md:flex-row justify-end items-center space-x-4 mr-10 mt-4'>
                {(hasSearched && fromDateRef.current?.value !== '') ||
                (hasSearched && toDateRef.current?.value !== '') ||
                (hasSearched && searchRef.current?.value !== '') ? (
                    <div className='border rounded shadow px-4 py-2 text-sm'>
                        {searchRef.current?.value !== '' ? (
                            <p>
                                Filtered by: <strong> {searchRef.current?.value}</strong>
                            </p>
                        ) : null}
                        {(hasSearched && fromDateRef.current?.value !== '') ||
                        (hasSearched && toDateRef.current?.value !== '') ? (
                            <>
                                <p className='underline'>
                                    {fromDateRef.current?.value} thru{' '}
                                    {toDateRef.current?.value ? toDateRef.current?.value : 'Present'}
                                </p>
                            </>
                        ) : null}
                    </div>
                ) : null}

                {(hasSearched && searchRef.current?.value !== '') ||
                (hasSearched && fromDateRef.current?.value !== '') ||
                (hasSearched && toDateRef.current?.value !== '') ? (
                    <button
                        onClick={() => {
                            fetchdata(true);
                        }}
                        type='submit'
                        className='inline-flex items-center mt-0.5 px-4 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-blue-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        Clear Filters
                    </button>
                ) : null}

                <DateInputs fromDateRef={fromDateRef} toDateRef={toDateRef} />
                <SearchBox
                    searchRef={searchRef}
                    placeholder={'Search by PO, Buyer, SO, AWB, Part Number or Ship Ref#'}
                    width={'110'}
                    submitFn={() => {
                        handleFilterSubmit(0, 'reset');
                    }}
                />
                <div>
                    <button
                        onClick={() => {
                            handleFilterSubmit(0, 'reset');
                        }}
                        type='button'
                        className='inline-flex items-center mt-5 md:mt-0.5 px-4 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-primary hover:bg-blue-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                        Search
                    </button>
                </div>
            </div>
            <OrdersTable
                data={data}
                gettingNextPage={gettingNextPage}
                handleDocumentClick={handleDocumentClick}
                setLoadingDocument={setLoadingDocument}
                openIframeModal={openIframeModal}
                handleFilterSubmit={handleFilterSubmit}
                arrowDirections={arrowDirections}
                setArrowDirections={setArrowDirections}
            />
            <Pagination
                pageOffset={pageOffset}
                nextPage={handleNextPage}
                prevPage={handlePrevPage}
                quoteData={data}
                gettingNextPage={gettingNextPage}
            />
        </>
    );
}
